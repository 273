import React, { Fragment, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../../Layout/footer/Footer";
import Sidebar from "../../Layout/sidebar/Sidebar";
import { toastSuccess, toastError } from "../../common/Toast";
import { ToastContainer } from "react-toastify";
import config from "../../config/config";
// import { CKEditor } from 'ckeditor4-react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import MDEditor from "@mdfe/ckeditor5-custom-build";
import translations from "@mdfe/ckeditor5-custom-build/build/translations/en";
import CustomLoader from "../../Layout/CustomLoader";
// import "select2";
import Select from "react-select";

const AddUsers = (props) => {
  let nav = useNavigate();
  const fixdata = "";
  const paramObject = useParams();
  const [showhide, setShowhide] = useState("");
  const [pagename, setPageName] = useState("");
  const [type, setPageType] = useState("");
  const [user, setUser] = useState("");
  const [usertype, setUsertype] = useState("");
  const [useroption, setUserOption] = useState("");
  const [userQuestion, setUserQuestion] = useState("");
  const [userQuestionType, setUserQuestionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dropdownitems, setDropDownItems] = useState([]);
  const [optionid, setOptionID] = useState([]);
  const [questiondropdownitems, setQuestionDropDownItems] = useState([]);
  const [optiondropdownitems, setOptionDropDownItems] = useState([]);
  const [QueCategorydropdownitems, setOptionCategoryDropDownItems] = useState(
    []
  );
  const [usertypedropdownitems, setUserTypeDropDownItems] = useState([]);
  const [formValues, setFormValues] = useState({
    seNotificationBy: "",
    selectUserType: "",
    selectUser: "",
    selectQCategoryType: "",
    selectQuestionType: "",
    title: "",
    msg: "",
  });
  const [editordata, setEditorData] = useState("");
  const [notificationtitle, setNotificationTitle] = useState("");
  const [notificationmessage, setNotificationMessage] = useState("");
  const [notificationby, setNotificationBy] = useState("");
  // const [usertypes, setUserTypes] = useState("");
  //const [addqustions, setAddQustions] = useState("");

  const validationSchema = Yup.object().shape({
    NotificationBy: Yup.string().required("Please select notificationby"),
  });
  let navigate = useNavigate();

  const handleSubmit = (values) => {
    let qoptionid = "";
    let notification = "";
    console.log(values.NotificationBy);
    if (values.NotificationBy === "2") {
      notification = "User";
    } else if (values.NotificationBy === "3") {
      notification = "UserType";
    } else if (values.NotificationBy === "4") {
      notification = "Question";
    } else {
      notification = values.NotificationBy;
    }
    if (optionid.length > 0) {
      qoptionid = optionid;
    } else {
      qoptionid = "";
    }

    //console.log(type);
    setLoading(true);

    axios
      .post(
        `${config.baseUrl}/customnotificationemail/saveandsendnotificationandemail`,
        {
          type: type,
          notificationby: notification, //values.NotificationBy,
          usertypeid: values.selectUserType,
          userid: user, // values.selectUser,
          questioncategoryid: values.selectQCategoryType,
          questionid: values.selectQuestionType,
          questionoptionids: qoptionid != "" ? qoptionid.join(",") : "",
          title: values.title,
          message: type == "Notification" ? values.msg : editordata,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.data.ResponseCode === "SUCCESS") {
          //&& res.data.ResponseID > 0
          //setPageType("Notification");
          if (type == "Notification") {
            toastSuccess(res.data.ResponseMessage);
            // navigate("/custom-notification-list");
          } else {
            toastSuccess(res.data.ResponseMessage);
            // navigate("/custom-email-list");
          }
        } else {
          console.log(res.data.ResponseMessage);
          toastError("Somthing went wrong" + res.data.ResponseMessage);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log("error occured", error);

        setLoading(false);
      });
  };

  const handleNotifictionby = (e) => {
    let id = e.target.value;
    if (id == 2) {
      handleUserDropdownApi();
    } else if (id == 3) {
      handleUserTypeDropdownApi();
    } else if (id == 4) {
      handleQueCategoryDropdownApi();
    }
    //const { ID, Name, ShortCode } = responseData;
    //  const seNotificationBy = setFormValues(seNotificationBy);
    const title = "",
      seNotificationBy = "",
      selectUserType = "",
      selectUser = "",
      selectQCategoryType = "",
      selectQuestionType = "",
      msg = "";
    setFormValues({
      seNotificationBy,
      selectUserType,
      selectUser,
      selectQCategoryType,
      selectQuestionType,
      title,
      msg,
    });

    // setFormValues({ seNotificationBy: '', selectUserType: '', selectUser: '', selectQCategoryType: '', selectQuestionType: '', title: '', msg: '' });
    setNotificationBy(id);
    // setUserTypes(id);
    //setAddQustions(id);
    setShowhide(id);
    setUserOption(0);
    setUserQuestion(0);
    setUsertype(0);
    setUser(0);
  };
  const handleTypeChange = (e) => {
    let id = e.target.value;

    let selectType = "",
      NotificationBy = "",
      selectUserType = "",
      selectUser = "",
      selectQCategoryType = "",
      selectQuestionType = "";
    const title = "";
    const msg = "";

    setFormValues({
      selectType,
      NotificationBy,
      selectUserType,
      selectUser,
      selectQCategoryType,
      selectQuestionType,
      title,
      msg,
    });
    setShowhide(0);
    setUserOption(0);
    setUserQuestion(0);
    setUsertype(0);
  };

  const checkboxhandleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      // push selected value in list
      setOptionID((prev) => [...prev, value]);
    } else {
      // remove unchecked value from the list
      setOptionID((prev) => prev.filter((x) => x !== value));
    }
  };

  const handleQuestionChangeType = (e) => {
    setUserQuestion(0);
    // setShowhide(0);
    setUserOption(0);
    setUsertype(e);
    setUser(0);

    let id = e.target.value;
    handleQuestionDropdownApi(id);
    if (id != "All" && id != "") {
      setUserOption(id);
    } else {
      setUserOption(0);
    }
  };

  const handleUserTypeDropdownApi = () => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(`${config.baseUrl}/users/allusertype`)
        .then((response) => {
          setUserTypeDropDownItems(response.data.ResponseData);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const handleUserDropdownApi = () => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(`${config.baseUrl}/users/fordropdown`)
        .then((response) => {
          let dropdownItemOptions = [];

          response.data.ResponseData.map((option) => {
            dropdownItemOptions = [
              ...dropdownItemOptions,
              { label: option.FullName, value: option.ID },
            ];
          });

          console.log(dropdownItemOptions);
          setDropDownItems(dropdownItemOptions);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const handleQuestionDropdownApi = (id) => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(`${config.baseUrl}/questions/findAllQuestionusertype?Id=${id}`)
        .then((response) => {
          console.log(response.data.ResponseData);
          setQuestionDropDownItems(response.data.ResponseData);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const handleQueCategoryDropdownApi = () => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(`${config.baseUrl}/questions/findAllCategory`)
        .then((response) => {
          setOptionCategoryDropDownItems(response.data.ResponseData);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const handleOptionDropdownApi = (id) => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(`${config.baseUrl}/questions/findbyQuestionid?QuestionID=${id}`)
        .then((response) => {
          setOptionDropDownItems(response.data.ResponseData);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const handleChangeQuestion = (e) => {
    const getQuestion = e.target.value;
    console.log(getQuestion);
    setUserQuestion(getQuestion);
    //setUserQuestion(0);
    if (getQuestion != "All") {
      handleOptionDropdownApi(getQuestion);
    }
  };

  useEffect(() => {
    axios
      .get(`${config.baseUrl}/emailtemplate/findbyid?Id=${26}`)
      .then((response) => {
        const responseData = response.data.ResponseData[0];
        // console.log(responseData);
        // console.log(responseData.BodyHTML);
        setEditorData(responseData.BodyHTML);
        // setEditorData(BodyHTML);
        // console.log("BodyHtml => ", BodyHTML);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setLoading(false);
      });

    if (paramObject.type === "Notification") {
      setPageType("Notification");
      setPageName("/custom-notification-list");
    } else {
      setPageType("Email");
      setPageName("/custom-email-list");
    }

    setUsertype("0");
    setUserOption("0");
    setUserQuestion("0");
  }, []);

  const [selecteduserValue, setSelecteduserValue] = useState(0);
  useEffect(() => {
    handleUserDropdownApi();

    if (paramObject.id > 0) {
      let selectUser = paramObject.id;
      setFormValues({ selectUser });
    }
  }, []);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      height: "40px",
      position: "relative",
      background: "#343a40",
      color: "white",
      font: "white",
    }), // dropdown input css
    menu: () => ({
      boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
      position: "absolute",
      background: "#343a40",
      width: "100%",
      color: "white",
      zIndex: 99,
    }), // dropdown menu css
  };

  return (
    <Fragment>
      <Sidebar />
      <ToastContainer />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Send Custom {type}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Add User Type</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header text-right">
                    <h3 className="card-title">Add</h3>
                  </div>
                  <Formik
                    {...props}
                    validationSchema={validationSchema}
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                    enableReinitialize
                  >
                    {({ errors, touched, handleChange }) => (
                      <Form>
                        <div className="card-body">
                          <div
                            id="example1_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Notification By</label>
                                  <Field
                                    as="select"
                                    className="form-control"
                                    id="NotificationBy"
                                    name="NotificationBy"
                                    value={notificationby}
                                    onInput={(e) => {
                                      handleNotifictionby(e);
                                    }}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setUser("");
                                      setUsertype("");
                                      setUserQuestionType("");
                                      setUserQuestion("");
                                      setUserOption("");
                                      setNotificationTitle("");
                                      setNotificationMessage("");
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="All">All</option>
                                    <option value="2">User</option>
                                    <option value="3">UserType</option>
                                    <option value="4">Question</option>
                                  </Field>
                                  <span className="text-danger">
                                    {touched.NotificationBy &&
                                    errors.NotificationBy
                                      ? errors.NotificationBy
                                      : ""}
                                  </span>
                                </div>
                              </div>

                              {/* Notification by user */}

                              <div
                                className={
                                  showhide === "2"
                                    ? "form-group col-md-4"
                                    : "form-group d-none"
                                }
                              >
                                <div className="form-group">
                                  <label>User</label>
                                  <Select
                                    id="selectUser"
                                    name="selectUser"
                                    backspaceRemovesValue={false}
                                    controlShouldRenderValue={true}
                                    hideSelectedOptions={true}
                                    isClearable={true}
                                    options={dropdownitems}
                                    placeholder="Please select a User"
                                    styles={selectStyles}
                                    value={selecteduserValue}
                                    onChange={(e) => {
                                      // handleChange(e);
                                      setUser(e.value);
                                      setSelecteduserValue(e);
                                    }}
                                  >
                                    {/* <option value="">Select User</option>
                                    <option value="All">All</option>
                                    {/* {dropdownitems.map((u) => (
                                      <option value={u.ID}>{u.FullName}</option>
                                    ))} */}
                                  </Select>
                                  <span className="text-danger">
                                    {touched.selectUser && errors.selectUser
                                      ? errors.selectUser
                                      : ""}
                                  </span>
                                </div>
                              </div>
                              <div
                                className={
                                  showhide === "3"
                                    ? "form-group col-md-4"
                                    : "form-group d-none"
                                }
                              >
                                <label>User Type</label>
                                <Field
                                  as="select"
                                  value={usertype}
                                  className="form-control"
                                  id="selectUserType"
                                  name="selectUserType"
                                  onChange={(e) => {
                                    handleChange(e);
                                    setUsertype(e.target.value);
                                  }}
                                >
                                  <option value="">Select Type</option>
                                  <option value="All">All</option>
                                  {usertypedropdownitems.map((u) => (
                                    <option value={u.ID}>{u.UserType}</option>
                                  ))}
                                </Field>
                                <span className="text-danger">
                                  {touched.selectUserType &&
                                  errors.selectUserType
                                    ? errors.selectUserType
                                    : ""}
                                </span>
                              </div>

                              <div
                                className={
                                  showhide === "4"
                                    ? "form-group col-md-4"
                                    : "form-group d-none"
                                }
                              >
                                <label>User Type</label>
                                <Field
                                  as="select"
                                  className="form-control"
                                  id="selectQCategoryType"
                                  name="selectQCategoryType"
                                  value={userQuestionType}
                                  onInput={(e) => {
                                    handleQuestionChangeType(e);
                                    setUserQuestionType(e.target.value);
                                  }}
                                  onChange={handleChange}
                                >
                                  <option value="">Select Type</option>
                                  <option value="All">All</option>
                                  {QueCategorydropdownitems.map((u) => (
                                    <option value={u.Id}>{u.Name}</option>
                                  ))}
                                </Field>
                                <span className="text-danger">
                                  {touched.selectQCategoryType &&
                                  errors.selectQCategoryType
                                    ? errors.selectQCategoryType
                                    : ""}
                                </span>
                              </div>

                              <div
                                className={
                                  useroption > 0
                                    ? "form-group col-md-4"
                                    : "form-group d-none"
                                }
                              >
                                {/* <div className="row"> */}
                                {/* <div className="col-md-4">
                                                                        <div className="form-group"> */}
                                <label>Add Question</label>
                                <Field
                                  as="select"
                                  className="form-control"
                                  id="selectQuestionType"
                                  name="selectQuestionType"
                                  value={userQuestion}
                                  onInput={(e) => {
                                    handleChangeQuestion(e);
                                  }}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  {/* <option value="All">All</option> */}
                                  {questiondropdownitems.map((u) => (
                                    <option value={u.Id}>{u.Question}</option>
                                  ))}
                                </Field>
                                {/* </div>
                                                                    </div> */}
                                {/* </div> */}
                              </div>

                              <div
                                className={
                                  userQuestion > 0
                                    ? "form-group"
                                    : "form-group d-none"
                                }
                              >
                                <div className="col-md-4">
                                  <div id="checkbox-group">
                                    {optiondropdownitems.map((x, i) => (
                                      <div key={i}>
                                        <input
                                          type="checkbox"
                                          name="optionid"
                                          value={x.ID}
                                          onChange={checkboxhandleChange}
                                        />{" "}
                                        {x.QuestionOption}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>

                              {/* Notification by all */}
                              <div
                                className={
                                  showhide === "All" ||
                                  user === "All" ||
                                  user > 0 ||
                                  usertype === "All" ||
                                  usertype > 0 ||
                                  userQuestionType === "All" ||
                                  userQuestion > 0
                                    ? "form-group"
                                    : "form-group d-none"
                                }
                              >
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label htmlFor="title">Title</label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        name="title"
                                        value={notificationtitle}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setNotificationTitle(e.target.value);
                                        }}
                                      />
                                      <span className="text-danger">
                                        {touched.title && errors.title
                                          ? errors.title
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div
                                      className={
                                        type === "Notification"
                                          ? "form-group"
                                          : "form-group d-none"
                                      }
                                    >
                                      <label>Message</label>
                                      <Field
                                        type="textarea"
                                        className="form-control"
                                        rows="3"
                                        placeholder="Enter ..."
                                        id="msg"
                                        name="msg"
                                        value={notificationmessage}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setNotificationMessage(
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      type === "Email"
                                        ? "form-group"
                                        : "form-group d-none"
                                    }
                                  >
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Body</label>
                                        {/* <CKEditor /> */}
                                        <CKEditor
                                          id="BodyHTML"
                                          name="BodyHTML"
                                          editor={MDEditor}
                                          data={editordata}
                                          config={{
                                            language: { ui: "en" },
                                            toolbar: {
                                              items: [
                                                "undo",
                                                "redo",
                                                "removeFormat",
                                                "|",
                                                "sourceEditing",
                                                "|",
                                                "fontFamily",
                                                "fontSize",
                                                "fontColor",
                                                "highlight",
                                                "|",
                                                "bold",
                                                "italic",
                                                "underline",
                                                "|",
                                                "bulletedList",
                                                "numberedList",
                                                "todoList",
                                                "|",
                                                "alignment",
                                                "indent",
                                                "outdent",
                                                "|",
                                                "blockQuote",
                                                "link",
                                                "code",
                                                "imageUpload",
                                                "mediaEmbed",
                                                "insertTable",
                                              ],
                                            },
                                            fontFamily: {
                                              options: [
                                                "default",
                                                "Arial, Helvetica, sans-serif",
                                                "Courier New, Courier, monospace",
                                                "Georgia, serif",
                                                "Lucida Sans Unicode, Lucida Grande, sans-serif",
                                                "Tahoma, Geneva, sans-serif",
                                                "Times New Roman, Times, serif",
                                                "Trebuchet MS, Helvetica, sans-serif",
                                                "Verdana, Geneva, sans-serif",
                                              ],
                                            },
                                            htmlSupport: {
                                              allow: [{ styles: true }],
                                            },
                                            // language: 'en'
                                          }}
                                          onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                            // console.log('Editor is ready to use!', editor);
                                          }}
                                          onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setEditorData(data);
                                            console.log({
                                              event,
                                              editor,
                                              data,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Notification by user type */}

                              {/* Notification by question */}

                              {/* Question Usertype All */}
                              {/* <div className={useroption === "01" ? "form-group" : "form-group d-none"}>
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <div className='form-group'>
                                                                            <label>Title</label>
                                                                            <input type="text" className="form-control" id="title" name="title" />
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-6'>
                                                                        <div className="form-group">
                                                                            <label>Meassage</label>
                                                                            <textarea className="form-control" rows="3" placeholder="Enter ..."></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}

                              {/* Question Usertype Others */}

                              {/* )} */}
                              {/* display option after selecting question  */}

                              <div className="row">
                                <div className="col-md-12">
                                  <button
                                    type="submit"
                                    className="btn me-2 btn-primary"
                                  >
                                    Save
                                  </button>
                                  <Link
                                    to={pagename}
                                    className="btn btn-secondary"
                                  >
                                    Cancel
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
};

export default AddUsers;
