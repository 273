import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Layout/footer/Footer";
import Sidebar from "../../Layout/sidebar/Sidebar";
import config from "../../config/config";
import { ToastContainer } from "react-toastify";
import { toastSuccess, toastError } from "../../common/Toast";
import { Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { useEffect } from "react";
import CustomLoader from "../../Layout/CustomLoader";
// import "select2";
import Select from "react-select";

const AllCampaignList = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [perPageSize, setperPageSize] = useState(10);
  const [closepopup, setClosePopup] = useState({ visible: null });
  const [searchtext, setSearchText] = useState("");
  const [totalrecord, setTotalRecord] = useState(0);
  const [artistdropdownitems, setArtistDropDownItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [subscription, setSubscription] = useState(0);
  const [toolkitid, settoolkitid] = useState("");
  const [isClose, setIsClose] = useState(0);
  const [isstationary, setIsstationary] = useState(0);
  const [issurgeplay, setIsSurgePlay] = useState(0);
  const [isactive, setIsActive] = useState(0);
  const [toolkits, setToolkits] = useState([]);
  const [statee, setSatete] = useState({ visible: null });
  const [stateefackecount, setSateteFakecount] = useState({ visible: null });
  const [user, setUser] = useState("");
  const [validreason, setValidReason] = useState(true);
  const [reason, setReason] = useState("");
  const [fakecount, setFakecount] = useState("");
  const [statuspopup, setStatusPopup] = useState({ visible: null });
  const [campaignstatus, setCampaignStatus] = useState("");
  const [reasonstatee, setreasonSatete] = useState(false);
  const [questionsatete, setQuestionSatete] = useState({ visible: null });
  const [remountComponent, setRemountComponent] = useState(0);
  const [questionList, setQuestionList] = useState([]);
  const [questionOptionList, setQuestionOptionList] = useState([]);

  // let nav = useNavigate();
  let userid = localStorage.getItem("ID");

  const handleApi = (
    pagenumber,
    pagesize,
    filtertext,
    ordercolumn,
    currentorder,
    userId,
    isstationary,
    isClose,
    toolkitid,
    issubscription,
    issurgeplay,
    isactive
  ) => {
    setLoading(true);
    axios
      .get(
        `${config.baseUrl}/campaign?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&userid=${userId}&isstationary=${isstationary}&isclose=${isClose}&toolkitid=${toolkitid}&issubscription=${issubscription}&issurgeplay=${issurgeplay}&isactive=${isactive}`
      )
      .then((response) => {
        setItems(response.data.ResponseData);

        setTotalRecord(response.data.ResponseData[0]?.TotalRecord);
        setpageCount(
          Math.ceil(response.data.ResponseData[0]?.TotalRecord / pagesize)
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setLoading(false);
      });
  };

  const handleApproveRejectModelpopup = (id, campaignStatus) => {
    localStorage.setItem("CampagionID", id);
    localStorage.setItem("CampaignStatus", campaignStatus);

    setCampaignStatus(campaignStatus);
    if (campaignStatus == "Reject") {
      setreasonSatete(true);
    }
    setStatusPopup({ visible: id });
  };

  const handleCloseApproveRejectStatuspopup = () => {
    setStatusPopup({ visible: null });
    setreasonSatete(false);
    localStorage.setItem("CampagionID", 0);
    localStorage.setItem("CampaignStatus", "");
  };

  const handleChangeReason = (e) => {
    if (e.target.value != "") {
      setValidReason(true);
    } else {
      setValidReason(false);
    }
    setReason(e.target.value);
  };

  const handleApproveRejectCampagion = () => {
    let id = localStorage.getItem("CampagionID");

    let campaignStatus = localStorage.getItem("CampaignStatus");
    let statusid = 1;
    if (campaignStatus === "Reject") {
      if (document.getElementById("Reason").value === "") {
        setValidReason(false);

        return false;
      }

      statusid = 2;
    }

    setStatusPopup({ visible: null });
    if (id > 0) {
      setLoading(true);
      axios
        .post(
          `${config.baseUrl}/campaign/approveorrejectcampaign`,
          {
            campaignid: id,
            status: statusid,
            reason: reason,
            loginuserid: userid,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
            toastSuccess("Record has been updated!");

            handleApi(
              //1,
              pageIndex > 0 ? pageIndex : 1,
              perPageSize,
              searchtext,
              0,
              "asc",
              user,
              isstationary,
              isClose,
              toolkitid,
              subscription,
              issurgeplay
            );
          } else {
            toastError("Enable to updated a record");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("error occured", error);
          setLoading(false);
        });
    }
  };

  const handlecloseblockpopup = () => {
    setClosePopup({ visible: null });
    localStorage.setItem("CampagionID", 0);
  };

  const handleCloseUncloseModelPopup = (id) => {
    localStorage.setItem("CampagionID", id);
    setClosePopup({ visible: id });
  };

  const BindArtistDropdown = () => {
    if (!loading === true) {
      setLoading(true);
      axios
        .get(`${config.baseUrl}/users/getallartistlist`)
        .then((response) => {
          let dropdownItemOptions = [];

          response.data.ResponseData.map((option) => {
            dropdownItemOptions = [
              ...dropdownItemOptions,
              { label: option.FullName, value: option.ID },
            ];
          });

          console.log(dropdownItemOptions);
          setArtistDropDownItems(dropdownItemOptions);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const BindToolkitDropdown = () => {
    if (!loading === true) {
      setLoading(true);
      axios
        .get(`${config.baseUrl}/campaintoolkit`)
        .then((response) => {
          setToolkits(response.data.ResponseData);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const ChangeArtist = (e) => {
    setSelectedArtistValue(e);
    console.log(e.target.value);
    setRemountComponent(Math.random());
    handleApi(
      1,
      perPageSize,
      "",
      0,
      "asc",
      e.target.value,
      isstationary,
      isClose,
      toolkitid,
      subscription,
      issurgeplay,
      isactive
    );
  };

  const ChangeSubscription = (e) => {
    setSubscription(e.target.value);
    setRemountComponent(Math.random());
    handleApi(
      1,
      perPageSize,
      "",
      0,
      "asc",
      user,
      isstationary,
      isClose,
      toolkitid,
      e.target.value,
      issurgeplay,
      isactive
    );
  };

  const ChangeToolkit = (e) => {
    settoolkitid(e.target.value);
    setRemountComponent(Math.random());
    handleApi(
      1,
      perPageSize,
      "",
      0,
      "asc",
      user,
      isstationary,
      isClose,
      e.target.value,
      subscription,
      issurgeplay,
      isactive
    );
  };

  const closechkboxOnchange = (e) => {
    setIsClose(e.target.value);
    setRemountComponent(Math.random());
    handleApi(
      1,
      perPageSize,
      "",
      0,
      "asc",
      user,
      isstationary,
      e.target.value,
      toolkitid,
      subscription,
      issurgeplay,
      isactive
    );
  };

  // const stationarychkboxOnchange = (e) => {
  //     if (e.target.checked) {
  //         setIsstationary(e.target.value);
  //         setRemountComponent(Math.random());
  //         handleApi(1, perPageSize, "", 0, "asc", 0, 0, 1, toolkitid, subscription,issurgeplay,isactive);
  //     } else {
  //         setIsstationary(0);
  //         setRemountComponent(Math.random());
  //         handleApi(1, perPageSize, "", 0, "asc", 0, 0, 0, toolkitid, subscription,issurgeplay,isactive);
  //     }

  // };

  const surgeplayOnchange = (e) => {
    setIsSurgePlay(e.target.value);
    setRemountComponent(Math.random());
    handleApi(
      1,
      perPageSize,
      searchtext,
      0,
      "asc",
      user,
      isstationary,
      isClose,
      toolkitid,
      subscription,
      e.target.value,
      isactive
    );
  };

  const activeOnchange = (e) => {
    // console.log(e.target.value);
    setIsActive(e.target.value);
    setRemountComponent(Math.random());
    handleApi(
      1,
      perPageSize,
      searchtext,
      0,
      "asc",
      user,
      isstationary,
      isClose,
      toolkitid,
      subscription,
      issurgeplay,
      e.target.value
    );
  };

  useEffect(() => {
    BindArtistDropdown();
    BindToolkitDropdown();
    handleApi(
      1,
      perPageSize,
      searchtext,
      0,
      "asc",
      user,
      isstationary,
      isClose,
      toolkitid,
      subscription,
      issurgeplay,
      isactive
    );
  }, []);

  const changePage = ({ selected }) => {
    selected = selected + 1;
    setPageIndex(selected);
    handleApi(
      selected,
      perPageSize,
      searchtext,
      0,
      "asc",
      user,
      isstationary,
      isClose,
      toolkitid,
      subscription,
      issurgeplay,
      isactive
    );
  };

  const ChangeFilterText = (e) => {
    setSearchText(e.target.value);
    setRemountComponent(Math.random());
    handleApi(
      1,
      perPageSize,
      e.target.value,
      0,
      "asc",
      user,
      isstationary,
      isClose,
      toolkitid,
      subscription,
      issurgeplay,
      isactive
    );
  };

  const ChangePageSize = (e) => {
    setperPageSize(e.target.value);
    handleApi(
      1,
      e.target.value,
      searchtext,
      0,
      "asc",
      user,
      isstationary,
      isClose,
      toolkitid,
      subscription,
      issurgeplay,
      isactive
    );
  };

  const ChangeProfanityStatus = (e, id) => {
    setLoading(true);
    axios
      .post(
        `${config.baseUrl}/campaign/updateProfanityStatusOfCampaign`,
        {
          campaignid: id,
          status: e,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
          toastSuccess("Record has been updated!");
          handleApi(
            //1,
            pageIndex > 0 ? pageIndex : 1,
            perPageSize,
            searchtext,
            0,
            "asc",
            user,
            isstationary,
            isClose,
            toolkitid,
            subscription,
            issurgeplay
          );
        } else {
          toastError("Enable to updated a record");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // const formatDate = (string) => {
  //     var options = { year: "numeric", month: "long", day: "numeric" };
  //     return new Date(string).toLocaleDateString([], options);
  // };
  const formatDate = (string) => {
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    };
    return new Date(string).toLocaleString([], options);
  };

  const handleCloseUncloseCampagion = () => {
    let id = localStorage.getItem("CampagionID");
    setClosePopup({ visible: null });
    if (id > 0) {
      setLoading(true);
      axios
        .post(
          `${config.baseUrl}/campaign/closecampaign`,
          {
            campaignid: id,
            userid: userid,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          console.log("Suces");
          if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
            toastSuccess("Record has been updated!");
            handleApi(
              // 1,
              pageIndex > 0 ? pageIndex : 1,
              perPageSize,
              searchtext,
              0,
              "asc",
              user,
              isstationary,
              isClose,
              toolkitid,
              subscription,
              issurgeplay,
              isactive
            );
          } else {
            toastError("Enable to update a record");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("error occured", error);
          setLoading(false);
        });
    }
  };

  const handlecloseSearchPlaypopup = () => {
    setSatete({ visible: null });
    localStorage.setItem("SearchPlayId", 0);
  };

  const handleSearchPlayModelpopup = (id) => {
    localStorage.setItem("SearchPlayId", id);
    setSatete({ visible: id });
  };

  const handleSearchPlayUser = () => {
    let id = localStorage.getItem("SearchPlayId");

    setSatete({ visible: null });
    if (id > 0) {
      setLoading(true);
      axios
        .post(
          `${config.baseUrl}/campaign/searchplayenabledisable`,
          {
            id: id,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.data.ResponseCode === "SUCCESS") {
            toastSuccess("Record has been updated!");
            handleApi(
              // 1,
              pageIndex > 0 ? pageIndex : 1,
              perPageSize,
              searchtext,
              0,
              "asc",
              user,
              isstationary,
              isClose,
              toolkitid,
              subscription,
              issurgeplay,
              isactive
            );
          } else {
            toastError("Enable to delete a record");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("error occured", error);
          setLoading(false);
        });
    }
  };

  const handlecloseblockpopupfakecount = () => {
    setSateteFakecount({ visible: null });
    localStorage.setItem("CampagionID", 0);
  };

  const handleFakecountpop = (id) => {
    localStorage.setItem("CampaignID", id);
    setSateteFakecount({ visible: id });
  };

  const handleChangeFakecount = (e) => {
    setValidReason(true);
    setFakecount(e.target.value);
  };

  const handleFakecount = () => {
    if (document.getElementById("fakecount").value == "") {
      setValidReason(false);
      return false;
    }

    let id = localStorage.getItem("CampaignID");

    if (id > 0) {
      setLoading(true);
      axios
        .post(
          `${config.baseUrl}/campaign/insertdummyplaycount`,
          {
            campaignid: id,
            fakecount: fakecount,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.data.ResponseCode === "SUCCESS") {
            toastSuccess("Record has been updated!");
            handleApi(
              //1,
              pageIndex > 0 ? pageIndex : 1,
              perPageSize,
              searchtext,
              0,
              "asc",
              user,
              isstationary,
              isClose,
              toolkitid,
              subscription,
              issurgeplay,
              isactive
            );
            setSateteFakecount({ visible: null });
          } else {
            toastError("Enable to updated a record");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("error occured", error);
          setLoading(false);
        });
    }
  };

  const [selectedArtistValue, setSelectedArtistValue] = useState(0);
  useEffect(() => {
    BindArtistDropdown();

    // if (paramObject.id > 0) {
    //   let artist = paramObject.id;
    //   setFormValues({ artist });
    // }
  }, []);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      height: "40px",
      position: "relative",
      background: "#343a40",
      color: "white",
      font: "white",
    }), // dropdown input css
    menu: () => ({
      boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
      position: "absolute",
      background: "#343a40",
      width: "100%",
      color: "white",
      zIndex: 99,
    }), // dropdown menu css
  };

  const handleViewQuestionModelpopup = (id) => {
    localStorage.setItem("CampaignID", id);
    setQuestionSatete({ visible: id });
    setLoading(true);
    axios
      .get(`${config.baseUrl}/campaign/campaignquestiondetails?id=${id}`)
      .then((response) => {
        // console.log(response.data.ResponseData);

        setQuestionList(response.data.ResponseData.QuestionsList);
        setQuestionOptionList(response.data.ResponseData.QuestionsAnswersList);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setError(err);
        setLoading(false);
      });
  };

  const handlecloseViewQuestionpopup = () => {
    //setQuestionSatete(!questionsatete);
    setQuestionSatete({ visible: null });
    localStorage.setItem("CampaignID", 0);
  };

  return (
    <Fragment>
      <Sidebar />
      <ToastContainer />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Campaign List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Campaign </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header text-right">
                    <h3 className="card-title">Campaign List</h3>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label col-sm-12">
                            Select Artist/Business
                          </label>
                          <Select
                            backspaceRemovesValue={false}
                            controlShouldRenderValue={true}
                            isClearable={true}
                            value={selectedArtistValue}
                            options={artistdropdownitems}
                            styles={selectStyles}
                            onChange={(selectedOption) => {
                              ChangeArtist({
                                target: { value: selectedOption.value },
                              });
                              setUser(selectedOption.value);
                              setSelectedArtistValue(selectedOption);
                            }}
                          ></Select>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label col-sm-12">
                            Select Subscription
                          </label>
                          <select
                            className="form-control"
                            value={subscription}
                            onChange={ChangeSubscription}
                          >
                            <option value="0">All</option>
                            <option value="1">Subscription</option>
                            <option value="2">Cancel</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label col-sm-12">
                            Select Toolkit
                          </label>
                          <select
                            className="form-control"
                            value={toolkitid}
                            onChange={ChangeToolkit}
                          >
                            <option value="">All</option>
                            {toolkits.map((u) => (
                              <option value={u.Id}>{u.ToolKitName}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label col-sm-12">
                            IsActive
                          </label>
                          <select
                            className="form-control"
                            value={isactive}
                            onChange={activeOnchange}
                          >
                            <option value="0">All</option>
                            <option value="1">Active</option>
                            <option value="2">InActive</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label col-sm-12">
                            Surge Play
                          </label>
                          <select
                            className="form-control"
                            value={issurgeplay}
                            onChange={surgeplayOnchange}
                          >
                            <option value="0">All</option>
                            <option value="1">Active SurgePlay</option>
                            <option value="2">InActive SurgePlay</option>
                            {/* {toolkits.map((u) => <option value={u.Id}>{u.ToolKitName}</option>)} */}
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label col-sm-12">Close</label>
                          <select
                            className="form-control"
                            value={isClose}
                            onChange={closechkboxOnchange}
                          >
                            <option value="0">All</option>
                            <option value="1">Close</option>
                            <option value="2">Unclose</option>
                            {/* {toolkits.map((u) => <option value={u.Id}>{u.ToolKitName}</option>)} */}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <select className="" onChange={ChangePageSize}>
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="500">500</option>
                        </select>
                      </div>

                      <div className="col-sm-6 col-md-6 text-right">
                        <div id="example1_filter" className="dataTables_filter">
                          <label>
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example1"
                              onChange={ChangeFilterText}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div
                      id="example1_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 table-responsive"
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            id="example1"
                            className="table table-bordered dataTable dtr-inline "
                            aria-describedby="example1_info"
                            style={{ width: "2600px" }}
                          >
                            <thead>
                              <tr>
                                <th
                                  className="sorting sorting_asc"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                  aria-label="ID: activate to sort column descending"
                                >
                                  ID
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="CampaignName: activate to sort column ascending"
                                >
                                  Name
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="UserName: activate to sort column ascending"
                                >
                                  User Name
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="ToolKit: activate to sort column ascending"
                                >
                                  ToolKit
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="Promoted By: activate to sort column ascending"
                                >
                                  Promoted
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="CampaignCountry: activate to sort column ascending"
                                >
                                  Country
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="CampaignState: activate to sort column ascending"
                                >
                                  State Name
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="ActionLink: activate to sort column ascending"
                                >
                                  Action Link
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="Amount: activate to sort column ascending"
                                >
                                  Amount($)
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="Title: activate to sort column ascending"
                                >
                                  Title
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="CreatedOn: activate to sort column ascending"
                                >
                                  Created On
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="IsActive: activate to sort column ascending"
                                >
                                  Is Active
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="Subscription"
                                >
                                  Subscription?
                                </th>
                                <th className="sorting" aria-label="ArtWork">
                                  Art Work
                                </th>
                                <th className="sorting" aria-label="Billboard">
                                  Billboard
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="Bill Board: activate to sort column ascending"
                                >
                                  BillBoard City
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="Bill Board: activate to sort column ascending"
                                >
                                  BillBoard Impression
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="Search Play"
                                >
                                  Surge Play
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="Discovery Consultation Hours"
                                >
                                  Discovery Consultation Hours
                                </th>

                                <th className="sorting" aria-label="Status">
                                  Status
                                </th>
                                <th className="sorting" aria-label="Action">
                                  Action
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="Approve/Reject"
                                >
                                  Approve/Reject
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="Close/Unclose"
                                >
                                  Close/Unclose
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="Add Impressions"
                                >
                                  Add Impressions
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="isProfanity"
                                >
                                  isProfanity
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {items.length > 0 ? (
                                items.map((item) => (
                                  <tr className="odd">
                                    <td
                                      className="dtr-control sorting_1 position-relative"
                                      tabIndex="0"
                                    >
                                      {item.CampaignId}
                                      {item.IsUsedFreeImpression == "1" && (
                                        <div class="ribbon-wrapper">
                                          <div class="ribbon">Free</div>
                                        </div>
                                      )}

                                      {item.IsUsedMasterCampaign == "1" && (
                                        <div class="ribbon-wrapper">
                                          <div class="ribbon bg-warning">
                                            Master
                                          </div>
                                        </div>
                                      )}

                                      {item.CombinedToolkitName !== "" && (
                                        <div class="ribbon-wrapper">
                                          <div class="ribbon bg-warning">
                                            UPSELL
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                    <td>{item.CampaignName}</td>
                                    <td>
                                      {item.UserName}
                                      <br></br>
                                      <code>{item.UserType}</code>
                                    </td>
                                    <td>
                                      {item.ToolKit}
                                      {item.CombinedToolkitName !== "" && (
                                        <div className="text-center">
                                          + <br />
                                          {item.CombinedToolkitName}
                                        </div>
                                      )}
                                    </td>
                                    <td>{item.Promoting}</td>
                                    <td>{item.CampaignCountry}</td>
                                    <td>{item.CampaignState}</td>
                                    <td>{item.CallToActionLink}</td>
                                    <td>
                                      {item.Amount > 0
                                        ? parseFloat(item.Amount).toFixed(2)
                                        : 0}{" "}
                                      <br></br>
                                      {item.ChargeId != "" ? (
                                        <span className="right badge badge-secondary">
                                          REFUNDED
                                        </span>
                                      ) : (
                                        <>
                                          {item.Trial === "Trial" ? (
                                            <>
                                              <br></br>{" "}
                                              <span className="right badge badge-info">
                                                TRIAL
                                              </span>
                                            </>
                                          ) : item.Trial === "Expired" ? (
                                            <>
                                              <br></br>{" "}
                                              <span className="right badge badge-danger">
                                                EXPIRED
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {item.CampaignProcessStatus != "" ? (
                                            <>
                                              {" "}
                                              <br></br>{" "}
                                              <span
                                                className={
                                                  item.CampaignProcessStatus ==
                                                  "COMPLETED"
                                                    ? "right badge badge-success"
                                                    : "right badge badge-danger"
                                                }
                                              >
                                                {item.CampaignProcessStatus}
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                    </td>
                                    {/* <td>{item.CampaignProcessStatus == "COMPLETED" ? item.Amount > 0 ? parseFloat(item.Amount).toFixed(2)  : 0 : item.CampaignProcessStatus} {item.Trial === 1 ?<><br></br> <span className="right badge badge-success">TRIAL</span></> :""}</td> */}
                                    {/* <td>{item.SongTitle}</td> */}
                                    <td>
                                      <a
                                        href={item.MusicFileName}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item.SongTitle}
                                      </a>
                                    </td>
                                    <td>{formatDate(item.CreatedDate)}</td>
                                    <td>
                                      <span
                                        className={
                                          item.IsActive
                                            ? "right badge badge-success"
                                            : "right badge badge-danger"
                                        }
                                      >
                                        {item.IsActive ? "Yes" : "No"}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          item.IsSubscription
                                            ? "right badge badge-success"
                                            : "right badge badge-danger"
                                        }
                                      >
                                        {item.IsSubscription ? "Yes" : "No"}
                                      </span>
                                    </td>
                                    <td>
                                      {item.ArtWorkImageName !== "" &&
                                      item.ArtWorkImageName !== undefined ? (
                                        <>
                                          <a
                                            href={item.ArtWorkImageName}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={item.ArtWorkImageName}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                              }}
                                            />
                                          </a>
                                        </>
                                      ) : (
                                        <img
                                          src="/dist/img/numella.png"
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {item.BillBoardImage !== "" &&
                                      item.BillBoardImage !== undefined ? (
                                        <>
                                          <a
                                            href={item.BillBoardImage}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={item.BillBoardImage}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                              }}
                                            />
                                          </a>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>{item.BillBoardCityName}</td>
                                    <td>
                                      {item.ToolKitId === 7 ||
                                      item.ToolKitId === 11 ||
                                      item.ToolKitId === 16 ||
                                      item.ToolKitId === 19
                                        ? item.BillboradImpressionCount
                                        : ""}
                                    </td>
                                    <td>
                                      {item.ToolKitId === 1 ||
                                      item.ToolKitId === 2 ||
                                      item.ToolKitId === 3 ||
                                      item.ToolKitId === 4 ||
                                      item.ToolKitId === 7 ||
                                      item.ToolKitId === 8 ? (
                                        <>
                                          <button
                                            type="button"
                                            title={
                                              item.IsSearchPlay
                                                ? "Disabled"
                                                : "Enabled"
                                            }
                                            className={
                                              item.IsSearchPlay
                                                ? "btn btn-danger btn-sm"
                                                : "btn btn-success btn-sm"
                                            }
                                            onClick={() =>
                                              handleSearchPlayModelpopup(
                                                item.CampaignId
                                              )
                                            }
                                          >
                                            {item.IsSearchPlay
                                              ? "Disabled"
                                              : "Enabled"}
                                          </button>
                                          <Modal
                                            show={
                                              statee.visible === item.CampaignId
                                            }
                                          >
                                            <div
                                              className="modal fade show"
                                              style={{
                                                display: "block",
                                                paddingRight: "17px",
                                              }}
                                              aria-modal="true"
                                              role="dialog"
                                            >
                                              <div className="modal-dialog modal-md">
                                                <div className="modal-content">
                                                  <div className="modal-header">
                                                    <h4 className="modal-title">
                                                      Confirmation
                                                    </h4>
                                                    <button
                                                      type="button"
                                                      className="close"
                                                      data-dismiss="modal"
                                                      aria-label="Close"
                                                      onClick={() =>
                                                        handlecloseSearchPlaypopup()
                                                      }
                                                    >
                                                      <span aria-hidden="true">
                                                        ×
                                                      </span>
                                                    </button>
                                                  </div>

                                                  <div className="modal-body">
                                                    <p>
                                                      Are you sure you want to
                                                      enable/disable surge play?
                                                    </p>
                                                  </div>
                                                  <div className="modal-footer text-right">
                                                    <button
                                                      type="button"
                                                      className="btn btn-primary"
                                                      onClick={() =>
                                                        handleSearchPlayUser()
                                                      }
                                                    >
                                                      Confirm
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-default"
                                                      data-dismiss="modal"
                                                      onClick={() =>
                                                        handlecloseSearchPlaypopup()
                                                      }
                                                    >
                                                      Cancel
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Modal>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>{item.DiscoveryConsultationHours}</td>

                                    <td>
                                      {item.Status === 1 ? (
                                        <span className="right badge badge-success">
                                          Verified
                                        </span>
                                      ) : item.Status === 2 ? (
                                        <>
                                          <span className="right badge badge-danger">
                                            Rejected
                                          </span>
                                          {/* <i className="fas fa-info-circle" title={item.Reason}></i>&nbsp; */}
                                          <br></br>
                                          <small>Reason: {item.Reason}</small>
                                        </>
                                      ) : (
                                        <span className="right badge badge-warning">
                                          Pending
                                        </span>
                                      )}
                                    </td>

                                    <td style={{ width: "250px" }}>
                                      <div>
                                        {/* Campaign edit */}
                                        {/* Campaign details */}
                                        {item.ToolKitId === 1 ||
                                        item.ToolKitId === 2 ||
                                        item.ToolKitId === 3 ||
                                        item.ToolKitId === 4 ||
                                        //  item.ToolKitId === 7 ||
                                        item.ToolKitId === 8 ||
                                        item.ToolKitId === 12 ? (
                                          <>
                                            <Link
                                              className="btn btn-primary btn-sm mb-1 mr-1"
                                              to={
                                                "/main-campaign-edit/" +
                                                item.CampaignId +
                                                "/" +
                                                item.CampaignDetailsId
                                              }
                                              title="Edit"
                                            >
                                              <i className="fas fa-pencil-alt"></i>
                                              &nbsp;Edit
                                            </Link>
                                            <Link
                                              to={
                                                "/main-campaign-details/" +
                                                item.CampaignId
                                              }
                                              title="Details"
                                              className="btn btn-info btn-sm mb-1 mr-1"
                                            >
                                              <i className="fas fa-info-circle"></i>
                                              &nbsp;Details
                                            </Link>
                                          </>
                                        ) : item.ToolKitId === 6 ||
                                          item.ToolKitId === 9 ? (
                                          <>
                                            <button
                                              type="button"
                                              title="View Questions/Options"
                                              className="btn btn-info btn-sm mb-1 mr-1 "
                                              onClick={() =>
                                                handleViewQuestionModelpopup(
                                                  item.CampaignId
                                                )
                                              }
                                            >
                                              {" "}
                                              <i className="fas fa-info-circle"></i>
                                              &nbsp; Details{" "}
                                            </button>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <Modal
                                        show={
                                          questionsatete.visible ===
                                          item.CampaignId
                                        }
                                      >
                                        <div
                                          className="modal fade show"
                                          style={{
                                            display: "block",
                                            paddingRight: "17px",
                                          }}
                                          aria-modal="true"
                                          role="dialog"
                                        >
                                          <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Campaign Questions/Options
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  onClick={() =>
                                                    handlecloseViewQuestionpopup()
                                                  }
                                                >
                                                  <span aria-hidden="true">
                                                    ×
                                                  </span>
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                {questionList.map(
                                                  (q, index) => (
                                                    <div className="callout callout-info">
                                                      <h5>
                                                        {index + 1}.{" "}
                                                        {q.Question}
                                                      </h5>
                                                      {questionOptionList
                                                        .filter(
                                                          (op) =>
                                                            op.QuestionID ===
                                                            q.Id
                                                        )
                                                        .map((option, i) => (
                                                          <p className="mb-0">
                                                            <i className="fas fa-check"></i>{" "}
                                                            {
                                                              option.QuestionOption
                                                            }
                                                          </p>
                                                        ))}

                                                      {/* {q.QuestionOptions.split(',').map((_option) => (
                                                                                                            <p className='mb-0'><i className='fas fa-check'></i> {_option}</p>
                                                                                                        ))} */}
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                              <div className="modal-footer text-right">
                                                <button
                                                  type="button"
                                                  className="btn btn-default"
                                                  data-dismiss="modal"
                                                  onClick={() =>
                                                    handlecloseViewQuestionpopup()
                                                  }
                                                >
                                                  Close
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Modal>

                                      {/* <button type="button" className="btn btn-success mb-1 btn1edit btn-xs">Detail</button> */}
                                    </td>
                                    <td style={{ width: "250px" }}>
                                      {/* Campaign approve or reject */}
                                      {item.Status === 0 &&
                                      item.IsClosed === 0 ? (
                                        <>
                                          <button
                                            type="button"
                                            title="Approve"
                                            className="btn btn-success btn-sm mr-1 mb-1"
                                            onClick={() =>
                                              handleApproveRejectModelpopup(
                                                item.CampaignId,
                                                "Approved"
                                              )
                                            }
                                          >
                                            <i className="fas fa-check mr-1" />{" "}
                                            Approve
                                          </button>

                                          <button
                                            type="button"
                                            title="Reject"
                                            className="btn btn-danger btn-sm mb-1 mr-1"
                                            onClick={() =>
                                              handleApproveRejectModelpopup(
                                                item.CampaignId,
                                                "Reject"
                                              )
                                            }
                                          >
                                            <i className="fas fa-times mr-1" />{" "}
                                            Reject
                                          </button>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      <Modal
                                        show={
                                          statuspopup.visible ===
                                          item.CampaignId
                                        }
                                      >
                                        <div
                                          className="modal fade show"
                                          style={{
                                            display: "block",
                                            paddingRight: "17px",
                                          }}
                                          aria-modal="true"
                                          role="dialog"
                                        >
                                          <div className="modal-dialog modal-md">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Confirmation
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  onClick={() =>
                                                    handleCloseApproveRejectStatuspopup()
                                                  }
                                                >
                                                  <span aria-hidden="true">
                                                    ×
                                                  </span>
                                                </button>
                                              </div>

                                              <div className="modal-body">
                                                <p>
                                                  Are you sure you want to{" "}
                                                  <strong>
                                                    {campaignstatus}
                                                  </strong>{" "}
                                                  this Campaign?
                                                </p>

                                                <div
                                                  className={
                                                    reasonstatee
                                                      ? "form-group"
                                                      : "form-group d-none"
                                                  }
                                                >
                                                  <label>Reject Reason</label>
                                                  <textarea
                                                    className="form-control"
                                                    id="Reason"
                                                    name="Reason"
                                                    onChange={
                                                      handleChangeReason
                                                    }
                                                  ></textarea>
                                                  <span
                                                    className={
                                                      validreason
                                                        ? "text-danger d-none"
                                                        : "text-danger"
                                                    }
                                                  >
                                                    Reason is required
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="modal-footer text-right">
                                                <button
                                                  type="button"
                                                  className="btn btn-primary"
                                                  onClick={() =>
                                                    handleApproveRejectCampagion()
                                                  }
                                                >
                                                  Yes
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-default"
                                                  data-dismiss="modal"
                                                  onClick={() =>
                                                    handleCloseApproveRejectStatuspopup()
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Modal>
                                    </td>
                                    <td style={{ width: "100px" }}>
                                      {/* Campaign close or unclose */}
                                      {item.Status === 1 ? (
                                        <>
                                          <Link
                                            className="btn btn-danger btn-sm mb-1 mr-1"
                                            title={
                                              item.IsClosed
                                                ? "UnClose"
                                                : "Closed"
                                            }
                                            onClick={() =>
                                              handleCloseUncloseModelPopup(
                                                item.CampaignId
                                              )
                                            }
                                          >
                                            <i
                                              className={
                                                item.IsClosed
                                                  ? "fas fa-check-circle"
                                                  : "fas fa-times-circle"
                                              }
                                            ></i>{" "}
                                            {item.IsClosed
                                              ? "UnClose"
                                              : "Closed"}
                                          </Link>

                                          <Modal
                                            show={
                                              closepopup.visible ===
                                              item.CampaignId
                                            }
                                          >
                                            <div
                                              className="modal fade show"
                                              style={{
                                                display: "block",
                                                paddingRight: "17px",
                                              }}
                                              aria-modal="true"
                                              role="dialog"
                                            >
                                              <div className="modal-dialog modal-md">
                                                <div className="modal-content">
                                                  <div className="modal-header">
                                                    <h4 className="modal-title">
                                                      Confirmation
                                                    </h4>
                                                    <button
                                                      type="button"
                                                      className="close"
                                                      data-dismiss="modal"
                                                      aria-label="Close"
                                                      onClick={() =>
                                                        handlecloseblockpopup()
                                                      }
                                                    >
                                                      <span aria-hidden="true">
                                                        ×
                                                      </span>
                                                    </button>
                                                  </div>

                                                  <div className="modal-body">
                                                    <p>
                                                      Are you sure you want to
                                                      change?
                                                    </p>
                                                  </div>
                                                  <div className="modal-footer text-right">
                                                    <button
                                                      type="button"
                                                      className="btn btn-primary"
                                                      onClick={() =>
                                                        handleCloseUncloseCampagion()
                                                      }
                                                    >
                                                      Confirm
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-default"
                                                      data-dismiss="modal"
                                                      onClick={() =>
                                                        handlecloseblockpopup()
                                                      }
                                                    >
                                                      Cancel
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Modal>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td style={{ width: "200px" }}>
                                      {/* Fake count */}
                                      {item.ToolKitId === 1 ||
                                      item.ToolKitId === 2 ||
                                      item.ToolKitId === 3 ||
                                      item.ToolKitId === 4 ||
                                      item.ToolKitId === 7 ||
                                      item.ToolKitId === 8 ? (
                                        <>
                                          <button
                                            type="button"
                                            title="Add Impressions"
                                            className="btn btn-warning btn-sm mb-1 mr-1"
                                            onClick={() =>
                                              handleFakecountpop(
                                                item.CampaignId
                                              )
                                            }
                                          >
                                            <i className="fas fa-pen-fancy" />{" "}
                                            Add Impressions
                                          </button>
                                          <Modal
                                            show={
                                              stateefackecount.visible ===
                                              item.CampaignId
                                            }
                                          >
                                            <div
                                              className="modal fade show"
                                              style={{
                                                display: "block",
                                                paddingRight: "17px",
                                              }}
                                              aria-modal="true"
                                              role="dialog"
                                            >
                                              <div className="modal-dialog modal-md">
                                                <div className="modal-content">
                                                  <div className="modal-header">
                                                    <h4 className="modal-title">
                                                      Add Count
                                                    </h4>
                                                    <button
                                                      type="button"
                                                      className="close"
                                                      data-dismiss="modal"
                                                      aria-label="Close"
                                                      onClick={() =>
                                                        handlecloseblockpopupfakecount()
                                                      }
                                                    >
                                                      <span aria-hidden="true">
                                                        ×
                                                      </span>
                                                    </button>
                                                  </div>
                                                  <div className="modal-body">
                                                    <div className="row">
                                                      <div className="col-md-12">
                                                        <label>
                                                          Add Impressions
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id="fakecount"
                                                          name="fakecount"
                                                          onChange={
                                                            handleChangeFakecount
                                                          }
                                                        />{" "}
                                                        <span
                                                          className={
                                                            validreason
                                                              ? "text-danger d-none"
                                                              : "text-danger"
                                                          }
                                                        >
                                                          Count is required
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="modal-footer text-right">
                                                    <button
                                                      type="button"
                                                      className="btn btn-primary"
                                                      onClick={() =>
                                                        handleFakecount()
                                                      }
                                                    >
                                                      Confirm
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-default"
                                                      data-dismiss="modal"
                                                      onClick={() =>
                                                        handlecloseblockpopupfakecount()
                                                      }
                                                    >
                                                      Cancel
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Modal>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>

                                    <td
                                      style={{
                                        textAlign: "center",
                                        fontSize: "30px",
                                      }}
                                    >
                                      <div class="form-check form-switch">
                                        <input
                                          onClick={(e) =>
                                            ChangeProfanityStatus(
                                              e.target.checked,
                                              item.CampaignId
                                            )
                                          }
                                          class="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={item.IsProfanity}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={22} align="center">
                                    Data not found!
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="other_page_pagination"
                            key={remountComponent}
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={items.length > 0 ? pageCount : 0}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              pageClassName={"page-link"}
                              previousLinkClassName={"page-link"}
                              nextLinkClassName={"page-link"}
                              activeClassName={"active page-link"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
};

export default AllCampaignList;
