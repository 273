import React from 'react'
import { Bar } from 'react-chartjs-2';
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, BarElement } from 'chart.js';
Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, BarElement);

const MounthWiseBarChart = (props) => {

    const formatDate = (string) => {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([], options);
    }

    const Chartdata = {
        labels: props.userData.map((m) => formatDate(m.CreatedDate)),// ['January', 'February', 'March','April', 'May'],
        datasets: [
            {
                label: 'Month',
                backgroundColor: '#ffbb2f',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 1,
                data: props.userData.map((m) => m.DatewiseCount)// [65, 59, 80, 81, 56]
            }
        ]
    }

    return (
        <div>
            <Bar data={Chartdata} options={{
                title: {
                    display: true,
                    text: 'User per month',
                    fontSize: 20
                },
                legend: {
                    display: true,
                    position: 'right'
                },
                datasets: [{
                    maxBarThickness: 8,
                }],
                maintainAspectRatio: false
            }} width={600} height={350} />
        </div>
    )
}

export default MounthWiseBarChart