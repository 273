import React, { Fragment, useState,useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { toastError, toastSuccess } from '../../../common/Toast';
import config from '../../../config/config';
import CustomLoader from "../../../Layout/CustomLoader";

const VerifyEmailUser =  () =>{
    const paramObject = useParams();
    const [success, setSuccess] = useState(false);
    const [successemail, setSuccessEmail] = useState(false);
    const [error, setError] = useState(false);
    const [Uniqueid, setUserUniqueId] = useState();
    const [loading, setLoading] = useState(false);


    // const schema = Yup.object().shape({
    //     newpassword: Yup.string().min(6, "Must be atleast 6 characters").required("This field is required"),
    //     confirmpassword: string()
    //         .oneOf([ref('newpassword')], 'Passwords do not match')
    //         .required('Password is required'),
    // });

    const handleSubmit = (values) => {
        setLoading(true);
        axios.post(`${config.baseUrl}/users/userverifyemailupdatetoken`, {
            uniqueid: paramObject.uniqueid
        }, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((res) => {
                if (res.data.ResponseCode === "SUCCESS") {
                    setSuccessEmail(true);
                    setError(false);
                    // setShowstate(false);
                    // toastSuccess('Password Successfully Changed, now please try to login !!');
                }
                else if (res.data.ResponseCode === "ERROR" && res.data.ResponseID === -1) {
                    setError(true);
                    setSuccessEmail(false);
                }
                else {
                    setError(true);
                    setSuccessEmail(false);
                }
                setLoading(false);
            }).catch((error) => {
                console.log("error occured", error);
                setLoading(false);
            });
    }


    const handleApi = () => {
        if (paramObject.uniqueid) {
            setLoading(true);
            axios.post(`${config.baseUrl}/users/userverifyemail`, {
                uniqueid: paramObject.uniqueid
            }, {

                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then((res) => {
                    // setUserId(res.data.ResponseID)
                    if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID === 1) {
                            setSuccess(true);
                    //     setShowstate(true);
                    //     // toastSuccess('Record has been added!');
                     }
                     else {
                        setError(true);
                    //     // toastError("Name already Exist!");
                    }
                    setLoading(false);

                }).catch((error) => {
                    console.log("error occured", error);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        handleApi();
    }, [])


    return (
        <Fragment>
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className={success ? "success-page" : "success-page d-none"}>
                <div className="successcode">
                    <div className='success-box'>
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="icon">
                                    <i className='fas fa-check text-success green-check'></i>
                                </div>
                                <h1 className='success-h1 text-center'>Sucess</h1>
                                <p class="success-p">Your email is successfully verified!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={successemail ? "success-page" : "success-page d-none"}>
                <div className="successcode">
                    <div className='success-box'>
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="icon">
                                    <i className='fas fa-check text-success green-check'></i>
                                </div>
                                <h1 className='success-h1 text-center'>Success</h1>
                                <p class="success-p">Verification link sent in your register mail!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className={showstate ? "login-page" : "login-page d-none"}>
                <div className="login-box">
                    <div className='row'>
                        <div className='col-md-12 text-center mb-2'>
                            <img src="/dist/img/Numella-f-logo_180_60.png" alt="Numella" />
                        </div>
                    </div>

                    <div className="card card-outline card-primary">
                        <div className="card-header text-center">
                            <span className="brand-text font-weight-light h3">Forgot Password</span>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={{ id: '', newpassword: '', confirmpassword: '' }}
                                validationSchema={schema}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    // handleSubmit,
                                    handleChange,
                                }) => (
                                    <Form>
                                        <Field type="hidden" className="form-control" id="id" name="id" />
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <Field type="password" id="newpassword" name="newpassword" className="form-control" placeholder="New Password" value={values.newpassword} onChange={handleChange} />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <span className="text-danger">
                                                    {touched.newpassword && errors.newpassword ? errors.newpassword : ''}
                                                </span>
                                            </div>
                                        </div>
                                        <div className=" mb-3">
                                            <div className="input-group">
                                                <Field type="password" id="confirmpassword" name="confirmpassword" className="form-control" placeholder="Confirm Password" value={values.confirmpassword} onChange={handleChange} />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock" />
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="text-danger">
                                                {touched.confirmpassword && errors.confirmpassword ? errors.confirmpassword : ''}
                                            </span>
                                        </div>
                                        <div className="row">

                                            <div className="col-12">
                                                <button type="submit" className="btn btn-dark btn-block">Submit</button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className={error ? "row" : "row  d-none"}>
                <div className="content-wrapper" style={{ marginLeft: 0 }}>
                    <div className="errorcode">
                        <section className='content'>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col" >
                                        <h4><i className="fas fa-exclamation-triangle text-danger" /> Oops! Your Token Is Expire!</h4>
                                        <span   >
                                            Please confirm/check the Token.
                                            Then, you may try again with appropriate code.
                                        </span>
                                        <div> 
                                        <span>
                                        <button className='btn btn-info mt-5' onClick={handleSubmit}>
                                            Resend Token
                                        </button>
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default VerifyEmailUser;