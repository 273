import React from "react";
import { Form } from "react-bootstrap";

const FileInput = ({ buttonTitle, id, handleChange, accept, name, value, className, errors, touched, loader }) => {
    return (
        <>
            <Form.Group className="d-flex flex-column">
                <label htmlFor={id} >
                    {buttonTitle}
                </label>
                <Form.Control
                    type="file"
                    id={id}
                    className={className}
                    name={name}
                    accept={accept}
                    onChange={(e) => handleChange(e)}
                    isInvalid={!!touched && Boolean(errors)}
                />
               {/* <label htmlFor={id} className={`${!!Boolean(errors) && 'border border-danger d-block'} btn-3`}>
                    {loader ? <div className="spinner-border spinner-border-sm text-light" role="statusd" /> : value?.name ? <span>{value?.name}</span> : ""}
                </label> */}
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errors}
                </Form.Control.Feedback>
            </Form.Group>
        </>
    );
};

export default FileInput;