import React, { Fragment, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { string, ref } from 'yup';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { toastError, toastSuccess } from '../../../common/Toast';
import config from '../../../config/config';
import { useEffect } from 'react';

const ForgotPassword = () => {
    const paramObject = useParams();
    const [showstate, setShowstate] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [userId, setUserId] = useState();


    const schema = Yup.object().shape({
        newpassword: Yup.string().min(6, "Must be atleast 6 characters").required("This field is required"),
        confirmpassword: string()
            .oneOf([ref('newpassword')], 'Passwords do not match')
            .required('Password is required'),
    });

    const handleSubmit = (values) => {
        axios.post(`${config.baseUrl}/users/updateforgotpassword`, {
            id: userId,
            newpassword: values.newpassword
        }, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((res) => {
                if (res.data.ResponseCode === "SUCCESS") {
                    setSuccess(true);
                    setShowstate(false);
                    // toastSuccess('Password Successfully Changed, now please try to login !!');
                }
                else if (res.data.ResponseCode === "ERROR" && res.data.ResponseID === -1) {
                    toastError("Password already Exist!");
                }
                else {
                    toastError("Somthing went wrong");
                }
            }).catch((error) => {
                console.log("error occured", error);
            });
    }



    const handleApi = () => {
        if (paramObject.code) {
            axios.post(`${config.baseUrl}/users/forgotpasswordverifycode`, {
                code: paramObject.code
            }, {

                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then((res) => {
                    setUserId(res.data.ResponseID)
                    if (res.data.ResponseCode === "SUCCESS") {
                        setShowstate(true);
                        // toastSuccess('Record has been added!');
                    }
                    else {
                        setError(true);
                        // toastError("Name already Exist!");
                    }

                }).catch((error) => {
                    console.log("error occured", error);
                });
        }
    }

    useEffect(() => {
        handleApi();
    }, [])

    return (
        <Fragment>
            <ToastContainer />

            <div className={success ? "success-page" : "success-page d-none"}>
                <div className="successcode">
                    <div className='success-box'>
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="icon">
                                    <i className='fas fa-check text-success green-check'></i>
                                </div>
                                <h1 className='success-h1 text-center'>Success</h1>
                                <p class="success-p">Password is changed successfully!!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={showstate ? "login-page" : "login-page d-none"}>
                <div className="login-box">
                    <div className='row'>
                        <div className='col-md-12 text-center mb-2'>
                            <img src="/dist/img/Numella-f-logo_180_60.png" alt="Numella" />
                        </div>
                    </div>

                    <div className="card card-outline card-primary">
                        <div className="card-header text-center">
                            <span className="brand-text font-weight-light h3">Forgot Password</span>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={{ id: '', newpassword: '', confirmpassword: '' }}
                                validationSchema={schema}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    // handleSubmit,
                                    handleChange,
                                }) => (
                                    <Form>
                                        <Field type="hidden" className="form-control" id="id" name="id" />
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <Field type="password" id="newpassword" name="newpassword" className="form-control" placeholder="New Password" value={values.newpassword} onChange={handleChange} />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <span className="text-danger">
                                                    {touched.newpassword && errors.newpassword ? errors.newpassword : ''}
                                                </span>
                                            </div>
                                        </div>
                                        <div className=" mb-3">
                                            <div className="input-group">
                                                <Field type="password" id="confirmpassword" name="confirmpassword" className="form-control" placeholder="Confirm Password" value={values.confirmpassword} onChange={handleChange} />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock" />
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="text-danger">
                                                {touched.confirmpassword && errors.confirmpassword ? errors.confirmpassword : ''}
                                            </span>
                                        </div>
                                        <div className="row">

                                            <div className="col-12">
                                                <button type="submit" className="btn btn-dark btn-block">Submit</button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>


            <div className={!error ? "row d-none" : "row"}>

                <div className="content-wrapper" style={{ marginLeft: 0 }}>
                    <div className="errorcode">
                        <section className='content'>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col" >
                                        <h4><i className="fas fa-exclamation-triangle text-danger" /> Oops! The Code send is not valid.</h4>
                                        <span>
                                            Please confirm/check the code.
                                            Then, you may try again with appropriate code.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default ForgotPassword

