import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from "../../config/config";
import QRCodeTemplate from "../Users/QRCodeTemplate";
import AWS from "aws-sdk";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWSAccessKeyId,
  secretAccessKey: process.env.REACT_APP_AWSSecretKey,
  region: process.env.REACT_APP_REGION,
});

const DriveQRCode = ({ id, userid, drivername, qrcodeurl, qrcodetype }) => {
  const paramObject = useParams();
  const QRCodeUrl = `${process.env.QRCode_Base_Url}`;
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleApi = () => {
    if (!loading === true) {
      axios
        .get(`${config.baseUrl}/users/userwiseqr?id=${paramObject.id}`, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          console.log(response.data.ResponseData);
          setItems(response.data.ResponseData);
        })
        .catch((err) => {
          console.log(err);

          setError(err);
        });
    }
  };

  useEffect(() => {
    handleApi(paramObject.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connvertBase64 = async (string) => {
    let splitData = string.split("USERS");

    const params = {
      Bucket: process.env.REACT_APP_Bucket,
      Key: "USERS" + splitData[1],
    };

    const myPromise = new Promise((resolve, reject) => {
      s3.getObject(params, (err, data) => {
        if (err) {
          console.log(err, err.stack);
        } else {
          //console.log(data);
          let csvBlob = new Blob([data.Body], { type: "image/png" });
          // console.log(csvBlob);

          var reader = new FileReader();
          reader.readAsDataURL(csvBlob);
          reader.onloadend = function () {
            var base64String = reader.result;
            console.log("Base64 String - ", base64String);
            //  setCodeUrl(base64String);

            resolve(base64String);
          };
        }
      });
    });
    console.log("Call Print");

    return myPromise;
  };

  return (
    <Fragment>
      <div className="content-wrapper" style={{ marginLeft: 0 }}>
        {/* <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Driver's QRCode</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">Driver's QRCode</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section> */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="qrcode-body">
                  {items.map((item) => (
                    <QRCodeTemplate
                      id={"divToDownloadQRCode-" + item.Id}
                      userid={item.Id}
                      drivername={item.FullName}
                      qrcodeurl={item.QRCodeURL}
                      qrcodetype={item.QRCodeType}
                      qraction={QRCodeUrl + "/drprofile/" + item.UniqueId}
                      qrcodePromise={connvertBase64(item.QRCodeURL)}
                    />
                    // <QRCodeTemplate id={"divToDownloadQRCode-" + item.Id} userid={item.Id} drivername={item.FullName} qrcodetype={item.QRCodeType} qrcodeurl={item.QRCodeURL} qraction={QRCode_Base_Url + '/drprofile/' + item.UniqueId} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default DriveQRCode;
