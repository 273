import React, { Fragment, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import Sidebar from '../../Layout/sidebar/Sidebar';
import Footer from '../../Layout/footer/Footer';
import { Formik, Field, Form } from 'formik'
import axios from 'axios';
import ReactPaginate from "react-paginate";
import config from '../../config/config';
import { ToastContainer } from 'react-toastify';
import { toastError } from '../../common/Toast';

import CustomLoader from "../../Layout/CustomLoader";
import Select from "react-select";

const PlayedUserSongHistoryList = (props) => {
    let date = new Date();
    let month = date.getMonth() + 1;
    let endDate = date.getFullYear() + '-' + (month >= 10 ? month : '0' + month) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : + date.getDate());
    let startDate = date.getFullYear() + '-' + (month >= 10 ? month : '0' + month) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : + date.getDate());

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState('');
    const [items, setItems] = useState([]);
    const [perPageSize, setperPageSize] = useState(10);
    const [searchtext, setSearchText] = useState("");
    const [totalrecord, setTotalRecord] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const paramObject = useParams();
    const [driverdropdownitems, setDriverDropDownItems] = useState([]);
    const [remountComponent, setRemountComponent] = useState(0);
    // const [datefromchange, setDatefromchange] = useState(startDate);
    // const [datetochange, setDatetochange] = useState(endDate);
    const [datefromchange, setDatefromchange] = useState("");
    const [datetochange, setDatetochange] = useState("");
    const [selectedDriverValue, setSelectedDriverValue] = useState(0);

    const selectStyles = {
        control: (provided) => ({
            ...provided,
            width: "100%",
            height: "40px",
            position: "relative",
            background: "#343a40",
            color: "#ffffff",
            font: "#ffffff",

        }), // dropdown input css
        menu: () => ({
            boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
            position: "absolute",
            background: "#343a40",
            width: "100%",
            color: "#ffffff",
            zIndex: 99,
        }), // dropdown menu css
    };

    // const selectStyles = {
    //     control: styles => ({ ...styles, backgroundColor: 'white' }),
    //     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //       return {
    //         ...styles,
    //         backgroundColor: "#ccc",
    //         color: '#FFF',
    //         cursor:  'default',
       
    //       };
    //     },
       
    //   }


    const handleApi = (pagenumber, perPageSize, searchtext, user, datefromchange, datetochange) => {

        console.log("datefromchange ==>", datefromchange)
        console.log("datetochange ==>", datetochange)
        // if (!loading === true) {
        setLoading(true);

        axios.get(`${config.baseUrl}/campaign/getallplayedsonghistoryfordriverforportal?pagenumber=${pagenumber}&pagesize=${perPageSize}&filtertext=${searchtext}&userid=${user}&startdate=${datefromchange}&todate=${datetochange}`)
            .then((response) => {
                setItems(response.data.ResponseData);
                setTotalRecord(response.data.ResponseData[0]?.TotalRecord);
                setpageCount(Math.ceil(response.data.ResponseData[0]?.TotalRecord / perPageSize));
                setLoading(false);
            }).catch((err) => {
                console.log(err)
                setError(err);
                setLoading(false);
            })
    }
    // else {
    //     toastError("Somthing went wrong!");
    // }
    const BindDriverDropdown = () => {
        if (!loading === true) {
            setLoading(true);
            axios.get(`${config.baseUrl}/users/getalldriverlist`)
                .then((response) => {
                    // setDriverDropDownItems(response.data.ResponseData);
                    
                    let dropdownItemOptions = [];
                    response.data.ResponseData.map((option) => {
                        dropdownItemOptions = [
                            ...dropdownItemOptions,
                            { label: option.FullName, value: option.Id },
                        ];
                    });
                    setDriverDropDownItems(dropdownItemOptions);

                    setSelectedDriverValue(dropdownItemOptions.filter((e) => e.value == paramObject.id));

                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                    setError(err);
                    setLoading(false);
                })
        }
        else {
            toastError("Somthing went wrong!");
        }
    }

    const ChangeDriver = (e) => {
        // setUser(e.target.value);
        setSelectedDriverValue(e);
        setRemountComponent(Math.random());
        handleApi(1, perPageSize, searchtext, e.target.value, datefromchange, datetochange);
    }

    const formatDate = (string) => {
        var options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return new Date(string).toLocaleDateString([], options);
    }
    const changePage = ({ selected }) => {
        selected = selected + 1;
        // setPageNumber(selected);
        handleApi(selected, perPageSize, searchtext, user, datefromchange, datetochange);
    };

    const ChangeFilterText = (e) => {
        setSearchText(e.target.value);
        handleApi(1, perPageSize, e.target.value, user, datefromchange, datetochange);
    }

    const handleFromDate = (e) => {
        setDatefromchange(e.target.value)
    }
    const handleToDate = (e) => {
        setDatetochange(e.target.value)
    }

    const handleDateChange = () => {
        console.log("1", datetochange);
        console.log("2", datefromchange);
        handleApi(1, perPageSize, searchtext, user, datefromchange, datetochange);
    }


    const ChangePageSize = (e) => {
        setperPageSize(e.target.value);
        setRemountComponent(Math.random());
        handleApi(1, e.target.value, searchtext, user, datefromchange, datetochange);
    }

    useEffect(() => {
        BindDriverDropdown();
        setUser(paramObject.id)
        handleApi(1, perPageSize, searchtext, paramObject.id, datefromchange, datetochange);
    }, [])



    return (
        <Fragment>
            <Sidebar />
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>User's History</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">User's History </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <Formik {...props}
                                    enableReinitialize >
                                    <Form>
                                        <div className="row">
                                            <div className="col-sm-3 col-md-3">
                                                <div className='form-group'>
                                                    <label className='form-label col-sm-12'>Select Driver</label>
                                                    {/* <select className='form-control select-2' value={user} onChange={ChangeDriver}>
                                                        <option value="0">All</option>
                                                        {driverdropdownitems.map((u) => <option value={u.Id}>{u.FullName}</option>)}
                                                    </select> */}

                                                    <Select
                                                        backspaceRemovesValue={false}
                                                        controlShouldRenderValue={true}
                                                        isClearable={true}
                                                        value={selectedDriverValue}
                                                        options={driverdropdownitems}
                                                        styles={selectStyles}
                                                        onChange={(selectedOption) => {
                                                            ChangeDriver({
                                                                target: { value: selectedOption.value },
                                                            });
                                                            setUser(selectedOption.value);
                                                            setSelectedDriverValue(selectedOption);
                                                        }}
                                                    ></Select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>From Date</label>
                                                    <Field type="date" className="form-control" name="fromdate" id="fromdate" dateformat="yyyy/mm/dd" value={datefromchange} onChange={(e) => handleFromDate(e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>To Date</label>
                                                    <Field type="date" className="form-control" name="todate" id="todate" dateformat="yyyy/mm/dd" value={datetochange} onChange={(e) => handleToDate(e)} />
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3 mt-4 mb-4">
                                                <button type="button" className="btn btn-primary mt-2" onClick={handleDateChange}>Search</button>
                                            </div>
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4 table-responsive" >
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6">
                                                    <select className='' onChange={ChangePageSize}>
                                                        <option value="10">10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </div>

                                                <div className="col-sm-6 col-md-6 text-right">
                                                    <div id="example1_filter" className="dataTables_filter">
                                                        <label><input type="search" className="form-control form-control-sm" placeholder="" aria-controls="example1" onChange={ChangeFilterText} /></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="example1" className="table table-bordered dataTable dtr-inline " aria-describedby="example1_info">
                                                        <thead>
                                                            <tr>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="ID: activate to sort column ascending">ID</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="CampaignName: activate to sort column ascending">Campaign Name</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Music: activate to sort column ascending">Music</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="ArtWork: activate to sort column ascending">Art Work</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="UserName: activate to sort column ascending">Artist Name</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="UserName: activate to sort column ascending">Driver Name</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="SongTitle: activate to sort column ascending">Song Title</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="GenreName: activate to sort column ascending">Genre Name</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="BillBoard: activate to sort column ascending">BillBoard</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="StartOn: activate to sort column ascending">Start On</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="EndedOn: activate to sort column ascending">EndedOn</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="StartAddress: activate to sort column ascending">StartAddress</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="EndAddress: activate to sort column ascending">EndAddress</th>
                                                                {/* <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="BillBoardImage: activate to sort column ascending">BillBoard</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {items.length > 0 ? items.map((item) => (
                                                                <tr className="odd">
                                                                    <td>{item.Id}</td>
                                                                    <td>{item.CampaignName}</td>
                                                                    <td>
                                                                        <a href={item.MusicFileName} target='_blank'>{item.SongTitle}</a>
                                                                        {/* <img src={item.MusicFileName} height={50} width={50}></img> */}
                                                                    </td>
                                                                    <td>
                                                                        <a href={item.ArtWorkImageName} target='_blank'>
                                                                            <img src={item.ArtWorkImageName} height={50} width={50}></img>
                                                                        </a>
                                                                    </td>
                                                                    <td>{item.UserName}</td>
                                                                    <td>{item.PlayedUserName}</td>
                                                                    <td>{item.SongTitle}</td>
                                                                    <td>{item.GenreName}</td>
                                                                    <td>
                                                                        {item.BillBoardImage != "" && item.BillBoardImage != undefined ?
                                                                            <>
                                                                                <a href={item.BillBoardImage} target='_blank'>
                                                                                    <img src={item.BillBoardImage} height={50} width={50}></img>
                                                                                </a>
                                                                            </>
                                                                            : ''}
                                                                             
                                                                       
                                                                    </td>
                                                                    <td>{formatDate(item.PlayStartTime)}</td>
                                                                    <td>{formatDate(item.PlayEndTime)}</td>
                                                                    <td>{item.StartAddress}</td>
                                                                    <td>{item.EndAddress}
                                                                    {/* <br></br>
                                                                    <div>
                                                                    <br></br>
                                                                            <span> <b>Country :</b> {item.Country}</span><br></br>
                                                                            <span> <b>State :</b>  {item.State}</span><br></br>
                                                                            <span> <b>City :</b> {item.City}</span><br></br>                                                                            
                                                                    </div> */}
                                                                    </td>
                                                                    {/* <td><img src={item.BillBoardImage} height={50} width={50}></img></td> */}
                                                                </tr>
                                                            )) : <tr>
                                                                <td colSpan={13} align='center'>Data not found!</td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-5' key={remountComponent}>
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={items.length > 0 ? pageCount : 0}
                                                        onPageChange={changePage}
                                                        containerClassName={"pagination"}
                                                        pageClassName={"page-link"}
                                                        previousLinkClassName={"page-link"}
                                                        nextLinkClassName={"page-link"}
                                                        activeClassName={"active"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </Fragment>
    )
}

export default PlayedUserSongHistoryList