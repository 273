import React, { useState, useEffect } from 'react';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import imagestetting from '../../img/qrcode/loader.gif'

const saveAs = (blob, fileName) => {
  var elem = window.document.createElement('a');
  elem.href = blob
  elem.download = fileName;
  elem.style = 'display:none;';
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    }));
  }
  URL.revokeObjectURL(elem.href);
  elem.remove()
}

const CustomLoader2 = ({ loading }) => {
  return (
    <section className={loading ? "" : "d-none"}>
      <div>
        <img src={imagestetting} style={{ "width": "30px", "height": "30px" }} />
      </div>
    </section>
  );
};


const QRCodePdfDownloader = ({ rootElementId, downloadFileName }) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [qrdownloadstatee, setqrdownloadSatete] = useState(false);
  const [qrcodedata, setqrcodedata] = useState("");

  const downloadPdfDocument = () => {
    setLoading(true);
    console.log("Call");
    console.log(qrcodedata);
    saveAs(qrcodedata, `${downloadFileName}.png`);
    setqrdownloadSatete(!qrdownloadstatee);
    localStorage.setItem("DownloadUserID", 0);
    setLoading(false);


    // let input = document.getElementById(rootElementId);// htmlDoc.getElementById("dvTest");

    // htmlToImage.toPng(input)
    //   .then(function (dataUrl) {

    //     console.log(dataUrl);

    //     setTimeout(function () {
    //       saveAs(dataUrl, `${downloadFileName}.png`);

    //       setqrdownloadSatete(!qrdownloadstatee);
    //       localStorage.setItem("DownloadUserID", 0);
    //       setLoading(false);

    //     }, 10000)
    //   });

  }

  useEffect(() => {
    setDisabled(true);
    setLoading(true);

    setTimeout(function () {

      let input = document.getElementById(rootElementId);// htmlDoc.getElementById("dvTest");
      htmlToImage.toPng(input)
      try {
        htmlToImage.toPng(input)
          .then(function (dataUrl) {
            setqrcodedata(dataUrl);
            setDisabled(false);
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
      // .then(function (dataUrl) {
      //   setqrcodedata(dataUrl);
      //   setDisabled(false);
      //   setLoading(false);
      // });

    }, 2000);
  }, []);

  return <>
    <CustomLoader2 loading={loading} />
    <button type="button" title='Download' className={disabled ? "btn btn-warning btn-sm mr-2 disabled" : "btn btn-warning btn-sm mr-2"} onClick={downloadPdfDocument} > <i className='fas fa-qrcode' /> Download</button>
  </>

}

export default QRCodePdfDownloader;