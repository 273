import React, { useState, useEffect, Fragment } from 'react'
import { Formik, Form, Field, useFormik } from "formik";
import Footer from '../../Layout/footer/Footer'
import Sidebar from '../../Layout/sidebar/Sidebar'
import Select from 'react-select'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toastError, toastSuccess } from '../../common/Toast';
import config from '../../config/config';
import axios from 'axios';
import * as Yup from 'yup';
import FileInput from "../FormControles/FileInput";
import { uploadAWS, uploadFileWithNameAWS } from "../../common/AWSHelper";
import CustomLoader from "../../Layout/CustomLoader";

const EditCampaign = (props) => {
  const paramObject = useParams();
  const [countryoption, setCountryOption] = useState([]);
  const [selectcountryoption, setSelectCountryOption] = useState([]);
  const [questionoption, setQuestionOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState({ Id: '', CampaignName: '', ToolKitId: '', SongTitle: '', SongTitle2: '', ArtWorkImageName: '',ArtWorkImageName2: '', BillBoardImage: '', CMDId: '',CMDId2: '', MusicFileName: '', MusicFileName2: '', CreatedBy: '' , BillBoardCity:'' });
  const [selectedValue, setSelectedValue] = useState([]);
  const [campaignOption, setCampaignOption] = useState([]);
  const [changeArtWorkImageUrl, setChangeArtWorkImageUrl] = useState(false);
  const [changeArtWorkImageUrl2, setChangeArtWorkImageUrl2] = useState(false);
  const [changeBillBoardImageUrl, setChangeBillBoardImageUrl] = useState(false);
  const [changeMusicUrl, setChangeMusicUrl] = useState(false);
  const [changeMusicUrl2, setChangeMusicUrl2] = useState(false);
  const [artWorkImageUrl, setArtWorkImage] = useState('');
  const [billboardImageUrl, setBillBoardImage] = useState('');
  const [musicUrl, setMusic] = useState('');

  const [artworkUrl, setartworkUrl] = useState('');
  const [artwork2Url, setartwork2Url] = useState('');
  const [musicfileUrl, setmusicfileUrl] = useState('');
  const [musicfile2Url, setmusicfile2Url] = useState('');

  const [test, setTest] = useState('');
  const validationSchema = Yup.object().shape({
    CampaignName: Yup.string().required("CampaignName is a required ")
  });

  let navigate = useNavigate();
  let modifiedBy = localStorage.getItem('ID');

  const handleSubmit = async (values) => {

    // new Promise(async (res) => { 
    let Questionarr = [];
    campaignOption.forEach((el) => {
      questionoption.forEach((e) => {
        if (el.QuestionId === e.Id) {
          let element = {};
          element.Id = el.Id;
          element.QuestionId = el.QuestionId;
          element.OptionIds = e.OptionIds;
          Questionarr.push(element);
        }
      });
    });
    let jsonstring = '';
    if (Questionarr.length > 0) {
      const jsonobj = {};
      jsonobj.CampaignQuestionDetails = Questionarr;
      jsonstring = JSON.stringify(jsonobj);
    }

    // //let artworkfilepath='';
    // if (artworkformik?.values?.ArtWorkImageName != null) {
    //   await artWorkuploadFile(artworkformik?.values?.ArtWorkImageName, "ArtWorkImageName", (data) => {
    //     if (data) {
    //       // artworkfilepath=data;
    //     }
    //   });
    // }
    
    // //let billboardfilepath='';
    // if (billboardformik?.values?.BillBoardImage != null) {
    //   await BillBoarduploadFile(billboardformik?.values?.BillBoardImage, "BillBoardImage", (data) => {
    //     if (data) {
    //       // billboardfilepath=data;
    //     }
    //   });
    // }
    
    // //  let musicfilepath='';
    // if (musicformik?.values?.MusicFileName != null) {
    //   await musicuploadFile(musicformik?.values?.MusicFileName, "MusicFileName", (data) => {
    //     if (data) {
    //       //musicfilepath=data;
    //     }
    //   });
    // }
    
    axios.post(`${config.baseUrl}/campaign/updateCampaign`, {
      CampaignId: values.Id,
      CampaignName: values.CampaignName,
      CampaignCountryIds: selectedValue.toString(),
      MusicFileName: values.MusicFileName != null && values.MusicFileName != '' ? values.MusicFileName :  musicfileUrl,
      MusicFileName2 : values.MusicFileName2 != null && values.MusicFileName2 != '' ? values.MusicFileName2 :  musicfile2Url,
      ArtWorkImageName: values.ArtWorkImageName != null && values.ArtWorkImageName != '' ? values.ArtWorkImageName :  artworkUrl,
      ArtWorkImageName2: values.ArtWorkImageName2 != null && values.ArtWorkImageName2 != '' ? values.ArtWorkImageName2 :  artwork2Url,
      BillBoardImage: values.BillBoardImage != null && values.BillBoardImage != '' ? values.BillBoardImage :  billboardImageUrl,
      SongTitle: values.SongTitle,
      SongTitle2: values.SongTitle2,
      CMDId: values.CMDId,
      CMDId2: values.CMDId2,
      CampaignQuestionDetailsXML: jsonstring,
      ModifiedBy: modifiedBy,
      BillBoardCityName : values.BillBoardCity
    }, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    })
      .then((res) => {
        if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
          // alert("success");
          toastSuccess('Campaign detail update sucessfully!');
          navigate("/campaign-all");
        }
        else {
          toastError("Somthing went wrong");
        }
      }).catch((error) => {
        console.log("error occured", error);
      });
  }
  useEffect(() => {
    if (paramObject.id > 0) {
      BindCountryDropdown();
      BindCampaignDetail(paramObject.id, paramObject.cmdid);
    }
  }, []);

  const BindCountryDropdown = () => {
    if (!loading === true) {
      let op = [];
      axios.get(`${config.baseUrl}/campaincountry/fordropdown`)
        .then((response) => {
          response.data.ResponseData.forEach((el) => {
            let element = {};
            element.label = el.Name;
            element.value = el.ID;
            op.push(element);
          })
          if (op.length > 0) {
            setCountryOption(op);
          }
        }).catch((err) => {
          console.log(err);
          setError(err);
        })
    }
    else {
      toastError("Somthing went wrong!");
    }
  }

  const onChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
  };

  const OptiononChange = (e, qid) => {
    let questionoptionupdate = questionoption;
    questionoptionupdate.forEach((q) => {
      if (q.Id === qid) {
        q.OptionIds = e.map(x => x.value).join(', ');
      }
    });
    setQuestionOption(questionoptionupdate);
    let x = test
    setTest(qid + x);
  };

  const [artbtnLoader, setartworkBtnLoader] = useState({ ArtWorkImageName: false });
  const artworkvalidate = (values) => {
    let errors = {};
    if (!values?.ArtWorkImageName?.name.match(/\.(jpg|jpeg|png)$/)) {
      errors.ArtWorkImageName = 'Only accept .jpg, .jpeg, .png file';
    }
    return errors;
  }

  const [billbtnLoader, setbillboardBtnLoader] = useState({ BillBoardImage: false });
  const billboardvalidate = (values) => {
    let errors = {};
    if (!values?.BillBoardImage?.name.match(/\.(jpg|jpeg|png)$/)) {
      errors.BillBoardImage = 'Only accept .jpg, .jpeg, .png file';
    }
    return errors;
  }

  const [musicbtnLoader, setmusicBtnLoader] = useState({ MusicFileName: false });
  const musicvalidate = (values) => {
    let errors = {};
    if (!values?.MusicFileName?.name.match(/\.(mp4|mp3|video)$/)) {
      errors.MusicFileName = 'Only accept .mp4, .mp3, .video file';
    }
    return errors;
  }

  const [artbtnLoader2, setartworkBtnLoader2] = useState({ ArtWorkImageName2: false });
  const artworkvalidate2 = (values) => {
    let errors = {};
    if (!values?.ArtWorkImageName2?.name.match(/\.(jpg|jpeg|png)$/)) {
      errors.ArtWorkImageName = 'Only accept .jpg, .jpeg, .png file';
    }
    return errors;
  }

  const [musicbtnLoader2, setmusicBtnLoader2] = useState({ MusicFileName2: false });
  const musicvalidate2 = (values) => {
    let errors = {};
    if (!values?.MusicFileName2?.name.match(/\.(mp4|mp3|video)$/)) {
      errors.MusicFileName2 = 'Only accept .mp4, .mp3, .video file';
    }
    return errors;
  }

  const musicformik = useFormik({
    initialValues: { MusicFileName: null },
    musicvalidate,
    onSubmit: values => {
    }
  });

  const artworkformik = useFormik({
    initialValues: { ArtWorkImageName: null },
    artworkvalidate,
    onSubmit: values => {
    }
  });

  const musicformik2 = useFormik({
    initialValues: { MusicFileName2: null },
    musicvalidate2,
    onSubmit: values => {
    }
  });

  const artworkformik2 = useFormik({
    initialValues: { ArtWorkImageName2: null },
    artworkvalidate2,
    onSubmit: values => {
    }
  });

  const billboardformik = useFormik({
    initialValues: { BillBoardImage: null },
    billboardvalidate,
    onSubmit: values => {
    }
  });

  /* AWS FILE UPLOAD */

  const _handleArtWorkFileChange = (e) => {
    setChangeArtWorkImageUrl(true);
    if (vaidation(e.target.name, e?.currentTarget?.files[0])) {
      artworkformik.setFieldValue([e.target.name], e?.currentTarget?.files[0])

      artWorkuploadFile(e?.currentTarget?.files[0], e.target.name);
    }
  }

  const _handleBillBoardFileChange = (e) => {
    setChangeBillBoardImageUrl(true);
    if (vaidation(e.target.name, e?.currentTarget?.files[0])) {
      billboardformik.setFieldValue([e.target.name], e?.currentTarget?.files[0])
      BillBoarduploadFile(e?.currentTarget?.files[0], e.target.name);
    }
  }

  const _handleMusicFileChange = (e) => {
    console.log("Call 111");
    setChangeMusicUrl(true);
    if (vaidation(e.target.name, e?.currentTarget?.files[0])) {
      musicformik.setFieldValue([e.target.name], e?.currentTarget?.files[0])
      musicuploadFile(e?.currentTarget?.files[0], e.target.name);
    }
  }

  // A/B testing
  const _handleArtWorkFileChange2 = (e) => {
    setChangeArtWorkImageUrl2(true);
    if (vaidation(e.target.name, e?.currentTarget?.files[0])) {
      artworkformik2.setFieldValue([e.target.name], e?.currentTarget?.files[0])

      artWorkuploadFile2(e?.currentTarget?.files[0], e.target.name);
    }
  }

  const _handleMusicFileChange2 = (e) => {
    console.log("Call");
    console.log(formValues.MusicFileName2);
    setChangeMusicUrl2(true);
    if (vaidation(e.target.name, e?.currentTarget?.files[0])) {
      musicformik2.setFieldValue([e.target.name], e?.currentTarget?.files[0])
      musicuploadFile2(e?.currentTarget?.files[0], e.target.name);
    }
  }

  const musicuploadFile = async (file, type, method) => {
    setLoading(true);
    setmusicBtnLoader({ [type]: true });
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const name = file.name.split(".");
    let filename = '';
    if (formValues.MusicFileName === null || formValues.MusicFileName === "") {
      filename = month + year + Math.floor(Date.now() / 1000) + "." + name[name.length - 1];
    }
    else {
      let url = formValues.MusicFileName;
      filename = url.substring(url.lastIndexOf('/') + 1);
    }

    await uploadFileWithNameAWS(file, "USERS/" + formValues.CreatedBy, filename, (data) => {
      if (data) {
        // console.log(data);
        //method(data);
        setmusicfileUrl(data);
      }
      setLoading(false);
    });
    
  }

  const musicuploadFile2 = async (file, type, method) => {
    setLoading(true);
    // console.log("Call 1");
    setmusicBtnLoader2({ [type]: true });
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const name = file.name.split(".");
    let filename = '';

   
    if (formValues.MusicFileName2 === null || formValues.MusicFileName2 === "") {
      filename = month + year + Math.floor(Date.now() / 1000) + "." + name[name.length - 1];
    }
    else {
      let url = formValues.MusicFileName2;
      filename = url.substring(url.lastIndexOf('/') + 1);
    }

    await uploadFileWithNameAWS(file, "USERS/" + formValues.CreatedBy, filename, (data) => {
      if (data) {
        //method(data);
        setmusicfile2Url(data);
      }
      setLoading(false);
    });
  }

  const artWorkuploadFile = async (file, type, method) => {
    setLoading(true);
    setartworkBtnLoader({ [type]: true });
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const name = file.name.split(".");

    let filename = '';
    if (formValues.ArtWorkImageName === null || formValues.ArtWorkImageName === "") {
      filename = month + year + Math.floor(Date.now() / 1000) + "." + name[name.length - 1];
    }
    else {
      let url = formValues.ArtWorkImageName;
      filename = url.substring(url.lastIndexOf('/') + 1);
    }
    //const 

    await uploadFileWithNameAWS(file, "USERS/" + formValues.CreatedBy , filename, (data) => {
      if (data) {
       // method(data);
       setartworkUrl(data)
      }
      setLoading(false);
    });
  }

  const artWorkuploadFile2 = async (file, type, method) => {
    setLoading(true);
    setartworkBtnLoader2({ [type]: true });
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const name = file.name.split(".");

    let filename = '';
    if (formValues.ArtWorkImageName2 === null || formValues.ArtWorkImageName2 === "") {
      filename = month + year + Math.floor(Date.now() / 1000) + "." + name[name.length - 1];
    }
    else {
      let url = formValues.ArtWorkImageName2;
      filename = url.substring(url.lastIndexOf('/') + 1);
    }
    //const 

    await uploadFileWithNameAWS(file, "USERS/" + formValues.CreatedBy , filename, (data) => {
      if (data) {
       // method(data);
       setartwork2Url(data)
      }
      setLoading(false);
    });
  }

  const BillBoarduploadFile = async (file, type, method) => {
    setLoading(true);
    setbillboardBtnLoader({ [type]: true });
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const name = file.name.split(".");
    // const filename=month+year+Math.floor(Date.now() / 1000)+"."+name[name.length-1];
    let filename = '';
    if (formValues.BillBoardImage === null || formValues.BillBoardImage === "") {
      filename = month + year + Math.floor(Date.now() / 1000) + "." + name[name.length - 1];
    }
    else {
      let url = formValues.BillBoardImage;
      filename = url.substring(url.lastIndexOf('/') + 1);
    }
    await uploadFileWithNameAWS(file, "USERS/" + formValues.CreatedBy, filename, (data) => {
      if (data) {
       // method(data);
       setBillBoardImage(data)
      }
      setLoading(false);
    });
  }

  const vaidation = (name, value) => {
    switch (name) {
      case 'ArtWorkImageName':
        if (!value.name.match(/\.(jpg|jpeg|png)$/)) {
          return false;
        } else {
          return true;
        }
        case 'ArtWorkImageName2':
          if (!value.name.match(/\.(jpg|jpeg|png)$/)) {
            return false;
          } else {
            return true;
          }
        case 'BillBoardImage':
        if (!value.name.match(/\.(jpg|jpeg|png)$/)) {
          return false;
        } else {
          return true;
        }
      case 'MusicFileName2':
        if (!value.name.match(/\.(mp4|mp3|video)$/)) {
          return false;
        } else {
          return true;
        }
      case 'MusicFileName':
      if (!value.name.match(/\.(mp4|mp3|video)$/)) {
        return false;
      } else {
        return true;
      }
      default: {
        return true
      }
    }
  }

  const BindCampaignDetail = (id, cmdid) => {
    if (!loading === true) {
      axios.get(`${config.baseUrl}/campaign/geteditcampaigndatailsById?campaignid=${id}&cmdid=${cmdid}`)
        .then((response) => {
          const responseData = response.data.ResponseData.CampaignDetails[0];
          const cmddata = response.data.ResponseData.CampaignMasterDetails[0];
          const cmddata2 = response.data.ResponseData?.CampaignMasterDetails[1];

          setCampaignOption(response.data.ResponseData.CampaignQuestionOption);
          const { Id, CampaignName, ToolKitId, CreatedBy} = responseData;
          const SongTitle = cmddata.SongTitle;
          const SongTitle2 = cmddata2?.SongTitle;
          const ArtWorkImageName = cmddata.ArtWorkImageName;
          const ArtWorkImageName2 = cmddata2?.ArtWorkImageName;
          const BillBoardImage = cmddata.BillBoardImage;
          const CMDId = cmddata.Id;
          const MusicFileName = cmddata.MusicFileName;
          const MusicFileName2 = cmddata2?.MusicFileName;
          const CMDId2 = cmddata2?.Id;
          const BillBoardCity =  cmddata.BillBoardCityName;

          console.log(BillBoardCity);
          // console.log("SECONDEDATA",MusicFileName2,MusicFileName);

          response.data.ResponseData.CampaignQuestionOption.forEach((el) => {
            response.data.ResponseData.QuestionOption.forEach((q) => {
              if (el.QuestionId === q.Id) {
                q["OptionIds"] = el.OptionIds;
              }
            });
          })
          setQuestionOption(response.data.ResponseData.QuestionOption);
          setSelectedValue(responseData.CampaignCountryIds);          
          setFormValues({ Id, CampaignName, ToolKitId, SongTitle, SongTitle2, ArtWorkImageName,ArtWorkImageName2, BillBoardImage, CMDId,CMDId2, MusicFileName, MusicFileName2, CreatedBy,BillBoardCity });
        }).catch((err) => {
          console.log(err);
          setError(err);
        })
    }
    else {
      toastError("Somthing went wrong!");
    }
  }

  return (
    <Fragment>
      <Sidebar />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Edit Campaign</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                  <li className="breadcrumb-item active">Edit Campaign
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className='content'>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header text-right" >
                    <h3 className="card-title">Campaign</h3>
                  </div>
                  <div className="card-body">
                    <Formik  {...props}
                      validationSchema={validationSchema}
                      initialValues={formValues}
                      onSubmit={handleSubmit}
                      enableReinitialize
                    >
                      {({
                        errors,
                        touched,
                        handleChange
                      }) => (
                        <Form>
                          <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4" >
                            <Field type="hidden" className="form-control" id="Id" name="Id" />
                            <Field type="hidden" className="form-control" id="ToolKitId" name="ToolKitId" />
                            <Field type="hidden" className="form-control" id="CMDId" name="CMDId" />
                            <Field type="hidden" className="form-control" id="test" name="test" value={test} />
                            <div className='row'>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="CampaignName">Campaign Name</label>
                                  <Field type="text" className="form-control" id="CampaignName" name="CampaignName" onChange={handleChange} />                    
                                  <span className="text-danger">
                                    {touched.CampaignName && errors.CampaignName ? errors.CampaignName : ''}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Country</label>
                                  <Select value={countryoption.filter(obj => selectedValue.includes(obj.value))} name="CampaignCountryIds1" id="CampaignCountryIds1" options={countryoption} isMulti={true} isClearable={true} onChange={onChange} />
                                </div>
                              </div>
                            </div>

                            {/* Music Not A/B  */}
                            {formValues.ToolKitId !== 7 ?  
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="SongTitle">Song Title</label>
                                  <Field type="text" className="form-control" id="SongTitle" name="SongTitle" onChange={handleChange} />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <FileInput buttonTitle='Music file' id="MusicFileName" name="MusicFileName" value={musicformik?.values?.MusicFileName}
                                    touched={musicformik?.touched?.MusicFileName} errors={musicformik?.errors?.MusicFileName}
                                    handleChange={_handleMusicFileChange} loader={setmusicBtnLoader.MusicFileName} />
                                    <a href={formValues.MusicFileName} target="_blank">{formValues.SongTitle == '' ? formValues.MusicFileName : formValues.SongTitle}</a>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <FileInput buttonTitle='ArtWork Image file' id="ArtWorkImageName" name="ArtWorkImageName" value={artworkformik?.values?.ArtWorkImageName}
                                    touched={artworkformik?.touched?.ArtWorkImageName} errors={artworkformik?.errors?.ArtWorkImageName}
                                    handleChange={_handleArtWorkFileChange} loader={setartworkBtnLoader.ArtWorkImageName} />
                                  <a href={formValues.ArtWorkImageName} target="_blank">
                                    <img src={formValues.ArtWorkImageName} style={{ width: "50px", height: "50px" }} />
                                  </a>
                                </div>
                              </div>
                            </div> :''}

                             {/* Music A/B  */}
                             {formValues.ToolKitId === 3 ?                                
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="SongTitle2">Song Title</label>
                                  <Field type="text" className="form-control" id="SongTitle2" name="SongTitle2" onChange={handleChange} />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <FileInput buttonTitle='Music file2' id="MusicFileName2" name="MusicFileName2" value={musicformik2?.values?.MusicFileName2}
                                    touched={musicformik2?.touched?.MusicFileName2} errors={musicformik2?.errors?.MusicFileName2}
                                    handleChange={_handleMusicFileChange2} loader={setmusicBtnLoader2.MusicFileName2} />
                                  <a href={formValues.MusicFileName2} target="_blank">{formValues.SongTitle2 == '' ? formValues.MusicFileName2 : formValues.SongTitle2}</a>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <FileInput buttonTitle='ArtWork Image file2' id="ArtWorkImageName2" name="ArtWorkImageName2" value={artworkformik2?.values?.ArtWorkImageName2}
                                    touched={artworkformik2?.touched?.ArtWorkImageName2} errors={artworkformik2?.errors?.ArtWorkImageName2}
                                    handleChange={_handleArtWorkFileChange2} loader={setartworkBtnLoader2.ArtWorkImageName2} />
                                  <a href={formValues.ArtWorkImageName2} target="_blank">
                                    <img src={formValues.ArtWorkImageName2} style={{ width: "50px", height: "50px" }} />
                                  </a>
                                </div>
                              </div>
                            </div> : '' }

                            {/* Bilborad */}
                            {formValues.ToolKitId === 7 || formValues.ToolKitId === 1 ?  
                            <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <FileInput buttonTitle='BillBoard Image file' id="BillBoardImage" name="BillBoardImage" value={billboardformik?.values?.BillBoardImage}
                                  touched={billboardformik?.touched?.BillBoardImage} errors={billboardformik?.errors?.BillBoardImage}
                                  handleChange={_handleBillBoardFileChange} loader={setbillboardBtnLoader.BillBoardImage} />
                                  <a href={formValues.BillBoardImage} target="_blank">
                                    <img src={formValues.BillBoardImage} style={{ width: "50px", height: "50px" }} />
                                  </a>
                              </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="CampaignName">BillBoard City Name</label>
                                  <Field type="text" className="form-control" id="BillBoardCity" name="BillBoardCity" onChange={handleChange} />                    
                                  <span className="text-danger">
                                    {touched.CampaignName && errors.CampaignName ? errors.CampaignName : ''}
                                  </span>
                                </div>
                              </div>
                            </div> : ''}

                            <div className="row">
                              <div className="col-md-4">
                                {questionoption.map((u) =>
                                  <div className="form-group">
                                    <label>{u.Question}</label>
                                    <Select value={u.Option.filter(obj => u.OptionIds.includes(obj.value))} name="option" id="option" options={u.Option} isMulti={true} isClearable={true} onChange={(e) => OptiononChange(e, u.Id)} />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className='row text-right'>
                              <div className='col-md-12'>
                                <button type="submit" className="btn btn-primary">Save</button>
                                {/* <button type="button" className="btn btn-secondary">Cancel</button> */}
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>

                  {/* <div className="card-body">
                    <Formik  {...props}
                      validationSchema={validationSchema}
                      initialValues={formValues}
                      onSubmit={handleSubmit}
                      enableReinitialize
                    >
                      {({
                        errors,
                        touched,
                        handleChange
                      }) => (
                        <Form>
                          <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4" >
                            <Field type="hidden" className="form-control" id="Id" name="Id" />
                            <Field type="hidden" className="form-control" id="ToolKitId" name="ToolKitId" />
                            <Field type="hidden" className="form-control" id="CMDId" name="CMDId" />
                            <Field type="hidden" className="form-control" id="test" name="test" value={test} />
                            
                            <div className="row">
                              <div className="col-md-4">
                                {questionoption.map((u) =>
                                  <div className="form-group">
                                    <label>{u.Question}</label>
                                    <Select value={u.Option.filter(obj => u.OptionIds.includes(obj.value))} name="option" id="option" options={u.Option} isMulti={true} isClearable={true} onChange={(e) => OptiononChange(e, u.Id)} />
                                  </div>
                                )}
                              </div>
                            </div>
                            

                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div> */}

                  {/* <div className="card-body">
                    <Formik  {...props}
                      validationSchema={validationSchema}
                      initialValues={formValues}
                      onSubmit={handleSubmit}
                      enableReinitialize
                    >
                      {({
                        errors,
                        touched,
                        handleChange
                      }) => (
                        <Form>
                          <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4" >
                            <Field type="hidden" className="form-control" id="Id" name="Id" />
                            <Field type="hidden" className="form-control" id="ToolKitId" name="ToolKitId" />
                            <Field type="hidden" className="form-control" id="CMDId" name="CMDId" />
                            <Field type="hidden" className="form-control" id="test" name="test" value={test} />
                            


                            <div className="row">
                              <div className="col-md-4">
                                {questionoption.map((u) =>
                                  <div className="form-group">
                                    <label>{u.Question}</label>
                                    <Select value={u.Option.filter(obj => u.OptionIds.includes(obj.value))} name="option" id="option" options={u.Option} isMulti={true} isClearable={true} onChange={(e) => OptiononChange(e, u.Id)} />
                                  </div>
                                )}
                              </div>
                            </div>
                           

                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  )
}

export default EditCampaign

