import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Layout/footer/Footer";
import Sidebar from "../../Layout/sidebar/Sidebar";
import config from "../../config/config";
import { ToastContainer } from "react-toastify";
import { toastError } from "../../common/Toast";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { useEffect } from "react";
import CustomLoader from "../../Layout/CustomLoader";
import Select from "react-select";

const CancelCampaignList = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [perPageSize, setperPageSize] = useState(10);
  const [searchtext, setSearchText] = useState("");
  const [totalrecord, setTotalRecord] = useState(0);
  const [artistdropdownitems, setArtistDropDownItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [user, setUser] = useState(0);
  const [remountComponent, setRemountComponent] = useState(0);
  const [selectedArtistValue, setSelectedArtistValue] = useState(0);

  const handleApi = (
    pagenumber,
    pagesize,
    filtertext,
    ordercolumn,
    currentorder,
    userId
  ) => {
    // if (!loading === true) {
    setLoading(true);
    axios
      .get(
        `${config.baseUrl}/campaign/cancel-campaignList?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&userid=${userId}`
      )
      .then((response) => {
        setItems(response.data.ResponseData);

        setTotalRecord(response.data.ResponseData[0]?.TotalRecord);
        setpageCount(
          Math.ceil(response.data.ResponseData[0]?.TotalRecord / pagesize)
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setLoading(false);
      });
  };

  const BindArtistDropdown = () => {
    if (!loading === true) {
      setLoading(true);
      axios
        .get(`${config.baseUrl}/users/getallartistlist`)
        .then((response) => {
          let dropdownItemOptions = [];

          response.data.ResponseData.map((option) => {
            return (dropdownItemOptions = [
              ...dropdownItemOptions,
              { label: option.FullName, value: option.ID },
            ]);
          });

          setArtistDropDownItems(dropdownItemOptions);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const ChangeArtist = (e) => {
    setSelectedArtistValue(e);
    setRemountComponent(Math.random());
    handleApi(1, perPageSize, "", 0, "asc", e.target.value);
  };

  useEffect(() => {
    BindArtistDropdown();
    handleApi(1, perPageSize, searchtext, 0, "asc", user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePage = ({ selected }) => {
    selected = selected + 1;
    setPageIndex(selected);
    handleApi(selected, perPageSize, searchtext, 0, "asc", user);
  };

  const ChangeFilterText = (e) => {
    setSearchText(e.target.value);
    setRemountComponent(Math.random());
    handleApi(1, perPageSize, e.target.value, 0, "asc", user);
  };

  const ChangePageSize = (e) => {
    setperPageSize(e.target.value);
    handleApi(1, e.target.value, searchtext, 0, "asc", user);
  };

  const formatDate = (string) => {
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    };
    return new Date(string).toLocaleString([], options);
  };

  useEffect(() => {
    BindArtistDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      height: "40px",
      position: "relative",
      background: "#343a40",
      color: "white",
      font: "white",
    }), // dropdown input css
    menu: () => ({
      boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
      position: "absolute",
      background: "#343a40",
      width: "100%",
      color: "white",
      zIndex: 99,
    }), // dropdown menu css
  };

  return (
    <Fragment>
      <Sidebar />
      <ToastContainer />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Cancel Campaign List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Campaign </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header text-right">
                    <h3 className="card-title">Cancel Campaign List</h3>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label col-sm-12">
                            Select Artist/Business
                          </label>
                          <Select
                            backspaceRemovesValue={false}
                            controlShouldRenderValue={true}
                            isClearable={true}
                            value={selectedArtistValue}
                            options={artistdropdownitems}
                            styles={selectStyles}
                            onChange={(selectedOption) => {
                              ChangeArtist({
                                target: { value: selectedOption.value },
                              });
                              setUser(selectedOption.value);
                              setSelectedArtistValue(selectedOption);
                            }}
                          ></Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <select className="" onChange={ChangePageSize}>
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="500">500</option>
                        </select>
                      </div>

                      <div className="col-sm-6 col-md-6 text-right">
                        <div id="example1_filter" className="dataTables_filter">
                          <label>
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example1"
                              onChange={ChangeFilterText}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div
                      id="example1_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 table-responsive"
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            id="example1"
                            className="table table-bordered dataTable dtr-inline "
                            aria-describedby="example1_info"
                            style={{ width: "2600px" }}
                          >
                            <thead>
                              <tr>
                                <th
                                  className="sorting sorting_asc"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                  aria-label="ID: activate to sort column descending"
                                >
                                  ID
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="CampaignName: activate to sort column ascending"
                                >
                                  Campaign Name
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="UserName: activate to sort column ascending"
                                >
                                  User Name
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="ImpressionCount: activate to sort column ascending"
                                >
                                  ImpressionCount
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="Amount: activate to sort column ascending"
                                >
                                  Amount($)
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="OriginalTransactionId: activate to sort column ascending"
                                >
                                  OriginalTransactionId
                                </th>
                                <th
                                  className="sorting"
                                  aria-label="CreatedOn: activate to sort column ascending"
                                >
                                  Cancel On
                                </th>

                                <th
                                  className="sorting"
                                  aria-label="IsActive: activate to sort column ascending"
                                >
                                  Is Active
                                </th>

                                <th className="sorting" aria-label="Status">
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {items.length > 0 ? (
                                items.map((item) => (
                                  <tr className="odd">
                                    <td
                                      className="dtr-control sorting_1 position-relative"
                                      tabIndex="0"
                                    >
                                      {item.CampaignId}
                                    </td>
                                    <td>{item.CampaignName}</td>
                                    <td>
                                      {item.UserName}
                                      <br></br>
                                      <code>{item.UserType}</code>
                                    </td>
                                    <td>{item.ImpressionCount}</td>

                                    <td>
                                      {item.Amount > 0
                                        ? parseFloat(item.Amount).toFixed(2)
                                        : 0}
                                    </td>
                                    <td>{item.OriginalTransactionId}</td>

                                    <td>{formatDate(item.CreatedDate)}</td>
                                    <td>
                                      <span
                                        className={
                                          item.IsActive
                                            ? "right badge badge-success"
                                            : "right badge badge-danger"
                                        }
                                      >
                                        {item.IsActive ? "Yes" : "No"}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          item.CampaignProcessStatus ===
                                          "COMPLETED"
                                            ? "right badge badge-success"
                                            : "right badge badge-danger"
                                        }
                                      >
                                        {item.CampaignProcessStatus}
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={22} align="center">
                                    Data not found!
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="other_page_pagination"
                            key={remountComponent}
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={items.length > 0 ? pageCount : 0}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              pageClassName={"page-link"}
                              previousLinkClassName={"page-link"}
                              nextLinkClassName={"page-link"}
                              activeClassName={"active page-link"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
};

export default CancelCampaignList;
