/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
// import RNFetchBlob from "rn-fetch-blob";
import logoimage from "../../img/qrcode/logo.png";
import heart from "../../img/qrcode/heart.png";
// import borderimage from '../../img/qrcode/border.png'
// import codeimage from '../../img/qrcode/code.jpg'
// import FS, { } from 'fs';

// const imageToBase64 = require('image-to-base64');
// var fs = require('fs');
// import RNFetchBlob from "rn-fetch-blob";
// const fs = RNFetchBlob.fs;
// let imagePath = null;
// import * as fs from 'fs'
// import fsreact from 'fs-react';

//import ImgToBase64 from 'react-native-image-base64';
// import fs from 'fs/promises'
// import axios from 'axios';
// import fs from "fs";

// import RNFetchBlob from "rn-fetch-blob";
// const fs = RNFetchBlob.fs;

// import {
//   REACT_APP_AWSAccessKeyId,
//   REACT_APP_AWSSecretKey,
//   REACT_APP_REGION,
// } from "../../common/common";
// import {Buffer} from 'buffer';

// import AWS from "aws-sdk";

// const s3 = new AWS.S3({
//   accessKeyId: process.env.REACT_APP_AWSAccessKeyId,
//   secretAccessKey: process.env.REACT_APP_AWSSecretKey,
//   region: process.env.REACT_APP_REGION,
// });

//import {convertS3ObjectUrlToBase64 } from "../../common/AWSHelper";

const QRCodePdfDownloader = ({
  id,
  userid,
  drivername,
  qrcodeurl,
  qrcodetype,
  qraction,
  qrcodePromise,
}) => {
  qrcodePromise
    .then((val) => {
      //   console.log(val);
      setCodeUrl(val);
      console.log("CALL STRING :=>", val);
    })
    .catch((err) => console.log(err));

  const [codeUrl, setCodeUrl] = useState("");

  // let splitData =  qrcodeurl.split("USERS");

  return (
    <div id={id} className="divToDownloadQRCode">
      {qrcodetype === "DOOR" ? (
        <div
          style={{
            "font-family":
              "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
          }}
        >
          <div
            style={{
              padding: "15px",
              "max-width": "450px",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <table
              style={{
                "max-width": "450px",
                width: "100%",
                "border-collapse": "collapse",
              }}
              border="0"
              cellSpacing="0"
              cellPadding="0"
            >
              <tbody>
                <tr style={{ "background-color": "black" }}>
                  <td align="center" style={{ padding: "0" }}>
                    <img src={logoimage} height="90px" />
                  </td>
                </tr>

                <tr>
                  <th
                    style={{
                      padding: "0",
                      width: "100%",
                      "background-color": "black",
                      color: "white",
                    }}
                  >
                    <table
                      style={{ padding: "15px 25px", width: "100%" }}
                      cellSpacing="0"
                      cellPadding="0"
                      border="0"
                    >
                      <tbody align="center">
                        <tr>
                          <td
                            style={{ color: "#FFF", "padding-bottom": "5px" }}
                          >
                            {drivername}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              style={{
                                "background-image":
                                  "url('/dist/img/qrcode/border.png')",
                                "background-size": "220px 220px",
                                "background-repeat": "no-repeat",
                                "background-position": "center",
                              }}
                            >
                              <img
                                src={codeUrl}
                                height="225px"
                                width="225px"
                                style={{ padding: "15px" }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    "https://media.tenor.com/UnFx-k_lSckAAAAM/amalie-steiness.gif";
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        {/* <tr>
                                                    <td style={{ "padding-top": "0px" }}>
                                                        {<a href={qraction} style={{ "color": "#FFBB2F", fontSize: "10px" }}>{qraction}</a> }
                                                    </td>
                                                </tr> */}
                        <tr>
                          <td>
                            <p
                              style={{
                                "font-size": "20px",
                                "margin-bottom": "0px",
                                "padding-top": "20px",
                              }}
                            >
                              To discover this audio scan QR code
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              "text-align": "center",
                              padding: "20px 10px",
                              "font-weight": "300",
                            }}
                          >
                            Made With{" "}
                            <img
                              src={heart}
                              style={{ width: "30px", height: "30px" }}
                            />{" "}
                            by Numella
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          style={{
            "font-family":
              "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          }}
        >
          <table
            style={{
              "max-width": "650px",
              width: "100%",
              "border-collapse": "collapse",
              margin: "0 auto",
            }}
          >
            <tbody>
              <tr style={{ "background-color": "black" }}>
                <td align="center" style={{ padding: "10px" }}>
                  <img src={logoimage} height="90px" />
                </td>
              </tr>

              <tr>
                <th
                  style={{
                    padding: "0",
                    width: "100%",
                    "background-color": "black",
                    color: "white",
                  }}
                >
                  <table
                    style={{ width: "100%" }}
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <tbody align="center">
                      <tr>
                        <td style={{ color: "#FFF" }}>{drivername}</td>
                      </tr>
                      <tr>
                        <th height="10"></th>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              "background-image":
                                "url('/dist/img/qrcode/border.png')",
                              "background-size": "175px 175px",
                              "background-repeat": "no-repeat",
                              "background-position": "center",
                            }}
                          >
                            <img
                              src={codeUrl}
                              height="175px"
                              width="175px"
                              style={{ padding: "15px" }}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "https://media.tenor.com/UnFx-k_lSckAAAAM/amalie-steiness.gif";
                              }}
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            "border-right": "1px solid #3C4043",
                            width: "5%",
                          }}
                        ></td>
                        <td>
                          <p style={{ "font-size": "30px" }}>
                            To discover <br /> this audio scan <br /> QR code
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th height="10">&nbsp;</th>
                      </tr>
                      <tr>
                        <td>
                          {/* <a href={qraction} style={{ "color": "#FFBB2F", fontSize: "10px" }}>{qraction}</a> */}
                        </td>
                        <td style={{ width: "5%" }}></td>
                        <td
                          style={{
                            "text-align": "center",
                            "font-weight": "300",
                            "padding-right": "10px",
                          }}
                        >
                          Made With{" "}
                          <img
                            src={heart}
                            style={{ width: "30px", height: "30px" }}
                          />{" "}
                          by Numella
                        </td>
                      </tr>
                      <tr>
                        <th height="20">&nbsp;</th>
                      </tr>
                    </tbody>
                  </table>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default QRCodePdfDownloader;
