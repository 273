import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';

import Footer from '../../Layout/footer/Footer';
import Sidebar from '../../Layout/sidebar/Sidebar';
import { toastError, toastSuccess } from '../../common/Toast';
import config from '../../config/config';
import 'bootstrap/dist/css/bootstrap.min.css';

import CustomLoader from "../../Layout/CustomLoader";

const ManageCampaign = (props) => {
    const paramObject = useParams();
    let navigate = useNavigate();

    const [formValues, setFormValues] = useState({ ID: '', Name: '', ShortCode: '', IsActive: true, Type: "RICH"});
    const [loading, setLoading] = useState(false);
    const validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is a required "),
        ShortCode: Yup.string().required("Short Code is a required")
    });

    let createdby = localStorage.getItem('ID');

    const handleSubmit = (values) => {
        setLoading(true);

        axios.post(`${config.baseUrl}/campaincountry/upsert`, {
            id: values.ID,
            name: values.Name,
            shortcode: values.ShortCode,
            isactive: values.IsActive,
            createdby: createdby,
            type:values.Type
        }, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((res) => {
                if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
                    toastSuccess('Record has been added!');
                    navigate("/campaign-county-list");
                }
                else if (res.data.ResponseCode === "ERROR" && res.data.ResponseID === -1) {
                    toastError("Name already Exist!");
                }
                else {
                    toastError("Somthing went wrong");
                }

                setLoading(false);
            }).catch((error) => {
                console.log("error occured", error);

                setLoading(false);
            });
    }

    useEffect(() => {
        if (paramObject.id > 0) {
            setLoading(true);

            axios.get(`${config.baseUrl}/campaincountry/findbyid?id=${paramObject.id}`)
                .then((response) => {
                    const responseData = response.data.ResponseData[0];
                    const { ID, Name, ShortCode,Type } = responseData;
                    const IsActive = responseData.IsActive === 1 ? true : false;
                    setFormValues({ ID, Name, ShortCode, IsActive, Type});

                    setLoading(false);
                }).catch((err) => {
                    console.log(err);

                    setLoading(false);
                })
        }
    }, []);

    return (
        <Fragment>
            <Sidebar />
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Campain Country </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">Campaign Country</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header text-right" >
                                        <h3 className="card-title">Manage </h3>
                                    </div>
                                    <div className="card-body">
                                        {/* <ManageForm
                                        initialValues={formValues}
                                        onSubmit={handleSubmit}
                                        enableReinitialize
                                        >
                                        Update
                                    </ManageForm> */}

                                        <Formik  {...props}
                                            validationSchema={validationSchema}
                                            initialValues={formValues}
                                            onSubmit={handleSubmit}
                                            enableReinitialize
                                        >
                                            {({
                                                errors,
                                                touched,
                                                handleChange
                                            }) => (
                                                <Form>
                                                    <div className="dataTables_wrapper dt-bootstrap4" >
                                                        <Field type="hidden" className="form-control" id="ID" name="ID" />

                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="name"> Name</label>
                                                                    <Field type="text" className="form-control" id="Name" name="Name" onChange={handleChange} />
                                                                    <span className="text-danger">
                                                                        {touched.Name && errors.Name ? errors.Name : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Short Code</label>
                                                                    <Field type="text" className="form-control" id="ShortCode" name="ShortCode" onChange={handleChange} />
                                                                    <span className="text-danger">
                                                                        {touched.ShortCode && errors.ShortCode ? errors.ShortCode : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type"> Type</label>
                                                                    <Field as="select" id="Type" name="Type" className='form-control custom-select2-dropdown' onChange={handleChange} >
                                                                        <option value="RICH">RICH</option>
                                                                        <option value="POOR">POOR</option>
                                                                    </Field>
                                                                    <span className="text-danger">
                                                                        {touched.Type && errors.Type ? errors.Type : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <Field type="checkbox" className="form-check-input" id="IsActive" name='IsActive' />
                                                                        <label className="form-check-label" htmlFor="IsActive">Active</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row text-right'>
                                                            <div className='col-md-12 me-2'>
                                                                <button type="submit" className="btn  me-2 btn-primary" >Save</button>
                                                                <Link to="/campaign-county-list" className="btn btn-secondary">Cancel</Link>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </Fragment>
    )
}

export default ManageCampaign