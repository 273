import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import Footer from '../../Layout/footer/Footer';
import Sidebar from '../../Layout/sidebar/Sidebar';
import { toastError, toastSuccess } from '../../common/Toast';
import config from '../../config/config';
import 'bootstrap/dist/css/bootstrap.min.css';

const ManageConfigSetting = (props) => {

    const paramObject = useParams();
    let navigate = useNavigate();

    const [formValues, setFormValues] = useState({ Id: 0, Settingkey: '', Settingvalueskey: '', SettingType: '' });

    const validationSchema = Yup.object().shape({
        Settingkey: Yup.string().required("Setting key is required "),
        Settingvalueskey: Yup.string().required("Settingvalueskey is required"),
        SettingType: Yup.string().required("Setting Type is required")
    });

    let createdby = localStorage.getItem('ID');

    const handleSubmit = (values) => {

        axios.post(`${config.baseUrl}/settings/settingupsert`, {
            id: values.Id,
            settingtype: values.SettingType,
            settingkey: values.Settingkey,
            settingvalueskey: values.Settingvalueskey,
            CreatedDate: createdby
        }, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((res) => {
                if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
                    toastSuccess('Record has been added!');
                    navigate("/configsetting-list");
                }
                else if (res.data.ResponseCode === "ERROR" && res.data.ResponseID === -1) {
                    toastError("Name already Exist!");
                }
                else {
                    toastError("Somthing went wrong");
                }
            }).catch((error) => {
                console.log("error occured", error);
            });
    }

    useEffect(() => {
        if (paramObject.id > 0) {
            axios.get(`${config.baseUrl}/settings/settingselectbyid?id=${paramObject.id}`)
                .then((response) => {
                    const responseData = response.data.ResponseData[0];
                    const { Id, Settingkey, Settingvalueskey, SettingType } = responseData;
                    const IsActive = responseData.IsActive === 1 ? true : false;
                    setFormValues({ Id, Settingkey, Settingvalueskey, SettingType, IsActive });

                }).catch((err) => {
                    console.log(err);
                })
        }
    }, []);
    return (
        <Fragment>
            <Sidebar />
            <ToastContainer />

            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1> Manage ConfigSetting</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">ConfigSetting</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header text-right" >
                                        <h3 className="card-title">Manage</h3>
                                    </div>
                                    <div className="card-body">
                                        {/* <ManageForm
                                        initialValues={formValues}
                                        onSubmit={handleSubmit}
                                        enableReinitialize
                                        >
                                        Update
                                    </ManageForm> */}

                                        <Formik  {...props}
                                            validationSchema={validationSchema}
                                            initialValues={formValues}
                                            onSubmit={handleSubmit}
                                            enableReinitialize
                                        >
                                            {({
                                                errors,
                                                touched,
                                                handleChange
                                            }) => (
                                                <Form>
                                                    <div className="dataTables_wrapper dt-bootstrap4" >
                                                        <Field type="hidden" className="form-control" id="Id" name="Id" />

                                                        <div className='row'>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="SettingType">Setting Type</label>
                                                                    <Field type="text" className="form-control" id="SettingType" name="SettingType" onChange={handleChange} />
                                                                    <span className="text-danger">
                                                                        {touched.SettingType && errors.SettingType ? errors.SettingType : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="Settingkey"> Settingkey</label>
                                                                    <Field type="text" className="form-control" id="Settingkey" name="Settingkey" onChange={handleChange} />
                                                                    <span className="text-danger">
                                                                        {touched.Settingkey && errors.Settingkey ? errors.Settingkey : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="Settingvalueskey">Settingvalues key</label>
                                                                    <Field type="text" className="form-control" id="Settingvalueskey" name="Settingvalueskey" onChange={handleChange} />
                                                                    <span className="text-danger">
                                                                        {touched.Settingvalueskey && errors.Settingvalueskey ? errors.Settingvalueskey : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row text-right'>
                                                            <div className='col-md-12 me-2'>
                                                                <button type="submit" className="btn me-2 btn-primary" >Save</button>
                                                                <Link to="/configsetting-list" className="btn btn-secondary">Cancel</Link>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />

        </Fragment>
    )
}

export default ManageConfigSetting