import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from 'yup';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';

import Footer from '../../Layout/footer/Footer';
import Sidebar from '../../Layout/sidebar/Sidebar';
import { toastError, toastSuccess } from '../../common/Toast';
import config from '../../config/config';
import 'bootstrap/dist/css/bootstrap.min.css';

import FileInput from "../FormControles/FileInput";
import { uploadAWS } from "../../common/AWSHelper";

import CustomLoader from "../../Layout/CustomLoader";

const ManageToolKit = (props) => {
    const paramObject = useParams();
    let navigate = useNavigate();
    let createdby = localStorage.getItem('ID');

    const [fieldvalue, setfieldvalue] = useState('');
    const [formValues, setFormValues] = useState({ Id: 0, ToolKitName: '', ToolKitDescripation: '', ToolKitImage: '', PlanType: '', IsActive: true, UserTypeID : 2 });
    const [toolKitImageUrl, setToolKitImage] = useState('');
    const [changeToolKitImageUrl, setChangeToolKitImageUrl] = useState(false);
    const [loading, setLoading] = useState(false);

    const valdationSchema = Yup.object().shape({
        ToolKitName: Yup.string().required("Name is required"),
        ToolKitDescripation: Yup.string().required("Description is required"),
        // ToolKitImage: Yup.mixed().required(" Required ! "),
        PlanType: Yup.string().required("PlanType is required"),
        UserTypeID: Yup.string().required("User Type is required"),
    });

    const handleSubmit = (values) => {
        setLoading(true);

        axios.post(`${config.baseUrl}/campaintoolkit/upsert`, {
            id: values.Id,
            toolkit_name: values.ToolKitName,
            toolkit_descripation: values.ToolKitDescripation,
            toolkit_image: (paramObject.id > 0 && !changeToolKitImageUrl) ? values.ToolKitImage : toolKitImageUrl,
            plantype: values.PlanType,
            isactive: values.IsActive,
            createdby: createdby,
            usertypeid: values.UserTypeID
        }, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((res) => {
                if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
                    toastSuccess('Record has been added!');
                    navigate("/toolkit-list");
                }
                else if (res.data.ResponseCode === "ERROR" && res.data.ResponseID === -1) {
                    toastError("Name already Exist!");
                }
                else {
                    toastError("Somthing went wrong");
                }

                setLoading(false);
            }).catch((error) => {
                console.log("error occured", error);

                setLoading(false);
            });
    }

    useEffect(() => {
        if (paramObject.id > 0) {
            setLoading(true);

            axios.get(`${config.baseUrl}/campaintoolkit/findbyid?id=${paramObject.id}`)
                .then((response) => {
                    const responseData = response.data.ResponseData[0];
                    const { Id, ToolKitName, ToolKitDescripation, ToolKitImage, PlanType, UserTypeID } = responseData;
                    const IsActive = responseData.IsActive === 1 ? true : false;

                    setFormValues({ Id, ToolKitName, ToolKitDescripation, ToolKitImage, PlanType, IsActive, UserTypeID });

                    setLoading(false);
                }).catch((err) => {
                    console.log(err);

                    setLoading(false);
                })
        }
    }, []);


    /* AWS FILE UPLOAD */
    const [btnLoader, setBtnLoader] = useState({ ToolKitImage: false });
    const validate = (values) => {
        let errors = {};
        if (!values?.ToolKitImage?.name.match(/\.(jpg|jpeg|png)$/)) {
            errors.ToolKitImage = 'Only accept .jpg, .jpeg, .png file';
        }
        return errors;
    }
    const vaidation = (name, value) => {
        switch (name) {
            case 'ToolKitImage':
                if (!value.name.match(/\.(jpg|jpeg|png)$/)) {
                    return false;
                } else {
                    return true;
                }
            default: {
                return true
            }
        }
    }
    const formik = useFormik({
        initialValues: { ToolKitImage: null },
        validate,
        onSubmit: values => {

        }
    })
    const _handleFileChange = (e) => {
        setChangeToolKitImageUrl(true);
        formik.setFieldValue([e.target.name], e?.currentTarget?.files[0])
        // console.log("_handleFileChange =>", vaidation(e.target.name, e?.currentTarget?.files[0]));

        if (vaidation(e.target.name, e?.currentTarget?.files[0])) {
            uploadFile(e?.currentTarget?.files[0], e.target.name);
        }
    }
    const uploadFile = async (file, type) => {
        setBtnLoader({ [type]: true });
        await uploadAWS(file, "TOOLKIT", (data) => {
            if (data) {
                setToolKitImage(data);
            }
        });
    }
    /* END AWS FILE UPLOAD */


    return (
        <Fragment>
            <Sidebar />
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage ToolKit</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">ToolKit</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header text-right" >
                                        <h3 className="card-title">Manage</h3>
                                    </div>
                                    <div className="card-body">
                                        {/* <ManageForm
                                        initialValues={formValues}
                                        onSubmit={handleSubmit}
                                        enableReinitialize
                                        >
                                        Update
                                    </ManageForm> */}

                                        <Formik  {...props}
                                            validationSchema={valdationSchema}
                                            initialValues={formValues}
                                            onSubmit={handleSubmit}
                                            enableReinitialize
                                        >
                                            {({
                                                errors,
                                                touched,
                                                handleChange
                                            }) => (
                                                <Form>
                                                    <div className="dataTables_wrapper dt-bootstrap4" >
                                                        <Field type="hidden" className="form-control" id="Id" name="Id" />

                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Toolkit Name</label>
                                                                    <Field type="text" className="form-control" id="ToolKitName" name="ToolKitName" onChange={handleChange} />
                                                                    <span className="text-danger">
                                                                        {touched.ToolKitName && errors.ToolKitName ? errors.ToolKitName : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Toolkit Description</label>
                                                                    <Field as="textarea" className="form-control" id="ToolKitDescripation" name="ToolKitDescripation" onChange={handleChange} />
                                                                    <span className="text-danger">
                                                                        {touched.ToolKitDescripation && errors.ToolKitDescripation ? errors.ToolKitDescripation : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <FileInput
                                                                        buttonTitle='ToolKit Image'
                                                                        id="ToolKitImage"
                                                                        name="ToolKitImage"
                                                                        value={formik?.values?.ToolKitImage}
                                                                        touched={formik?.touched?.ToolKitImage}
                                                                        errors={formik?.errors?.ToolKitImage}
                                                                        handleChange={_handleFileChange}
                                                                        loader={btnLoader.ToolKitImage}
                                                                    />
                                                                    {/* <label htmlFor="name">Image</label>
                                                                    <div className="input-group">
                                                                        <div className="custom-file">
                                                                            <Field type="file" name="Image" accept="image/*" onChange={(e) => setfieldvalue(" image ", e.target.files[0])} />
                                                                            <label className="custom-file-label" htmlFor="name">Choose file</label>
                                                                            <span className="text-danger" style={{ marginTop: "15%" }}>
                                                                                {touched.Image && errors.Image ? errors.Image : ''}
                                                                            </span>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="PlanType">Plan Type</label>
                                                                    <Field as="select" id="PlanType" name="PlanType" className="form-control" onChange={handleChange}>
                                                                        <option value="">Select Plan Type</option>
                                                                        <option value="SUBSCRIPTION">Subscription</option>
                                                                        <option value="NOPAYMENT">No Payment</option>
                                                                        <option value="ONETIME">One Time</option>
                                                                    </Field>
                                                                    <span className="text-danger">
                                                                        {touched.PlanType && errors.PlanType ? errors.PlanType : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-6'>

                                                                <div className='form-group'>
                                                                    <label className='form-label col-sm-6'>User Type</label>
                                                                    <div className='col-sm-10'>
                                                                        <Field as="select" id="UserTypeID" name="UserTypeID" className="form-control" onChange={handleChange}>
                                                                        <option value={2}>Artist</option>
                                                                            <option value={4}>Business</option>
                                                                        </Field>
                                                                        <span className="text-danger">
                                                                            {touched.UserTypeID && errors.UserTypeID ? errors.UserTypeID : ''}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className="form-group">
                                                                    <div class="form-check">
                                                                        <Field type="checkbox" class="form-check-input" id="IsActive" name='IsActive' />
                                                                        <label className="form-check-label" for="IsActive">Active</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row text-right'>
                                                            <div className='col-md-12 me-2'>
                                                                <button type="submit" className="btn  me-2 btn-primary" >Save</button>
                                                                <Link to="/toolkit-list" className="btn btn-secondary">Cancel</Link>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </Fragment>
    )
}

export default ManageToolKit