import React, { useState, useEffect, Fragment } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";

import Footer from "../../Layout/footer/Footer";
import Sidebar from "../../Layout/sidebar/Sidebar";
import { toastError, toastSuccess } from "../../common/Toast";
import config from "../../config/config";
import "bootstrap/dist/css/bootstrap.min.css";

import FileInput from "../FormControles/FileInput";
import { uploadAWS } from "../../common/AWSHelper";

import CustomLoader from "../../Layout/CustomLoader";
import Select from "react-select";

const ManageStory = (props) => {
  const paramObject = useParams();
  let navigate = useNavigate();
  let createdby = localStorage.getItem("ID");

  const [formValues, setFormValues] = useState({
    Id: 0,
    Name: "",
    ScheduleDate: "",
    Type: "",
    SourceFileName: "",
    StoryContent: "",
    LinkURL: "",
    IsActive: true,
    NotificationBy: "",
    selectUserType: "",
    selectUser: "",
    selectQCategoryType: "",
    selectQuestionType: "",
    title: "",
    msg: "",
  });
  const [typestate, settypestate] = useState([]);
  const [sourceFileUrl, setSourceFileUrl] = useState("");
  const [sourceFileDuration, setSourceFileDuration] = useState(0);
  const [changeSourceFile, setChangeSourceFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showhide, setShowhide] = useState("");
  const [useroption, setUserOption] = useState("");
  const [userQuestion, setUserQuestion] = useState("");
  const [userQuestionOption, setUserQuestionOption] = useState("");
  const [usertype, setUsertype] = useState("");
  const [user, setUser] = useState("");
  const [dropdownitems, setDropDownItems] = useState([]);
  const [usertypedropdownitems, setUserTypeDropDownItems] = useState([]);
  const [QueCategorydropdownitems, setOptionCategoryDropDownItems] = useState(
    []
  );
  const [error, setError] = useState(null);
  const [questiondropdownitems, setQuestionDropDownItems] = useState([]);
  const [optiondropdownitems, setOptionDropDownItems] = useState([]);
  const [optionid, setOptionID] = useState([]);
  const [userQuestionType, setUserQuestionType] = useState("");
  const [notificationtitle, setNotificationTitle] = useState("");
  const [notificationmessage, setNotificationMessage] = useState("");
  const [notificationby, setNotificationBy] = useState("");

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Name is a required "),
    ScheduleDate: Yup.string().required("Schedule Date is a required "),
    Type: Yup.string().required("Type is a required"),
    //NotificationBy: Yup.string().required("Please select notificationby"),
  });

  const handleSubmit = (values) => {
    let qoptionid = "";
    let notification = "";
    console.log(values.NotificationBy);
    if (values.NotificationBy === "2") {
      notification = "User";
    } else if (values.NotificationBy === "3") {
      notification = "UserType";
    } else if (values.NotificationBy === "4") {
      notification = "Question";
    } else {
      notification = values.NotificationBy;
    }
    if (optionid.length > 0) {
      qoptionid = optionid.join(",");
    } else {
      qoptionid = "";
    }

    setLoading(true);

    axios
      .post(
        `${config.baseUrl}/story/upsert`,
        {
          id: values.Id,
          name: values.Name,
          scheduledate: values.ScheduleDate,
          sourcefilename:
            paramObject.id > 0 && !changeSourceFile
              ? values.SourceFileName
              : sourceFileUrl, // values.SourceFileName,
          storycontent: values.StoryContent,
          storyduration: sourceFileDuration,
          type: values.Type,
          linkurl: values.LinkURL,
          isactive: values.IsActive,
          createdby: createdby,
          notificationby: notification,
          usertypeid: values.selectUserType,
          userid: user, // values.selectUser,
          questioncategoryid: values.selectQCategoryType,
          questionid: values.selectQuestionType,
          questionoptionids: qoptionid.toString(),
          title: values.title,
          message: values.msg,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
          toastSuccess("Record has been added!");
          navigate("/story-list");
        } else {
          toastError("Somthing went wrong");
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log("error occured", error);

        setLoading(false);
      });
  };

  const handleNotifictionby = (e) => {
    let id = e.target.value;
    if (id == 2) {
      handleUserDropdownApi();
    } else if (id == 3) {
      handleUserTypeDropdownApi();
    } else if (id == 4) {
      handleQueCategoryDropdownApi();
    }

    // document.getElementById("selectUser").value = "";
    setNotificationBy(id);
    setShowhide(id);
    setUserOption(0);
    setUserQuestion(0);
    setUsertype(0);
    setUser(0);
  };

  const handleUserDropdownApi = () => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(`${config.baseUrl}/users/fordropdown`)
        .then((response) => {
          let dropdownItemOptions = [];

          response.data.ResponseData.map((option) => {
            dropdownItemOptions = [
              ...dropdownItemOptions,
              { label: option.FullName, value: option.ID },
            ];
          });

          console.log(dropdownItemOptions);
          setDropDownItems(dropdownItemOptions);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const handleUserTypeDropdownApi = () => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(`${config.baseUrl}/users/allusertype`)
        .then((response) => {
          setUserTypeDropDownItems(response.data.ResponseData);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const handleQueCategoryDropdownApi = () => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(`${config.baseUrl}/questions/findAllCategory`)
        .then((response) => {
          setOptionCategoryDropDownItems(response.data.ResponseData);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const handleQuestionChangeType = (e) => {
    setUserQuestion(0);
    let id = e.target.value;
    handleQuestionDropdownApi(id);
    if (id != "All" && id != "") {
      setUserOption(id);
    } else {
      setUserOption(0);
    }
  };

  const handleChangeQuestion = (e) => {
    const getQuestion = e.target.value;
    setUserQuestion(getQuestion);
    handleOptionDropdownApi(getQuestion);
  };

  const handleQuestionDropdownApi = (id) => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(`${config.baseUrl}/questions/findAllQuestionusertype?Id=${id}`)
        .then((response) => {
          setQuestionDropDownItems(response.data.ResponseData);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const handleOptionDropdownApi = (id) => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(`${config.baseUrl}/questions/findbyQuestionid?QuestionID=${id}`)
        .then((response) => {
          setOptionDropDownItems(response.data.ResponseData);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const checkboxhandleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setUserQuestionOption(value);
      // push selected value in list
      setOptionID((prev) => [...prev, value]);
    } else {
      // remove unchecked value from the list
      setOptionID((prev) => prev.filter((x) => x !== value));
    }
  };

  useEffect(() => {
    if (paramObject.id > 0) {
      setLoading(true);

      axios
        .get(`${config.baseUrl}/story/findbyid?id=${paramObject.id}`)
        .then((response) => {
          const responseData = response.data.ResponseData[0];
          const { Id, Name, Type, SourceFileName, StoryContent, LinkURL } =
            responseData;

          const title = "",
            NotificationBy = "",
            selectUserType = "",
            selectUser = "",
            selectQCategoryType = "",
            selectQuestionType = "",
            msg = "";

          const IsActive = responseData.IsActive === 1 ? true : false;
          let ScheduleDate = responseData.ScheduleDate.split("."); // Sample Formate : "2022-09-29T11:13:00" ;
          ScheduleDate = ScheduleDate[0];

          setFormValues({
            Id,
            Name,
            ScheduleDate,
            Type,
            SourceFileName,
            StoryContent,
            LinkURL,
            IsActive,
            NotificationBy,
            selectUserType,
            selectUser,
            selectQCategoryType,
            selectQuestionType,
            title,
            msg,
          });
          settypestate(Type);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setLoading(false);
        });
    }
  }, []);

  const handleChangeStoryType = (e) => {
    settypestate(e.target.value);
  };

  /* AWS FILE UPLOAD */
  const [btnLoader, setBtnLoader] = useState({ SourceFileName: false });
  const validate = (values) => {
    let errors = {};

    // alert("validate : " + values?.SourceFileName?.name);
    let fileName = values?.SourceFileName?.name.toLowerCase(); //To convert Lower Case

    if (
      !fileName.match(
        /\.(jpg|jpeg|png|gif|mp4|hevc|heic|heif|mov|ogv|avi|mp4|wmv|mkv|mpg|3g2|flv|webm|3gp|mpeg4|mts|m2ts|mxf)$/
      )
    ) {
      errors.SourceFileName =
        "Only accept .jpg, .jpeg, .png .gif .mp4, .hevc, .heic, .heif, .mov, .ogv, .avi, .mp4, .wmv, .mkv, .mpg, .3g2, .flv, .webm, .3gp, .mpeg4, .mts, .m2ts, .mxf file";
    }
    return errors;
  };

  const vaidation = (name, value) => {
    // alert("vaidation : " + value.name);
    let fileName = value.name.toLowerCase(); //To convert Lower Case
    switch (name) {
      case "SourceFileName":
        if (
          !fileName.match(
            /\.(jpg|jpeg|png|gif|mp4|hevc|heic|heif|mov|ogv|avi|mp4|wmv|mkv|mpg|3g2|flv|webm|3gp|mpeg4|mts|m2ts|mxf)$/
          )
        ) {
          return false;
        } else {
          return true;
        }
      default: {
        return true;
      }
    }
  };

  const formik = useFormik({
    initialValues: { SourceFileName: null },
    validate,
    onSubmit: (values) => {},
  });

  const _handleFileChange = (e) => {
    setChangeSourceFile(true);
    formik.setFieldValue([e.target.name], e?.currentTarget?.files[0]);
    // console.log("_handleFileChange =>", vaidation(e.target.name, e?.currentTarget?.files[0]));

    if (vaidation(e.target.name, e?.currentTarget?.files[0])) {
      uploadFile(e?.currentTarget?.files[0], e.target.name);
      //
      let file = e?.currentTarget?.files[0];
      if (file) {
        const isVideo = typestate == "Video";
    
        if (isVideo) {
          const videoURL = URL.createObjectURL(file);
          const videoElement = document.createElement('video');
          videoElement.src = videoURL;
    
          videoElement.onloadedmetadata = () => {
            const duration = videoElement.duration;
            setSourceFileDuration(duration);
          };
        } else {
          // Handle images or other non-video files here
        }
      }
    }
  };

  const uploadFile = async (file, type) => {
    setLoading(true);
    setBtnLoader({ [type]: true });
    await uploadAWS(file, "STORY", (data) => {
      if (data) {
        setSourceFileUrl(data);
      }
      setLoading(false);
    });
  };
  /* END AWS FILE UPLOAD */

  const [selectedUserValue, setSelectedUserValue] = useState(0);
  useEffect(() => {
    handleUserDropdownApi();

    if (paramObject.id > 0) {
      let selectUser = paramObject.id;
      setFormValues({ selectUser });
    }
  }, []);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      height: "40px",
      position: "relative",
      background: "#343a40",
      color: "white",
      font: "white",
    }), // dropdown input css
    menu: () => ({
      boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
      position: "absolute",
      background: "#343a40",
      width: "100%",
      color: "white",
      zIndex: 99,
    }), // dropdown menu css
  };

  const formatDate = (string) => {
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    };
    return new Date(string).toLocaleString([], options);
  };

  return (
    <Fragment>
      <Sidebar />
      <ToastContainer />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Manage Story </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Story</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header text-right">
                    <h3 className="card-title">Manage</h3>
                  </div>
                  <div className="card-body">
                    <Formik
                      {...props}
                      validationSchema={validationSchema}
                      initialValues={formValues}
                      onSubmit={handleSubmit}
                      enableReinitialize
                    >
                      {({ errors, touched, handleChange }) => (
                        <Form>
                          <div className={paramObject.id ? "d-none" : ""}>
                            <div
                              id="example1_wrapper"
                              className="dataTables_wrapper dt-bootstrap4"
                            >
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Notification By</label>
                                    <Field
                                      as="select"
                                      className="form-control"
                                      id="NotificationBy"
                                      name="NotificationBy"
                                      value={notificationby}
                                      onInput={(e) => {
                                        handleNotifictionby(e);
                                      }}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setUser("");
                                        setUsertype("");
                                        setUserQuestionType("");
                                        setUserQuestion("");
                                        setUserOption("");
                                        setNotificationTitle("");
                                        setNotificationMessage("");
                                      }}
                                    >
                                      <option value="">Select</option>
                                      <option value="All">All</option>
                                      <option value="2">User</option>
                                      <option value="3">UserType</option>
                                      <option value="4">Question</option>
                                    </Field>
                                    <span className="text-danger">
                                      {touched.NotificationBy &&
                                      errors.NotificationBy
                                        ? errors.NotificationBy
                                        : ""}
                                    </span>
                                  </div>
                                </div>

                                {/* Notification by user */}

                                <div
                                  className={
                                    showhide === "2"
                                      ? "form-group col-md-6"
                                      : "form-group d-none"
                                  }
                                >
                                  {/* <div className="form-group"> */}
                                  <label>User</label>
                                  <Select
                                    backspaceRemovesValue={false}
                                    controlShouldRenderValue={true}
                                    isClearable={true}
                                    value={selectedUserValue}
                                    id="selectUser"
                                    name="selectUser"
                                    placeholder="Please select a User"
                                    options={dropdownitems}
                                    styles={selectStyles}
                                    onChange={(e) => {
                                      // handleChange(e);
                                      setUser(e.value);
                                      setSelectedUserValue(e);
                                    }}
                                  />

                                  <span className="text-danger">
                                    {touched.selectUser && errors.selectUser
                                      ? errors.selectUser
                                      : ""}
                                  </span>
                                  {/* </div> */}
                                </div>

                                {/* Notification by user type */}
                                <div
                                  className={
                                    showhide === "3"
                                      ? "form-group col-md-6"
                                      : "form-group d-none"
                                  }
                                >
                                  <label>User Type</label>
                                  <Field
                                    as="select"
                                    value={usertype}
                                    className="form-control"
                                    id="selectUserType"
                                    name="selectUserType"
                                    onChange={(e) => {
                                      handleChange(e);
                                      setUsertype(e.target.value);
                                    }}
                                  >
                                    <option value="">Select Type</option>
                                    <option value="All">All</option>
                                    {usertypedropdownitems.map((u) => (
                                      <option value={u.ID}>{u.UserType}</option>
                                    ))}
                                  </Field>
                                  <span className="text-danger">
                                    {touched.selectUserType &&
                                    errors.selectUserType
                                      ? errors.selectUserType
                                      : ""}
                                  </span>
                                </div>

                                {/* Notification by question */}
                                <div
                                  className={
                                    showhide === "4"
                                      ? "form-group col-md-6"
                                      : "form-group d-none"
                                  }
                                >
                                  <label>User Type</label>
                                  <Field
                                    as="select"
                                    className="form-control"
                                    value={userQuestionType}
                                    id="selectQCategoryType"
                                    name="selectQCategoryType"
                                    onInput={(e) => {
                                      handleQuestionChangeType(e);
                                      setUserQuestionType(e.target.value);
                                    }}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Type</option>
                                    <option value="All">All</option>
                                    {QueCategorydropdownitems.map((u) => (
                                      <option value={u.Id}>{u.Name}</option>
                                    ))}
                                  </Field>
                                  <span className="text-danger">
                                    {touched.selectQCategoryType &&
                                    errors.selectQCategoryType
                                      ? errors.selectQCategoryType
                                      : ""}
                                  </span>
                                </div>

                                {/* Question Usertype Others */}
                                <div
                                  className={
                                    useroption > 0
                                      ? "form-group"
                                      : "form-group d-none"
                                  }
                                >
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Add Question</label>
                                        <Field
                                          as="select"
                                          className="form-control"
                                          id="selectQuestionType"
                                          name="selectQuestionType"
                                          value={userQuestion}
                                          onInput={(e) => {
                                            handleChangeQuestion(e);
                                          }}
                                          onChange={handleChange}
                                        >
                                          <option value="">Select</option>
                                          {/* <option value="All">All</option> */}
                                          {questiondropdownitems.map((u) => (
                                            <option value={u.Id}>
                                              {u.Question}
                                            </option>
                                          ))}
                                        </Field>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* display option after selecting question  */}
                                <div
                                  className={
                                    userQuestion > 0
                                      ? "form-group"
                                      : "form-group d-none"
                                  }
                                >
                                  <div className="col-md-6">
                                    <div id="checkbox-group">
                                      {optiondropdownitems.map((x, i) => (
                                        <div key={i}>
                                          <input
                                            type="checkbox"
                                            name="optionid"
                                            value={x.ID}
                                            onChange={(e) => {
                                              handleChange(e);
                                              checkboxhandleChange(e);
                                            }}
                                          />{" "}
                                          {x.QuestionOption}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>

                                {/* Notification by all */}
                                <div
                                  className={
                                    showhide === "All" ||
                                    user === "All" ||
                                    user > 0 ||
                                    usertype === "All" ||
                                    usertype > 0 ||
                                    userQuestionType === "All" ||
                                    userQuestionOption > 0
                                      ? "form-group"
                                      : "form-group d-none"
                                  }
                                >
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label htmlFor="title">Title</label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          id="title"
                                          name="title"
                                          value={notificationtitle}
                                          onChange={(e) => {
                                            handleChange(e);
                                            setNotificationTitle(
                                              e.target.value
                                            );
                                          }}
                                        />
                                        <span className="text-danger">
                                          {touched.title && errors.title
                                            ? errors.title
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Message</label>
                                        <Field
                                          type="textarea"
                                          className="form-control"
                                          rows="3"
                                          placeholder="Enter ..."
                                          id="msg"
                                          name="msg"
                                          value={notificationmessage}
                                          onChange={(e) => {
                                            handleChange(e);
                                            setNotificationMessage(
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr />

                          <div className="dataTables_wrapper dt-bootstrap4">
                            <Field
                              type="hidden"
                              className="form-control"
                              id="Id"
                              name="Id"
                            />

                            <div className="row">
                              {/* <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="UserTypeId">User Type</label>
                                                                    <Field as="select" className="form-control" id="UserTypeId" name="UserTypeId" onChange={handleChange}>
                                                                        <option value="">All User</option>
                                                                        <option value="2">Artist</option>
                                                                        <option value="3">Driver</option>
                                                                        <option value="4">Business</option>
                                                                    </Field>

                                                                </div>
                                                            </div> */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="Type">Story Type</label>
                                  <Field
                                    as="select"
                                    className="form-control"
                                    id="Type"
                                    name="Type"
                                    value={typestate}
                                    onInput={(e) => {
                                      handleChangeStoryType(e);
                                    }}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Type</option>
                                    <option value="Image">Image</option>
                                    <option value="Video">Video</option>
                                    <option value="Text">Text</option>
                                  </Field>
                                  <span className="text-danger">
                                    {touched.Type && errors.Type
                                      ? errors.Type
                                      : ""}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="name">Name</label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    id="Name"
                                    name="Name"
                                    placeholder="Please Provide Name"
                                    onChange={handleChange}
                                  />
                                  <span className="text-danger">
                                    {touched.Name && errors.Name
                                      ? errors.Name
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    Scheduled Date (UTC) (Current UTC DateTime:{" "}
                                    {formatDate(new Date())} )
                                  </label>
                                  <Field
                                    type="datetime-local"
                                    className="form-control"
                                    name="ScheduleDate"
                                    id="ScheduleDate"
                                    dateFormat="MM/dd/yyyy hh:mm tt"
                                    onChange={handleChange}
                                  />
                                  <span className="text-danger">
                                    {touched.ScheduleDate && errors.ScheduleDate
                                      ? errors.ScheduleDate
                                      : ""}
                                  </span>
                                  {/* <div className="input-group date" id="reservationdatetime" data-target-input="nearest">
                                                            <Field type="text" className="form-control datetimepicker-input" data-target="#reservationdatetime" />
                                                            <div className="input-group-append" data-target="#reservationdatetime" data-toggle="datetimepicker">
                                                                <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                                                            </div>
                                                        </div> */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div
                                  className={
                                    typestate != "Text"
                                      ? "form-group"
                                      : "form-group d-none"
                                  }
                                >
                                  <FileInput
                                    buttonTitle="Source File"
                                    id="SourceFileName"
                                    name="SourceFileName"
                                    value={formik?.values?.SourceFileName}
                                    touched={formik?.touched?.SourceFileName}
                                    errors={formik?.errors?.SourceFileName}
                                    handleChange={_handleFileChange}
                                    loader={btnLoader.SourceFileName}
                                  />
                                </div>
                                {/* <div className={typestate != "Text" ? "form-group":"form-group d-none"}>
                                                        <label htmlFor="SourceFileName">Source File</label>
                                                        <div className="input-group">
                                                            <div className="custom-file">
                                                                <Field type="file" className="custom-file-input" id="SourceFileName" name="SourceFileName" accept="image/*,video/*" />
                                                                <label className="custom-file-label" htmlFor="SourceFileName">Choose file</label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="LinkURL">Link Url</label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    id="LinkURL"
                                    name="LinkURL"
                                    placeholder="Enter url..."
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div
                                  className={
                                    typestate == "Text"
                                      ? "form-group"
                                      : "form-group d-none"
                                  }
                                >
                                  <label htmlFor="StoryContent">
                                    Story Content
                                  </label>
                                  <Field
                                    as="textarea"
                                    className="form-control"
                                    id="StoryContent"
                                    name="StoryContent"
                                    rows="5"
                                    placeholder="Enter content..."
                                    onChange={handleChange}
                                  ></Field>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-check">
                                    <Field
                                      type="checkbox"
                                      className="form-check-input"
                                      id="IsActive"
                                      name="IsActive"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="IsActive"
                                    >
                                      Active
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row text-right">
                              <div className="col-md-12 me-2">
                                <button
                                  type="submit"
                                  className="btn  me-2 btn-primary"
                                >
                                  Save
                                </button>
                                <Link
                                  to="/story-list"
                                  className="btn btn-secondary"
                                >
                                  Cancel
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
};

export default ManageStory;
