import React, { Fragment } from 'react'
import AboutUsFooter from '../../Layout/footer/AboutUsFooter'
import AboutUsHeader from '../../Layout/header/AboutUsHeader'

const TermsConditions = () => {
  return (
    <Fragment>
      <AboutUsHeader />
      <div className="content-wrapper custom-contain-weapper">
        <section className='content'>
          <div className="container-fluid">
            <h1 style={{ paddingTop: "8px" }}>Terms of Use</h1>
            <h2>Welcome to numellastream.com, a flagship website and mobile application operated by Numella Ltd (hereinafter referred to as the "company").</h2>
            <ol start={1} style={{ marginLeft: "-28px" }}>
              <li style={{ listStyle: "none" }}>
                <h3>1. ACCEPTANCE OF TERMS OF USE</h3>
                <p className="custom-p">These terms of use (Terms) constitute a legally binding agreement between User or you (as defined below) and the Company regarding your use of the Numella web site i.e www.numellastream.com m.numellastream.com, touch.numellastream.com (collectively referred to as “the Site”), Numella Applications (the Mobile Application on Android and iOS devices) and the services including but not limited to delivery of music and related content via the Site, Numella Applications¸ any mobile or Internet connected device or otherwise (the "Numella Service").</p>
                <p className="custom-p">"User" or "You" means any person who access or use the Site, the WAP Site, the Mobile Site, the Numella Applications or Numella Services for the purpose of hosting, listening, publishing, sharing, transacting, displaying or uploading information or views.</p>
                <p className="custom-p">By accessing the Site, Numella Application or Numella Service and/or by clicking "I agree", you agree to be bound by these Terms. You hereby represent and warrant to the Company that you are at least eighteen (18) years of age or above and are capable of entering, performing and adhering to these Terms and that you agree to be bound by the following terms and conditions. You agree to register prior to uploading any content and / or comment and any other use or services of this site and provide your details including, but not limited to email address, contact number.</p>
                <p className="custom-p">Individuals who are located outside of European Union and who are under the age of 18 may utilize the Service of the site, provided they shall do so only with the involvement, consent, supervision &amp; guidance of their parents and / or legal guardians. </p>
                <p className="custom-p">The Company reserves the right, at its discretion, to change, modify, add, or remove portions of these Terms at any time by posting the amended Terms. Please check these Terms periodically for changes. Your continued use of the Numella Service after the posting of changes constitutes your binding acceptance of such changes. In addition, when using any particular services, you may be subject to any posted guidelines or rules applicable to such services. All such guidelines or rules are hereby incorporated by reference into the Terms.</p>
                <p className="custom-p">If you would like to use Numella Services, you must accept the provisions of this Terms of Use. If you do not agree with the Terms, please do not use this Site or the Numella Service.</p>
              </li>
              <li style={{ listStyle: "none" }}>
                <h3>2. Numella SERVICE, REGISTRATION AND ACCESS TO USE</h3>
                <p className="custom-p">The Numella Service provides its members with access to music over the Internet to the Numella Service ready devices.</p>
                <ul className="custom-ul-style listBullet">
                  <li><h4>Registration</h4>
                    <p className="custom-p">To register for the Numella Service you will be required to open an account by completing the registration process (i.e. by providing us with current, complete and accurate information as prompted by the applicable registration form). If you register using your email address, You also will choose a password and a user name. You are entirely responsible for maintaining the confidentiality of your password and account. In particular, as a parent or legal guardian, you acknowledge and assume sole responsibility to ensure that content which is meant for mature audiences (i.e, above the age of majority) is not accessed by children. Hence, you may not share your login credentials with your children. You expressly agree to absolve the Company and/or the Numella Service of any responsibility / liability in this regard.</p></li>
                  <li>
                    <h4>Facebook Connect</h4>
                    <p className="custom-p">You may also register for the Numella Service by using your Facebook username and password. Using Facebook Connect allows us to personalize and enhance your experience while using the Numella Service, based on your personal information, profile, likes, and other relevant information. When you use this feature, you expressly consent to information about your activity on the Numella Service (i.e. what you have listened, what you have liked, ratings given by you, etc.).</p>
                    <p className="custom-p">By registering, you agree to the Terms stated herein and in addition to any other specific terms which shall be posted at an appropriate location of the Site, Numella Application. Each registration is for a single individual user only.</p>
                  </li>
                  <li><h4>Subscription</h4>
                    <p className="custom-p"> Your subscription to the Numella Service in a particular geographical territory shall be valid for that territory only and shall not automatically entitle you to access your account from a different geographical territory, unless specifically permitted by the Company. The content downloaded during subscription period is only allowed to be accessed from the Numella Service and is accessible only till subscription is active and in good standing.</p></li>
                  <li>
                    <h4>Access to use</h4>
                    <p className="custom-p"> To access the Numella Service, you will be asked to enter your individual user name and password, as chosen by you during your registration. Therefore, the Company does not permit any of the following:-</p>
                    <ul className="custom-sub-ul-style">
                      <li>Any other person sharing your account and Password;</li>
                      <li>Any part of the Site being cached in proxy servers and accessed by individuals who have not registered with the Company as users of the Site; or</li>
                      <li>Access through a single account and Password being made available to multiple users on a network.</li>
                    </ul>
                  </li> <br />
                  <p className="custom-p">If the Company reasonably believes that an account and password is being used / misused in any manner, the Company shall reserve the right to cancel access rights immediately without notice, and block access to all users from that IP address. Furthermore, you shall be entirely responsible for any and all activities that occur under your account. You agree to notify the Company immediately of any unauthorized use of your account or any other breach of security. The Company will not be liable for any loss that you may incur as a result of someone else using your password or account, however, you could be held liable for losses incurred by the Company or another party due to someone else using your account or password.</p>
                  <li>
                    <h4>Availability</h4>
                    <p className="custom-p">The availability of content through Numella Services will change from time to time. The quality of the music of the streaming may vary from computer to computer, and device to device, and may be affected by a variety of factors, such as your location, the bandwidth available through and/or speed of your Internet connection. You are responsible for all Internet access charges. Please check with your Internet provider for information on possible Internet data usage charges.</p>
                  </li>
                  <li>
                    <h4>Geographic Limitation</h4>
                    <p className="custom-p">You may listen music through the Numella Service only in geographic locations where the Company offers the Numella Service. The content that may be available to listen will vary by geographic location. The Company may use technologies to verify your geographic location.</p>
                  </li>
                  <li>
                    <h4>Numella Service streaming software</h4>
                    <p className="custom-p">The Numella Service streaming software is developed and designed to enable streaming of content from the Numella Service through the Numella Service ready devices. This software may vary by device and medium, and functionalities may also differ between devices. This software is licensed to you pursuant to these Terms and solely for the purpose of using the Numella Service and for no other purpose whatsoever. The Company does not warrant the performance of the software, including its continuing compatibility with the Numella service. Any unauthorized use of the software is strictly prohibited and Numella Service reserves the right to not provide the software (including updates) to you at any time and to discontinue the ability to access the Numella Service through such software at any time, without prior or any notice.When you use a Numella ready device, you expressly represent that you have the express authority to use such device and be liable for any transaction conducted through such device on the Site/Numella Application.</p>
                  </li>
                </ul>
              </li>
              <li style={{ listStyle: "none" }}>
                <h3>3. BILLING (If Applicable)</h3>
                <p className="custom-p">By signing up for the Numella Service, you are expressly agreeing that the Company is authorized to charge you subscription fee as per your selected plan, any other fees for additional services you may purchase, and any applicable taxes in connection with your use of the Numella Service through the credit card or other payment method accepted by the Company ("Payment Method") that you provided during registration. If you want to use a different Payment Method than the one you signed up for during registration, or if there is a change in your credit card validity or expiration date, you may edit your Payment Method information by clicking on your account. If your Payment Method expires and you do not edit your Payment Method information or cancel your account, you authorize us to continue billing, and you will remain responsible for any uncollected amounts.</p>
                <p className="custom-p">As used in these Terms, "billing" shall indicate either a charge or debit, as applicable, against your Payment Method. The subscription fee will be billed at the beginning of your subscription and on each subsequent renewal (as per plan chosen by you) thereafter unless and until you cancel your subscription or the account or service is otherwise suspended or discontinued pursuant to these Terms. To see the commencement date for your next renewal period, go to the billing information section on your account page.</p>
                <p className="custom-p">In order to sustain the Numella Service, it is important that you honor the payment obligations to which you have agreed. Accordingly, the Company reserves the right to pursue any amounts you fail to pay in connection with the Numella Service. You will remain liable to the Company for all such amounts and all costs incurred by the Company in connection with the collection of these amounts, including, without limitation, collection agency fees, reasonable attorneys´ fees, and arbitration or court costs.</p>
                <p className="custom-p">You also understand and acknowledge that the Company only facilitates the third party payment gateway for processing of payment. This facility is managed by the third party payment gateway provider and you are required to follow all the terms and conditions of such third party payment gateway provider. You are responsible for the accuracy and authenticity of the information provided by you, including the bank account number/credit card details and the like. You agree and acknowledge that the Company shall not be liable and in no way be held responsible for any losses whatsoever, whether direct, indirect, incidental or consequential, including without limitation any losses due to delay in processing of payment instruction or any credit card fraud.</p>
                <p className="custom-p">You can file any complaint related to payment transfer at Site and the same forwarded to the concerned third party payment gateway provider for redressal.</p>
                <p className="custom-p">User agrees to authorise Numella to make recurring purchases from user's credit card (mastercard &amp; visa) and or debit card (HDFC &amp; ICICI) after the end of plan.</p>
                <p className="custom-p">User can select one of the following plans (1 month, 3 month, 6 month or 1 year Numella Plus subscriptions) and authorise Numella to charge user at the time of renewal of the plan selected.</p>
                <p className="custom-p">Once billing commences, we may continue to bill your Payment Method for your membership fee until you cancel. You may cancel your membership at anytime; however, there are no refunds or credits for partially used periods.</p>
              </li>
              <li style={{ listStyle: "none" }}>
                <h3>4. INTELLECTUAL PROPERTY RIGHTS AND LIMITED LICENSE</h3>
                <ul className="custom-ul-style listBullet">
                  <li><h4>Limited License</h4>
                    <p className="custom-p">Subject to your compliance with the Terms herein, the Company hereby grants you a personal, limited, non-exclusive, non-transferable, freely revocable license to use the Numella Service for the personal and non-commercial viewing of videos only. Except for the foregoing limited license, no right, title or interest shall be transferred to you. Content on the Site/Numella Application and/or Numella Service is provided to you AS IS for your information and personal use only and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners. We reserve all rights not expressly granted in and to the Site/ Numella Application and/or Numella Service and the Content. You agree to not engage in the use, copying, or distribution of any of the Content other than expressly permitted herein. Any unauthorized use of the Contents or Numella Service will result in termination of the limited license granted by the Company and cancellation of your membership. Use of Site or Numella Service for any other purpose may result in severe civil and criminal penalties. The Company does not promote, foster or condone the copying of Content, or any other infringing activity. Violators will be prosecuted to the maximum extent possible.</p></li>
                  <li><h4>The Content</h4>
                    <p className="custom-p">The content on the Numella Service including, without limitation, videos, software, text, graphics, interactive features, logos, photos, music and all other audible and visual materials, as well as the selection, organization, coordination, compilation and overall look and feel of the Site / Numella Application (collectively, the "Content") are the intellectual property of the Company, its licensors and its suppliers. The Content is protected by copyright, trade dress, patent, trademark and other laws, international conventions and proprietary rights and all ownership rights to the Content remain with the Company, its licensors or its suppliers, as the case may be. All trademarks, service marks, and trade names are proprietary to the Company or its affiliates and/or third party licensors.</p></li>
                </ul>
              </li>
              <li style={{ listStyle: "none" }}>
                <h3>5. YOUR OBLIGATIONS</h3>
                <p className="custom-p">You hereby agree and assure the Company that the Site, Numella Application and/or Numella Service shall be used for lawful purposes only and that you will not violate laws, regulations, ordinances or other such requirements of any applicable Central, State or local government or any other international laws. You further concur that you shall not:</p>
                <ul className="custom-ul-style">
                  <li>Circumvent, remove, alter, deactivate, degrade or thwart any of the content protections in the Site / Numella Application or Numella Service.</li>
                  <li>Either directly or through the use of any device, software, internet site, web-based service, or other means copy, download, stream capture, reproduce, duplicate, archive, distribute, upload, publish, modify, translate, broadcast, perform, display, sell, transmit or retransmit the Content unless expressly permitted by the Company in writing;</li>
                  <li>Either directly or through the use of any device, software, internet site, web-based service, or other means remove, alter, bypass, avoid, interfere with, or circumvent any copyright, trademark, or other proprietary notices marked on the Content or any digital rights management mechanism, device, or other content protection or access control measure associated with the Content including geo-filtering mechanisms</li>
                  <li>Use the Site/ Numella Application or Numella Service in any manner that could damage, disable, overburden, or impair and not to undertake any action which is harmful or potentially harmful to any the Company´s servers, or the network(s), computer systems / resource connected to any the Company server, or interfere with any other party´s use and enjoyment of the Site/ Numella Application or Numella Service;</li>
                  <li>Obtain or attempt to obtain any materials or information through any means not intentionally made available through the Site /Numella Application /Numella Service;</li>
                  <li>Perform any activity which is likely to cause such harm;</li>
                  <li>Carry out any "denial of service" (DoS, DDoS) or any other harmful attacks on application or internet service or;</li>
                  <li>Use the Site/ Numella Application or Numella Service for illegal purposes;</li>
                  <li>Disrupt, place unreasonable burdens or excessive loads on, interfere with or attempt to make or attempt any unauthorized access to any the Company website or the website of any the Company´s customer;</li>
                  <li>Forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted through the Site/ Numella Application or Numella Service</li>
                  <li>Attempt to gain unauthorized access to Numella Service, other accounts and computer systems through hacking, password mining or any other means. You shall not obtain or attempt to obtain any materials or information through any means not intentionally made available through the Site/ Numella Application or Numella Service;</li>
                  <li>Incorporate the Content into, or stream or retransmit the Content via, any hardware or software application or make it available via frames or in-line links unless expressly permitted by the Company in writing;</li>
                  <li>Create, recreate, distribute or advertise an index of any significant portion of the Content unless authorized by the Company;</li>
                  <li>Use or launch any "robots", "spiders", "offline readers" etc. or any other automated system, that accesses the Site/ Numella Application and / or Numella Service in a manner that sends numerous automated requests to Numella Application /the Site´s servers in a given period of time, which a human cannot reasonably send in the same period by using conventional web browsing application or tool(s) for similar purposes.</li>
                </ul>
                <p className="custom-p">In addition, you are strictly prohibited from creating derivative works or materials that otherwise are derived from or based ,on the Content in any way, including montages, mash-ups and similar videos, wallpaper, desktop themes, greeting cards, and merchandise, unless it is expressly permitted by the Company in writing. This prohibition applies even if you intend to give away the derivative materials free of charge.</p>
                <p className="custom-p">The Site may permit you to post user submissions including but not limited to reviews of Content available through the Numella Service, comments on such Content, etc (User Submissions). You understand that these User Submissions, once posted by you, are visible to all members since it is a public forum.</p>
              </li>
              <li style={{ listStyleType: "none" }}>
                <h3>6. PROHIBITED ACTIVITIES</h3>
                <p className="custom-p">You agree not to host, display, upload, modify, publish, transmit, update or share any information or User Submissions which</p>
                <ul className="listSquare custom-ul-style">
                  <li><p className="custom-p">Belongs to another person and to which you do not have any right to;</p></li>
                  <li><p className="custom-p">Is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</p></li>
                  <li><p className="custom-p">Harm minors in any way;</p></li>
                  <li><p className="custom-p">Infringes any patent, trademark, copyright or other proprietary rights;</p></li>
                  <li><p className="custom-p">Violates any law for the time being in force;</p></li>
                  <li><p className="custom-p">Deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</p></li>
                  <li><p className="custom-p">Impersonate another person;</p></li>
                  <li><p className="custom-p">Contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</p></li>
                  <li><p className="custom-p">Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation;</p></li>
                  <li><p className="custom-p">Contain misleading information regarding the origin of the Content;</p></li>
                  <li><p className="custom-p">Or otherwise contains objectionable content.</p></li>
                </ul>
                <p className="custom-p">Any content uploaded by you shall be subject to relevant laws and may disabled, or and may be subject to investigation under appropriate laws. Furthermore, if you are found to be in non-compliance with the laws and regulations, these terms, or the privacy policy of the Site, Numella Application we may terminate your account/block your access to the Site/ Numella Application and we reserve the right to remove any non-compliant content uploaded by you.</p>
                <p className="custom-p">If you are found to be in non-compliance with the laws and regulations, these terms, or the privacy policy of the Site/Numella Application, the Company shall have the right to immediately terminate/block your access and usage of the Site/Numella Application and the Company shall have the right to immediately remove any non-compliant Content and or comment, uploaded by you and shall further have the right to take recourse to such remedies as would be available to the Company under the applicable laws.</p>
                <p className="custom-p">You understand and agree that the Company may, but is not obligated to, review the User Submissions and may delete or remove it (without notice) in its sole and absolute discretion, for any reason or without assigning any reason. More specifically, when you review / rate any Content available on the Numella Service (as per functionality made available on the Site/Numella Application), you give the Company express rights and consent to display your rating / review in relation to the relevant Content on the Site/Numella Application, including making it available to other members for viewing. If you do not want your User Submissions / reviews / ratings to be shared in a public forum, do not use these features.</p>
                <p className="custom-p">More specifically, when you review /  rate any Content available on the Numella Service (as per functionality made  available on the Site), you give the Company express rights and consent to  display your rating / review in relation to the relevant Content on the Site,  including making it available to other members for viewing. If you do not want  your User Submissions / reviews / ratings to be shared in a public forum, do  not use these features.</p>
                <p className="custom-p">These features may change without notice to you and the degrees of associated information sharing and functionality may also change without notice.</p>
                <p className="custom-p">the Company is free to use any comments, information, ideas, concepts, reviews, or techniques or any other material contained in any communication you may send to us ("User Feedback"), including responses to questionnaires or through postings to the Numella Service / the Site/Numella Application and User Submissions, without further compensation, acknowledgement or payment to you for any purpose whatsoever including, but not limited to, developing, manufacturing and marketing products and creating, modifying or improving the Numella Service. By posting / submitting any User Feedback / User Submission on the Site/Numella Application, you grant the Company a perpetual, worldwide, non-exclusive, royalty-free irrevocable, sub-licensable license and right in such User Feedback / User Submission to the Company, including the right to display, use, reproduce or modify the User Feedback / User Submission in any media, software or technology of any kind now existing or developed in the future.</p>
                <p className="custom-p">Operators of public search engines have the permission to use functionalities like spiders to copy materials from the Site/Numella Application for the sole purpose of creating publicly available searchable indices of the materials, but not caches or archives of such materials. We reserve the right to revoke these exceptions either generally or in specific cases, in our sole discretion. You agree not to collect or harvest any personally identifiable information, including account names, from the Site/Numella Application, nor to use the communication systems provided by the Site/Numella Application for any commercial solicitation purposes. You agree not to solicit, for commercial purposes, any users of the Site/Numella Application with respect to its User submissions.</p>
                <p className="custom-p">You shall be financially responsible for your use of the Numella Service (as well as for use of your account by others, including without limitation minors living with you). You undertake to supervise and be responsible for all usage of minors of the Numella Service and access of the Site/Numella Application under your name or account and absolve the Company from any liability on this account. You also warrant that all information supplied by you or members of your family for using the Numella Service and accessing the Site/Numella Application, including without limitation your name, email address, street address, telephone number, mobile number, credit card number is correct and accurate. Failure to provide accurate information may subject you to civil and criminal penalties. </p>
                <p className="custom-p">You shall be responsible for obtaining and maintaining any equipment or ancillary services needed to connect to, access the Site/Numella Application or otherwise use the Numella Service, including, without limitation, modems, hardware, software, and long distance or local telephone service. You shall be responsible for ensuring that such equipment or ancillary services are compatible with the Numella Service. </p>
                <p className="custom-p">You agree that the Company may directly or through third party service providers send information to you about the various services offered by the Company from time to time.</p>
              </li>
              <li style={{ listStyle: "none" }}>
                <h3>7. ADVERTISING MATERIAL</h3>
                <p className="custom-p">Part of the Site/Numella Application or the Numella Service may contain advertising information or promotion material or other material submitted to the Company by third parties. Responsibility for ensuring that material submitted for inclusion on the Site/Numella Application complies with applicable international and national law is exclusively on the party providing the information/material. Your correspondence or business dealings with, or participation in promotions of advertisers including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser. Before relying on any advertising material, you should independently verify its relevance for your purpose, and should obtain appropriate professional advice. The Company shall not be responsible nor liable for any loss or claim that you may have against an advertiser or any consequential damages arising on account of your relying on the contents of the advertisement.</p>
              </li>
              <li style={{ listStyle: "none" }}>
                <h3>8. COLLECTION AND USE OF PERSONAL INFORMATION</h3>
                <p className="custom-p">For information about the Company´s policies and practices regarding the collection and use of your personally identifiable information, please read the Privacy Policy as available on the Site/Numella Application. The Privacy Policy is incorporated by reference and made part of these Terms. Thus, by agreeing to these Terms, you agree that your presence on the Site/Numella Application and use of the Numella Service are governed by the Company’s Privacy Policy in effect at the time of your use.</p>
              </li>
              <li style={{ listStyle: "none" }}>
                <h3>9. DISCLAIMER OF WARRANTIES AND LIABILITY</h3>
                <p className="custom-p">You understand and agree that the Company provides the Numella Service on an 'as-is' 'with all faults' and 'as available' basis. You agree that use of the Site/Numella Application or the Numella Service is at your risk. All warranties including without limitation, the implied warranties of merchantability, fitness for a particular purpose, for the title and non-infringement are disclaimed and excluded.</p>
                <p className="custom-p">No representations, warranties or guarantees whatsoever are made by the Company as to the (a) accuracy, adequacy, reliability, completeness, suitability or applicability of the information to a particular situation; (b) that the service will be uninterrupted, timely, secure, or error-free; (c) the quality of any services, content, information, or other material on the website will meet your expectations or requirements; (d) any errors in the Site/Numella Application will be corrected; (e) warranties against infringement of any third party intellectual property or proprietary rights; or (f) other warranties relating to performance, non-performance, or other acts or omissions of the Company, its officers, directors, employees, affiliates, agents, licensors, or suppliers.</p>
                <p className="custom-p">The Company does not warrant that any of the software used and or licensed in connection with the Numella Service will be compatible with other third party software or devices nor does it warrant that operation of the Numella Service and the associated software will not damage or disrupt other software or hardware.</p>
                <p className="custom-p">The Company, its affiliates, successors, and assigns, and each of their respective investors, directors, officers, employees, agents, and suppliers (including distributors and content licensors) shall not be liable, at any time for any, direct, indirect, punitive, incidental, special, consequential, damages arising out of or in any way connected with the use of Site or the Numella Service, whether based in contract, tort, strict liability, or other theory, even if the Company have been advised of the possibility of damages.</p>
                <p className="custom-p">In the event any exclusion contained herein be held to be invalid for any reason and the Company or any of its affiliate entities, officers, directors or employees become liable for loss or damage, then, any such liability of the Company or any of its affiliate entities, officers, directors or employees shall be limited to not exceeding subscription charges paid by you in the month preceding the date of your claim for the particular subscription in question chosen by you.</p>
              </li>
              <li style={{ listStyle: "none" }}>
                <h3>10. INDEMNIFICATION</h3>
                <p className="custom-p">You agree to indemnify, defend and hold harmless, the Company, its affiliates, successors, and assigns, and each of their respective investors, directors, officers, employees, agents, and suppliers (including distributors and content licensors) from and against any losses, claims, damages, liabilities, including legal fees and expenses, arising out of:</p>
                <ul className="custom-ul-style">
                  <li><p className="custom-p">Any claim due to or arising out of your violation of these Terms, including but not limited to a claim arising out of a breach of your representations or warranties made hereunder;</p></li>
                  <li><p className="custom-p">Your use or misuse of or access to the Site/Numella Application or the Numella Service;</p></li>
                  <li><p className="custom-p">Your violation of any law, regulation or third party right, including without limitation any copyright, property, or privacy right; or</p></li>
                  <li><p className="custom-p">Any claim that you have caused damage to a third party.</p></li>
                  <li><p className="custom-p">The Company reserves the right, at its own expense, to employ separate counsel and assume the exclusive defence and control of any matter otherwise subject to indemnification by you, and you agree to cooperate with the Company´s defence of these claims.</p></li>
                </ul>
              </li>
              <li style={{ listStyle: "none" }}>
                <h3>11. TERMINATION OF ACCOUNT, SUSPENSION OR DISCONTINUATION OF Numella SERVICE</h3>
                <p className="custom-p">The Company reserves the right to change, suspend, or discontinue temporarily or permanently, some or all of the Numella Service (including the Content and the devices through which the Numella Service are accessed), with respect to any or all users, at any time without notice. You acknowledge that the Company may do so in its sole discretion. You also agree that the Company will not be liable to you for any modification, suspension, or discontinuance of the Numella Service, although if you are a Numella Services paid subscriber and the Company suspends or discontinues the Numella Service, the Company may, in its sole discretion, provide you with a credit, refund, discount or other form of consideration (for example, the Company may credit additional days of service to your account). However, if the Company terminates your account or suspends or discontinues your access to the Numella Service due to your violation of these Terms, then you will not be eligible for any such credit, refund, discount or other consideration.</p>
              </li>
              <li style={{ listStyle: "none" }}>
                <h3>12. GENERAL TERMS</h3>
                <ul className="custom-ul-style">
                  <li>
                    <p className="custom-p">
                      <h4>Contact Numella</h4>
                      2913 E, 78th Place, Chicago,Illinois 60649</p>
                    <p className="custom-p"> <a href="mailto:contact@numellastream.com"> contact@numellastream.com </a>
                    </p>
                  </li>
                  <li>
                    <h4>Notice of Copyright Infringement</h4>
                    <p className="custom-p">Our policy is to comply with all Intellectual Property Laws and to act expeditiously upon receiving any notice of claimed infringement. If you believe that any work has been reproduced on this website in a manner that constitutes copyright infringement, please provide a notice of copyright infringement containing all of the following information:</p>
                    <ul className="custom-sub-ul-style">
                      <li><p className="custom-p">A physical or electronic signature of a person authorized to act on behalf of the copyright owner for the purposes of the complaint.</p></li>
                      <li><p className="custom-p">Identification of the copyrighted work claimed to have been infringed.</p></li>
                      <li><p className="custom-p">Identification of the material on our website that is claimed to be infringing or to be the subject of infringing activity.</p></li>
                      <li><p className="custom-p">The address, telephone number or e-mail address of the complaining party.</p></li>
                      <li><p className="custom-p">A statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law.</p></li>
                      <li><p className="custom-p">A statement, under penalty of perjury, that the information in the notice of copyright infringement is accurate, and that the complaining party is authorized to act on behalf of the owner of the right that is allegedly infringed.</p></li>
                    </ul>
                    <p className="custom-p">All notices of copyright infringement should be sent to contact@numellastream.com
                    </p></li>
                  <li>
                    <h4>Relationship </h4>
                    <p className="custom-p">None of the provisions of the Terms shall be deemed to constitute a partnership or agency between you and the Company and you shall have no authority to bind the Company in any manner, whatsoever. This agreement is solely for your and the Company’s benefit and not for the benefit of any other person, except for permitted successors and assigns under this Agreement.</p>
                  </li>
                  <li>
                    <h4>Assignment </h4>
                    <p className="custom-p">You may not transfer to anyone else, either temporarily or permanently, any rights to use the Numella Service or any part of the Numella Service. Any attempt by you to do so is void. the Company may assign, transfer, delegate and/or grant all or any part of its rights, privileges and properties hereunder to any person or entity.</p>
                  </li>
                  <li>
                    <h4>Force Majeure </h4>
                    <p className="custom-p">Neither Party shall have any liability for any interruption or delay, to access the Site/Numella Application due to Force Majeure Event. For the purposes of this clause, ´Force Majeure Event´ means any event or circumstance or combination of events and circumstances which is reasonably beyond the control of the party affected thereby and which causes or results in default or delay in performance by such affected party of any of its obligations under this agreement and includes an act of God, war, hostilities, civil commotion, strikes, lockouts and other industrial disputes.</p>
                  </li>
                  <li>
                    <h4>Applicable Law </h4>
                    <p className="custom-p">These Terms are governed by and construed in accordance with, the laws of India without giving effect to principles of conflict of law. In the event of any dispute or claim by you against the Company, you agree to submit to the exclusive jurisdiction of courts at New Delhi.</p>
                  </li>
                  <li>
                    <h4>Limited Time To Bring Your Claim </h4>
                    <p className="custom-p">You and the Company agree that any cause of action arising out of or related to use of the Site/Numella Application or the Numella Service must commence within one (1) year after the cause of action accrues otherwise, such cause of action will be permanently barred.</p>
                  </li>
                  <li>
                    <h4>Survival </h4>
                    <p className="custom-p">Rights and obligations under the Terms which by their nature should survive will remain in full effect after termination or expiration of the subscription.</p>
                  </li>
                  <li>
                    <h4>Non Waiver </h4>
                    <p className="custom-p">Any express waiver or failure to exercise promptly any right under this agreement will not create a continuing waiver or any expectation of non-enforcement.</p>
                  </li>
                  <li>
                    <h4>Disclaimer </h4>
                    <p className="custom-p">Numella Services may allow the User(s) of Numella App to play/stream legal music stored locally on their respective devices. By way of the aforesaid feature Numella provides music player functionality to the User(s) to access the music legally stored on their devices and does not in any way provide for the storage of such music on the Numella App/Numella Sites or servers. User(s) understands, agrees and confirms that only legitimate and legally procured music is to be played/broadcast/streamed through their device for the use of the “Music on my phone” feature. User(s) assume total responsibility of the source and legitimacy of the music played through the “Music on my phone” feature.</p>
                    <p className="custom-p">Furthermore, the User(s) hereby waive any liability or responsibility of Times Internet Limited and/ or Numella Limited and/ or their associate companies for any music related licenses or permissions for any music played by the User(s) through the “Music on my phone” functionality. User(s) further represent and warrant to remain solely responsible for any and all claims in relation thereto and shall without any contestation and demur, fully indemnify and keep indemnified Times Internet Limited and/ or Numella Limited and/ or their associate companies, directors and/or employees for any claims, royalties or penalties.</p>
                  </li>
                  <li>
                    <h4>Entire Agreement </h4>
                    <p className="custom-p">These Terms constitute the entire agreement between the parties with respect to the subject matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.</p>
                  </li>
                </ul>
              </li>
            </ol>
            <p className="custom-p">
              Copyright Notice
              © Numella Limited. 2021. All Rights reserved.
              This disclaimer/terms of service notification is subject to change without  notice.
            </p>
          </div>
        </section>
      </div>
      <AboutUsFooter />
    </Fragment>
  )
}

export default TermsConditions