import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';

import Footer from '../../Layout/footer/Footer';
import Sidebar from '../../Layout/sidebar/Sidebar';
import { toastError, toastSuccess } from '../../common/Toast';
import config from '../../config/config';
import 'bootstrap/dist/css/bootstrap.min.css';

import CustomLoader from "../../Layout/CustomLoader";

const ManageQuestionOption = (props) => {
    const paramObject = useParams();
    let navigate = useNavigate();

    const [formValues, setFormValues] = useState({ QuestionId: '', addquestion: '', IsActive: true, Category: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [category, setCategory] = useState("Select Category");

    const validationSchema = Yup.object().shape({
        addquestion: Yup.string().required("Question is a required "),
        Category: Yup.string().required("Please select Category")
    });

    let createdby = localStorage.getItem('ID');

    const handleSubmit = (values) => {
        const jsonobj = {};
        jsonobj.Option = questionList
        
        let jsonstring = JSON.stringify(jsonobj);

        setLoading(true);
 
        axios.post(`${config.baseUrl}/questions/QuestionOptionupsert`, {
            QuestionId: values.QuestionId,
            categoryid: values.Category,
            question: values.addquestion,
            isactive: (values.IsActive == true ? 1 : 0),
            createdby: createdby,
            Inputjson: jsonstring//"{Option:"+JSON.stringify(questionList)+"}"//JSON.stringify(questionList)
        }, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {

                    toastSuccess('Record has been added!');
                    navigate("/question-list");
                }
                else if (res.data.ResponseCode === "ERROR" && res.data.ResponseID === -1) {
                    toastError("Question Already Exists!");
                }
                else {
                    toastError("Somthing went wrong");
                }

                setLoading(false);
            }).catch((error) => {
                console.log("error occured", error);

                setLoading(false);
            });
    }

    let handleCategoryChange = (e) => {
        console.log(e.target.value)
    }
    const handleApi = () => {
        if (!loading === true) {
            setLoading(true);

            axios.get(`${config.baseUrl}/questions/findAllCategory`)
                .then((response) => {
                    setItems(response.data.ResponseData);

                    setLoading(false);
                }).catch((err) => {
                    console.log(err)

                    setError(err);
                    setLoading(false);
                })
        }
        else {
            toastError("Somthing went wrong!");
        }
    }
    useEffect(() => {
        handleApi();
        getIDWiseData();
    }, [])

    const [questionList, setQuestionList] = useState([{ ID: 0, QuestionOption: "", CreatedBy: createdby, IsActive: true }]);

    const handleQuestionChange = (e, index) => {
        const {name,value} = e.target;
        const list = [...questionList];
        list[index]["QuestionOption"] = value;
        setQuestionList(list);
    };

    const handleQuestionAdd = () => {
        setQuestionList([...questionList, { ID: 0, QuestionOption: "", CreatedBy: createdby, IsActive: true }]);
    };

    const handleQuestionRemove = (index) => {
        const list = [...questionList];
        list.splice(index, 1);
        setQuestionList(list);
    };

    const getIDWiseData = () => {
        if (paramObject.id > 0) {
            setLoading(true);

            axios.get(`${config.baseUrl}/questions/QuestionOptionbyid?id=${paramObject.id}`)
                .then((response) => {
                    const responseData = response.data.ResponseData;
                    let questiondata = responseData.Question;
                    let IsActive = questiondata[0].IsActive == 1 ? true : false;
                    
                    let QuestionId = questiondata[0].Id;
                    const addquestion = questiondata[0].Question;
                    const Category = questiondata[0].CategoryID;
                    setFormValues({ QuestionId, addquestion, IsActive, Category });
                    let optionsdata = responseData.Options;
                    let optiondatalist = [];

                    for (const [i,li] of optionsdata.entries()) {
                        let option = li;
                        let index = i;
                        //([...questionList, { ID:option.Id, QuestionOption: option.QuestionOption
                        //   ,CreatedBy: option.CreatedBy,IsActive:option.IsActive }]);                
                        //console.log(setQuestionList);
                        //const list = [...questionList];
                        //list[index]["QuestionOption"] = "test";

                        //const list = [...questionList];
                        //console.log(list);
                        //list[i]["QuestionOption"] = option.QuestionOption;
                        const datalist = {};
                        datalist.ID = option.Id;
                        datalist.QuestionOption = option.QuestionOption;
                        datalist.CreatedBy = option.CreatedBy;
                        datalist.IsActive = option.IsActive;
                     
                        optiondatalist.push(datalist);

                        //const list = [...questionList];
                        //list[index]["ID"] = option.Id; 
                        //list[i]["QuestionOption"] = li.QuestionOption;  
                        //console.log(li    st);
                        setQuestionList(optiondatalist);

                        // for (const [j, li] of setQuestionList.entries()) {
                        //     console.log(li);
                        // }
                    }

                    //let count=
                    setLoading(false);

                }).catch((err) => {
                    console.log(err);

                    setLoading(false);
                })
        }
    }

    return (
        <Fragment>
            <Sidebar />
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Question-Option</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">Question Option List
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header text-right" >
                                        <h3 className="card-title">Manage</h3>
                                    </div>
                                    <Formik  {...props}
                                        validationSchema={validationSchema}
                                        initialValues={formValues}
                                        onSubmit={handleSubmit}
                                        enableReinitialize
                                    >
                                        {({
                                            errors,
                                            touched,
                                            handleChange
                                        }) => (
                                            <Form>
                                                <div className="card-body">
                                                    <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4" >
                                                        <Field type="hidden" className="form-control" id="QuestionId" name="QuestionId" />
                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Select Question</label>
                                                                    <Field as="select" className="form-control" name="Category" id="Category">
                                                                        <option value=""> -- Select Category -- </option>
                                                                        {items.map((category) => <option value={category.Id}>{category.Name}</option>)}
                                                                    </Field>
                                                                    <span className="text-danger">
                                                                        {touched.Category && errors.Category ? errors.Category : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className='form-group'>    
                                                                    <label>Add Question</label>
                                                                    <Field type="text" className="form-control" id="addquestion" name="addquestion" onChange={handleChange} />
                                                                    <span className="text-danger">
                                                                        {touched.addquestion && errors.addquestion ? errors.addquestion : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        {/* <input type="checkbox" className="form-check-input" id="isActive" name='isActive' />
                                                                        <label className="form-check-label" for="IsActive">Active</label> */}
                                                                        <Field type="checkbox" class="form-check-input" id="IsActive" name='IsActive' />
                                                                        <label className="form-check-label" for="IsActive">Active</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className='form-group'>
                                                                    <label>Add option</label>
                                                                    {questionList.map((singleQuestion, index) => (
                                                                        <div className='input-group'>
                                                                            <input id="question" name="question" type="text" className="form-control" placeholder="Add" value={singleQuestion.QuestionOption} onChange={(e) => handleQuestionChange(e, index)} />
                                                                            <div className="input-group-append">
                                                                                {questionList.length - 1 === index && questionList.length < 20 && (
                                                                                    <button id="add-question" type="button" className="btn btn-primary" onClick={handleQuestionAdd}>Add</button>
                                                                                )}
                                                                                {questionList.length !== 1 && (
                                                                                    <button type="button" className="btn btn-default btn-md" onClick={() => handleQuestionRemove(index)}>
                                                                                        <i className="far fa-trash-alt"></i>
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row text-right'>
                                                            <div className='col-md-12 me-2'>
                                                                <button type="submit" className="btn  me-2 btn-primary" >Save</button>
                                                                <Link to="/question-list" className="btn btn-secondary">Cancel</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </Fragment>
    )
}

export default ManageQuestionOption
