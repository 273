import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import Footer from "../../Layout/footer/Footer";
import Sidebar from "../../Layout/sidebar/Sidebar";
import { toastError, toastSuccess } from "../../common/Toast";
import config from "../../config/config";
import CustomLoader from "../../Layout/CustomLoader";
import "bootstrap/dist/css/bootstrap.min.css";

const TransactionRequestList = () => {
  const [statee, setSatete] = useState({ visible: null });
  const [reasonstatee, setreasonSatete] = useState(false);
  const [reason, setReason] = useState("");
  const [transactionstatus, settransactionstatus] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [perPageSize, setperPageSize] = useState(10);
  const [searchtext, setSearchText] = useState("");
  const [requestType, setRequestType] = useState("");
  const [totalrecord, setTotalRecord] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [validreason, setValidReason] = useState(true);
  const [remountComponent, setRemountComponent] = useState(0);

  const handleApi = (
    pagenumber,
    pagesize,
    filtertext,
    ordercolumn,
    currentorder,
    requesttype
  ) => {
    setLoading(true);
    axios
      .get(
        `${config.baseUrl}/account?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&requesttype=${requesttype}`
      )
      .then((response) => {
        setItems(response.data.ResponseData);
        setTotalRecord(response.data.ResponseData[0].TotalRecord);
        setpageCount(
          Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize)
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setLoading(false);
      });
  };

  const changePage = ({ selected }) => {
    selected = selected + 1;
    handleApi(selected, perPageSize, searchtext, 0, "asc", requestType);
  };

  const ChangeFilterText = (e) => {
    setSearchText(e.target.value);
    setRemountComponent(Math.random());
    handleApi(1, perPageSize, e.target.value, 0, "asc", requestType);
  };

  const ChangePageSize = (e) => {
    setperPageSize(e.target.value);
    handleApi(1, e.target.value, searchtext, 0, "asc", requestType);
  };

  const formatDate = (string) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };

  const ChangeRequestType = (e) => {
    setRequestType(e.target.value);
    setRemountComponent(Math.random());
    handleApi(1, perPageSize, "", 0, "asc", e.target.value);
  };

  // Transaction Approve or reject

  const handlecloseTransactionStatuspopup = () => {
    localStorage.setItem("UserTransactionID", 0);
    localStorage.setItem("Usertransactionstatus", "");
    setSatete({ visible: null });
    setreasonSatete(false);
  };

  const handleTransactionStatusModelpopup = (id, transactionstatus) => {
    localStorage.setItem("UserTransactionID", id);
    localStorage.setItem("Usertransactionstatus", transactionstatus);

    settransactionstatus(transactionstatus);
    if (transactionstatus === "Rejected") {
      setreasonSatete(true);
    }
    setSatete({ visible: id });
  };

  const handleTransactionStatus = () => {
    let loginuserID = localStorage.getItem("ID");
    let id = localStorage.getItem("UserTransactionID");

    let usertransactionstatus = localStorage.getItem("Usertransactionstatus");
    let statusid = 0;
    if (usertransactionstatus === "Approved") {
      statusid = 1;
    }
    if (usertransactionstatus === "Rejected") {
      if (document.getElementById("Reason").value === "") {
        setValidReason(false);

        return false;
      }

      statusid = 2;
    }

    setSatete({ visible: null });
    if (id > 0) {
      setLoading(true);
      axios
        .post(
          `${config.baseUrl}/account/withdrawalstatus`,
          {
            id: id,
            statusid: statusid,
            reason: reason,
            updateduserid: loginuserID,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          console.log(res);

          if (res.data.ResponseCode === "SUCCESS") {
            toastSuccess("Record has been updated!");
          } else {
            toastError("Enable to updated a record");
          }
          handleApi(1, perPageSize, "", 0, "asc", requestType);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error occured", error);
          setLoading(false);
        });
    }
  };

  const handleChangeReason = (e) => {
    if (e.target.value !== "") {
      setValidReason(true);
    } else {
      setValidReason(false);
    }
    setReason(e.target.value);
  };

  useEffect(() => {
    handleApi(1, perPageSize, "", 0, "asc", requestType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Sidebar />
      <ToastContainer />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Transaction Request</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Transaction Request
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header text-right">
                    <h3 className="card-title">Transaction Request</h3>
                  </div>

                  <div className="card-body">
                    <div
                      id="example1_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 table-responsive"
                    >
                      <div className="row">
                        <div className="col-sm-3 col-md-3   ">
                          <div className="form-group">
                            <label className="form-label col-sm-6">
                              Request Type
                            </label>
                            <div className="col-sm-10">
                              <select
                                className="form-control"
                                onChange={(e) => ChangeRequestType(e)}
                              >
                                <option value="">All</option>
                                <option value="Withdrawal">Withdrawal</option>
                                <option value="Tip">Tip</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6 col-md-6">
                          <select className="" onChange={ChangePageSize}>
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                        </div>

                        <div className="col-sm-6 col-md-6 text-right">
                          <div
                            id="example1_filter"
                            className="dataTables_filter"
                          >
                            <label>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="example1"
                                onChange={ChangeFilterText}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <table
                            id="example1"
                            className="table table-bordered dataTable dtr-inline"
                            aria-describedby="example1_info"
                          >
                            <thead>
                              <tr>
                                <th
                                  className="sorting sorting_asc"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                  aria-label="ID: activate to sort column descending"
                                >
                                  ID
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="RequestType: activate to sort column ascending"
                                >
                                  Request Type
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="RequestedUser: activate to sort column ascending"
                                >
                                  Requested User
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="ReceiverUser: activate to sort column ascending"
                                >
                                  Receiver User
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Amount: activate to sort column ascending"
                                >
                                  Amount
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Status: activate to sort column ascending"
                                >
                                  Status
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="CreatedOn: activate to sort column ascending"
                                >
                                  Created On
                                </th>
                                <th
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Action"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {items.length > 0 ? (
                                items.map((item) => (
                                  <tr className="odd">
                                    <td
                                      className="dtr-control sorting_1"
                                      tabIndex="0"
                                    >
                                      {item.ID}
                                    </td>
                                    <td>{item.RequestType}</td>
                                    <td>{item.RequestedUser}</td>
                                    <td>{item.ReceiverUser}</td>
                                    <td>{item.Amount}</td>
                                    <td>
                                      {" "}
                                      {item.Status === 0 ? (
                                        <span className="right badge badge-warning">
                                          Pending
                                        </span>
                                      ) : item.Status === 1 ? (
                                        <span className="right badge badge-success">
                                          Approved
                                        </span>
                                      ) : item.Status === 2 ? (
                                        <>
                                          <span className="right badge badge-danger">
                                            Rejected
                                          </span>{" "}
                                          <br></br>{" "}
                                          <small>Reason: {item.Reason}</small>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            type="button"
                                            title="Approved"
                                            className="btn btn-success btn-sm mr-2"
                                          >
                                            <i className="fas fa-check" />
                                          </button>
                                          <button
                                            type="button"
                                            title="Rejected"
                                            className="btn btn-danger btn-sm mr-2"
                                          >
                                            <strong>X</strong>
                                          </button>
                                        </>
                                      )}
                                    </td>
                                    <td>{formatDate(item.CreatedDateTime)}</td>
                                    <td>
                                      {item.Status === 0 ? (
                                        <>
                                          <button
                                            type="button"
                                            title="Approved"
                                            className="btn btn-success btn-sm mb-2 mr-2 "
                                            onClick={() =>
                                              handleTransactionStatusModelpopup(
                                                item.ID,
                                                "Approved"
                                              )
                                            }
                                          >
                                            <i className="fas fa-check" />
                                          </button>
                                          <button
                                            type="button"
                                            title="Rejected"
                                            className="btn btn-danger btn-sm mb-2 mr-2 custom-icon-width"
                                            onClick={() =>
                                              handleTransactionStatusModelpopup(
                                                item.ID,
                                                "Rejected"
                                              )
                                            }
                                          >
                                            <strong>X</strong>
                                          </button>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      <Modal show={statee.visible === item.ID}>
                                        <div
                                          className="modal fade show"
                                          style={{
                                            display: "block",
                                            paddingRight: "17px",
                                          }}
                                          aria-modal="true"
                                          role="dialog"
                                        >
                                          <div className="modal-dialog modal-md">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Confirmation
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  onClick={() =>
                                                    handlecloseTransactionStatuspopup()
                                                  }
                                                >
                                                  <span aria-hidden="true">
                                                    ×
                                                  </span>
                                                </button>
                                              </div>

                                              <div className="modal-body">
                                                <p>
                                                  Are you sure you want to{" "}
                                                  <strong>
                                                    {transactionstatus}
                                                  </strong>{" "}
                                                  this request?
                                                </p>

                                                <div
                                                  className={
                                                    reasonstatee
                                                      ? "form-group"
                                                      : "form-group d-none"
                                                  }
                                                >
                                                  <label>Reject Reason</label>
                                                  <textarea
                                                    className="form-control"
                                                    id="Reason"
                                                    name="Reason"
                                                    onChange={
                                                      handleChangeReason
                                                    }
                                                  ></textarea>
                                                  <span
                                                    className={
                                                      validreason
                                                        ? "text-danger d-none"
                                                        : "text-danger"
                                                    }
                                                  >
                                                    Reason is required
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="modal-footer text-right">
                                                <button
                                                  type="button"
                                                  className="btn btn-primary"
                                                  onClick={() =>
                                                    handleTransactionStatus()
                                                  }
                                                >
                                                  Yes
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-default"
                                                  data-dismiss="modal"
                                                  onClick={() =>
                                                    handlecloseTransactionStatuspopup()
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Modal>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={8} align="center">
                                    Data not found!
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-sm-12 col-md-5"
                        key={remountComponent}
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={items.length > 0 ? pageCount : 0}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          pageClassName={"page-link"}
                          previousLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <!-- /.card-body --> */}
                </div>
                {/* <!-- /.card --> */}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
};

export default TransactionRequestList;
