// import { faSlash } from '@fortawesome/free-solid-svg-icons'
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// Authentication Routes
import InnerContent from "../components/InnerContent";
import ProtectedRoutes from "../components/ProtectedRoutes";
import PublicRoutes from "../components/PublicRoutes";
import PermissionDenied from "../components/PermissionDenied";

// Module Routes
import EditQuestionOption from "../components/QuestionOption/EditQuestionOption";
import QuestionDatatableLists from "../components/QuestionOption/QuestionDatatableLists";
import ManageQuestionOption from "../components/QuestionOption/ManageQuestionOption";
import AddQuestionOption from "../components/QuestionOption/AddQuestionOption";
import Login from "../forms/Login/Login";
import Dashboard from "../components/Dashboard/DashboardSummary";
import EthnicityDatatableLists from "../components/Ethnicity/EthnicityDatatableLists";
import CampaignDatatableLists from "../components/CampaignCountry/CampaignDatatableLists";
import GenreDatatableLists from "../components/Genre/GenreDatatableLists";
import NotificationTemplateLists from "../components/NotificationTemplate/NotificationTemplateLists";
import EmailTemplateDatatableLists from "../components/EmailTemplate/EmailTemplateDatatableLists";
import UserList from "../components/Users/UserList";
import DocumentList from "../components/Documents/DocumentList";
import ManageStory from "../components/Story/ManageStory";
import StoryDatatableList from "../components/Story/StoryDatatableList";
import CustomEmailSendDatatableList from "../components/custom/Email/CustomEmailSendDatatableList";
import CustomNotificationSendDatatableList from "../components/custom/Notification/CustomNotificationSendDatatableList";
import NotificationLists from "../components/custom/Notification/NotificationLists";
import EmailList from "../components/custom/Email/EmailList";
import AddUsers from "../components/custom/AddUsers";
import EarnHistoryList from "../components/Referral/EarnHistoryList";
import TransactionRequestList from "../components/Account/TransactionRequestList";
import TransactionHistoryList from "../components/Account/TransactionHistoryList";
import EditCampaign from "../components/Campaign/EditCampaign";
import AllCampaignList from "../components/Campaign/AllCampaignList";
import ManageCampaign from "../components/CampaignCountry/ManageCampaign";
import ManageGenre from "../components/Genre/ManageGenre";
import ManageEtnicity from "../components/Ethnicity/ManageEthnicity";
import ManageNotificationTemplate from "../components/NotificationTemplate/ManageNotificationTemplate";
import ToolKitDatatableLists from "../components/ToolKit/ToolKitDatatableLists";
import ManageToolKit from "../components/ToolKit/ManageToolKit";
import ManageEmailTemplate from "../components/EmailTemplate/MangeEmailTemplate";
import ImpressionDetailsDatatableLists from "../components/ImpressionDetails/ImpressionDetailsDatatableLists";
import ManageImpressionDetails from "../components/ImpressionDetails/ManageImpressionDetails";
import MasterCampaignDatatableList from "../components/MasterCampaignImpression/MasterCampaignDatatableList";
import MangeMasterCampaign from "../components/MasterCampaignImpression/MangeMasterCampaign";
import ManageCampaignPackage from "../components/CampaignPackage/ManageCampaignPackage";
import CampaignPackageDatatableLists from "../components/CampaignPackage/CampaignPackageDatatableLists";
import StoryUserDataTableList from "../components/Story/StoryUserDatatableList";
import FrontDriverProfile from "../components/FrontPublic/FrontDriverProfile";
import ConfigSettingDatatableList from "../components/ConfigSetting/ConfigSettingDatatableList";
import ManageConfigSetting from "../components/ConfigSetting/ManageConfigSetting";

import FrontArtistsProfile from "../components/FrontPublic/FrontArtistsProfile";
import DriveQRCode from "../components/DriverQRCode/DriveQRCode";
import CampaignDetails from "../components/Campaign/CampaignDetails";
import BalanceDatatableList from "../components/Balance/BalanceDatatableList";
import UserReports from "../components/Reports/User/UserReports";
import CampaignReports from "../components/Reports/Campaign/CampaignReports";
import PlayedUserSongHistoryList from "../components/Users/PlayedUserSongHistoryList";
import FanFunnelDatatableLists from "../components/FanFunnel/FanFunnelDatatableLists";
import ScheduledEvent from "../components/ScheduledEvent/ScheduledEvent";
import ActivityDatatableList from "../components/Activity/ActivityDatatableList";
import AboutUs from "../components/AboutUs/AboutUs";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import PaymentPolicy from "../components/PaymentPolicy/PaymentPolicy";
import TermsConditions from "../components/Terms&Conditions/TermsConditions";
import ForgotPassword from "../components/FrontPublic/ForgotPassword/ForgotPassword";
import VerifyEmailUser from "../components/FrontPublic/VerifyEmailUser/VerifyEmailUser";
import FreeImpressionDatatableList from "../components/FreeImpression/FreeImpressionDatatableList";
import AddFreeImpression from "../components/FreeImpression/AddFreeImpression";
import CancelCampaignList from "../components/Campaign/CancelCampaignList";

const Main_Route = (props) => {
  return (
    <Routes>
      {/** Protected Routes */}
      {/** Wrap all Route under ProtectedRoutes element */}
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/" element={<InnerContent />}>
          <Route path="/" element={<Navigate replace to="dashboard" />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route
            path="/campaign-county-list"
            element={<CampaignDatatableLists />}
          />
          <Route path="/manage-campaign-county" element={<ManageCampaign />} />
          <Route
            path="/manage-campaign-county/:id"
            element={<ManageCampaign />}
          />

          <Route path="/genre-list" element={<GenreDatatableLists />} />
          <Route path="/manage-genre" element={<ManageGenre />} />
          <Route path="/manage-genre/:id" element={<ManageGenre />} />

          <Route path="/ethnicity-list" element={<EthnicityDatatableLists />} />
          <Route path="/manage-ethnicity" element={<ManageEtnicity />} />
          <Route path="/manage-ethnicity/:id" element={<ManageEtnicity />} />

          <Route
            path="/notification-list"
            element={<NotificationTemplateLists />}
          />
          <Route
            path="/manage-notification"
            element={<ManageNotificationTemplate />}
          />
          <Route
            path="/manage-notification/:id"
            element={<ManageNotificationTemplate />}
          />

          <Route path="/toolkit-list" element={<ToolKitDatatableLists />} />
          <Route path="/manage-toolkit" element={<ManageToolKit />} />
          <Route path="/manage-toolkit/:id" element={<ManageToolKit />} />

          <Route path="/question-list" element={<QuestionDatatableLists />} />
          <Route path="/manage-question" element={<ManageQuestionOption />} />
          <Route
            path="/manage-question/:id"
            element={<ManageQuestionOption />}
          />
          <Route path="/question-add" element={<AddQuestionOption />} />
          <Route path="/question-edit" element={<EditQuestionOption />} />

          <Route
            path="/emailtemplate-list"
            element={<EmailTemplateDatatableLists />}
          />
          <Route
            path="/manage-emailtemplate"
            element={<ManageEmailTemplate />}
          />
          <Route
            path="/manage-emailtemplate/:id"
            element={<ManageEmailTemplate />}
          />

          <Route
            path="/impressiondetails-list"
            element={<ImpressionDetailsDatatableLists />}
          />
          <Route
            path="/manage-impressiondetails"
            element={<ManageImpressionDetails />}
          />
          <Route
            path="/manage-impressiondetails/:id"
            element={<ManageImpressionDetails />}
          />

          <Route
            path="/campaignpackage-list"
            element={<CampaignPackageDatatableLists />}
          />
          <Route
            path="/manage-campaignpackage"
            element={<ManageCampaignPackage />}
          />
          <Route
            path="/manage-campaignpackage/:id"
            element={<ManageCampaignPackage />}
          />

          <Route path="/user-list" element={<UserList />} />
          <Route path="/user-list/:id" element={<UserList />} />

          <Route path="/fanfunnle-list" element={<FanFunnelDatatableLists />} />
          <Route path="/balance-list" element={<BalanceDatatableList />} />

          <Route path="/userdocument" element={<DocumentList />} />
          <Route path="/userdocument/:id" element={<DocumentList />} />
          {/* User history */}
          <Route
            path="/userhistory/:id"
            element={<PlayedUserSongHistoryList />}
          />

          <Route path="/story-list" element={<StoryDatatableList />} />
          <Route path="/manage-story" element={<ManageStory />} />
          <Route path="/manage-story/:id" element={<ManageStory />} />
          <Route
            path="/story-viewuser/:id"
            element={<StoryUserDataTableList />}
          />

          <Route
            path="/configsetting-list"
            element={<ConfigSettingDatatableList />}
          />
          <Route
            path="/manage-configsetting"
            element={<ManageConfigSetting />}
          />
          <Route
            path="/manage-configsetting/:id"
            element={<ManageConfigSetting />}
          />

          <Route
            path="/custom-notification-list"
            element={<NotificationLists />}
          />
          <Route path="/custom-email-list" element={<EmailList />} />
          <Route
            path="/customnotification-list"
            element={<CustomNotificationSendDatatableList />}
          />
          <Route
            path="/customemail-list"
            element={<CustomEmailSendDatatableList />}
          />
          <Route path="/custom-add/:type" element={<AddUsers />} />

          <Route path="/earn-list" element={<EarnHistoryList />} />
          <Route
            path="/transaction-history-list"
            element={<TransactionHistoryList />}
          />
          <Route
            path="/transaction-request-list"
            element={<TransactionRequestList />}
          />

          <Route path="/cancelCampaign-all" element={<CancelCampaignList />} />
          <Route path="/campaign-all" element={<AllCampaignList />} />
          <Route
            path="/main-campaign-edit/:id/:cmdid"
            element={<EditCampaign />}
          />
          <Route
            path="/main-campaign-details/:id"
            element={<CampaignDetails />}
          />

          {/* Reports */}
          <Route path="/user-reports" element={<UserReports />} />
          <Route path="/campaign-reports" element={<CampaignReports />} />

          {/* Activity Routes */}
          <Route path="/activity-list" element={<ActivityDatatableList />} />

          {/* Calendar Page Route */}
          <Route path="/calendar" element={<ScheduledEvent />} />

          {/* MasterCampaign Page Route */}
          <Route
            path="/mastercampaign-list"
            element={<MasterCampaignDatatableList />}
          />
          <Route
            path="/mange-mastercampaign"
            element={<MangeMasterCampaign />}
          />
          <Route
            path="/mange-mastercampaign/:id"
            element={<MangeMasterCampaign />}
          />

          <Route
            path="/free-impression"
            element={<FreeImpressionDatatableList />}
          />
          <Route path="/free-impression/add" element={<AddFreeImpression />} />
        </Route>
      </Route>

      {/** Public Routes */}
      {/** Wrap all Route under PublicRoutes element */}
      <Route path="/login" element={<PublicRoutes />}>
        <Route path="/login" element={<Login />} />
      </Route>

      {/** Permission denied route */}
      <Route path="/denied" element={<PermissionDenied />} />

      {/* Forgot Password route */}
      <Route path="/forgotpassword/:code" element={<ForgotPassword />} />

      {/* {Verify Email Of User} */}
      <Route path="/verifyEmail/:uniqueid" element={<VerifyEmailUser />} />

      {/* Driver's  QRCode route*/}
      <Route path="/QRCode/:id" element={<DriveQRCode />} />

      {/** Front Page routes */}
      <Route path="/drprofile/:id" element={<FrontDriverProfile />} />
      <Route path="/profile/:id" element={<FrontArtistsProfile />} />

      {/* Extra Routes  */}
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/payment-policy" element={<PaymentPolicy />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
    </Routes>
  );

  // return (
  //     <Routes>
  //         <Route path='/' element={<Login />} />
  //         <Route path="/dashboard" element={<Dashboard />} />
  //         <Route path='/campaign-list' element={<CampaignLists />} />
  //         <Route path='/genre-list' element={<GenreLists />} />
  //         <Route path='/ethnicity-list' element={<EthnicityLists />} />
  //         <Route path='/notification-list' element={<NotificationTemplateLists />} />
  //     </Routes>
  // )
};

export default Main_Route;
