import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';

import Footer from '../../Layout/footer/Footer';
import Sidebar from '../../Layout/sidebar/Sidebar';
import { toastError, toastSuccess } from '../../common/Toast';
import config from '../../config/config';
import 'bootstrap/dist/css/bootstrap.min.css';

import CustomLoader from "../../Layout/CustomLoader";

const ManageCampaignPackage = (props) => {

    const paramObject = useParams();
    let navigate = useNavigate();

    const [formValues, setFormValues] = useState({ Id: 0, ToolKitID: 0, CountryCode: '', ImpressionDetailsID: 0, IsActive: true });
    const [countryitems, setCountryItems] = useState([]);
    const [toolkititems, setToolkititems] = useState([]);
    const [impressionitems, setImpressionitems] = useState([]);
    const [country, setCountry] = useState();
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        ToolKitID: Yup.string().required("Toolkit Name is a required "),
        CountryCode: Yup.string().required("Campaign Country is required"),
        ImpressionDetailsID: Yup.string().required("Impression Detail is required")
    });

    const BindCountryApi = () => {
        setLoading(true);

        axios.get(`${config.baseUrl}/campaincountry/fordropdown`)
            .then((response) => {
                setCountryItems(response.data.ResponseData);

                setLoading(false);
            }).catch((err) => {
                console.log(err)
                //setError(err);

                setLoading(false);
            })
    }


    const ToolkitDropdown = () => {
        setLoading(true);

        axios.get(`${config.baseUrl}/campaintoolkit/`)
            .then((response) => {
                setToolkititems(response.data.ResponseData);

                setLoading(false);
            }).catch((err) => {
                console.log(err)
                //setError(err);

                setLoading(false);
            })
    }

    const handleApi = (countrycode) => {
        setLoading(true);

        axios.get(`${config.baseUrl}/campaintoolkit/getAllToolKitXImpressionForDropdown?countrycode=${countrycode}`)
            .then((response) => {
                setImpressionitems(response.data.ResponseData);

                setLoading(false);
            }).catch((err) => {
                console.log(err)
                //setError(err);

                setLoading(false);
            })
    }


    const handleCountryChange = (e) => {
        setCountry(e.target.value);
        handleApi(e.target.value);
    }

    const handleSubmit = (values) => {
        console.log(values);

        setLoading(true);

        axios.post(`${config.baseUrl}/campaintoolkit/ToolKitXImpressionDetailsupsert`, {
            id: values.Id,
            toolkitid: values.ToolKitID,
            countrycode: values.CountryCode,
            impressiondetailsid: values.ImpressionDetailsID,
            isactive: values.IsActive
        }, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((res) => {
                if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
                    toastSuccess('Record has been added!');
                    navigate("/campaignpackage-list");
                }
                else if (res.data.ResponseCode === "ERROR" && res.data.ResponseID === -1) {
                    toastError("Name already Exist!");
                }
                else {
                    toastError("Somthing went wrong");
                }

                setLoading(false);
            }).catch((error) => {
                console.log("error occured", error);

                setLoading(false);
            });
    }


    const CampaignPackageById = () => {
        if (paramObject.id > 0) {
            setLoading(true);

            axios.get(`${config.baseUrl}/campaintoolkit/ToolKitXImpressionDetailsfindbyid?id=${paramObject.id}`)
                .then((response) => {
                    const responseData = response.data.ResponseData[0];
                    const { Id, ToolKitID, CountryCode, ImpressionDetailsID } = responseData;
                    const IsActive = responseData.IsActive === 1 ? true : false;
                    setFormValues({ Id, ToolKitID, CountryCode, ImpressionDetailsID, IsActive });
                    handleApi(CountryCode)
                    setCountry(CountryCode);
                    setLoading(false);
                }).catch((err) => {
                    console.log(err);

                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        BindCountryApi();
        ToolkitDropdown();
        CampaignPackageById();
    }, []);


    return (
        <Fragment>
            <Sidebar />
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-md-6">
                                <h1>Manage Campaign Package </h1>
                            </div>
                            <div className="col-md-6">
                                <ol className="breadcrumb float-md-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">Campaign Package</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header text-right" >
                                        <h3 className="card-title">Manage</h3>
                                    </div>
                                    <div className="card-body">
                                        {/* <ManageForm
                                        initialValues={formValues}
                                        onSubmit={handleSubmit}
                                        enableReinitialize
                                        >
                                        Update
                                    </ManageForm> */}

                                        <Formik  {...props}
                                            validationSchema={validationSchema}
                                            initialValues={formValues}
                                            onSubmit={handleSubmit}
                                            enableReinitialize
                                        >
                                            {({
                                                errors,
                                                touched,
                                                handleChange
                                            }) => (
                                                <Form>
                                                    <div className="dataTables_wrapper dt-bootstrap4" >
                                                        <Field type="hidden" className="form-control" id="Id" name="Id" />

                                                        <div className='row'>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">ToolKit</label>
                                                                    <Field as="select" className="form-control custom-select2-dropdown" id="ToolKitID" name="ToolKitID" onChange={handleChange}>
                                                                        <option value=""> -- Select ToolKitName -- </option>
                                                                        {toolkititems.map((t) => <option value={t.Id}>{t.ToolKitName}</option>)}
                                                                    </Field>
                                                                    <span className="text-danger">
                                                                        {touched.ToolKitID && errors.ToolKitID ? errors.ToolKitID : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="name"> Country Code</label>
                                                                    <Field as="select" className="form-control custom-select2-dropdown" id="CountryCode" name="CountryCode" value={country} onInput={(e) => handleCountryChange(e)} onChange={(e)=>{handleChange(e)}}>
                                                                        <option value=""> -- Select Country -- </option>
                                                                        {countryitems.map((country) => <option value={country.ShortCode}>{country.Name}</option>)}

                                                                    </Field>
                                                                    <span className="text-danger">
                                                                        {touched.CountryCode && errors.CountryCode ? errors.CountryCode : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            { }
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Impression Detail</label>
                                                                    <Field as="select" className="form-control custom-select2-dropdown" id="ImpressionDetailsID" name="ImpressionDetailsID" onChange={handleChange}>
                                                                        <option value="">--Impression Details--</option>
                                                                        {impressionitems.map((i) => <option value={i.ID}>{i.ImpressionType}</option>)}
                                                                    </Field>
                                                                    <span className="text-danger">
                                                                        {touched.ImpressionDetailsID && errors.ImpressionDetailsID ? errors.ImpressionDetailsID : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <Field type="checkbox" className="form-check-input" id="IsActive" name='IsActive' />
                                                                        <label className="form-check-label" htmlFor="IsActive">Active</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row text-right'>
                                                            <div className='col-md-12 me-2'>
                                                                <button type="submit" className="btn me-2 btn-primary" >Save</button>
                                                                <Link to="/campaignpackage-list" className="btn btn-secondary">Cancel</Link>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
            <Footer />
        </Fragment >
    )
}

export default ManageCampaignPackage