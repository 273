import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import Footer from '../../Layout/footer/Footer';

import axios from 'axios';
import ReactPaginate from "react-paginate";
import config from '../../config/config';
import { ToastContainer } from 'react-toastify';
import { toastError, toastSuccess } from '../../common/Toast';

import CustomLoader from "../../Layout/CustomLoader";

const FrontArtistProfile = () => {
    const paramObject = useParams();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [showEmailSubscriber, setShowEmailSubscriber] = useState(false);
    const [showFanFunnle, setshowFanFunnle] = useState(false);

    const [items, setItems] = useState([]);
    const [perPageSize, setperPageSize] = useState(10);
    const [searchtext, setSearchText] = useState("");
    const [totalrecord, setTotalRecord] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [remountComponent, setRemountComponent] = useState(0);

    const [ethnicityitems, setEthnicityItems] = useState([]);
    const [countryitems, setCountryItems] = useState([]);
    const [stateitems, setStateItems] = useState([]);

    const [profileValues, setProfileValues] = useState({ Id: 0, FullName: '', ProfileImage: '', UserType: '', SocialMedia: '' });
    const [callToActionValuesItems, setCallToActionValuesItems] = useState([]);
    const [emailListingCampaignValues, setEmailListingCampaignValues] = useState([]);
    const [fanfunnelCampaignValuesItems, setfanfunnelCampaignValuesItems] = useState([]);
    const [countryID, setCountryID] = useState(0);

    const [formValuesEmailSubscribers, setFormValuesEmailSubscribers] = useState({ UserID: 0, CampaignID: 0, Email: '' });
    const validationSchemaEmailSubscribers = Yup.object().shape({
        Email: Yup.string().required("Email is a required ")
    });
    const [formValuesFanFunnel, setFormValuesFanFunnel] = useState({ Name: '', ProfileLink: '', AgeGroup: '', CountryCode: '', EthnicityId: '', SocialPlatfrom: '', Gender: '', SupportArtistLiveShow: 'NO', StateId: 0 });
    const fanFunnleRef = useRef(null);
    const emailSubscriberRef = useRef(null);

    const validationSchemaFanFunnel = Yup.object().shape({
        // ProfileLink: Yup.string().required("ProfileLink is a required "),
        AgeGroup: Yup.string().required("Age Group is a required"),
        CountryCode: Yup.string().required("Country is a required"),
        EthnicityId: Yup.string().required("Ethnicity is a required"),
        SocialPlatfrom: Yup.string().required("Social Platfrom is a required"),
        Gender: Yup.string().required("Gender is a required"),
        StateId: Yup.string().required("State is a required")
    });

    const handleCallToActionApi = (uniqueid) => {
        if (!loading === true) {
            setLoading(true);

            axios.get(`${config.baseUrl}/campaign/calltoaction?uniqueid=${uniqueid}`)
                .then((response) => {

                    let responseObjData = response.data.ResponseData;

                    const { Id, FullName, ProfileImage, UserType, SocialMedia } = responseObjData.Profile[0];
                    setProfileValues({ Id, FullName, ProfileImage, UserType, SocialMedia });

                    setCallToActionValuesItems(responseObjData.Social);
                    setfanfunnelCampaignValuesItems(responseObjData.FanfunnelCampaign);
                    setEmailListingCampaignValues(responseObjData.EmailListingCampaign);

                    setLoading(false);

                }).catch((err) => {
                    console.log(err)

                    setError(err);

                    setLoading(false);
                })
        }
        else {
            toastError("Somthing went wrong!");
        }
    }

    const handleApi = (pagenumber, pagesize, filtertext, ordercolumn, currentorder, uniqueid) => {
        if (!loading === true) {
            setLoading(true);

            axios.get(`${config.baseUrl}/campaign/getAllCampaignListwithuniqueid?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&uniqueid=${uniqueid}`)
                .then((response) => {
                    setItems(response.data.ResponseData);
                    setTotalRecord(response.data.ResponseData[0].TotalRecord);
                    setpageCount(Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize));

                    setLoading(false);
                }).catch((err) => {
                    console.log(err)

                    setError(err);

                    setLoading(false);
                })
        }
        else {
            toastError("Somthing went wrong!");
        }
    }

    const changePage = ({ selected }) => {
        selected = selected + 1;
        handleApi(selected, perPageSize, searchtext, 0, "asc", paramObject.id);
    };

    const ChangeFilterText = (e) => {
        setSearchText(e.target.value);
        setRemountComponent(Math.random());
        handleApi(1, perPageSize, e.target.value, 0, "asc", paramObject.id);
    }

    const ChangePageSize = (e) => {
        setperPageSize(e.target.value);
        handleApi(1, e.target.value, searchtext, 0, "asc", paramObject.id);
    }

    const handleEthnicityApi = () => {
        if (!loading === true) {
            setLoading(true);

            axios.get(`${config.baseUrl}/ethnicity/fordropdown`)
                .then((response) => {
                    setEthnicityItems(response.data.ResponseData);

                    setLoading(false);
                }).catch((err) => {
                    console.log(err)

                    setError(err);

                    setLoading(false);
                })
        }
        else {
            toastError("Somthing went wrong!");
        }
    }

    const handleCountryApi = () => {
        if (!loading === true) {
            setLoading(true);

            axios.get(`${config.baseUrl}/country`)
                .then((response) => {
                    setCountryItems(response.data.ResponseData);

                    setLoading(false);
                }).catch((err) => {
                    console.log(err)

                    setError(err);

                    setLoading(false);
                })
        }
        else {
            toastError("Somthing went wrong!");
        }
    }

    const handleStateApi = (e) => {

        if (!loading === true) {
            let countryCode = e.target.value;
            setCountryID(e.target.value);
            console.log(countryCode);

            setLoading(true);

            axios.get(`${config.baseUrl}/country/findallstatebycountry?countrycode=${countryCode}`)
                .then((response) => {
                    setStateItems(response.data.ResponseData);

                    setLoading(false);
                }).catch((err) => {
                    console.log(err)

                    setError(err);

                    setLoading(false);
                })
        }
        else {
            toastError("Somthing went wrong!");
        }
    }


    useEffect(() => {

        if (paramObject.id !== "" && paramObject.id !== undefined) {
            // Profile View Click
            handleProfileViewClickApi(paramObject.id);

            // Bind Left Pannle Data
            handleCallToActionApi(paramObject.id);

            // Bind Campaign list
            handleApi(1, perPageSize, "", 0, "asc", paramObject.id);

            // Bind Ethnicity list
            handleEthnicityApi();

            // Bind Country list
            handleCountryApi();
        }
        else {
            navigate("denied");
        }

    }, []);

    const handleProfileViewClickApi = (uniqueid) => {
        if (!loading === true) {
            setLoading(true);

            axios.post(`${config.baseUrl}/users/addviewclick`, {
                uniqueid: uniqueid
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then((res) => {
                    console.log("SUCCESS");

                    setLoading(false);
                }).catch((error) => {
                    console.log("error occured", error);

                    setLoading(false);
                });
        }
        else {
            toastError("Somthing went wrong!");
        }
    }


    const handleClickCallToAction = (campaignId) => {
        if (!loading === true) {
            setLoading(true);

            axios.post(`${config.baseUrl}/users/addcalltoaction`, {
                uniqueid: paramObject.id,
                campaignid: campaignId
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then((res) => {
                    console.log("SUCCESS");

                    setLoading(false);
                }).catch((error) => {
                    console.log("error occured", error);

                    setLoading(false);
                });
        }
        else {
            toastError("Somthing went wrong!");
        }
    }

    const handleSocialMediaCountClick = () => {
        if (!loading === true) {
            setLoading(true);

            axios.post(`${config.baseUrl}/users/addsocialmedia`, {
                uniqueid: paramObject.id,
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then((res) => {
                    console.log("SUCCESS");

                    setLoading(false);
                }).catch((error) => {
                    console.log("error occured", error);

                    setLoading(false);
                });
        }
        else {
            toastError("Somthing went wrong!");
        }
    }

    const handleClickEmailSubscribers = (campaignId) => {
        let UserID = profileValues.Id;
        let CampaignID = campaignId;
        let Email = '';
        setFormValuesEmailSubscribers({ UserID, CampaignID, Email });

        setShowEmailSubscriber(true);

        if (emailSubscriberRef.current) {
            emailSubscriberRef.current.scrollIntoView({ behavior: "smooth" });
        }

    }

    const handleSubmitEmailSubscribers = (values) => {

        if (!loading === true) {
            setLoading(true);

            axios.post(`${config.baseUrl}/users/subscribers`, {
                userid: values.UserID,
                subscriberemailId: values.Email
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then((res) => {

                    if (res.data.ResponseCode == "SUCCESS") {
                        toastSuccess('Your request has been sent successfully!');

                        let UserID = profileValues.Id;
                        let CampaignID = 0;
                        let Email = '';

                        setFormValuesEmailSubscribers({ UserID, CampaignID, Email });
                    }
                    else {
                        toastError("Enable to send request!");
                    }

                    setLoading(false);
                }).catch((error) => {
                    console.log("error occured", error);

                    setLoading(false);
                });
        }
        else {
            toastError("Somthing went wrong!");
        }

    }


    const handleClickFanfunnle = (campaignId) => {

        setshowFanFunnle(true);

        if (fanFunnleRef.current) {
            fanFunnleRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    const handleSubmitFanfunnle = (values) => {
        if (!loading === true) {
            setLoading(true);

            axios.post(`${config.baseUrl}/fanfunnle/upsert`, {
                id: 0,
                Name: values.Name,
                ProfileLink: '',// values.ProfileLink,
                AgeGroup: values.AgeGroup,
                countrycode: values.CountryCode,
                ethnicityid: values.EthnicityId,
                socialplatfrom: values.SocialPlatfrom,
                gender: values.Gender,
                isactive: true,
                createby: profileValues.Id,
                funnletype: "ENGAGED",
                supportartistliveshow: values.SupportArtistLiveShow,
                stateid: values.StateId,
                devicetype: "WEB"
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then((res) => {

                    if (res.data.ResponseCode == "SUCCESS") {
                        toastSuccess('Your request has been sent successfully!');
                        let Name = '';
                        let ProfileLink = '';
                        let AgeGroup = '';
                        let CountryCode = '';
                        let EthnicityId = '';
                        let SocialPlatfrom = '';
                        let Gender = '';
                        let SupportArtistLiveShow = 'NO';
                        let StateId = 0;
                        setFormValuesFanFunnel({ Name, ProfileLink, AgeGroup, CountryCode, EthnicityId, SocialPlatfrom, Gender, SupportArtistLiveShow, StateId });
                        // window.location.reload();
                    }
                    else {
                        toastError("Enable to send request!");
                    }

                    setLoading(false);
                }).catch((error) => {
                    console.log("error occured", error);

                    setLoading(false);
                });
        }
        else {
            toastError("Somthing went wrong!");
        }

    }

    const formatDate = (string) => {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([], options);
    }

    return (
        <Fragment>
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper public-content-wrapper" style={{ marginLeft: "0px" }}>
                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-md-3'>
                                <div className="card card-warning card-outline">
                                    <div className="card-body box-profile">
                                        <div className="text-center">
                                            <img className="profile-user-img img-fluid img-circle" src={profileValues.ProfileImage} height="128" width="128" alt={profileValues.FullName} />
                                        </div>
                                        <h3 className="profile-username text-center">{profileValues.FullName}</h3>
                                        <p className="text-muted text-center">{profileValues.UserType}</p>
                                        <div class="card public-card">
                                            <div class="card-header">
                                                <h3 class="card-title"><i class="fas fa-text-width"></i> Call to Action Link</h3>
                                            </div>

                                            <div class="card-body clearfix">
                                                {callToActionValuesItems.map((item) => (
                                                    <blockquote class="quote-primary public-blockquote">
                                                        <p>{item.CampaignName}</p>
                                                        <a href={item.CallToActionLink} class="btn btn-dark btn-sm" target="_blank" onClick={() => handleClickCallToAction(item.Id)}>{item.CallToActionLink}</a>
                                                    </blockquote>

                                                ))}

                                            </div>

                                        </div>
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="card-title"><i class="fas fa-text-width"></i> Social Account</h3>
                                            </div>
                                            {profileValues.SocialMedia != "" ?
                                                <div class="card-body clearfix">
                                                    <a href={profileValues.SocialMedia} class="btn btn-block btn-dark btn-sm mb-2" target="_blank" onClick={() => handleSocialMediaCountClick()}>{profileValues.SocialMedia}</a>
                                                </div>
                                                : ""}
                                        </div>


                                        {/* <p>Call to Actino Link</p>
                                        <ul className="list-group list-group-unbordered mb-3">
                                            {callToActionValuesItems.map((item) => (
                                                <li className="list-group-item">
                                                    <span className='text-white'>{item.CampaignName}</span>
                                                    <a href={item.CallToActionLink} class="btn btn-block btn-dark btn-sm" target="_blank" onClick={() => handleClickCallToAction(item.Id)}>{item.CallToActionLink}</a>
                                                </li>
                                            ))}
                                        </ul> */}
                                        {/* <p>Social Media Links</p>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <a href={profileValues.SocialMedia} class="btn btn-block btn-dark btn-sm mb-2" target="_blank" onClick={() => handleSocialMediaCountClick()}>{profileValues.SocialMedia}</a>
                                            </div>
                                        </div> */}

                                        <p></p>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                {fanfunnelCampaignValuesItems.map((item) => (
                                                    <button type="button" class="btn btn-outline-secondary btn-block text-white" onClick={() => handleClickFanfunnle(item.Id)}><i class="fa fa-bell"></i> Get to know {profileValues.FullName}</button>
                                                ))}

                                                {emailListingCampaignValues.map((item) => (
                                                    <button type="button" class="btn btn-outline-secondary btn-block  text-white" onClick={() => handleClickEmailSubscribers(item.Id)}  ><i class="fa fa-bell"></i> Get updates from {profileValues.FullName}</button>
                                                ))}
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div id="dvEmailSubscriber" ref={emailSubscriberRef} className={showEmailSubscriber ? "card card-success card-outline" : "card card-success card-outline d-none"}>
                                    <div className="card-header">
                                        <h3>Email Subscribers & Newsletters</h3>
                                    </div>
                                    <div className="card-body box-profile">

                                        <Formik
                                            validationSchema={validationSchemaEmailSubscribers}
                                            initialValues={formValuesEmailSubscribers}
                                            onSubmit={handleSubmitEmailSubscribers}
                                            enableReinitialize
                                        >
                                            {({
                                                errors,
                                                touched,
                                                handleChange
                                            }) => (
                                                <Form>
                                                    <div className="dataTables_wrapper dt-bootstrap4">
                                                        <Field type="hidden" className="form-control" id="UserID" name="UserID" />

                                                        <div className='row'>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="Email"> Email</label>
                                                                    <Field type="text" className="form-control" id="Email" name="Email" onChange={handleChange} />
                                                                    <span className="text-danger">
                                                                        {touched.Email && errors.Email ? errors.Email : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <button type="submit" className="btn me-2 btn-primary btn-block">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>

                                    </div>
                                </div>

                            </div>
                            <div className="col-md-9">
                                <div className="card">
                                    <div className="card-header text-right" >
                                        <h3 className="card-title">Campaign Details</h3>
                                    </div>

                                    <div className="card-body">
                                        <div className="dataTables_wrapper dt-bootstrap4" >
                                            <div className="row">

                                                <div className="col-sm-6 col-md-6">
                                                    <select className='' onChange={ChangePageSize}>
                                                        <option value="10">10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </div>

                                                <div className="col-sm-6 col-md-6 text-right">
                                                    <div id="example1_filter" className="dataTables_filter">
                                                        <label><input type="search" className="form-control form-control-sm" placeholder="" aria-controls="example1" onChange={ChangeFilterText} /></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 table-responsive">
                                                    <table id="example1" className="table table-bordered dataTable dtr-inline" aria-describedby="example1_info">
                                                        <thead>
                                                            <tr>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Campaign: activate to sort column ascending">Campaign</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Cover Image: activate to sort column ascending">Cover Image</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Music: activate to sort column ascending">Music</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {items.length > 0 ? items.map((item) => (
                                                                <tr className="odd">
                                                                    <td>{item.CampaignName}</td>
                                                                    <td>
                                                                        <a href={item.ArtWorkImageName} target="_blank">
                                                                            <img src={item.ArtWorkImageName} height={50} width={50}></img>
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <audio controls>
                                                                            <source src={item.MusicFileName} type="audio/mp3"></source>
                                                                        </audio>

                                                                    </td>
                                                                </tr>
                                                            )) : <tr>
                                                                <td colSpan={3} align='center'>Data not found!</td>
                                                            </tr>}

                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-12 col-md-5' key={remountComponent}>
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={items.length > 0 ? pageCount : 0}
                                                        onPageChange={changePage}
                                                        containerClassName={"pagination"}
                                                        pageClassName={"page-link"}
                                                        previousLinkClassName={"page-link"}
                                                        nextLinkClassName={"page-link"}
                                                        activeClassName={"active"}
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6'>
                                    
                                        <div id="dvFanFunnle" ref={fanFunnleRef}  className={showFanFunnle ? "card" : "card d-none"} >
                                            <Formik
                                                validationSchema={validationSchemaFanFunnel}
                                                initialValues={formValuesFanFunnel}
                                                onSubmit={handleSubmitFanfunnle}
                                                enableReinitialize
                                            >
                                                {({
                                                    errors,
                                                    touched,
                                                    handleChange
                                                }) => (
                                                    <Form>
                                                        <div className="card-header text-right" >
                                                            <h3 className="card-title">Fan Funnel</h3>
                                                        </div>

                                                        <div className="card-body">
                                                            <div className="dataTables_wrapper dt-bootstrap4" >

                                                                <div className="dataTables_wrapper dt-bootstrap4" >
                                                                    {/* <Field type="hidden" className="form-control" id="ID" name="ID" /> */}

                                                                    <div className='row'>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="Name"> Name</label>
                                                                                <Field type="text" className="form-control" id="Name" name="Name" onChange={handleChange} />
                                                                                <span className="text-danger">
                                                                                    {touched.Name && errors.Name ? errors.Name : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='row'>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="ProfileLink">Profile Link</label>
                                                                                <Field type="text" className="form-control" id="ProfileLink" name="ProfileLink" onChange={handleChange} />
                                                                                <span className="text-danger">
                                                                                    {touched.ProfileLink && errors.ProfileLink ? errors.ProfileLink : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className='row'>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="AgeGroup">Age Group</label>
                                                                                <Field as="select" className="form-control" id="AgeGroup" name="AgeGroup" onChange={handleChange}>
                                                                                    <option value="">Select Age Group</option>
                                                                                    <option value="15-25">15-25</option>
                                                                                    <option value="26-45">26-45</option>
                                                                                    <option value="46-65">46-65</option>
                                                                                </Field>

                                                                                {/* <Field type="text" className="form-control" id="AgeGroup" name="AgeGroup" onChange={handleChange} /> */}
                                                                                <span className="text-danger">
                                                                                    {touched.AgeGroup && errors.AgeGroup ? errors.AgeGroup : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="CountryCode">Country</label>
                                                                                <Field as="select" className="form-control" id="CountryCode" name="CountryCode" value={countryID} onChange={handleChange} onInput={e => { handleStateApi(e) }} >
                                                                                    <option value="">Select Country</option>
                                                                                    {countryitems.map((item) => (
                                                                                        <option value={item.ShortCode}>{item.Name}</option>
                                                                                    ))}
                                                                                </Field>
                                                                                <span className="text-danger">
                                                                                    {touched.CountryCode && errors.CountryCode ? errors.CountryCode : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="StateId">State</label>
                                                                                <Field as="select" className="form-control" id="StateId" name="StateId" onChange={handleChange}>
                                                                                    <option value="">Select State</option>
                                                                                    {stateitems.map((item) => (
                                                                                        <option value={item.Id}>{item.Name}</option>
                                                                                    ))}
                                                                                </Field>
                                                                                <span className="text-danger">
                                                                                    {touched.StateId && errors.StateId ? errors.StateId : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="EthnicityId">Ethnicity</label>
                                                                                <Field as="select" className="form-control" id="EthnicityId" name="EthnicityId" onChange={handleChange}>
                                                                                    <option value="">Select Ethnicity</option>
                                                                                    {ethnicityitems.map((item) => (
                                                                                        <option value={item.Id}>{item.Name}</option>
                                                                                    ))}
                                                                                </Field>
                                                                                {/* <Field type="text" className="form-control" id="EthnicityId" name="EthnicityId" onChange={handleChange} /> */}
                                                                                <span className="text-danger">
                                                                                    {touched.EthnicityId && errors.EthnicityId ? errors.EthnicityId : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="SocialPlatfrom">Social Platfrom</label>
                                                                                <Field as="select" className="form-control" id="SocialPlatfrom" name="SocialPlatfrom" onChange={handleChange}>
                                                                                    <option value="">Select Social Platfrom</option>
                                                                                    <option value="Instagram">Instagram</option>
                                                                                    <option value="Facebook">Facebook</option>
                                                                                    <option value="Linkedin">linkedin</option>
                                                                                </Field>
                                                                                {/* <Field type="text" className="form-control" id="SocialPlatfrom" name="SocialPlatfrom" onChange={handleChange} /> */}
                                                                                <span className="text-danger">
                                                                                    {touched.SocialPlatfrom && errors.SocialPlatfrom ? errors.SocialPlatfrom : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="Gender">Gender</label>
                                                                                <Field as="select" className="form-control" id="Gender" name="Gender" onChange={handleChange}>
                                                                                    <option value="">Select Gender</option>
                                                                                    <option value="Male">Male</option>
                                                                                    <option value="Female">Female</option>
                                                                                    <option value="Other">Other</option>
                                                                                </Field>
                                                                                {/* <Field type="text" className="form-control" id="Gender" name="Gender" onChange={handleChange} /> */}
                                                                                <span className="text-danger">
                                                                                    {touched.Gender && errors.Gender ? errors.Gender : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="SupportArtistLiveShow">Will you support this artist at live show?</label>
                                                                                <Field as="select" className="form-control" id="SupportArtistLiveShow" name="SupportArtistLiveShow" onChange={handleChange}>
                                                                                    <option value="NO">NO</option>
                                                                                    <option value="YES">YES</option>
                                                                                </Field>
                                                                                <span className="text-danger">
                                                                                    {touched.SupportArtistLiveShow && errors.SupportArtistLiveShow ? errors.SupportArtistLiveShow : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='card-footer'>
                                                            <div className='row text-right'>
                                                                <div className='col-md-12 me-2'>
                                                                    <button type="submit" className="btn  me-2 btn-primary" >Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </Fragment>
    )
}

export default FrontArtistProfile