import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../Layout/sidebar/Sidebar";
import Footer from "../../Layout/footer/Footer";
import { Modal } from "react-bootstrap";
import axios from "axios";
import ReactPaginate from "react-paginate";
import config from "../../config/config";
import { ToastContainer } from "react-toastify";
import { toastError, toastSuccess } from "../../common/Toast";
import QRCodePdfDownloader from "./QRCodePdfDownloader";
import QRCodeTemplate from "./QRCodeTemplate";
import CustomLoader from "../../Layout/CustomLoader";
import AWS from "aws-sdk";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWSAccessKeyId,
  secretAccessKey: process.env.REACT_APP_AWSSecretKey,
  region: process.env.REACT_APP_REGION,
});

const UserList = () => {
  const paramObject = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [perPageSize, setperPageSize] = useState(10);
  const [searchtext, setSearchText] = useState("");
  const [searchUserType, setsearchUserType] = useState("");
  const [totalrecord, setTotalRecord] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const QRCodeUrl = `${process.env.QRCode_Base_Url}`;
  const [usertypeid, setusertypeid] = useState(0);

  const [questionList, setQuestionList] = useState([]);
  const [questionsatete, setQuestionSatete] = useState({ visible: null });
  const [blocksatete, setBlockSatete] = useState({ visible: null });
  const [statee, setSatete] = useState({ visible: null });
  const [stationerysatete, setStationerySatete] = useState({ visible: null });
  const [remountComponent, setRemountComponent] = useState(0);
  const [userstatus, setuserstatus] = useState("");
  const [reasonstatee, setreasonSatete] = useState(false);
  const [validreason, setValidReason] = useState(true);
  const [reason, setReason] = useState("");

  const handleApi = (
    pagenumber,
    pagesize,
    filtertext,
    ordercolumn,
    currentorder,
    userTypeId
  ) => {
    // if (!loading === true) {
    setLoading(true);

    axios
      .get(
        `${config.baseUrl}/users?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&userTypeId=${userTypeId}`
      )
      .then((response) => {
        setItems(response.data.ResponseData);
        setTotalRecord(response.data.ResponseData[0].TotalRecord);
        setpageCount(
          Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize)
        );

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        setError(err);
      });
    // }
    // else {
    //     toastError("Somthing went wrong!");
    // }
  };

  useEffect(() => {
    if (paramObject.id > 0 && paramObject.id != undefined) {
      setusertypeid(paramObject.id);
      handleApi(1, perPageSize, "", 0, "asc", paramObject.id);
    } else {
      handleApi(1, perPageSize, "", 0, "asc", searchUserType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObject]);

  const changePage = ({ selected }) => {
    selected = selected + 1;
    // setPageNumber(selected);
    handleApi(selected, perPageSize, searchtext, 0, "asc", searchUserType);
  };

  // user status handle
  const handleUserStatusModelpopup = (ID, Status) => {
    localStorage.setItem("UserID", ID);
    localStorage.setItem("UserStatus", Status);

    setuserstatus(Status);
    console.log(Status);
    if (Status === "Reject") {
      setreasonSatete(true);
    }
    setSatete({ visible: ID });
  };

  const handlecloseUserStatuspopup = () => {
    setSatete({ visible: null });
    setreasonSatete(false);
    localStorage.setItem("UserID", 0);
    localStorage.setItem("UserStatus", "");
  };

  const handleChangeReason = (e) => {
    if (e.target.value !== "") {
      setValidReason(true);
    } else {
      setValidReason(false);
    }
    setReason(e.target.value);
  };

  const handleDocuemntStatus = () => {
    let id = localStorage.getItem("UserID");
    let userStatus = localStorage.getItem("UserStatus");
    let statusid = 1;
    if (userStatus === "Reject") {
      if (document.getElementById("Reason").value === "") {
        setValidReason(false);

        return false;
      }

      statusid = 2;
    }

    setSatete({ visible: null });
    if (id > 0) {
      setLoading(true);
      axios
        .post(
          `${config.baseUrl}/users/approvebyadmin`,
          {
            userid: id,
            statusId: statusid,
            reason: reason,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.data.ResponseCode === "SUCCESS") {
            toastSuccess("Record has been updated!");
            handleApi(1, perPageSize, "", 0, "asc", searchUserType);
          } else {
            toastError("Enable to updated a record");
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const ChangePageSize = (e) => {
    setperPageSize(e.target.value);
    handleApi(1, e.target.value, searchtext, 0, "asc", searchUserType);
  };

  const ChangeFilterText = (e) => {
    setSearchText(e.target.value);
    setRemountComponent(Math.random());
    handleApi(1, perPageSize, e.target.value, 0, "asc", searchUserType);
  };

  const ChangeUserType = (e) => {
    setsearchUserType(e.target.value);
    setusertypeid(e.target.value);
    setRemountComponent(Math.random());
    handleApi(1, perPageSize, "", 0, "asc", e.target.value);
  };

  const handlecloseblockpopup = () => {
    //setSatete(!statee)
    // blocksatete
    setBlockSatete({ visible: null });
    localStorage.setItem("BlockedUserID", 0);
  };

  const handleBlockModelpopup = (id) => {
    localStorage.setItem("BlockedUserID", id);
    // setSatete(!statee);
    setBlockSatete({ visible: id });
  };

  const handleBlockUser = () => {
    let id = localStorage.getItem("BlockedUserID");
    // let loginuserID = localStorage.getItem("ID");

    // setSatete(!statee);
    setBlockSatete({ visible: null });
    if (id > 0) {
      setLoading(true);
      axios
        .post(
          `${config.baseUrl}/users/changeisactive`,
          {
            userid: id,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.data.ResponseCode === "SUCCESS") {
            toastSuccess("Record has been updated!");
            handleApi(1, perPageSize, "", 0, "asc");
          } else {
            toastError("Enable to update status!");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("error occured", error);
          setLoading(false);
        });
    }
  };

  const formatDate = (string) => {
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    };
    return new Date(string).toLocaleDateString("en-US", options);
  };

  const handleViewQuestionModelpopup = (id) => {
    localStorage.setItem("UserID", id);
    setQuestionSatete({ visible: id });
    setLoading(true);
    axios
      .get(`${config.baseUrl}/users/userquestions?id=${id}`)
      .then((response) => {
        setQuestionList(response.data.ResponseData);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setError(err);
        setLoading(false);
      });
  };

  // const handlepromotionactivity = (id) => {
  //     localStorage.setItem("UserID", id);
  //     //setQuestionSatete(!questionsatete);
  //     setLoading(true);
  //     axios.get(`${config.baseUrl}users/promotionactivity?userid=${id}`)
  //         .then((response) => {
  //             console.log(response.data);
  //             return response.data.ResponseData
  //             // setPromotion(response.data.ResponseData)

  //             setLoading(false);
  //         }).catch((err) => {
  //             console.log(err)

  //             setError(err);
  //             setLoading(false);
  //         })

  // }

  const handlecloseViewQuestionpopup = () => {
    //setQuestionSatete(!questionsatete);
    setQuestionSatete({ visible: null });
    localStorage.setItem("UserID", 0);
  };

  const [state, setState] = useState({ visible: null });
  const handleDownloadQRCode = (index) => () => setState({ visible: index });
  const handlecloseQrdownloadpopup = () => setState({ visible: null });

  const handleclosestationerypopup = () => {
    //setSatete(!statee)
    setStationerySatete({ visible: null });
    localStorage.setItem("StationeryUserID", 0);
  };

  const handleStationeryModelpopup = (id) => {
    localStorage.setItem("StationeryUserID", id);
    // setSatete(!statee);
    setStationerySatete({ visible: id });
  };

  const handleStationeryUser = () => {
    let id = localStorage.getItem("StationeryUserID");
    // let loginuserID = localStorage.getItem("ID");

    // setSatete(!statee);
    setStationerySatete({ visible: null });
    if (id > 0) {
      setLoading(true);
      axios
        .post(
          `${config.baseUrl}/users/addstationery`,
          {
            userid: id,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
            toastSuccess("Record has been updated!");
            handleApi(1, perPageSize, "", 0, "asc");
          } else {
            toastError("Enable to delete a record");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("error occured", error);
          setLoading(false);
        });
    }
  };

  const connvertBase64 = async (string) => {
    let splitData = string.split("USERS");

    const params = {
      Bucket: process.env.REACT_APP_Bucket,
      Key: "USERS" + splitData[1],
    };

    const myPromise = new Promise((resolve, reject) => {
      s3.getObject(params, (err, data) => {
        if (err) {
          console.log(err, err.stack);
        } else {
          //console.log(data);
          let csvBlob = new Blob([data.Body], { type: "image/png" });
          // console.log(csvBlob);

          var reader = new FileReader();
          reader.readAsDataURL(csvBlob);
          reader.onloadend = function () {
            var base64String = reader.result;
            console.log("Base64 String - ", base64String);
            //  setCodeUrl(base64String);

            resolve(base64String);
          };
        }
      });
    });
    console.log("Call Print");

    return myPromise;
  };

  return (
    <Fragment>
      <Sidebar />
      <ToastContainer />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Users</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">User</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header text-right">
                    <h3 className="card-title">User</h3>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label col-sm-6">
                            User Type
                          </label>
                          <div className="col-sm-10">
                            <select
                              className="form-control"
                              onChange={ChangeUserType}
                              value={usertypeid}
                            >
                              <option value={0}>All</option>
                              <option value={1}>Admin</option>
                              <option value={2}>Artist</option>
                              <option value={3}>Driver</option>
                              <option value={4}>Business</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-6 col-md-6">
                            <select className="" onChange={ChangePageSize}>
                              <option value="10">10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                            </select>
                          </div>

                          <div className="col-sm-6 col-md-6 text-right">
                            <div
                              id="example1_filter"
                              className="dataTables_filter"
                            >
                              <label>
                                <input
                                  type="search"
                                  className="form-control form-control-sm"
                                  placeholder=""
                                  aria-controls="example1"
                                  onChange={ChangeFilterText}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        <div
                          id="example1_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 table-responsive"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="">
                                <table
                                  id="example1"
                                  className="table table-bordered dataTable dtr-inline"
                                  aria-describedby="example1_info"
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Action"
                                      >
                                        Action
                                      </th>
                                      <th
                                        className="sorting sorting_asc"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="ID: activate to sort column descending"
                                      >
                                        ID
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Profile: activate to sort column ascending"
                                      >
                                        Profile
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Name: activate to sort column ascending"
                                      >
                                        Name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Email: activate to sort column ascending"
                                      >
                                        Email
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="UserType: activate to sort column ascending"
                                      >
                                        User Type
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Country: activate to sort column ascending"
                                      >
                                        Country
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="MobileNo: activate to sort column ascending"
                                      >
                                        Mobile No
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="ReferralCode: activate to sort column ascending"
                                      >
                                        Referral Code
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="IsActive: activate to sort column ascending"
                                      >
                                        Active
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="IsblueTickEnable: activate to sort column ascending"
                                      >
                                        Approve?(Bluetick)
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="IsMasterCampaign: activate to sort column ascending"
                                      >
                                        Master Campaign ?
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Promotion Activity: activate to sort column ascending"
                                      >
                                        Promotion Activity
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="CreatedDate: activate to sort column ascending"
                                      >
                                        Created Date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Email Verified: activate to sort column ascending"
                                      >
                                        Email Verified
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Status: activate to sort column ascending"
                                      >
                                        Approving User
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="address to sort column ascending"
                                      >
                                        Address
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="deviceType to sort column ascending"
                                      >
                                        Device Type
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="deviceType to sort column ascending"
                                      >
                                        RecordLabel
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {items.length > 0 ? (
                                      items.map((item) => (
                                        <tr className="odd">
                                          <td>
                                            <Link
                                              to={"/userdocument/" + item.ID}
                                              className="btn btn-primary btn-sm mb-2 mr-2 "
                                              title="Documents"
                                            >
                                              <i className="fas fa-file"></i>
                                            </Link>
                                            {item.UserTypeId === 3 ? (
                                              <>
                                                <button
                                                  type="button"
                                                  title="Download"
                                                  className="btn btn-warning btn-sm mb-2 mr-2 "
                                                  onClick={handleDownloadQRCode(
                                                    item.ID
                                                  )}
                                                >
                                                  <i className="fas fa-qrcode" />
                                                </button>
                                                <Modal
                                                  show={
                                                    state.visible === item.ID
                                                  }
                                                >
                                                  <div
                                                    className="modal fade show"
                                                    style={{
                                                      display: "block",
                                                      paddingRight: "17px",
                                                    }}
                                                    aria-modal="true"
                                                    role="dialog"
                                                  >
                                                    <div className="modal-dialog modal-lg">
                                                      <div className="modal-content">
                                                        <div className="modal-body">
                                                          <QRCodeTemplate
                                                            id={
                                                              "divToDownloadQRCode-" +
                                                              item.ID
                                                            }
                                                            userid={item.ID}
                                                            drivername={
                                                              item.FullName
                                                            }
                                                            qrcodeurl={
                                                              item.QRCodeURL
                                                            }
                                                            qrcodetype={
                                                              item.QRCodeType
                                                            }
                                                            qraction={
                                                              QRCodeUrl +
                                                              "/drprofile/" +
                                                              item.UniqueId
                                                            }
                                                            qrcodePromise={connvertBase64(
                                                              item.QRCodeURL
                                                            )}
                                                          />
                                                        </div>
                                                        <div className="modal-footer text-right">
                                                          <QRCodePdfDownloader
                                                            downloadFileName={
                                                              "QRCode_" +
                                                              item.EmailId
                                                            }
                                                            rootElementId={
                                                              "divToDownloadQRCode-" +
                                                              item.ID
                                                            }
                                                          />
                                                          <button
                                                            type="button"
                                                            className="btn btn-default btn-sm"
                                                            data-dismiss="modal"
                                                            onClick={() =>
                                                              handlecloseQrdownloadpopup()
                                                            }
                                                          >
                                                            Close
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Modal>

                                                <Link
                                                  to={"/userhistory/" + item.ID}
                                                  className="btn btn-secondary btn-sm mb-2 mr-2 "
                                                  title="History"
                                                >
                                                  <i className="fas fa-history"></i>
                                                </Link>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {item.UserTypeId === 2 ||
                                            item.UserTypeId === 3 ||
                                            item.UserTypeId === 4 ? (
                                              <button
                                                type="button"
                                                title="View Questions/Options"
                                                className="btn btn-warning btn-sm mb-2 mr-2 "
                                                onClick={() =>
                                                  handleViewQuestionModelpopup(
                                                    item.ID
                                                  )
                                                }
                                              >
                                                <i className="fas fa-eye" />
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                            <Modal
                                              show={
                                                questionsatete.visible ===
                                                item.ID
                                              }
                                            >
                                              <div
                                                className="modal fade show"
                                                style={{
                                                  display: "block",
                                                  paddingRight: "17px",
                                                }}
                                                aria-modal="true"
                                                role="dialog"
                                              >
                                                <div className="modal-dialog modal-lg">
                                                  <div className="modal-content">
                                                    <div className="modal-header">
                                                      <h4 className="modal-title">
                                                        User Questions/Options
                                                      </h4>
                                                      <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={() =>
                                                          handlecloseViewQuestionpopup()
                                                        }
                                                      >
                                                        <span aria-hidden="true">
                                                          ×
                                                        </span>
                                                      </button>
                                                    </div>
                                                    <div className="modal-body">
                                                      {questionList.map(
                                                        (q, index) => (
                                                          <div className="callout callout-info">
                                                            <h5>
                                                              {index + 1}.{" "}
                                                              {q.Question}
                                                            </h5>
                                                            {q.OtherOption ? (
                                                              <>
                                                                <p className="mb-0">
                                                                  <i className="fas fa-check"></i>{" "}
                                                                  {
                                                                    q.OtherOption
                                                                  }
                                                                </p>
                                                              </>
                                                            ) : (
                                                              <>
                                                                {q.QuestionOptions.split(
                                                                  ","
                                                                ).map(
                                                                  (_option) => (
                                                                    <p className="mb-0">
                                                                      <i className="fas fa-check"></i>{" "}
                                                                      {_option}
                                                                    </p>
                                                                  )
                                                                )}
                                                              </>
                                                            )}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                    <div className="modal-footer text-right">
                                                      <button
                                                        type="button"
                                                        className="btn btn-default"
                                                        data-dismiss="modal"
                                                        onClick={() =>
                                                          handlecloseViewQuestionpopup()
                                                        }
                                                      >
                                                        Close
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Modal>

                                            <button
                                              type="button"
                                              title={
                                                !item.IsBlock
                                                  ? "Block"
                                                  : "Unblock"
                                              }
                                              className={
                                                !item.IsBlock
                                                  ? "btn btn-success btn-sm  mb-2 mr-2 "
                                                  : "btn btn-danger btn-sm  mb-2 mr-2 "
                                              }
                                              onClick={() =>
                                                handleBlockModelpopup(item.ID)
                                              }
                                            >
                                              <i
                                                className={
                                                  !item.IsBlock
                                                    ? "fas fa-lock-open"
                                                    : "fas fa-lock"
                                                }
                                              />
                                            </button>
                                            <Modal
                                              show={
                                                blocksatete.visible === item.ID
                                              }
                                            >
                                              <div
                                                className="modal fade show"
                                                style={{
                                                  display: "block",
                                                  paddingRight: "17px",
                                                }}
                                                aria-modal="true"
                                                role="dialog"
                                              >
                                                <div className="modal-dialog modal-md">
                                                  <div className="modal-content">
                                                    <div className="modal-header">
                                                      <h4 className="modal-title">
                                                        Confirmation
                                                      </h4>
                                                      <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={() =>
                                                          handlecloseblockpopup()
                                                        }
                                                      >
                                                        <span aria-hidden="true">
                                                          ×
                                                        </span>
                                                      </button>
                                                    </div>

                                                    <div className="modal-body">
                                                      <p>
                                                        Are you sure you want to
                                                        change user status?
                                                      </p>
                                                    </div>
                                                    <div className="modal-footer text-right">
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() =>
                                                          handleBlockUser()
                                                        }
                                                      >
                                                        Confirm
                                                      </button>
                                                      <button
                                                        type="button"
                                                        className="btn btn-default"
                                                        data-dismiss="modal"
                                                        onClick={() =>
                                                          handlecloseblockpopup()
                                                        }
                                                      >
                                                        Cancel
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Modal>

                                            {item.UserTypeId === 3 ? (
                                              <>
                                                <button
                                                  type="button"
                                                  title={
                                                    item.IsStationery
                                                      ? "Remove Stationery"
                                                      : "Allow Stationery"
                                                  }
                                                  className={
                                                    item.IsStationery
                                                      ? "btn btn-success btn-sm mb-2 mr-2  "
                                                      : "btn btn-danger btn-sm mb-2 mr-2 "
                                                  }
                                                  onClick={() =>
                                                    handleStationeryModelpopup(
                                                      item.ID
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className={
                                                      item.IsStationery
                                                        ? "fas fa-user"
                                                        : "fas fa-user"
                                                    }
                                                  />
                                                </button>
                                                <Modal
                                                  show={
                                                    stationerysatete.visible ===
                                                    item.ID
                                                  }
                                                >
                                                  <div
                                                    className="modal fade show"
                                                    style={{
                                                      display: "block",
                                                      paddingRight: "17px",
                                                    }}
                                                    aria-modal="true"
                                                    role="dialog"
                                                  >
                                                    <div className="modal-dialog modal-md">
                                                      <div className="modal-content">
                                                        <div className="modal-header">
                                                          <h4 className="modal-title">
                                                            Confirmation
                                                          </h4>
                                                          <button
                                                            type="button"
                                                            className="close"
                                                            data-dismiss="modal"
                                                            aria-label="Close"
                                                            onClick={() =>
                                                              handleclosestationerypopup()
                                                            }
                                                          >
                                                            <span aria-hidden="true">
                                                              ×
                                                            </span>
                                                          </button>
                                                        </div>

                                                        <div className="modal-body">
                                                          <p>
                                                            Are you sure you
                                                            want to change user
                                                            status for
                                                            stationery?
                                                          </p>
                                                        </div>
                                                        <div className="modal-footer text-right">
                                                          <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() =>
                                                              handleStationeryUser()
                                                            }
                                                          >
                                                            Confirm
                                                          </button>
                                                          <button
                                                            type="button"
                                                            className="btn btn-default"
                                                            data-dismiss="modal"
                                                            onClick={() =>
                                                              handleclosestationerypopup()
                                                            }
                                                          >
                                                            Cancel
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Modal>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td
                                            className="dtr-control sorting_1"
                                            tabIndex="0"
                                          >
                                            {item.ID}
                                          </td>
                                          <td>
                                            {item.ProfileImage !== "" &&
                                            item.ProfileImage !== undefined ? (
                                              <>
                                                <a
                                                  href={item.ProfileImage}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <img
                                                    src={item.ProfileImage}
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      borderRadius: "10px",
                                                    }}
                                                  />
                                                </a>
                                              </>
                                            ) : (
                                              <a
                                                href={"/dist/img/numella.png"}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <img
                                                  src="/dist/img/numella.png"
                                                  style={{
                                                    width: "50px",
                                                    height: "50px",
                                                  }}
                                                />
                                              </a>
                                            )}
                                          </td>
                                          <td>{item.FullName}</td>
                                          <td>{item.EmailId}</td>
                                          <td>
                                            {item.UserType === "Advertiser"
                                              ? "Business"
                                              : item.UserType}
                                            <>
                                              <span
                                                className={
                                                  "right badge badge-primary"
                                                }
                                              >
                                                {item.DriverType}
                                              </span>
                                            </>
                                          </td>
                                          <td>{item.CountryName}</td>
                                          <td>{item.MobileNo}</td>
                                          <td>{item.ReferralCode}</td>
                                          <td>
                                            <span
                                              className={
                                                item.IsActive
                                                  ? "right badge badge-success"
                                                  : "right badge badge-danger"
                                              }
                                            >
                                              {item.IsActive ? "Yes" : "No"}
                                            </span>
                                          </td>
                                          {/* <td><span className={item.IsblueTickEnable ? 'right badge badge-success' : 'right badge badge-danger'}>{item.UserType === "Driver" ? "" : (item.IsblueTickEnable ? "Yes" : "No")}</span></td> */}
                                          <td>
                                            <span
                                              className={
                                                item.IsblueTickEnable
                                                  ? "right badge badge-success"
                                                  : "right badge badge-danger"
                                              }
                                            >
                                              {item.IsblueTickEnable
                                                ? "Yes"
                                                : "No"}
                                            </span>
                                          </td>
                                          <td>
                                            <span
                                              className={
                                                item.IsMasterCampaign
                                                  ? "right badge badge-success"
                                                  : "right badge badge-danger"
                                              }
                                            >
                                              {item.IsMasterCampaign
                                                ? "Yes"
                                                : "No"}
                                            </span>
                                          </td>
                                          <td>
                                            {item.UserType === "Driver" ? (
                                              ""
                                            ) : (
                                              <>
                                                <div>
                                                  <span>
                                                    {" "}
                                                    Profile View:
                                                    {item.ProfileViewCount}
                                                  </span>
                                                  <br></br>
                                                  <span>
                                                    {" "}
                                                    Social Media:{" "}
                                                    {item.SocialMediaCount}
                                                  </span>
                                                  <br></br>
                                                  <span>
                                                    {" "}
                                                    Billborad:{" "}
                                                    {
                                                      item.BillboradImpressionCount
                                                    }
                                                  </span>
                                                  <br></br>
                                                  <span>
                                                    {" "}
                                                    Call to Action:{" "}
                                                    {item.CallToActionCount}
                                                  </span>
                                                </div>
                                              </>
                                            )}
                                          </td>
                                          <td>
                                            {formatDate(item.CreatedDate)}
                                          </td>
                                          <td>
                                            <span
                                              className={
                                                item.IsEmailVerified
                                                  ? "right badge badge-success"
                                                  : "right badge badge-danger"
                                              }
                                            >
                                              {item.IsEmailVerified
                                                ? "Yes"
                                                : "No"}
                                            </span>
                                          </td>
                                          <td>
                                            {" "}
                                            {item.UserType === "Driver" ? (
                                              ""
                                            ) : (
                                              <>
                                                {item.Status == 1 ? (
                                                  <span className="right badge badge-success">
                                                    Verified
                                                  </span>
                                                ) : item.Status === 2 ? (
                                                  <>
                                                    <button
                                                      type="button"
                                                      title="Approve"
                                                      className="btn btn-success btn-sm mr-2 mb-2"
                                                      style={{ width: "30px" }}
                                                      onClick={() =>
                                                        handleUserStatusModelpopup(
                                                          item.ID,
                                                          "Approved"
                                                        )
                                                      }
                                                    >
                                                      <i className="fas fa-check" />
                                                    </button>
                                                    <button
                                                      type="button"
                                                      title="Reject"
                                                      className="btn btn-danger btn-sm mr-2 mb-2"
                                                      style={{ width: "30px" }}
                                                      onClick={() =>
                                                        handleUserStatusModelpopup(
                                                          item.ID,
                                                          "Reject"
                                                        )
                                                      }
                                                    >
                                                      <strong>X</strong>
                                                    </button>
                                                    <span className="right badge badge-danger">
                                                      Rejected
                                                    </span>
                                                    <br></br>
                                                    <small>
                                                      Reason: {item.Reason}
                                                    </small>
                                                  </>
                                                ) : (
                                                  <>
                                                    <button
                                                      type="button"
                                                      title="Approve"
                                                      className="btn btn-success btn-sm mr-2 mb-2"
                                                      style={{ width: "30px" }}
                                                      onClick={() =>
                                                        handleUserStatusModelpopup(
                                                          item.ID,
                                                          "Approved"
                                                        )
                                                      }
                                                    >
                                                      <i className="fas fa-check" />
                                                    </button>
                                                    <button
                                                      type="button"
                                                      title="Reject"
                                                      className="btn btn-danger btn-sm mr-2 mb-2"
                                                      style={{ width: "30px" }}
                                                      onClick={() =>
                                                        handleUserStatusModelpopup(
                                                          item.ID,
                                                          "Reject"
                                                        )
                                                      }
                                                    >
                                                      <strong>X</strong>
                                                    </button>
                                                  </>
                                                )}
                                                <Modal
                                                  show={
                                                    statee.visible === item.ID
                                                  }
                                                >
                                                  <div
                                                    className="modal fade show"
                                                    style={{
                                                      display: "block",
                                                      paddingRight: "17px",
                                                    }}
                                                    aria-modal="true"
                                                    role="dialog"
                                                  >
                                                    <div className="modal-dialog modal-md">
                                                      <div className="modal-content">
                                                        <div className="modal-header">
                                                          <h4 className="modal-title">
                                                            Confirmation
                                                          </h4>
                                                          <button
                                                            type="button"
                                                            className="close"
                                                            data-dismiss="modal"
                                                            aria-label="Close"
                                                            onClick={() =>
                                                              handlecloseUserStatuspopup()
                                                            }
                                                          >
                                                            <span aria-hidden="true">
                                                              ×
                                                            </span>
                                                          </button>
                                                        </div>

                                                        <div className="modal-body">
                                                          <p>
                                                            Are you sure you
                                                            want to{" "}
                                                            <strong>
                                                              {userstatus}
                                                            </strong>{" "}
                                                            this user?
                                                          </p>

                                                          <div
                                                            className={
                                                              reasonstatee
                                                                ? "form-group"
                                                                : "form-group d-none"
                                                            }
                                                          >
                                                            <label>
                                                              Reject Reason
                                                            </label>
                                                            <textarea
                                                              className="form-control"
                                                              id="Reason"
                                                              name="Reason"
                                                              onChange={
                                                                handleChangeReason
                                                              }
                                                            ></textarea>
                                                            <span
                                                              className={
                                                                validreason
                                                                  ? "text-danger d-none"
                                                                  : "text-danger"
                                                              }
                                                            >
                                                              Reason is required
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="modal-footer text-right">
                                                          <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() =>
                                                              handleDocuemntStatus()
                                                            }
                                                          >
                                                            Yes
                                                          </button>
                                                          <button
                                                            type="button"
                                                            className="btn btn-default"
                                                            data-dismiss="modal"
                                                            onClick={() =>
                                                              handlecloseUserStatuspopup()
                                                            }
                                                          >
                                                            Cancel
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Modal>
                                              </>
                                            )}
                                          </td>
                                          <td>{item.ShippingAddress}</td>
                                          <td>{item.DeviceType}</td>
                                          <td>{item.RecordLabel}</td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={16} align="center">
                                          Data not found!
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="col-sm-12 col-md-5"
                              key={remountComponent}
                            >
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={items.length > 0 ? pageCount : 0}
                                onPageChange={changePage}
                                containerClassName={"pagination"}
                                pageClassName={"page-link"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div>
                                            <div>
                                            <div id={"divToDownload"}>
                                                <h1>Testing Div Content</h1>
                                            </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
};

export default UserList;
