import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../Layout/sidebar/Sidebar';
import Footer from '../../Layout/footer/Footer';
import { ToastContainer } from 'react-toastify';
import { toastError, toastSuccess } from '../../common/Toast';
import config from '../../config/config';
import 'bootstrap/dist/css/bootstrap.min.css';

import CustomLoader from "../../Layout/CustomLoader";

const ManageImpressionDetails = (props) => {

    const paramObject = useParams();
    let navigate = useNavigate();
    const [typestate, settypestate] = useState([]);
    const [formValues, setFormValues] = useState({ Id: 0, CountryCode: '', ToolkitId: 0, ImpressionCount: 0, IsActive: true });
    const [countryitems, setCountryItems] = useState([]);
    const [toolkitItems, setToolkitItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        CountryCode: Yup.string().required("Code is required"),
        ToolkitId: Yup.string().required("Toolkit Type is required"),
        ImpressionCount: Yup.number().required("Count is required")
    });

    let createdby = localStorage.getItem('ID');

    const handleSubmit = (values) => {
        setLoading(true);

        axios.post(`${config.baseUrl}/mastercampaign/mastercampaignupsert`, {
            id: values.Id,
            countrycode: values.CountryCode,
            toolkitid: values.ToolkitId,
            impressioncount: values.ImpressionCount,
            perdaydollor: 0,
            IsActive: values.IsActive,
            CreatedBy: createdby
        }, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((res) => {
                if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
                    toastSuccess('Record has been added!');
                    navigate("/mastercampaign-list");
                }
                else {
                    toastError("Somthing went wrong");
                }

                setLoading(false);
            }).catch((error) => {
                console.log("error occured", error);

                setLoading(false);
            });
    }

    // const handleChangeToolkitType = (e) => {
    //     console.log(e.target.value);
    //     settypestate(e.target.value);
    //     BindToolkitdropdwonApi(e.target.value);
    // }


    const BindCountryApi = () => {
        setLoading(true);

        // axios.get(`${config.baseUrl}/country`)
        axios.get(`${config.baseUrl}/campaincountry/fordropdown`)
            .then((response) => {
                setCountryItems(response.data.ResponseData);

                setLoading(false);
            }).catch((err) => {
                console.log(err)
                //setError(err);

                setLoading(false);
            })
    }

    const BindToolkitdropdwonApi = () => {
        setLoading(true);

        // axios.get(`${config.baseUrl}/country`)
        axios.get(`${config.baseUrl}/campaintoolkit/`)
            .then((response) => {
                setToolkitItems(response.data.ResponseData);

                setLoading(false);
            }).catch((err) => {
                console.log(err)
                //setError(err);

                setLoading(false);
            })
    }

    const EditMasterCampaignDetails = () => {
        if (paramObject.id > 0) {
            setLoading(true);

            axios.get(`${config.baseUrl}/mastercampaign/findbyid?id=${paramObject.id}`)
                .then((response) => {
                    const responseData = response.data.ResponseData[0];
                    const { Id, CountryCode, ToolkitId, ImpressionCount } = responseData;
                    const IsActive = responseData.IsActive === 1 ? true : false;
                    setFormValues({ Id, CountryCode, ToolkitId, ImpressionCount, IsActive });

                    setLoading(false);
                }).catch((err) => {
                    console.log(err);

                    setLoading(false);
                })
        }
    }


    useEffect(() => {
        BindCountryApi();
        BindToolkitdropdwonApi();
        EditMasterCampaignDetails();
    }, []);

    return (
        <Fragment>
            <Sidebar />
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Master Campaign Details</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">Master Campaign Details</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header text-right" >
                                        <h3 className="card-title">Manage</h3>
                                    </div>
                                    <div className="card-body">
                                        {/* <ManageForm
                                        initialValues={formValues}
                                        onSubmit={handleSubmit}
                                        enableReinitialize
                                        >
                                        Update
                                    </ManageForm> */}

                                        <Formik  {...props}
                                            validationSchema={validationSchema}
                                            initialValues={formValues}
                                            onSubmit={handleSubmit}
                                            enableReinitialize
                                        >
                                            {({
                                                errors,
                                                touched,
                                                handleChange
                                            }) => (
                                                <Form>
                                                    <div className="dataTables_wrapper dt-bootstrap4" >
                                                        <Field type="hidden" className="form-control" id="Id" name="Id" />

                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="CountryCode"> Country Code</label>
                                                                    <Field as="select" className="form-control" id="CountryCode" name="CountryCode" onChange={handleChange}>
                                                                        <option value=""> -- Select Country -- </option>
                                                                        {countryitems.map((country) => <option value={country.ShortCode}>{country.Name}</option>)}
                                                                    </Field>
                                                                    <span className="text-danger">
                                                                        {touched.CountryCode && errors.CountryCode ? errors.CountryCode : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Toolkits">Toolkit</label>
                                                                    <Field as="select" className="form-control" id="ToolkitId" name="ToolkitId" onChange={handleChange}>
                                                                        <option value=""> -- Toolkit -- </option>
                                                                        {toolkitItems.map((Toolkits) => <option value={Toolkits.Id}>{Toolkits.ToolKitName}</option>)}
                                                                    </Field>
                                                                    <span className="text-danger">
                                                                        {touched.ToolkitId && errors.ToolkitId ? errors.ToolkitId : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Impression Count</label>
                                                                    <Field type="number" className="form-control" id="ImpressionCount" name="ImpressionCount" onChange={handleChange} />
                                                                    <span className="text-danger">
                                                                        {touched.ImpressionCount && errors.ImpressionCount ? errors.ImpressionCount : ''}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <Field type="checkbox" className="form-check-input" id="IsActive" name='IsActive' />
                                                                        <label className="form-check-label" htmlFor="IsActive">Active</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row text-right'>
                                                            <div className='col-md-12 me-2'>
                                                                <button type="submit" className="btn me-2 btn-primary" >Save</button>
                                                                <Link to="/mastercampaign-list" className="btn btn-secondary">Cancel</Link>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />

        </Fragment>
    )
}

export default ManageImpressionDetails