import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, Field } from "formik";
import { Link, useParams } from 'react-router-dom';
import Footer from '../../Layout/footer/Footer';
import Sidebar from '../../Layout/sidebar/Sidebar';

import axios from 'axios';
import ReactPaginate from "react-paginate";
import { ToastContainer } from 'react-toastify';
import { toastError } from '../../common/Toast';
import config from '../../config/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomLoader from "../../Layout/CustomLoader";

const FanFunnelDatatableLists = (props) => {

    // const [statee, setSatete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [fanfunnelItems, setFanfunnelItems] = useState([]);
    const [perPageSize, setperPageSize] = useState(10);
    const [searchtext, setSearchText] = useState("");
    const [totalrecord, setTotalRecord] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [formValues, setFormValues] = useState({ userid: '' });
    const [dropdownitems, setDropDownItems] = useState([]);
    const [remountComponent, setRemountComponent] = useState(0);
    const [useriddropdown, setUserIdDropdown] = useState(0);

    const handleUserDropdownApi = () => {
        if (!loading === true) {
            setLoading(true);
            axios.get(`${config.baseUrl}/users/getallartistlist`)
                .then((response) => {
                    setDropDownItems(response.data.ResponseData);
                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                    setError(err);
                    setLoading(false);
                })
        }
        else {
            toastError("Somthing went wrong!");
        }
    }

    const handleApi = (pagenumber, pagesize, filtertext, ordercolumn, currentorder, funnletype, userid) => {
       // if (!loading === true) {
            setLoading(true);
            axios.get(`${config.baseUrl}/fanfunnle?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&funnletype=${funnletype}&userid=${userid}`)
                .then((response) => {
                    setFanfunnelItems(response.data.ResponseData);
                    setTotalRecord(response.data.ResponseData[0].TotalRecord);
                    setpageCount(Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize));
                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                    setError(err);
                    setLoading(false);
                })
        // }
        // else {
        //     toastError("Somthing went wrong!");
        // }
    }

    useEffect(() => {
        handleUserDropdownApi();
        handleApi(1, perPageSize, "", 0, "asc", '', '');
    }, [])

    const changePage = ({ selected }) => {
        selected = selected + 1;
        handleApi(selected, perPageSize, searchtext, 0, "asc", '', '');
    };

    const ChangeFilterText = (e) => {
        setSearchText(e.target.value);
        setRemountComponent(Math.random());
        handleApi(1, perPageSize, e.target.value, 0, "asc", '', '');
    }

    const ChangePageSize = (e) => {
        setperPageSize(e.target.value);
        handleApi(1, e.target.value, searchtext, 0, "asc", '', '');
    }


    const formatDate = (string) => {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([], options);
    }

    const ChangeFanType = (e) => {
        setRemountComponent(Math.random());
        handleApi(1, perPageSize, '', 0, "asc", e.target.value, '');
    }

    const ChangeUser = (e) => {
        setUserIdDropdown(e.target.value)
        setRemountComponent(Math.random());
        handleApi(1, perPageSize, '', 0, "asc", '', e.target.value);
        
    }

    return (
        <Fragment>
            <Sidebar />
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Fan Funnel List</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">Fan Funnel</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header text-right" >
                                        <h3 className="card-title">Fan Funnel List</h3>
                                    </div>

                                    <div className="card-body">
                                        <Formik  {...props}
                                            initialValues={formValues}
                                            enableReinitialize>
                                            {({
                                                handleChange,
                                                errors,
                                                touched
                                            }) => (
                                                <Form>
                                                    <div className="row">
                                                        <div className="col-sm-3 col-md-3   ">
                                                            <div className='form-group'>
                                                                <label className='form-label col-sm-6'>Fan Type</label>
                                                                <div className='col-sm-10'>
                                                                    <Field as="select" id="funnletype" name="funnletype" className='form-control custom-select2-dropdown' onChange={(e) => ChangeFanType(e)}>
                                                                        <option value="">All</option>
                                                                        <option value="AWARENESS">AWARENESS</option>
                                                                        <option value="INTERESTED">INTERESTED</option>
                                                                        <option value="ENGAGED">ENGAGED</option>
                                                                        <option value="BRANDIDENTIFIED">BRAND IDENTIFIED</option>
                                                                        <option value="FAN">FAN</option>
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 col-md-3">
                                                            <div className="form-group">
                                                                <label>User</label>
                                                                <Field as="select" className="form-control select2bs4 custom-select2-dropdown" name="userid" id="userid" value={useriddropdown} onInput={(e) => ChangeUser(e)} onChange={handleChange} >
                                                                    <option value="">All</option>
                                                                    {dropdownitems.map((u) => <option value={u.ID}>{u.FullName}</option>)}
                                                                </Field>
                                                                <span className="text-danger">
                                                                    {touched.userid && errors.userid ? errors.userid : ''}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                        <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4 table-responsive">
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6">
                                                    <select onChange={ChangePageSize}>
                                                        <option value="10">10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </div>
                                                <div className="col-sm-6 col-md-6 text-right">
                                                    <div id="example1_filter" className="dataTables_filter">
                                                        <label><input type="search" className="form-control form-control-sm" placeholder="" aria-controls="example1" onChange={ChangeFilterText} /></label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12">

                                                    <table id="example1" className="table table-bordered dataTable dtr-inline" aria-describedby="example1_info">
                                                        <thead>
                                                            <tr>
                                                                <th className="sorting sorting_asc" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="ID: activate to sort column descending">ID</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Name: activate to sort column ascending">Name</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="ArtistName: activate to sort column ascending">Artist Name</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="ProfileLink: activate to sort column ascending">Profile Link</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="AgeGroup: activate to sort column ascending">Age Group</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Country: activate to sort column ascending">Country</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="State: activate to sort column ascending">State</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Ethincity: activate to sort column ascending">Ethincity</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="SocialPlatform: activate to sort column ascending">Social Platform</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Gender: activate to sort column ascending">Gender</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="FunnleType: activate to sort column ascending">FunnleType</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Support Artist Live Show: activate to sort column ascending">Support Artist Live Show</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Is Active: activate to sort column ascending">Is Active</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="CreatedOn: activate to sort column ascending">Created On</th>
                                                                {/* <th tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Action">Action</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {fanfunnelItems.length > 0 ? fanfunnelItems.map((fan) => (
                                                                <tr className="odd">
                                                                    <td className="dtr-control sorting_1" tabIndex="0">{fan.ID}</td>
                                                                    <td>{fan.Name}</td>
                                                                    <td>{fan.ArtistName}</td>
                                                                    <td>{fan.ProfileLink}</td>
                                                                    <td>{fan.AgeGroup}</td>
                                                                    <td>{fan.Country}</td>
                                                                    <td>{fan.State}</td>
                                                                    <td>{fan.Ethnicity}</td>
                                                                    <td>{fan.SocialPlatfrom}</td>
                                                                    <td>{fan.Gender}</td>
                                                                    <td>{fan.FunnleType === "BRANDIDENTIFIED" ? "BRAND IDENTIFIED" :  fan.FunnleType}</td>
                                                                    <td>{fan.SupportArtistLiveShow}</td>
                                                                    <td><span className={fan.IsActive ? 'right badge badge-success' : 'right badge badge-danger'}>{fan.IsActive ? "Yes" : "No"}</span></td>
                                                                    <td>{formatDate(fan.CreatedDate)}</td>
                                                                    {/* <td>
                                                                        <Link to={"#" + item.Id} className="btn btn-primary me-2">
                                                                            Edit
                                                                        </Link>

                                                                        <button type="button" className="btn btn-danger btn-xs" onClick={() => handleModelpopup(item.Id)}>Delete</button>

                                                                        <Modal show={statee} onHide={() => setSatete(!statee)} >
                                                                            <div className="modal fade show" style={{ display: "block", paddingRight: "17px" }} aria-modal="true" role="dialog">
                                                                                <div className="modal-dialog modal-md">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header">
                                                                                            <h4 className="modal-title">Confirmation</h4>
                                                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => handleclosepopup()}>
                                                                                                <span aria-hidden="true">×</span>
                                                                                            </button>
                                                                                        </div>

                                                                                        <div className="modal-body">
                                                                                            <p>Are you sure you want to remove this item.</p>
                                                                                        </div>
                                                                                        <div className="modal-footer text-right">
                                                                                            <button type="button" className="btn btn-primary" onClick={() => handleDelete()}>Confirm</button>
                                                                                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => handleclosepopup()}>Cancel</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Modal>
                                                                    </td> */}
                                                                </tr>
                                                            )) : <tr>
                                                                <td colSpan={12} align='center'>Data not found!</td>
                                                            </tr>}

                                                        </tbody>

                                                    </table>

                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-5' key={remountComponent}>
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={fanfunnelItems.length > 0 ? pageCount : 0}
                                                        onPageChange={changePage}
                                                        containerClassName={"pagination"}
                                                        pageClassName={"page-link"}
                                                        previousLinkClassName={"page-link"}
                                                        nextLinkClassName={"page-link"}
                                                        activeClassName={"active"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- /.card-body --> */}
                                </div>
                                {/* <!-- /.card --> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </Fragment>
    )
}

export default FanFunnelDatatableLists