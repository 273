import React, { Fragment, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../Layout/sidebar/Sidebar";
import Footer from "../../Layout/footer/Footer";
import { Modal } from "react-bootstrap";
import axios from "axios";
import config from "../../config/config";
import { ToastContainer } from "react-toastify";
import { toastError, toastSuccess } from "../../common/Toast";
import CustomLoader from "../../Layout/CustomLoader";
import Select from "react-select";

const UserDocumentList = (props) => {
  const paramObject = useParams();
  const [formValues, setFormValues] = useState({ UserID: "" });
  const [statee, setSatete] = useState({ visible: null });
  const [reasonstatee, setreasonSatete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [dropdownitems, setDropDownItems] = useState([]);
  const [documentstatus, setdocumentstatus] = useState("");
  const [reason, setReason] = useState("");
  const [validreason, setValidReason] = useState(true);

  const handleApi = (userid, fromdate, todate) => {
    if (!loading === true) {
      setLoading(true);
      axios
        .get(
          `${config.baseUrl}/users/userdocumentlist?userid=${userid}&fromdate=${fromdate}&todate=${todate}`
        )
        .then((response) => {
          setItems(response.data.ResponseData);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const handleUserDropdownApi = () => {
    if (!loading === true) {
      setLoading(true);
      axios
        .get(`${config.baseUrl}/users/fordropdown`)
        .then((response) => {
          let dropdownItemOptions = [];
          response.data.ResponseData.map((option) => {
            return (dropdownItemOptions = [
              ...dropdownItemOptions,
              { label: option.FullName, value: option.ID },
            ]);
          });
          setDropDownItems(dropdownItemOptions);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const [selectedUserValue, setSelectedUserValue] = useState(0);
  useEffect(() => {
    handleUserDropdownApi();

    if (paramObject.id > 0) {
      let UserID = paramObject.id;
      setFormValues({ UserID });
      handleApi(UserID, "", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlecloseDocuemntStatuspopup = () => {
    setSatete({ visible: null });
    setreasonSatete(false);
    localStorage.setItem("UserDocumentID", 0);
    localStorage.setItem("UserDocumentStatus", "");
  };

  const handleDocuemntStatusModelpopup = (id, documentStatus) => {
    localStorage.setItem("UserDocumentID", id);
    localStorage.setItem("UserDocumentStatus", documentStatus);

    setdocumentstatus(documentStatus);
    if (documentStatus == "Reject") {
      setreasonSatete(true);
    }
    setSatete({ visible: id });
  };

  const handleDocuemntStatus = () => {
    let loginuserID = localStorage.getItem("ID");
    let id = localStorage.getItem("UserDocumentID");
    let userDocumentStatus = localStorage.getItem("UserDocumentStatus");
    let statusid = 1;

    if (userDocumentStatus == "Reject") {
      if (document.getElementById("Reason").value == "") {
        setValidReason(false);
        return false;
      }
      statusid = 2;
    }

    setSatete({ visible: null });
    if (id > 0) {
      setLoading(true);
      axios
        .post(
          `${config.baseUrl}/users/documentapprovereject`,
          {
            id: id,
            statusid: statusid,
            reason: reason,
            updateduserid: loginuserID,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.data.ResponseCode === "SUCCESS") {
            toastSuccess("Record has been updated!");
            handleApi(paramObject.id, "", "");
          } else {
            toastError("Enable to updated a record");
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleChangeReason = (e) => {
    if (e.target.value !== "") {
      setValidReason(true);
    } else {
      setValidReason(false);
    }
    setReason(e.target.value);
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      height: "40px",
      position: "relative",
      background: "#343a40",
      color: "white",
    }), // dropdown input css
    menu: () => ({
      boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
      position: "absolute",
      background: "#343a40",
      width: "100%",
      color: "white",
      zIndex: 99,
    }), // dropdown menu css
  };

  const handleSelectChange = (e) => {
    handleApi(e.value, "", "");
  };

  return (
    <Fragment>
      <Sidebar />
      <ToastContainer />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>User Document</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">User Document</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">User Document</h3>
            </div>
            <div className="card-body">
              <Formik {...props} initialValues={formValues} enableReinitialize>
                {({ errors, touched }) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>User</label>

                          <Select
                            backspaceRemovesValue={false}
                            controlShouldRenderValue={true}
                            hideSelectedOptions={true}
                            isClearable={true}
                            onChange={(e) => {
                              setSelectedUserValue(e);
                              handleSelectChange(e);
                            }}
                            options={dropdownitems}
                            placeholder="Please select a User"
                            styles={selectStyles}
                            value={selectedUserValue}
                            id="UserID"
                            name="UserID"
                          />
                          <span className="text-danger">
                            {touched.UserID && errors.UserID
                              ? errors.UserID
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="table-responsive">
                <div className="row">
                  <div className="col-sm-12">
                    <table
                      id="example1"
                      className="table table-bordered dataTable dtr-inline"
                      aria-describedby="example1_info"
                    >
                      <thead>
                        <tr>
                          <th
                            className="sorting sorting_asc"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-sort="ascending"
                            aria-label="ID: activate to sort column descending"
                          >
                            ID
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Name: activate to sort column ascending"
                          >
                            Name
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Profile: activate to sort column ascending"
                          >
                            Profile
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="DocumentType: activate to sort column ascending"
                          >
                            Document Type
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="DocumentNo: activate to sort column ascending"
                          >
                            Document No
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="UploadedFile: activate to sort column ascending"
                          >
                            Uploaded File
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Status: activate to sort column ascending"
                          >
                            Status
                          </th>
                          {/* <th tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Action">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {items.length > 0 ? (
                          items.map((item) => (
                            <tr className="odd">
                              <td
                                className="dtr-control sorting_1"
                                tabIndex="0"
                              >
                                {item.Id}
                              </td>
                              <td>{item.FullName}</td>
                              <td>
                                {item.Profile != "" &&
                                item.Profile != undefined ? (
                                  <>
                                    <a href={item.Profile} target="_blank">
                                      <img
                                        src={item.Profile}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    </a>
                                  </>
                                ) : (
                                  <a
                                    href={"/dist/img/numella.png"}
                                    target="_blank"
                                  >
                                    <img
                                      src="/dist/img/numella.png"
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  </a>
                                )}
                              </td>
                              <td>{item.DocumentType}</td>
                              <td>{item.DocumentNo}</td>
                              <td>
                                <a href={item.FileName} target="_blank">
                                  <img
                                    src={item.FileName}
                                    height={50}
                                    width={50}
                                  ></img>
                                </a>
                              </td>
                              <td>
                                {item.Status == 1 ? (
                                  <span className="right badge badge-success">
                                    Verified
                                  </span>
                                ) : item.Status == 2 ? (
                                  <>
                                    <span className="right badge badge-danger">
                                      Rejected
                                    </span>{" "}
                                    <br></br>{" "}
                                    <small>Reason: {item.Reason}</small>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      title="Approve"
                                      className="btn btn-success btn-sm mr-2 mb-2"
                                      style={{ width: "30px" }}
                                      onClick={() =>
                                        handleDocuemntStatusModelpopup(
                                          item.Id,
                                          "Approved"
                                        )
                                      }
                                    >
                                      <i className="fas fa-check" />
                                    </button>
                                    <button
                                      type="button"
                                      title="Reject"
                                      className="btn btn-danger btn-sm mr-2 mb-2"
                                      style={{ width: "30px" }}
                                      onClick={() =>
                                        handleDocuemntStatusModelpopup(
                                          item.Id,
                                          "Reject"
                                        )
                                      }
                                    >
                                      <strong>X</strong>
                                    </button>
                                  </>
                                )}

                                <Modal show={statee.visible === item.Id}>
                                  <div
                                    className="modal fade show"
                                    style={{
                                      display: "block",
                                      paddingRight: "17px",
                                    }}
                                    aria-modal="true"
                                    role="dialog"
                                  >
                                    <div className="modal-dialog modal-md">
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h4 className="modal-title">
                                            Confirmation
                                          </h4>
                                          <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() =>
                                              handlecloseDocuemntStatuspopup()
                                            }
                                          >
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>

                                        <div className="modal-body">
                                          <p>
                                            Are you sure you want to{" "}
                                            <strong>{documentstatus}</strong>{" "}
                                            this document?
                                          </p>

                                          <div
                                            className={
                                              reasonstatee
                                                ? "form-group"
                                                : "form-group d-none"
                                            }
                                          >
                                            <label>Reject Reason</label>
                                            <textarea
                                              className="form-control"
                                              id="Reason"
                                              name="Reason"
                                              onChange={handleChangeReason}
                                            ></textarea>
                                            <span
                                              className={
                                                validreason
                                                  ? "text-danger d-none"
                                                  : "text-danger"
                                              }
                                            >
                                              Reason is required
                                            </span>
                                          </div>
                                        </div>
                                        <div className="modal-footer text-right">
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleDocuemntStatus}
                                          >
                                            Yes
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-default"
                                            data-dismiss="modal"
                                            onClick={
                                              handlecloseDocuemntStatuspopup
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Modal>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7} align="center">
                              Data not found!
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
      </div>
      <Footer />
    </Fragment>
  );
};

export default UserDocumentList;
