import React, { Fragment } from 'react'
import AboutUsFooter from '../../Layout/footer/AboutUsFooter'
import AboutUsHeader from '../../Layout/header/AboutUsHeader'

const PaymentPolicy = () => {
    return (
        <Fragment>
            <AboutUsHeader />
            <div className="content-wrapper custom-contain-weapper" >

                <section className='content'>
                    <div className="container-fluid">
                        <h1>Numella Payment Policies</h1>
                        <h2 style={{ paddingTop: "2px" }}>Payment Methods</h2>
                        <p className="custom-p">Numella accepts payment for most campaigns by credit/debit card (VISA or MC), with Paypal. Payments can be made out to "Numella."</p>
                        <h2>Payment terms for Numella apps</h2>
                        <p className="custom-p">If you have a Numella apps that allow customers to purchase subscription plans or pay a regular fee at regular intervals (such as a <strong>monthly recurring bill</strong>), you'll want to consider adding clauses in your legal agreements that discuss the following</p>
                        <ul className="custom-ul-style">
                            <li>Reserving your right to suspend a customer's service or cancel the customer's account, and what will trigger you to do so</li>
                            <li>How often a customer will be billed for the service</li>
                            <li>Reserving your right to change prices of your service at any time</li>
                        </ul>
                        <h2>Credit/Debit Card Payment</h2>
                        <ul className="custom-ul-style">
                            <li><span>Visa and Mastercard are accepted. Payments are processed through our secure online merchant service (Paypal).</span></li>
                        </ul>
                        <h2>Refund Policy</h2>
                        <ul className="custom-ul-style">
                            <li>Numella memberships are non-refundable and non-transferable.</li>
                        </ul>
                        <h2>Contact Numella</h2>
                        <p className="custom-p">2913 E 78th Place Chicago Illinois 60649</p>
                        <p className="custom-p"><span>Please note that the Numella office is only staffed part time, so email is the best way to reach us.</span></p>
                        <p className="custom-p"><span><a href="mailto:contact@numellastream.com">contact@numellastream.com </a></span><span className="ml-3"><a href="tel:+1 (312) 834-1709"> +1 (312) 834-1709</a></span></p>
                    </div>
                </section>
            </div>
            <AboutUsFooter />
        </Fragment>
    )
}

export default PaymentPolicy