import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./../header/Header";
import { useNavigate, useLocation } from "react-router-dom";
// import config from "../../config/config";

//import { authContext } from "../../contexts/AuthContext";

const Sidebar = () => {
  // const loginUserID = localStorage.getItem('ID');
  const loginFullName = localStorage.getItem("FullName");
  //const { setAuthData, auth } = useContext(authContext);
  let navigate = useNavigate();

  function changeLocation(placeToGo) {
    navigate(placeToGo, { replace: true });
    // window.location.reload();
  }
  const [navactive, setNavactive] = useState("");

  const handleActiveMenu = (menu) => {
    // console.log(menu);
    setNavactive(menu);
    //setActiveMenu(menu);
    // useLocation(menu);
  };
  const location = useLocation();

  let activeLink = "";
  if (location.pathname === "/campaign-county-list") {
    activeLink = "/master";
  } else if (location.pathname === "/genre-list") {
    activeLink = "/master";
  } else if (location.pathname.startsWith("/manage-campaign-county")) {
    activeLink = "/master";
  } else if (location.pathname.startsWith("/manage-genre")) {
    activeLink = "/master";
  } else if (location.pathname === "/ethnicity-list") {
    activeLink = "/master";
  } else if (location.pathname.startsWith("/manage-ethnicity")) {
    activeLink = "/master";
  } else if (location.pathname === "/question-list") {
    activeLink = "/master";
  } else if (location.pathname.startsWith("/manage-question")) {
    activeLink = "/master";
  } else if (location.pathname === "/notification-list") {
    activeLink = "/master";
  } else if (location.pathname.startsWith("/manage-notification")) {
    activeLink = "/master";
  } else if (location.pathname === "/emailtemplate-list") {
    activeLink = "/master";
  } else if (location.pathname.startsWith("/manage-emailtemplate")) {
    activeLink = "/master";
  } else if (location.pathname === "/toolkit-list") {
    activeLink = "/Campaign";
  } else if (location.pathname.startsWith("/manage-toolkit")) {
    activeLink = "/Campaign";
  } else if (location.pathname === "/impressiondetails-list") {
    activeLink = "/Campaign";
  } else if (location.pathname.startsWith("/manage-impressiondetails")) {
    activeLink = "/Campaign";
  } else if (location.pathname === "/campaignpackage-list") {
    activeLink = "/Campaign";
  } else if (location.pathname.startsWith("/manage-campaignpackage")) {
    activeLink = "/Campaign";
  } else if (location.pathname === "/mastercampaign-list") {
    activeLink = "/Campaign";
  } else if (location.pathname.startsWith("/mange-mastercampaign")) {
    activeLink = "/Campaign";
  } else if (location.pathname === "/custom-notification-list") {
    activeLink = "/email";
  } else if (location.pathname === "/custom-add/Notification") {
    activeLink = "/email";
  } else if (location.pathname === "/custom-email-list") {
    activeLink = "/email";
  } else if (location.pathname === "/custom-add/Email") {
    activeLink = "/email";
  } else if (location.pathname === "/campaign-all") {
    activeLink = "/campaign";
  } else if (location.pathname.startsWith("/main-campaign-edit")) {
    activeLink = "/campaign";
  } else if (location.pathname.startsWith("/main-campaign-details")) {
    activeLink = "/campaign";
  } else if (location.pathname === "/transaction-history-list") {
    activeLink = "/account";
  } else if (location.pathname === "/transaction-request-list") {
    activeLink = "/account";
  } else if (location.pathname === "/earn-list") {
    activeLink = "/account";
  } else if (location.pathname === "/user-reports") {
    activeLink = "/reports";
  } else if (location.pathname === "/campaign-reports") {
    activeLink = "/reports";
  } else if (location.pathname === "/user-list") {
    activeLink = "/user";
  } else if (location.pathname === "/user-list/2") {
    activeLink = "/user";
  } else if (location.pathname === "/user-list/3") {
    activeLink = "/user";
  } else if (location.pathname === "/user-list/4") {
    activeLink = "/user";
  } else if (location.pathname === "/userdocument") {
    activeLink = "/user";
  } else if (location.pathname === "/balance-list") {
    activeLink = "/user";
  } else if (location.pathname === "/free-impression") {
    activeLink = "/user";
  } else if (location.pathname === "/free-impression/add") {
    activeLink = "/user";
  }

  const isActive = (menu) => {
    return navactive === menu || activeLink === menu;
  };

  function checkUserPermission(permission) {
    let userType = localStorage.getItem("UserType");

    if (userType.toUpperCase() === "SUBADMIN") {
      // Show the Story and Master menus to the SubAdmin user
      if (
        permission === "/story-list" ||
        permission.startsWith("/manage-story")
      ) {
        return true;
      } else {
        return false;
      }
    } else if (userType.toUpperCase() === "ADMIN") {
      return true;
    } else {
      return false;
    }
  }

  // const [activeMenuItem, setActiveMenuItem] = useState(null);
  // const handleNavLinkClick = (e, menuItem) => {
  //   e.preventDefault();
  //   setActiveMenuItem(menuItem);
  // };

  return (
    <Fragment>
      <Header />

      {/* <!-- Main Sidebar Container --> */}
      <div className="main-sidebar sidebar-dark-primary elevation-4">
        {/* <!-- Brand Logo --> */}
        <a href="/" className="brand-link text-decoration-none">
          <img
            src="/dist/img/numella192.png"
            alt="Numella"
            className="brand-image img-circle elevation-3"
            style={{ opacity: "8" }}
          />
          <span className="brand-text font-weight-normal">NUMELLA</span>
        </a>
        {/* <!-- Sidebar --> */}
        <div className="sidebar">
          {/* <!-- Sidebar Menu --> */}
          <div className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column custom-nav-sidebar"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* <!-- Add icons to the links using the .nav-icon class */}
              {/* with font-awesome or any other icon font library --> */}
              {/* Dashboard */}
              {checkUserPermission("/dashboard") ? (
                <li className="nav-item">
                  <Link
                    to="/"
                    className={
                      location.pathname === "/dashboard"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="nav-icon fas fa-home"></i>
                    <p>Dashboard</p>
                  </Link>
                </li>
              ) : null}

              {checkUserPermission("/calendar") ? (
                <li className="nav-item">
                  <Link
                    to="/calendar"
                    className={
                      location.pathname === "/calendar"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-calendar-alt nav-icon"></i>
                    <p>Calendar</p>
                  </Link>
                </li>
              ) : null}

              {checkUserPermission("/master") ? (
                <li className="nav-item">
                  <a
                    // href="#"
                    className={
                      isActive("/master") ? "nav-link active" : "nav-link"
                    }
                  >
                    <i className="nav-icon fas fa-list"></i>
                    <p>
                      {" "}
                      Master<i className="right fas fa-angle-left"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {/* Campaign Country */}
                    <li className="nav-item">
                      <Link
                        to="/campaign-county-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/master")}
                      >
                        <i className="nav-icon fa fa-flag"></i>
                        <p> Campaign Country</p>
                      </Link>
                    </li>
                    {/* Genre */}
                    <li className="nav-item">
                      <Link
                        to="/genre-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/master")}
                      >
                        <i className="nav-icon fa fa-music"></i>
                        <p> Genre</p>
                      </Link>
                    </li>
                    {/* Ethnicity */}
                    <li className="nav-item">
                      <Link
                        to="/ethnicity-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/master")}
                      >
                        <i className="nav-icon fa fa-user-secret"></i>
                        <p> Ethnicity</p>
                      </Link>
                    </li>
                    {/* Question/Option */}
                    <li className="nav-item">
                      <Link
                        to="/question-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/master")}
                      >
                        <i className="nav-icon fas fa-question"></i>
                        <p> Question/Option</p>
                      </Link>
                    </li>
                    {/* Notification */}
                    <li className="nav-item">
                      <Link
                        to="/notification-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/master")}
                      >
                        <i className="nav-icon fa fa-envelope-square"></i>
                        <p> Notification Template</p>
                      </Link>
                    </li>
                    {/* Email Template */}
                    <li className="nav-item">
                      <Link
                        to="/emailtemplate-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/master")}
                      >
                        <i className="nav-icon fa fa-envelope"></i>
                        <p> Email Template</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {/* Story */}
              {checkUserPermission("/story-list") ? (
                <li className="nav-item">
                  <Link
                    to="/story-list"
                    className={
                      location.pathname === "/story-list" ||
                      location.pathname.startsWith("/manage-story") ||
                      location.pathname.startsWith("/story-viewuser")
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="nav-icon fas fa-music"></i>
                    <p> Story</p>
                  </Link>
                </li>
              ) : null}

              {/* CampaignPackage */}
              {checkUserPermission("/Campaign") ? (
                <li className="nav-item">
                  <a
                    // // href="#"
                    className={
                      isActive("/Campaign") ? "nav-link active" : "nav-link"
                    }
                  >
                    <i className="nav-icon fas fa-dollar-sign"></i>
                    <p>
                      Campaign Package
                      <i className="right fas fa-angle-left"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {/* ToolKit */}
                    <li className="nav-item">
                      <Link
                        to="/toolkit-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/Campaign")}
                      >
                        <i className="nav-icon far fa-circle"></i>
                        <p> Toolkit</p>
                      </Link>
                    </li>
                    {/* ImpressionDetails */}
                    <li className="nav-item">
                      <Link
                        to="/impressiondetails-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/Campaign")}
                      >
                        <i className="nav-icon far fa-circle"></i>
                        <p> Impression Details</p>
                      </Link>
                    </li>
                    {/* Campaign Package */}
                    <li className="nav-item">
                      <Link
                        to="/campaignpackage-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/Campaign")}
                      >
                        <i className="nav-icon far fa-circle"></i>
                        <p> Campaign Package</p>
                      </Link>
                    </li>

                    {/* MasterCampaign Package */}
                    <li className="nav-item">
                      <Link
                        to="/mastercampaign-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/Campaign")}
                      >
                        <i className="nav-icon far fa-circle"></i>
                        <p> MasterCampaign Package</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {/* Custom Send Email */}
              {checkUserPermission("/email") ? (
                <li className="nav-item">
                  <a
                    // href="#"
                    className={
                      isActive("/email") ? "nav-link active" : "nav-link"
                    }
                  >
                    <i className="nav-icon far fa-envelope"></i>
                    <p>
                      Custom Send Email
                      <i className="right fas fa-angle-left"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {/* Notification Template */}
                    <li className="nav-item">
                      <Link
                        to="/custom-notification-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/email")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Notification Template</p>
                      </Link>
                    </li>

                    {/* Email Template */}
                    <li className="nav-item">
                      <Link
                        to="/custom-email-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/email")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Email Template</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {/* Fan Funnel */}
              {checkUserPermission("/fanfunnle-list") ? (
                <li className="nav-item">
                  <Link
                    to="/fanfunnle-list"
                    className={
                      location.pathname === "/fanfunnle-list"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="nav-icon fas fa-funnel-dollar"></i>
                    <p> Fan Funnel</p>
                  </Link>
                </li>
              ) : null}

              {/* User */}
              {checkUserPermission("/user") ? (
                <li className="nav-item">
                  <a
                    // href="#"
                    className={
                      isActive("/user") ? "nav-link active" : "nav-link"
                    }
                  >
                    <i className="nav-icon fa fa-users"></i>
                    <p>
                      User
                      <i className="right fas fa-angle-left"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {/* All Users */}
                    <li className="nav-item">
                      <Link
                        to="/user-list"
                        onClick={() => changeLocation("/user-list")}
                        className="nav-link"
                      >
                        {/* <Link to="/user-list" className="nav-link"> */}
                        <i className="far fa-circle nav-icon"></i>
                        <p>All Users</p>
                      </Link>
                    </li>

                    {/* Artist */}
                    <li className="nav-item">
                      <Link
                        to="/user-list/2"
                        onClick={() => changeLocation("/user-list/2")}
                        className="nav-link"
                      >
                        {/* <Link to="/user-list/2" className="nav-link"> */}
                        <i className="far fa-circle nav-icon"></i>
                        <p>Artist</p>
                      </Link>
                    </li>

                    {/* Driver */}
                    <li className="nav-item">
                      <Link
                        to="/user-list/3"
                        onClick={() => changeLocation("/user-list/3")}
                        className="nav-link"
                      >
                        {/* <Link to="/user-list/3" className="nav-link"> */}
                        <i className="far fa-circle nav-icon"></i>
                        <p>Driver</p>
                      </Link>
                    </li>

                    {/* Business */}
                    <li className="nav-item">
                      <Link
                        to="/user-list/4"
                        onClick={() => changeLocation("/user-list/4")}
                        className="nav-link"
                      >
                        {/* <Link to="/user-list/4" className="nav-link"> */}
                        <i className="far fa-circle nav-icon"></i>
                        <p>Business</p>
                      </Link>
                    </li>

                    {/* Documents */}
                    <li className="nav-item">
                      <Link
                        to="/userdocument"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/user")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Documents</p>
                      </Link>
                    </li>

                    {/* Balance */}
                    <li className="nav-item">
                      <Link
                        to="/balance-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/user")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Balance</p>
                      </Link>
                    </li>

                    {/* Free Impression */}
                    <li className="nav-item">
                      <Link
                        to="/free-impression"
                        className={
                          location.pathname === "/free-impression"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => handleActiveMenu("/user")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Free Impression</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {/* Campaign */}
              {checkUserPermission("/campaign") ? (
                <li className="nav-item">
                  <a
                    // // href="#"
                    className={
                      isActive("/campaign") ? "nav-link active" : "nav-link"
                    }
                  >
                    <i className="nav-icon fas fa-compact-disc"></i>
                    <p>
                      Campaign
                      <i className="right fas fa-angle-left"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {/* All Campaign */}
                    <li className="nav-item">
                      <Link
                        to="/campaign-all"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/campaign")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>All Campaign</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/cancelCampaign-all"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/campaign")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Cancel Campaign</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {/* Account */}
              {checkUserPermission("/account") ? (
                <li className="nav-item">
                  <a
                    // href="#"
                    className={
                      isActive("/account") ? "nav-link active" : "nav-link"
                    }
                  >
                    <i className="nav-icon fas fa-table"></i>
                    <p>
                      Account
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {/* Transaction History */}
                    <li className="nav-item">
                      <Link
                        to="/transaction-history-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/account")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Transaction History</p>
                      </Link>
                    </li>

                    {/* Transaction Request */}
                    <li className="nav-item">
                      <Link
                        to="/transaction-request-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/account")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Transaction Request</p>
                      </Link>
                    </li>

                    {/* Referral Earning History */}
                    <li className="nav-item">
                      <Link
                        to="/earn-list"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/account")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Referral Earning History</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {/* Reports */}
              {checkUserPermission("/reports") ? (
                <li className="nav-item">
                  <a
                    // href="#"
                    className={
                      isActive("/reports") ? "nav-link active" : "nav-link"
                    }
                  >
                    <i className="nav-icon fas fa-chart-pie"></i>
                    <p>
                      Reports
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {/* User */}
                    <li className="nav-item">
                      <Link
                        to="/user-reports"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/reports")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>User</p>
                      </Link>
                    </li>

                    {/* Campaign */}
                    <li className="nav-item">
                      <Link
                        to="/campaign-reports"
                        className="nav-link"
                        onClick={() => handleActiveMenu("/reports")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Campaign</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {/* Activity */}
              {checkUserPermission("/activity-list") ? (
                <li className="nav-item">
                  <Link
                    to="/activity-list"
                    className={
                      location.pathname === "/activity-list"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="nav-icon fas fa-tasks"></i>
                    <p>Activity</p>
                  </Link>
                </li>
              ) : null}

              {/* Config Setting */}
              {checkUserPermission("/configsetting-list") ? (
                <li className="nav-item">
                  <Link
                    to="/configsetting-list"
                    className={
                      location.pathname === "/configsetting-list" ||
                      location.pathname.startsWith("/manage-configsetting")
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="fas fa-cogs nav-icon"></i>
                    <p>Config Setting</p>
                  </Link>
                </li>
              ) : null}
            </ul>
          </div>
          {/* <!-- /.sidebar-menu --> */}
        </div>
        {/* <!-- /.sidebar --> */}
      </div>
    </Fragment>
  );
};

export default Sidebar;

// import React, { useState } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Header from "./../header/Header";

// const SidebarItem = ({ item, isActive, handleActiveMenu }) => {
//   const hasChildMenu = item.childmenu && item.childmenu.length > 0;

//   return (
//     <li className="nav-item">
//       <Link
//         to={item.path}
//         // className={`nav-link ${isActive(item.path) ? "active" : ""}`}
//         className={`nav-link ${isActive(item.path) ? "" : ""}`}
//         onClick={() => handleActiveMenu(item.path)}
//       >
//         <i className={`nav-icon ${item.icon}`}></i>
//         <p>
//           {item.text} {hasChildMenu && <i className="right fas fa-angle-left"></i>}
//         </p>
//       </Link>
//       {hasChildMenu && (
//         <ul className="nav nav-treeview">
//           {item.childmenu.map((childItem, index) => (
//             <SidebarItem
//               key={index}
//               item={childItem}
//               isActive={isActive}
//               handleActiveMenu={handleActiveMenu}
//             />
//           ))}
//         </ul>
//       )}
//     </li>
//   );
// };

// const Sidebar = () => {
//   const loginFullName = localStorage.getItem("FullName");
//   const navigate = useNavigate();
//   const location = useLocation();

//   const [activeMenu, setActiveMenu] = useState("");

//   const handleActiveMenu = (menu) => {
//     console.log("Menu :", menu);
//     setActiveMenu(menu);

//     // navigate(menu, { replace: true });
//     // window.location.reload();
//   };

//   const checkUserPermission = (permission) => {
//     const userType = localStorage.getItem("UserType").toUpperCase();

//     if (userType === "SUBADMIN" && (permission === "/story-list" || permission.startsWith("/manage-story"))) {
//       return true;
//     } else if (userType === "ADMIN") {
//       return true;
//     } else {
//       return false;
//     }
//   };

//   const isActive = (menu) => {
//     console.log(activeMenu === menu || location.pathname === menu);
//     // return activeMenu === menu || location.pathname === menu;
//     return activeMenu === menu || location.pathname === menu;
//   };

//   const menuItems = [
//     { path: "/", text: "Dashboard", permission: "/dashboard", icon: "fas fa-home", childmenu: [] },
//     { path: "/calendar", text: "Calendar", permission: "/calendar", icon: "far fa-calendar-alt", childmenu: [] },
//     {
//       path: "", text: "Master", permission: "/master", icon: "fas fa-list",
//       childmenu: [
//         { path: "/campaign-county-list", text: "Campaign Country", permission: "/master", icon: "fa fa-flag" },
//         { path: "/genre-list", text: "Genre", permission: "/master", icon: "fa fa-music" },
//         { path: "/ethnicity-list", text: "Ethnicity", permission: "/master", icon: "fa fa-user-secret" },
//         { path: "/question-list", text: "Question/Option", permission: "/master", icon: "fas fa-question" },
//         { path: "/notification-list", text: "Notification Template", permission: "/master", icon: "fa fa-envelope-square" },
//         { path: "/emailtemplate-list", text: "Email Template", permission: "/master", icon: "fa fa fa-envelope" },
//       ]
//     },
//     { path: "/story-list", text: "Story", permission: "/story-list", icon: "fas fa-music", childmenu: [] },
//     {
//       path: "", text: "Campaign Package", permission: "/Campaign", icon: "fas fa-dollar-sign",
//       childmenu: [
//         { path: "/toolkit-list", text: "Toolkit", permission: "/Campaign", icon: "far fa-circle" },
//         { path: "/impressiondetails-list", text: "Impression Details", permission: "/Campaign", icon: "far fa-circle" },
//         { path: "/campaignpackage-list", text: "Campaign Package", permission: "/Campaign", icon: "far fa-circle" },
//         { path: "/mastercampaign-list", text: "Master Campaign Package", permission: "/Campaign", icon: "far fa-circle" },
//       ]
//     },
//     {
//       path: "", text: "Custom Send Email", permission: "/email", icon: "far fa-envelope",
//       childmenu: [
//         { path: "/custom-notification-list", text: "Notification Template", permission: "/email", icon: "far fa-circle" },
//         { path: "/custom-email-list", text: "Email Template", permission: "/email", icon: "far fa-circle" },
//       ]
//     },
//     { path: "/fanfunnle-list", text: "Fan Funnel", permission: "/fanfunnle-list", icon: "fas fa-funnel-dollar", childmenu: [] },
//     {
//       path: "", text: "User", permission: "/user", icon: "fa fa-users",
//       childmenu: [
//         { path: "/user-list", text: "All Users", permission: "/user", icon: "far fa-circle" },
//         { path: "/user-list/2", text: "Artist", permission: "/user", icon: "far fa-circle" },
//         { path: "/user-list/3", text: "Driver", permission: "/user", icon: "far fa-circle" },
//         { path: "/user-list/4", text: "Business", permission: "/user", icon: "far fa-circle" },
//         { path: "/userdocument", text: "Documents", permission: "/user", icon: "far fa-circle" },
//         { path: "/balance-list", text: "Balance", permission: "/user", icon: "far fa-circle" },
//       ]
//     },
//     {
//       path: "", text: "Campaign", permission: "/campaign", icon: "fas fa-compact-disc",
//       childmenu: [
//         { path: "/campaign-all", text: "All Campaign", permission: "/campaign", icon: "far fa-circle" },
//       ]
//     },
//     {
//       path: "", text: "Account", permission: "/account", icon: "fas fa-table",
//       childmenu: [
//         { path: "/transaction-history-list", text: "Transaction History", permission: "/account", icon: "far fa-circle" },
//         { path: "/transaction-request-list", text: "Transaction Request", permission: "/account", icon: "far fa-circle" },
//         { path: "/earn-list", text: "Referral Earning History", permission: "/account", icon: "far fa-circle" },
//       ]
//     },
//     {
//       path: "", text: "Reports", permission: "/reports", icon: "fas fa-chart-pie",
//       childmenu: [
//         { path: "/user-reports", text: "User", permission: "/reports", icon: "far fa-circle" },
//         { path: "/campaign-reports", text: "Campaign", permission: "/reports", icon: "far fa-circle" },
//       ]
//     },
//     { path: "/activity-list", text: "Activity", permission: "/activity-list", icon: "fas fa-tasks", childmenu: [] },
//     { path: "/configsetting-list", text: "Config Setting", permission: "/configsetting-list", icon: "fas fa-cogs", childmenu: [] },
//     // Add more menu items here...
//   ];

//   return (
//     <>
//       <Header />

//       <div className="main-sidebar sidebar-dark-primary elevation-4">
//         <a href="/" className="brand-link text-decoration-none">
//           <img
//             src="/dist/img/numella192.png"
//             alt="Numella"
//             className="brand-image img-circle elevation-3"
//             style={{ opacity: "8" }}
//           />
//           <span className="brand-text font-weight-normal">NUMELLA</span>
//         </a>
//         <div className="sidebar">
//           <div className="mt-2">
//             <ul
//               className="nav nav-pills nav-sidebar flex-column custom-nav-sidebar"
//               data-widget="treeview"
//               role="menu"
//               data-accordion="false"
//             >
//               {menuItems.map((item, index) => (
//                 checkUserPermission(item.permission) && (
//                   <SidebarItem
//                     key={index}
//                     item={item}
//                     isActive={isActive}
//                     handleActiveMenu={handleActiveMenu}
//                   />
//                 )
//               ))}
//             </ul>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Sidebar;
