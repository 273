import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import Sidebar from '../../Layout/sidebar/Sidebar';
import Footer from '../../Layout/footer/Footer';
import { toastError, toastSuccess } from '../../common/Toast';
import config from '../../config/config';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import MDEditor from '@mdfe/ckeditor5-custom-build';
import translations from '@mdfe/ckeditor5-custom-build/build/translations/en';

import CustomLoader from "../../Layout/CustomLoader";

const ManageEmailTemplate = (props) => {
    const paramObject = useParams();
    let navigate = useNavigate();

    const [formValues, setFormValues] = useState({ ID: 0, EmailType: '', EmailSubject: '', BodyHTML: '', IsActive: true });
    const [isFormDisabled, setisFormDisabled] = useState(false);
    const [editordata, setEditorData] = useState("");
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        EmailType: Yup.string().required("Type is required "),
        EmailSubject: Yup.string().required("Subject is required ")
    });

    let createdby = localStorage.getItem('ID');

    const handleSubmit = (values) => {
        console.log(editordata);
        setLoading(true);

        axios.post(`${config.baseUrl}/emailtemplate/upsert`, {
            id: values.ID,
            emailtype: values.EmailType,
            subject: values.EmailSubject,
            body: editordata,// values.BodyHTML,
            isactive: values.IsActive,
            createdby: createdby
        }, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((res) => {
                if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
                    toastSuccess('Record has been added!');
                    navigate("/emailtemplate-list");
                }
                else if (res.data.ResponseCode === "ERROR" && res.data.ResponseID === -1) {
                    toastError("Code already Exist!");
                }
                else {
                    toastError("Somthing went wrong");
                }

                setLoading(false);
            }).catch((error) => {
                console.log("error occured", error);

                setLoading(false);
            });
    }

    useEffect(() => {
        if (paramObject.id > 0) {
            setLoading(true);

            axios.get(`${config.baseUrl}/emailtemplate/findbyid?Id=${paramObject.id}`)
                .then((response) => {
                    const responseData = response.data.ResponseData[0];
                    console.log(responseData);

                    const { ID, EmailType, EmailSubject, BodyHTML } = responseData;
                    const IsActive = responseData.IsActive === 1 ? true : false;
                    setEditorData(BodyHTML);
                    console.log("BodyHtml => ", BodyHTML);
                    setFormValues({ ID, EmailType, EmailSubject, BodyHTML, IsActive });
                    setisFormDisabled(true);

                    setLoading(false);
                }).catch((err) => {
                    console.log(err);

                    setLoading(false);
                })
        }
    }, []);

    return (
        <Fragment>
            <Sidebar />
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Email Template </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">Email Template</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header text-right" >
                                        <h3 className="card-title">Manage </h3>
                                    </div>
                                    <div className="card-body">

                                        <Formik  {...props}
                                            validationSchema={validationSchema}
                                            initialValues={formValues}
                                            onSubmit={handleSubmit}
                                            enableReinitialize
                                        >
                                            {({
                                                errors,
                                                touched,
                                                handleChange
                                            }) => (
                                                <Form >
                                                    <div className="dataTables_wrapper dt-bootstrap4" >
                                                        <Field type="hidden" className="form-control" id="ID" name="ID" />

                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="EmailType">Email Type</label>
                                                                    <Field type="text" className="form-control" id="EmailType" name="EmailType" onChange={handleChange} disabled={isFormDisabled} />
                                                                    <span className="text-danger">
                                                                        {touched.EmailType && errors.EmailType ? errors.EmailType : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="subject">Email Subject</label>
                                                                    <Field type="text" className="form-control" id="EmailSubject" name="EmailSubject" onChange={handleChange} />
                                                                    <span className="text-danger">
                                                                        {touched.EmailSubject && errors.EmailSubject ? errors.EmailSubject : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="BodyHTML">Body</label>
{/* 
                                                                    <ReactSummernote
                                                                        value="Default value"
                                                                        options={{
                                                                            lang: 'ru-RU',
                                                                            height: 350,
                                                                            dialogsInBody: true,
                                                                            toolbar: [
                                                                                ['style', ['style']],
                                                                                ['font', ['bold', 'underline', 'clear']],
                                                                                ['fontname', ['fontname']],
                                                                                ['para', ['ul', 'ol', 'paragraph']],
                                                                                ['table', ['table']],
                                                                                ['insert', ['link', 'picture', 'video']],
                                                                                ['view', ['fullscreen', 'codeview']]
                                                                            ]
                                                                        }}
                                                                        onChange={ChangeSummernote}
                                                                    /> */}

                                                                    <CKEditor id="BodyHTML" name="BodyHTML"
                                                                        editor={MDEditor}
                                                                        data={editordata}
                                                                        config={{
                                                                            language: {ui: 'en'},
                                                                            toolbar: {
                                                                                items: [
                                                                                    'undo','redo','removeFormat',
                                                                                    '|',
                                                                                    'sourceEditing',
                                                                                    '|',
                                                                                    'fontFamily','fontSize','fontColor','highlight',
                                                                                    '|',
                                                                                    'bold','italic','underline',
                                                                                    '|',
                                                                                    'bulletedList','numberedList','todoList',
                                                                                    '|',
                                                                                    'alignment','indent','outdent',
                                                                                    '|',
                                                                                    'blockQuote','link','code','imageUpload','mediaEmbed','insertTable',
                                                                                ]
                                                                            },
                                                                            fontFamily: {
                                                                                options: ['default','Arial, Helvetica, sans-serif','Courier New, Courier, monospace','Georgia, serif','Lucida Sans Unicode, Lucida Grande, sans-serif','Tahoma, Geneva, sans-serif','Times New Roman, Times, serif','Trebuchet MS, Helvetica, sans-serif','Verdana, Geneva, sans-serif',],
                                                                            },
                                                                            htmlSupport: {
                                                                                allow: [{styles: true}]
                                                                            },
                                                                            // language: 'en'
                                                                        }}
                                                                        onReady={(editor) => {
                                                                            // You can store the "editor" and use when it is needed.
                                                                            // console.log('Editor is ready to use!', editor);
                                                                        }}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            setEditorData(data);
                                                                            console.log({ event, editor, data });
                                                                        }}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <Field type="checkbox" className="form-check-input" id="IsActive" name='IsActive' />
                                                                        <label className="form-check-label" for="IsActive">Active</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row text-right'>
                                                            <div className='col-md-12 me-2'>
                                                                <button type="submit" className="btn  me-2 btn-primary" >Save</button>
                                                                <Link to="/emailtemplate-list" className="btn btn-secondary">Cancel</Link>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </Fragment>
    )
}

export default ManageEmailTemplate