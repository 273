/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toastError } from "../../common/Toast";
import config from "../../config/config";
import { formatDistanceToNow } from "date-fns";

const Header = () => {
  let navigate = useNavigate();

  const signOut = (evt) => {
    evt.preventDefault();
    localStorage.clear();
    navigate("/login");
  };

  const [items, setItems] = useState([]);
  const [unreadActivityCount, setunreadActivityCount] = useState(0);

  const checkUserPermission = (permission) => {
    let userType = localStorage.getItem("UserType");

    if (userType.toUpperCase() === "SUBADMIN") {
      if (
        permission === "/story-list" ||
        permission.startsWith("/manage-story")
      ) {
        return true;
      } else {
        return false;
      }
    } else if (userType.toUpperCase() === "ADMIN") {
      return true;
    } else {
      return false;
    }
  };

  const handleApi = (
    pagenumber,
    pagesize,
    filtertext,
    ordercolumn,
    currentorder,
    touserid,
    usertype
  ) => {
    axios
      .get(
        `${config.baseUrl}/activity?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&touserid=${touserid}&usertype=${usertype}`
      )
      .then((response) => {
        setItems(response.data.ResponseData);
        if (response.data.ResponseData.length > 0) {
          let UnreadActivityCount =
            response.data.ResponseData[0].UnreadActivityCount;
          setunreadActivityCount(UnreadActivityCount);
        }
      })
      .catch((err) => {
        toastError("Somthing went wrong!");
      });
  };

  useEffect(() => {
    handleApi(1, 5, "", 0, "desc", "", "ADMIN");
  }, []);

  const formatDateAndTimeAgo = (string) => {
    const date = new Date(string);
    const distance = formatDistanceToNow(date, { addSuffix: true });
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = new Date(string).toLocaleDateString([], options); //format(date, options);
    return `${formattedDate} (${distance})`;
  };

  return (
    <Fragment>
      {/* <!-- Navbar --> */}
      <nav className="main-header navbar navbar-expand navbar-dark">
        {/* <!-- Left navbar links --> */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>
        </ul>

        {/* <!-- Right navbar links --> */}
        <ul className="navbar-nav ml-auto">
          {/* <!-- Notifications Dropdown Menu --> */}
          {checkUserPermission("/activity-list") ? (
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="/#">
                <i className="far fa-bell" style={{ "font-size": "33px" }}></i>
                <span
                  className="badge badge-danger navbar-badge"
                  style={{ "font-size": "10px" }}
                >
                  {unreadActivityCount}
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                {/* <!-- Message Start --> */}
                {items &&
                  items?.map((item, key) => (
                    <>
                      <a
                        key={key}
                        href={
                          item.ActivityType == "Create_New_Campaign_Review"
                            ? "campaign-all"
                            : item.ActivityType ==
                                "Notification_Message_NewUser"
                              ? "user-list"
                              : item.ActivityType == "WITHDRAWAL_PROCESS"
                                ? "transaction-request-list"
                                : item.ActivityType ==
                                    "Notification_Message_NewDocument"
                                  ? "userdocument"
                                  : "/#"
                        }
                        className="dropdown-item"
                      >
                        <div className="media">
                          {/* {item.FromUser} */}
                          <div className="media-body">
                            <h3 className="dropdown-item-title custom-dropdown-item-title">
                              {item.Title}
                              {/* <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span> */}
                            </h3>
                            <p className="text-sm">{item.Message}</p>
                            <p className="text-sm text-muted">
                              <i className="far fa-clock mr-1"></i>{" "}
                              {formatDateAndTimeAgo(item.CreatedDate)}
                            </p>
                          </div>
                        </div>
                      </a>
                      <div className="dropdown-divider"></div>
                    </>
                  ))}
                {/* <!-- Message End --> */}

                <Link
                  to="/activity-list"
                  className="dropdown-item dropdown-footer"
                >
                  See All Messages
                </Link>
              </div>
            </li>
          ) : null}
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <div className="user-panel pb-3 mb-3 d-flex">
                <div className="user-logo">
                  <img
                    src="/dist/img/numella192.png"
                    className="img-circle elevation-2"
                    alt="User Image"
                  />
                </div>
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <a
                href="#"
                className="dropdown-item dropdown-header"
                onClick={(evt) => signOut(evt)}
              >
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      {/* <!-- /.navbar --> */}
    </Fragment>
  );
};

export default Header;
