import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Sidebar from '../../Layout/sidebar/Sidebar';
import Footer from '../../Layout/footer/Footer';
import config from '../../config/config';
//import { ToastContainer } from 'react-toastify';
import { toastError, toastSuccess } from '../../common/Toast';

import UserSummaryChart from "./UserSummaryChart";
import CampaignSummaryChart from "./CampaignSummaryChart";

import CustomLoader from "../../Layout/CustomLoader";

let data = [];
const Dashboard = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [dashboardsummaryitems, setDashboardSummaryItems] = useState([]);

    const [artistcount, setArtistCount] = useState(0);
    const [drivercount, setDriverCount] = useState(0);
    const [advertisercount, setAdvertiserCount] = useState(0);
    // const [storycount, setStoryCount] = useState(0);

    const [artistcampaigncount, setArtistCampaignCount] = useState(0);
    const [advertisercampaigncount, setAdvertiserCampaignCount] = useState(0);

    const handleSummaryApi = () => {

        if (!loading === true) {
            setLoading(true);
            axios.get(`${config.baseUrl}/dashborad/getcounts`)
                .then((response) => {
                    let responseObjData = response.data.ResponseData[0];
                    setDashboardSummaryItems(responseObjData);

                    setArtistCount(responseObjData.ArtistCount);
                    setDriverCount(responseObjData.DriverCount);
                    setAdvertiserCount(responseObjData.AdvertiserCount);
                    // setStoryCount(responseObjData.StoryCount);

                    setArtistCampaignCount(responseObjData.ArtistCampainCount);
                    setAdvertiserCampaignCount(responseObjData.AdvertiserCampignCount);

                    setLoading(false);
                }).catch((err) => {
                    console.log(err)

                    setError(err);
                    setLoading(false);
                })
        }
        else {
            toastError("Somthing went wrong!");
        }
    }

    useEffect(() => {

        handleSummaryApi();
    }, []);




    return (
        <Fragment>
            <Sidebar />
            <CustomLoader loading={loading} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                {/* <h1 className="m-0">Dashboard</h1> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-6">

                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{dashboardsummaryitems?.ArtistCount}</h3>
                                        <p>Artist</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person"></i>
                                    </div>
                                    <Link to={"/user-list/2"} className="small-box-footer text-right pr-2">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">

                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{dashboardsummaryitems?.DriverCount}</h3>
                                        <p>Driver</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person text-muted"></i>
                                    </div>
                                    <Link to={"/user-list/3"} className="small-box-footer text-right pr-2">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">

                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>{dashboardsummaryitems?.AdvertiserCount}</h3>
                                        <p>Business</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person"></i>
                                    </div>
                                    <Link to={"/user-list/4"} className="small-box-footer text-right pr-2">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">

                                <div className="small-box bg-dark">
                                    <div className="inner">
                                        <h3>{dashboardsummaryitems?.TotalUserCount}</h3>
                                        <p>Total Users</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person text-muted"></i>
                                    </div>
                                    <Link to={"/user-list"} className="small-box-footer text-right pr-2">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{dashboardsummaryitems?.VerifiedArtitstCount}</h3>
                                        <p>Total Veirfy Artist (Blue Tik)</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-check-circle fa-lg"></i>
                                    </div>
                                    <a href="#" className="small-box-footer text-right pr-2">&nbsp;
                                        {/* <i className="fas fa-arrow-circle-right"></i> */}
                                    </a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{dashboardsummaryitems?.VerifedDriverCount}</h3>
                                        <p>Total Veirfy Driver </p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person text-muted"></i>
                                    </div>
                                    <a href="#" className="small-box-footer text-right pr-2">&nbsp;
                                        {/* <i className="fas fa-arrow-circle-right"></i> */}
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">

                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>{dashboardsummaryitems?.StoryCount}</h3>
                                        <p>Story Count</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa fa-music fa-lg"></i>
                                    </div>
                                    <a href="#" className="small-box-footer text-right pr-2">&nbsp;
                                        {/* <i className="fas fa-arrow-circle-right"></i> */}
                                    </a>
                                    {/* <Link to={"/user-list/4"} className="small-box-footer text-right pr-2">More info <i className="fas fa-arrow-circle-right"></i></Link> */}
                                </div>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                    <span className="info-box-icon bg-info elevation-1"><i className="fas fa-compact-disc"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text">Total Campaign</span>
                                        <span className="info-box-number">
                                            {dashboardsummaryitems?.CampaignCount}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-danger elevation-1"><i className="fas fa-podcast"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text">Artist Campaign</span>
                                        <span className="info-box-number">{dashboardsummaryitems?.ArtistCampainCount}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-success elevation-1"><i className="fas fa-podcast"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text">Business Campaign</span>
                                        <span className="info-box-number">{dashboardsummaryitems?.AdvertiserCampignCount}</span>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="row">


                            <div className="col-md-6">
                                <div className="card card-dark">
                                    <div className="card-header">
                                        <h3 className="card-title">User's Chart</h3>

                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <UserSummaryChart artistCount={artistcount} driverCount={drivercount} advertiserCount={advertisercount} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="card card-dark">
                                    <div className="card-header">
                                        <h3 className="card-title">Campaign Chart</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <CampaignSummaryChart artistCampaignCount={artistcampaigncount} advertiserCampaignCount={advertisercampaigncount} />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </div>
            <Footer />
        </Fragment>
    )
}

export default Dashboard