import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import Footer from '../../Layout/footer/Footer'
import Sidebar from '../../Layout/sidebar/Sidebar'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { toastError, toastSuccess } from '../../common/Toast';
import config from '../../config/config';

import CustomLoader from "../../Layout/CustomLoader";

const ScheduledEvent = () => {
    // const [items, setItems] = useState({ id: '', title: '', start: '', end: ''});
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const formatDate = (string) => {
        return new Date(string).toISOString().split('T')[0]
    }

    const handleApi = () => {
        if (!loading === true) {
            setLoading(true);
            axios.get(`${config.baseUrl}/story/schedulestorysevent`, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then((response) => {
                    response.data.ResponseData.forEach((e) => {
                        e.start = formatDate(e.start)
                        e.end = formatDate(e.end)
                    });

                    setItems(response.data.ResponseData);
                    setLoading(false);
                }).catch((err) => {
                    console.log(err)

                    setError(err);
                    setLoading(false);
                })
        }
        else {
            toastError("Somthing went wrong!");
        }
    }



    useEffect(() => {
        handleApi();
    }, [])


    return (
        <Fragment>
            <Sidebar />
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Schedule Story Events</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">Schedule Story Events</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 custom-calender-css">
                                <FullCalendar
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}

                                    initialView="dayGridMonth"
                                    headerToolbar={{
                                        left: 'prev,next,today',
                                        center: 'title',
                                        right: 'dayGridMonth,timeGridWeek,timeGridDay',
                                    }}
                                    events={items}
                                    eventColor="red"
                                    nowIndicator
                                />
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            <Footer />
        </Fragment>
    )
}

export default ScheduledEvent