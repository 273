import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import Sidebar from "../../Layout/sidebar/Sidebar";
import Footer from "../../Layout/footer/Footer";
import ReactPaginate from "react-paginate";
import config from "../../config/config";
import CustomLoader from "../../Layout/CustomLoader";

const BalanceDatatableList = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [perPageSize, setperPageSize] = useState(10);
  const [searchtext, setSearchText] = useState("");
  const [totalrecord, setTotalRecord] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [remountComponent, setRemountComponent] = useState(0);

  const changePage = ({ selected }) => {
    selected = selected + 1;
    handleApi(selected, perPageSize, searchtext, 0, "asc");
  };

  const ChangeFilterText = (e) => {
    setSearchText(e.target.value);
    setRemountComponent(Math.random());
    handleApi(1, perPageSize, e.target.value, 0, "asc");
  };

  const ChangePageSize = (e) => {
    setperPageSize(e.target.value);
    handleApi(1, e.target.value, searchtext, 0, "asc");
  };

  const handleApi = (
    pagenumber,
    pagesize,
    filtertext,
    ordercolumn,
    currentorder
  ) => {
    setLoading(true);
    axios
      .get(
        `${config.baseUrl}/account/userwisewalletamount?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}`
      )
      .then((response) => {
        setItems(response.data.ResponseData);
        setTotalRecord(response.data.ResponseData[0].TotalRecord);
        setpageCount(
          Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize)
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setError(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    handleApi(1, perPageSize, "", 0, "asc");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Sidebar />
      <ToastContainer />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Balance </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Balance </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header text-right">
                    <h3 className="card-title">Balance List</h3>
                    {/* <Link to="/manage-ethnicity" className="btn btn-secondary">
                                            Add New
                                        </Link> */}
                  </div>

                  <div className="card-body">
                    <div
                      id="example1_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 table-responsive"
                    >
                      <div className="row">
                        <div className="col-sm-6 col-md-6">
                          <select className="" onChange={ChangePageSize}>
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                        </div>

                        <div className="col-sm-6 col-md-6 text-right">
                          <div
                            id="example1_filter"
                            className="dataTables_filter"
                          >
                            <label>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="example1"
                                onChange={ChangeFilterText}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            id="example1"
                            className="table table-bordered dataTable dtr-inline"
                            aria-describedby="example1_info"
                          >
                            <thead>
                              <tr>
                                <th
                                  className="sorting sorting_asc"
                                  tabIndex="0"
                                  style={{ width: "6%" }}
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                  aria-label="ID: activate to sort column descending"
                                >
                                  ID
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  style={{ width: "6%" }}
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Image: activate to sort column ascending"
                                >
                                  Image
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Name: activate to sort column ascending"
                                >
                                  Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="EmailId: activate to sort column ascending"
                                >
                                  Email Id
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  style={{ width: "6%" }}
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="UserType: activate to sort column ascending"
                                >
                                  Usertype
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  style={{ width: "10%" }}
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Balance: activate to sort column ascending"
                                >
                                  Balance
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {items.length > 0 ? (
                                items.map((item) => (
                                  <tr className="odd">
                                    <td
                                      className="dtr-control sorting_1"
                                      tabIndex="0"
                                    >
                                      {item.ID}
                                    </td>
                                    <td>
                                      {item.ProfileImage !== "" &&
                                      item.ProfileImage !== undefined ? (
                                        <>
                                          <a
                                            href={item.ProfileImage}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={item.ProfileImage}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                                borderRadius: "10px",
                                              }}
                                            />
                                          </a>
                                        </>
                                      ) : (
                                        <a
                                          href={"/dist/img/numella.png"}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <img
                                            src="/dist/img/numella.png"
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                          />
                                        </a>
                                      )}
                                    </td>
                                    <td>{item.FullName}</td>
                                    <td>{item.EmailId}</td>
                                    <td>{item.UserType}</td>
                                    <td className="text-right">
                                      {item.WalletAmount}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6} align="center">
                                    Data not found!
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-sm-12 col-md-5"
                          key={remountComponent}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={items.length > 0 ? pageCount : 0}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            pageClassName={"page-link"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- /.card-body --> */}
                </div>
                {/* <!-- /.card --> */}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default BalanceDatatableList;
