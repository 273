import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../Layout/sidebar/Sidebar";
import Footer from "../../Layout/footer/Footer";

import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import ReactPaginate from "react-paginate";
import config from "../../config/config";
import { ToastContainer } from "react-toastify";
import { toastError, toastSuccess } from "../../common/Toast";

import CustomLoader from "../../Layout/CustomLoader";

const StoryUserDataTableList = () => {
  const paramObject = useParams();

  //const [statee, setSatete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [perPageSize, setperPageSize] = useState(10);
  const [searchtext, setSearchText] = useState("");
  // const [searchUserType, setsearchUserType] = useState("");
  const [totalrecord, setTotalRecord] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [paramstoryid, setParamStoryID] = useState(0);
  const [remountComponent, setRemountComponent] = useState(0);

  const handleApi = (
    pagenumber,
    pagesize,
    filtertext,
    ordercolumn,
    currentorder,
    storyid
  ) => {
    // if (!loading === true) {
    setLoading(true);

    axios
      .get(
        `${config.baseUrl}/story/userliststorywise?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&storyid=${storyid}`
      )
      .then((response) => {
        setItems(response.data.ResponseData);
        setTotalRecord(response.data.ResponseData[0].TotalRecord);
        setpageCount(
          Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize)
        );

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setError(err);

        setLoading(false);
      });
    // }
    // else {
    //     toastError("Somthing went wrong!");
    // }
  };

  useEffect(() => {
    let storyId = paramObject.id > 0 ? paramObject.id : 0;
    setParamStoryID(storyId);
    handleApi(1, perPageSize, "", 0, "asc", storyId);
  }, []);

  const changePage = ({ selected }) => {
    selected = selected + 1;
    // setPageNumber(selected);
    handleApi(selected, perPageSize, searchtext, 0, "asc", paramstoryid);
  };

  const ChangePageSize = (e) => {
    setperPageSize(e.target.value);
    handleApi(1, e.target.value, searchtext, 0, "asc", paramstoryid);
  };

  const ChangeFilterText = (e) => {
    setSearchText(e.target.value);
    setRemountComponent(Math.random());
    handleApi(1, perPageSize, e.target.value, 0, "asc", paramstoryid);
  };

  return (
    <Fragment>
      <Sidebar />
      <ToastContainer />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>View Story Users</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/story-list">Story</Link>
                  </li>
                  <li className="breadcrumb-item active">View Story User</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header text-right">
                    <h3 className="card-title">User</h3>
                  </div>

                  <div className="card-body">
                    <div
                      id="example1_wrapper"
                      className="dataTables_wrapper dt-bootstrap4"
                    >
                      {/* <div className="row">
                                                <div className="col-sm-3 col-md-3">
                                                    <div className='form-group'>
                                                        <label className='form-label col-sm-6'>Story</label>
                                                        <div className='col-sm-10'>
                                                            <select className='form-control' onChange={ChangeUserType}>
                                                                <option value={0}>All</option>
                                                                <option value={1}>Admin</option>
                                                                <option value={2}>Artist</option>
                                                                <option value={3}>Driver</option>
                                                                <option value={4}>Business</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                      <div className="row">
                        <div className="col-sm-6 col-md-6">
                          <select className="" onChange={ChangePageSize}>
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                        </div>
                        <div className="col-sm-6 col-md-6 text-right">
                          <div
                            id="example1_filter"
                            className="dataTables_filter"
                          >
                            <label>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="example1"
                                onChange={ChangeFilterText}
                              />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12">
                          <div className="">
                            <table
                              id="example1"
                              className="table table-bordered dataTable dtr-inline"
                              aria-describedby="example1_info"
                            >
                              <thead>
                                <tr>
                                  <th
                                    className="sorting sorting_asc"
                                    tabIndex="0"
                                    aria-controls="example1"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    aria-label="ID: activate to sort column descending"
                                  >
                                    ID
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="example1"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Profile: activate to sort column ascending"
                                  >
                                    Profile
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="example1"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Name: activate to sort column ascending"
                                  >
                                    Name
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="example1"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Email: activate to sort column ascending"
                                  >
                                    Email
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="example1"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="UserType: activate to sort column ascending"
                                  >
                                    Story Name
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {items.length > 0 ? (
                                  items.map((item) => (
                                    <tr className="odd">
                                      <td
                                        className="dtr-control sorting_1"
                                        tabIndex="0"
                                      >
                                        {item.Id}
                                      </td>
                                      <td>
                                        <a
                                          href={item.ProfilePic}
                                          target="_blank"
                                        >
                                          {item.ProfilePic != "" && item.ProfilePic != undefined ?
                                            <>
                                              <a href={item.ProfilePic} target="_blank">
                                                <img src={item.ProfilePic} style={{ width: "50px", height: "50px", borderRadius: "10px" }} />
                                              </a>
                                            </>
                                            :
                                            <a href={"/dist/img/numella.png"} target="_blank">
                                              <img src="/dist/img/numella.png" style={{ width: "50px", height: "50px" }} />
                                            </a>
                                          }

                                          {/* <img
                                            src={item.ProfilePic}
                                            height={50}
                                            width={50}
                                          /> */}
                                        </a>
                                      </td>
                                      <td>{item.UserName}</td>
                                      <td>{item.EmailId}</td>
                                      <td>{item.StoryName}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td align="center" colSpan={5}>
                                      Data not found!
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-sm-12 col-md-5"
                          key={remountComponent}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={items.length > 0 ? pageCount : 0}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            pageClassName={"page-link"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default StoryUserDataTableList;
