import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
//import Sidebar from '../../Layout/sidebar/Sidebar';
import Footer from '../../Layout/footer/Footer';

import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import config from '../../config/config';
import { ToastContainer } from 'react-toastify';
import { toastError, toastSuccess } from '../../common/Toast';

import CustomLoader from "../../Layout/CustomLoader";

const FrontDriverProfile = () => {
    const paramObject = useParams();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [perPageSize, setperPageSize] = useState(10);
    const [searchtext, setSearchText] = useState("");
    const [searchUserType, setsearchUserType] = useState("");
    const [totalrecord, setTotalRecord] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [remountComponent, setRemountComponent] = useState(0);


    const handleApi = (pagenumber, pagesize, filtertext, ordercolumn, currentorder, uniqueid) => {
        setItems([]);
        // if (!loading === true) {
        setLoading(true);

        axios.get(`${config.baseUrl}/campaign/getallplayedsongartistlistbydriver?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&uniqueid=${uniqueid}`)
            .then((response) => {
                setItems(response.data.ResponseData);
                setTotalRecord(response.data.ResponseData[0].TotalRecord);
                setpageCount(Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize));

                setLoading(false);
            }).catch((err) => {
                console.log(err)

                setError(err);

                setLoading(false);
            })
    }
    //     else {
    //         toastError("Somthing went wrong!");
    //     }
    // }

    useEffect(() => {

        if (paramObject.id != "" && paramObject.id != undefined) {
            handleApi(1, perPageSize, "", 0, "desc", paramObject.id);
        }
        else {
            navigate("denied");
        }

    }, [])

    const changePage = ({ selected }) => {
        selected = selected + 1;
        handleApi(selected, perPageSize, searchtext, 0, "asc", paramObject.id);
    };

    const ChangePageSize = (e) => {
        setperPageSize(e.target.value);
        handleApi(1, e.target.value, searchtext, 0, "asc", paramObject.id);
    }

    const ChangeFilterText = (e) => {
        setSearchText(e.target.value);
        setRemountComponent(Math.random());
        handleApi(1, perPageSize, e.target.value, 0, "asc", paramObject.id);
    }


    return (
        <Fragment>
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper public-content-wrapper" style={{ marginLeft: "0px" }}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Play List</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header text-right" >
                                        <h3 className="card-title">Play List</h3>
                                    </div>

                                    <div className="card-body">
                                        <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4" >

                                            <div className="row">
                                                <div className="col-sm-6 col-md-6">
                                                    <select className='' onChange={ChangePageSize}>
                                                        <option value="10">10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </div>
                                                <div className="col-sm-6 col-md-6 text-right">
                                                    <div id="example1_filter" className="dataTables_filter">
                                                        <label><input type="search" className="form-control form-control-sm" placeholder="" aria-controls="example1" onChange={ChangeFilterText} /></label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className='table-responsive'>
                                                        <table id="example1" className="table table-bordered dataTable dtr-inline " aria-describedby="example1_info">
                                                            <thead>
                                                                <tr>
                                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Profile: activate to sort column ascending">Profile</th>
                                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Name: activate to sort column ascending">Name</th>
                                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Type: activate to sort column ascending">Type</th>
                                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Campaign Image: activate to sort column ascending">Campaign Image</th>
                                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Campaign Name: activate to sort column ascending">Campaign Name</th>
                                                                    {/* <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Genre Name: activate to sort column ascending">Genre Name</th>
                                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Played Count: activate to sort column ascending">Played Count</th>              */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {items.length > 0 ? items.map((item) => (
                                                                    <tr className="odd">
                                                                        <td><a href={item.ProfileImage} target="_blank">
                                                                            <img src={item.ProfileImage} height={50} width={50}></img>
                                                                        </a>
                                                                        </td>
                                                                        <td>
                                                                            <Link to={"/profile/" + item.UniqueId} >
                                                                                {item.UserName}
                                                                            </Link>
                                                                        </td>
                                                                        <td>{item.UserType}</td>
                                                                        <td>
                                                                            <a href={item.ArtWorkImageName} target="_blank">
                                                                                <img src={item.ArtWorkImageName} height={50} width={50}></img>
                                                                            </a>
                                                                        </td>
                                                                        <td>{item.CampaignName}</td>
                                                                        {/* <td>{item.GenreName}</td>
                                                                        <td>{item.PlayedCount}</td> */}
                                                                    </tr>
                                                                )) : <tr>
                                                                    <td colSpan={5} align='center'>Data not found!</td>
                                                                </tr>}
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-12 col-md-5' key={remountComponent}>
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={items.length > 0 ? pageCount : 0}
                                                        onPageChange={changePage}
                                                        containerClassName={"pagination"}
                                                        pageClassName={"page-link"}
                                                        previousLinkClassName={"page-link"}
                                                        nextLinkClassName={"page-link"}
                                                        activeClassName={"active"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </Fragment>
    )
}

export default FrontDriverProfile