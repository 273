import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Layout/footer/Footer'
import Sidebar from '../../Layout/sidebar/Sidebar'
const EditQuestionOption = () => {
    const [questionList, setQuestionList] = useState([{ question: "" }]);
    const handleQuestionChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...questionList];
        list[index][name] = value;
        setQuestionList(list);
    };

    const handleQuestionAdd = () => {
        setQuestionList([...questionList, { question: "" }]);
    };

    const handleQuestionRemove = (index) => {
        const list = [...questionList];
        list.splice(index, 1);
        setQuestionList(list);
    };
    return (
        <Fragment>
            <Sidebar />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Edit Question Option List

                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">Edit Question Option List
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header text-right" >
                                        <h3 className="card-title">Question List</h3>
                                    </div>
                                    <div className="card-body">
                                        <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4" >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Select Question</label>
                                                        <select className="form-control" id="selectquestion" name="selectquestion">
                                                            <option>option 1</option>
                                                            <option>option 2</option>
                                                            <option>option 3</option>
                                                            <option>option 4</option>
                                                            <option>option 5</option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label>Add Question</label>
                                                        <input type="text" className="form-control" id="addquestion" name="addquestion" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="isActive" name='isActive' />
                                                            <label className="form-check-label" htmlFor="isActive">IsActive</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label>Add option</label>
                                                        {questionList.map((singleQuestion, index) => (
                                                            <div className='input-group'>
                                                                <input id="question" name="question" type="text" className="form-control" placeholder="Add " value={singleQuestion.question} onChange={(e) => handleQuestionChange(e, index)} />
                                                                <div className="input-group-append">
                                                                    {questionList.length - 1 === index && questionList.length < 20 && (
                                                                        <button id="add-question" type="button" className="btn btn-primary" onClick={handleQuestionAdd}>Add</button>
                                                                    )}
                                                                    {questionList.length !== 1 && (
                                                                        <button type="button" className="btn btn-default btn-md" onClick={() => handleQuestionRemove(index)}>
                                                                            <i className="far fa-trash-alt"></i>
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='row text-right'>
                                                <div className='col-md-12 me-2'>
                                                    <button type="submit" className="btn  me-2 btn-primary">Save</button>
                                                    <button type="button" className="btn btn-secondary">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </Fragment>
    )
}

export default EditQuestionOption