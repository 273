import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import Main_Route from './routes/Main_Route';

function App() {

  return (
    <BrowserRouter>
      <Main_Route />
    </BrowserRouter>
  );
}

export default App;
