import AWS from "aws-sdk";
import { toast } from "react-toastify";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWSAccessKeyId,
  secretAccessKey: process.env.REACT_APP_AWSSecretKey,
  region: process.env.REACT_APP_REGION,
});

export const uploadAWS = async (fileObj, directory, method) => {
  try {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    const name = fileObj.name.split(".");

    const params = {
      Bucket: process.env.REACT_APP_Bucket,
      Key: `${directory}/${month}${year}_${Math.floor(Date.now() / 1000)}.${name[name.length - 1]}`,
      ContentType: name[name.length - 1],
      Body: fileObj,
    };

    await s3.upload(params, function (err, data) {
      if (err) {
        toast.error("Something went wrong");
      } else {
        method(data.Location);
      }
    });
  } catch (e) {
    toast.error("Something went wrong");
  }
};

export const uploadFileWithNameAWS = async (
  fileObj,
  directory,
  filename,
  method
) => {
  try {
    const name = fileObj.name.split(".");

    const params = {
      Bucket: process.env.REACT_APP_Bucket,
      Key: `${directory}/${filename}`,
      ContentType: name[name.length - 1],
      Body: fileObj,
    };

    await s3.upload(params, function (err, data) {
      if (err) {
        toast.error("Something went wrong");
      } else {
        method(data.Location);
      }
    });
  } catch (e) {
    toast.error("Something went wrong");
  }
};
