import React, { Fragment } from "react";
import AboutUsFooter from "../../Layout/footer/AboutUsFooter";
import AboutUsHeader from "../../Layout/header/AboutUsHeader";

const AboutUs = () => {
  return (
    <Fragment>
      <AboutUsHeader />
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <h1 style={{ paddingTop: "30px" }}>ABOUT US</h1>
            <p className="custom-p">
              Rideshare music and audio advertising For{" "}
            </p>
            <ul className="custom-ul-style">
              <li>Independent music artists</li>
              <li>Podcast</li>
              <li>Comedy</li>
              <li>Advertisements</li>
              <li>And more</li>
            </ul>
            <p className="custom-p">
              It’s simple to get started, Upfront prices And is good for any
              budget
            </p>
            <p className="custom-p">It is our priority that you get heard</p>
            <h2>How it works</h2>
            <ul className="custom-ul-style">
              <li>Download the app</li>
              <li>Create a profile</li>
              <li>Once we verify you</li>
              <li>Upload clean music </li>
              <li>12 people listen to verify sound quality and if accepted</li>
              <li>Choose a campaign </li>
              <li>And your live</li>
            </ul>
            <h2>Contact Numella</h2>
            <p className="custom-p">2913 E 78th Place Chicago Illinois 60649</p>
            <p className="custom-p">
              <span>
                Please note that the Numella office is only staffed part time,
                so email is the best way to reach us.
              </span>
            </p>
            <p className="custom-p">
              <span>
                <a href="mailto:contact@numellastream.com">
                  {" "}
                  contact@numellastream.com{" "}
                </a>
              </span>
              <span className="ml-3">
                <a href="tel:+1 (312) 834-1709"> +1 (312) 834-1709</a>
              </span>
            </p>
          </div>
        </section>
      </div>

      <AboutUsFooter />
    </Fragment>
  );
};

export default AboutUs;
