import React from 'react'
import { Pie } from 'react-chartjs-2';

const CountrySummaryChart = (props) => {
    //const labels = ["Canada", "Nigeria", "United Kingdom", "United States"];


    // const jsonData = [props.canadaCount, props.nigeriaCount, props.unitedkingdomCount, props.unitedstatesCount];
    //const jsonData = props.countrydata.map((m) => m.CountryWisePlayCount);//[10, 20, 30, 40];

    const data = {
        labels: props.countrydata.map((m) => m.Country),//labels,
        datasets: [
            {
                label: "My First dataset",
                //backgroundColor: "rgb(255, 99, 132)",
                // borderColor: "rgb(0,0,255)",
                data: props.countrydata.map((m) => m.CountryWisePlayCount),//jsonData,
                backgroundColor: props.countrydata.map((m) => m.color),//['#f56954', '#00a65a', '#f39c12', "#efefef"],
            },
        ],
    };
    return (
        <div>
            <Pie data={data} width={600} height={350} options={{ maintainAspectRatio: false }} />
        </div>
    )
}

export default CountrySummaryChart