import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';

const AboutUsFooter = () => {
    return (
        <Fragment>
            <div className="main-footer" style={{ marginLeft: 0 }}>
                {/* <div className="float-right d-none d-sm-block">
                    <b>Version</b> 3.2.0
                </div> */}
                <strong>Copyright © 2020-{new Date().getFullYear()} <Link to="#">Numella</Link>.</strong> All rights reserved.
            </div>
        </Fragment>
    )
}

export default AboutUsFooter