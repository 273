import React, { Fragment } from 'react'

const AboutUsHeader = () => {
    return (
        <Fragment>
            <nav className="main-header navbar navbar-expand navbar-dark" style={{ marginLeft: 0 }}>
                {/* <!-- Left navbar links --> */}
                <ul className="navbar-nav">
                    <li className="nav-item" style={{ paddingLeft: "20px" }}>
                        <img src="/dist/img/numella192.png" alt="Numella" width="50px" height="50px" />
                    </li>
                    <h3 className="pl-2 mt-2">NUMELLA</h3>
                </ul>
            </nav>
        </Fragment>
    )
}

export default AboutUsHeader