import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../Layout/footer/Footer';
import Sidebar from '../../Layout/sidebar/Sidebar';

import { Modal } from 'react-bootstrap';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import { ToastContainer } from 'react-toastify';
import { toastError, toastSuccess } from '../../common/Toast';
import config from '../../config/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomLoader from "../../Layout/CustomLoader";

const CampaignPackageDatatableLists = () => {

  // const [statee, setSatete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [perPageSize, setperPageSize] = useState(10);
  const [searchtext, setSearchText] = useState("");
  const [totalrecord, setTotalRecord] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [toolkititems, setToolkititems] = useState([]);
  const [remountComponent, setRemountComponent] = useState(0);
  const [tookkitvalue, settookkitvalue] = useState(0);

  const handleApi = (pagenumber, pagesize, filtertext, ordercolumn, currentorder,tookkitid) => {
    // if (!loading === true) {
      setLoading(true);
      axios.get(`${config.baseUrl}/campaintoolkit/ToolKitXImpressionDetailslisting?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&tookkitid=${tookkitid}`)
        .then((response) => {
          setItems(response.data.ResponseData);
          setTotalRecord(response.data.ResponseData[0].TotalRecord);
          setpageCount(Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize));
          setLoading(false);
        }).catch((err) => {
          console.log(err);
          setError(err);
          setLoading(false);
        })
    // }
    // else {
    //   toastError("Somthing went wrong!");
    // }
  }

  const ToolkitDropdown = () => {
    setLoading(true);
    axios.get(`${config.baseUrl}/campaintoolkit/`)
      .then((response) => {
        setToolkititems(response.data.ResponseData);
        setLoading(false);
      }).catch((err) => {
        console.log(err)
        setLoading(false);
      })
  }

  const handleChangeTollKitFilter = (e) => {
    setRemountComponent(Math.random());
    // settookkitvalue(e.target.value);
    
    console.log(e.target.value);

    handleApi(1, perPageSize, "", 0, "asc",e.target.value)
  }

  useEffect(() => {
    ToolkitDropdown();
    handleApi(1, perPageSize, "", 0, "asc",tookkitvalue);
  }, [])

  const changePage = ({ selected }) => {
    selected = selected + 1;
    handleApi(selected, perPageSize, searchtext, 0, "asc",tookkitvalue);
  };

  const ChangeFilterText = (e) => {
    setSearchText(e.target.value);
    setRemountComponent(Math.random());
    handleApi(1, perPageSize, e.target.value, 0, "asc",tookkitvalue);
  }

  const ChangePageSize = (e) => {
    setperPageSize(e.target.value);
    handleApi(1, e.target.value, searchtext, 0, "asc",tookkitvalue);
  }


  const formatDate = (string) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  };

  return (
    <Fragment>
      <Sidebar />
      <ToastContainer />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Campaign Package</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                  <li className="breadcrumb-item active">Campaign Package</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className='content'>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header text-right" >
                    <h3 className="card-title">Campaign Package List</h3>
                    <Link to="/manage-campaignpackage" className="btn btn-secondary">
                      Add New
                    </Link>
                  </div>

                  <div className="card-body">
                    <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4 table-responsive" >
                      <div className="row">
                        <div className="col-sm-3 col-md-3   ">
                          <div className='form-group'>
                            <label className='form-label col-sm-6'>TollKit Name</label>
                            <div className='col-sm-10'>
                              <select className='form-control' id="ToolKitName" name="ToolKitName" onChange={(e) => handleChangeTollKitFilter(e)}>
                                <option value=""> -- Select ToolKit -- </option>
                                {toolkititems.map((t) => <option value={t.Id}>{t.ToolKitName}</option>)}

                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-md-6">
                          <select onChange={(e) => ChangePageSize(e)}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                        </div>
                        <div className="col-sm-6 col-md-6 text-right">
                          <div id="example1_filter" className="dataTables_filter">
                            <label><input type="search" className="form-control form-control-sm" placeholder="" aria-controls="example1" onChange={ChangeFilterText} /></label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table id="example1" className="table table-bordered dataTable dtr-inline" aria-describedby="example1_info">
                            <thead>
                              <tr>
                                <th className="sorting sorting_asc" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="ID: activate to sort column descending">ID</th>
                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="ToolkitName: activate to sort column ascending">Toolkit Name</th>
                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="CampaignCountyName: activate to sort column ascending">Campaign County Name</th>
                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="ImpressionDetail: activate to sort column ascending">Impression Detail</th>
                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Is Active: activate to sort column ascending">Is Active</th>
                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="CreatedDate: activate to sort column ascending">Created Date</th>
                                <th tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Action">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {items.length > 0 ? items.map((item) => (
                                <tr className="odd">
                                  <td className="dtr-control sorting_1" tabIndex="0">{item.Id}</td>
                                  <td>{item.ToolKit}</td>
                                  <td>{item.CountryCode}</td>
                                  <td>{item.ImpressionDetails}</td>
                                  <td><span className={item.IsActive ? 'right badge badge-success' : 'right badge badge-danger'}>{item.IsActive ? "Yes" : "No"}</span></td>
                                  <td>{formatDate(item.CreatedDate)}</td>
                                  <td>
                                    <Link to={"/manage-campaignpackage/" + item.Id} className="btn btn-success btn-sm mb-2 mr-2" title='Edit'>
                                      <i className="fas fa-pencil-alt"></i>
                                    </Link>
                                  </td>
                                </tr>
                              )) : <tr>
                                <td colSpan={7} align='center'>Data not found!</td>
                              </tr>}

                            </tbody>

                          </table>

                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12 col-md-5' key={remountComponent}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={items.length > 0 ? pageCount : 0}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            pageClassName={"page-link"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- /.card-body --> */}
                </div>
                {/* <!-- /.card --> */}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  )
}

export default CampaignPackageDatatableLists