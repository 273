import React, { useState } from 'react';
import loadermage from '../../src/img/loader.gif'

const CustomLoader = ({ loading }) => {
    return (
        <section className={loading ? "" : "d-none"}>
            <div class="loader" id="AjaxLoader">
                <div class="strip-holder">
                    <img src={loadermage} className="loader-img-size" />
                </div>
            </div>
        </section>
    );
};


export default CustomLoader;