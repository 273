import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../Layout/footer/Footer';
import Sidebar from '../../Layout/sidebar/Sidebar';

import { Modal } from 'react-bootstrap';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import { ToastContainer } from 'react-toastify';
import { toastError, toastSuccess } from '../../common/Toast';
import config from '../../config/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomLoader from "../../Layout/CustomLoader";

const EmailTemplateDatatableLists = () => {

    const [statee, setSatete] = useState({ visible: null });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [perPageSize, setperPageSize] = useState(10);
    const [searchtext, setSearchText] = useState("");
    const [totalrecord, setTotalRecord] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [remountComponent, setRemountComponent] = useState(0);

    const handleApi = (pagenumber, pagesize, filtertext, ordercolumn, currentorder) => {
        // if (!loading === true) {
            setLoading(true);
            axios.get(`${config.baseUrl}/emailtemplate?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}`)
                .then((response) => {
                    setItems(response.data.ResponseData);
                    setTotalRecord(response.data.ResponseData[0].TotalRecord);
                    setpageCount(Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize));
                    setLoading(false);
                }).catch((err) => {
                    console.log(err)

                    setError(err);
                    setLoading(false);
                })
        // }
        // else {
        //     toastError("Somthing went wrong!");
        // }
    }

    useEffect(() => {
        handleApi(1, perPageSize, "", 0, "asc");
    }, [])

    const changePage = ({ selected }) => {
        selected = selected + 1;
        handleApi(selected, perPageSize, searchtext, 0, "asc");
    };

    const ChangeFilterText = (e) => {
        setSearchText(e.target.value);
        setRemountComponent(Math.random());
        handleApi(1, perPageSize, e.target.value, 0, "asc");
    }

    const handleclosepopup = () => {
        setSatete({ visible: null })
        localStorage.setItem("DeletedID", 0);
    }

    const handleModelpopup = (id) => {
        localStorage.setItem("DeletedID", id);
        setSatete({ visible: id })
    }


    const handleDelete = () => {
        let id = localStorage.getItem("DeletedID");
        let loginuserID = localStorage.getItem("ID");

        setSatete({ visible: null })
        if (id > 0) {

            // http://127.0.0.1:3000/api/v1/emailtemplate/delete 
            setLoading(true);
            axios.post(`${config.baseUrl}/emailtemplate/delete`, {
                id: id,
                deletedby: loginuserID
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then((res) => {
                    if (res.data.ResponseCode === "SUCCESS" && res.data.ResponseID > 0) {
                        toastSuccess('Record has been deleted!');
                        handleApi(1, perPageSize, "", 0, "asc");
                    }
                    else {
                        toastError("Enable to delete a record");
                    }
                    setLoading(false);
                }).catch((error) => {
                    console.log("error occured", error);
                    setLoading(false);
                });
        }
    };

    const formatDate = (string) => {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([], options);
    }

    const ChangePageSize = (e) => {
        setperPageSize(e.target.value);
        handleApi(1, e.target.value, searchtext, 0, "asc");
    }

    return (
        <Fragment>
            <Sidebar />
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Email Template</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">Email Template</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header text-right" >
                                        <h3 className="card-title">Email Template List</h3>
                                        <Link to="/manage-emailtemplate" className="btn btn-secondary">
                                            Add New
                                        </Link>
                                    </div>

                                    <div className="card-body">
                                        <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4 table-responsive" >
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6">
                                                    <select className='' onChange={ChangePageSize}>
                                                        <option value="10">10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </div>
                                                <div className="col-sm-12 col-md-12 text-right">
                                                    <div id="example1_filter" className="dataTables_filter">
                                                        <label><input type="search" className="form-control form-control-sm" placeholder="" aria-controls="example1" onChange={ChangeFilterText} /></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="example1" className="table table-bordered dataTable dtr-inline" aria-describedby="example1_info">
                                                        <thead>
                                                            <tr>
                                                                <th className="sorting sorting_asc" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="ID: activate to sort column descending">ID</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="EmailType: activate to sort column ascending">Email Type</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="EmailSubject: activate to sort column ascending">Email Subject</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Is Active: activate to sort column ascending">Is Active</th>
                                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="CreatedOn: activate to sort column ascending">Created On</th>
                                                                <th tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Action">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {items.length > 0 ? items.map((item) => (
                                                                <tr className="odd">
                                                                    <td className="dtr-control sorting_1" tabIndex="0">{item.ID}</td>
                                                                    <td>{item.EmailType}</td>
                                                                    <td>{item.EmailSubject}</td>
                                                                    <td><span className={item.IsActive ? 'right badge badge-success' : 'right badge badge-danger'}>{item.IsActive ? "Yes" : "No"}</span></td>
                                                                    <td>{formatDate(item.CreatedDate)}</td>
                                                                    <td>
                                                                        <Link to={"/manage-emailtemplate/" + item.ID} className="btn btn-success btn-sm mb-2 mr-2 " title='Edit'>
                                                                            <i className="fas fa-pencil-alt"></i>
                                                                        </Link>

                                                                        <button type="button" title='Delete' className="btn btn-danger btn-sm mb-2 mr-2 " onClick={() => handleModelpopup(item.ID)}><i className="fas fa-trash-alt"></i></button>

                                                                        <Modal show={statee.visible === item.ID}>
                                                                            <div className="modal fade show" style={{ display: "block", paddingRight: "17px" }} aria-modal="true" role="dialog">
                                                                                <div className="modal-dialog modal-md">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header">
                                                                                            <h4 className="modal-title">Confirmation</h4>
                                                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => handleclosepopup()}>
                                                                                                <span aria-hidden="true">×</span>
                                                                                            </button>
                                                                                        </div>

                                                                                        <div className="modal-body">
                                                                                            <p>Are you sure you want to remove this item.</p>
                                                                                        </div>
                                                                                        <div className="modal-footer text-right">
                                                                                            <button type="button" className="btn btn-primary" onClick={() => handleDelete()}>Confirm</button>
                                                                                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => handleclosepopup()}>Cancel</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Modal>
                                                                    </td>
                                                                </tr>
                                                            )) : <tr>
                                                                <td colSpan={6} align='center'>Data not found!</td>
                                                            </tr>}

                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-5' key={remountComponent}>
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={items.length > 0 ? pageCount : 0}
                                                        onPageChange={changePage}
                                                        containerClassName={"pagination"}
                                                        pageClassName={"page-link"}
                                                        previousLinkClassName={"page-link"}
                                                        nextLinkClassName={"page-link"}
                                                        activeClassName={"active"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- /.card-body --> */}
                                </div>
                                {/* <!-- /.card --> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </Fragment>
    )
}

export default EmailTemplateDatatableLists