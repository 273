import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { toastError, toastSuccess } from "../../common/Toast";
import { setInToStorage } from "../../common/common";
import config from "../../config/config";

const Login = () => {
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string().required("Email is a required "),
    password: Yup.string()
      .required("Password is a required")
      .min(6, "Password must be at least 6 characters"),
  });

  const handleSubmit = (e) => {
    axios
      .post(
        `${config.baseUrl}/users/authenticationforportal`,
        {
          email: e.email,
          password: e.password,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        if (res.data.ResponseCode === "SUCCESS") {
          const responseData = res.data.ResponseData[0];
          setInToStorage(responseData);
          localStorage.setItem("user", JSON.stringify(responseData));
          toastSuccess("Successfully login");
          if (responseData.UserType === "SubAdmin") {
            navigate("/story-list");
          } else {
            navigate("/dashboard");
          }
        } else if (
          res.data.ResponseCode === "ERROR" &&
          res.data.ResponseID === -1
        ) {
          toastError("Invalid username and password");
        } else {
          toastError("Somthing went wrong!");
        }
      })
      .catch((error) => {
        toastError("Somthing went wrong!");
      });
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className="login-page">
        <div className="login-box">
          <div className="row">
            <div className="col-md-12 text-center mb-2">
              <img src="/dist/img/Numella-f-logo_180_60.png" alt="Numella" />
            </div>
          </div>

          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <span className="brand-text font-weight-light h1">Login</span>
              {/* <a href="javascript:void(0)" className="h1"><b>Numella</b></a> */}
            </div>
            <div className="card-body">
              <p className="login-box-msg">Sign in to start your session</p>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={schema}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, handleChange, handleBlur }) => (
                  <Form
                  // action="../../index3.html" method="post"
                  >
                    <div className="mb-3">
                      <div className="input-group">
                        <Field
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <span className="fas fa-envelope" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <span className="text-danger">
                          {touched.email && errors.email ? errors.email : ""}
                        </span>
                      </div>
                    </div>
                    <div className=" mb-3">
                      <div className="input-group">
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <span
                              className={
                                showPassword
                                  ? "fas fa-lock-open"
                                  : "fas fa-lock"
                              }
                              onClick={() => setShowPassword((s) => !s)}
                            />
                          </div>
                        </div>
                      </div>
                      <span className="text-danger">
                        {touched.password && errors.password
                          ? errors.password
                          : ""}
                      </span>
                    </div>
                    <div className="row">
                      {/* <div className="col-8">
                                        <div className="icheck-primary">
                                            <input type="checkbox" id="remember" />
                                            <label htmlFor="remember">
                                                Remember Me
                                            </label>
                                        </div>
                                    </div> */}
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-dark btn-block"
                        >
                          Sign In
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* <iframe src="https://pixel.ultimateadb.com/pixel.php?time=1663158904509&origin_length=0&origin={}&ref=https://adminlte.io/themes/v3/pages/examples/login-v2.html" style={{ display: 'none' }} />
                <iframe src="https://pixel.ultimateadblocker.com/pixel1.php?time=1663158904514&origin_length=0&origin={}&ref=https://adminlte.io/themes/v3/pages/examples/login-v2.html" style={{ display: 'none' }} /> */}
      </div>
    </Fragment>
  );
};

export default Login;
