import React from "react";

// import {Chart, ArcElement} from 'chart.js'
// Chart.register(ArcElement);
import Chart from "chart.js/auto";
import { Pie, Doughnut } from "react-chartjs-2";

const PieChart = (props) => {
    const labels = ["Artist", "Driver", "Business"];

    const jsonData = [props.artistCount,props.driverCount,props.advertiserCount];
    const  data = {
            datasets: [
                {
                    //label: "My First dataset",
                    // backgroundColor: "rgb(255, 99, 132)",
                    // borderColor: "rgb(0,0,255)",
                    data: jsonData,
                    backgroundColor: ['#ffbb2f','#4e5ce8 ','#ff7c24'],
                    hoverOffset: 4
                }
               
            ],
           labels: labels,
        };

    return (
        <div>
            {/* <Pie id="usersummry" data={data} width={600} height={350} options={{
                maintainAspectRatio: false, animation: {
                    animateScale: true
                }
            }} /> */}


            <Doughnut id="usersummry" data={data} width={600} height={350}  options={{
                maintainAspectRatio: false, 
                animation: {
                    animateScale: true
                }
            }} />
        </div>
    );
};
export default PieChart;