import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Footer from "../../Layout/footer/Footer";
import { ToastContainer } from "react-toastify";
import Sidebar from "../../Layout/sidebar/Sidebar";
import ReactPaginate from "react-paginate";
import { toastError, toastSuccess } from "../../common/Toast";
import config from "../../config/config";
import CustomLoader from "../../Layout/CustomLoader";
import "bootstrap/dist/css/bootstrap.min.css";

const TransactionHistoryList = () => {
  const [statee, setSatete] = useState({ visible: null });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [perPageSize, setperPageSize] = useState(10);
  const [searchtext, setSearchText] = useState("");
  const [totalrecord, setTotalRecord] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [remountComponent, setRemountComponent] = useState(0);

  const changePage = ({ selected }) => {
    selected = selected + 1;
    handleApi(selected, perPageSize, searchtext);
  };

  const ChangeFilterText = (e) => {
    setSearchText(e.target.value);
    setRemountComponent(Math.random());
    handleApi(1, perPageSize, e.target.value);
  };

  const ChangePageSize = (e) => {
    setperPageSize(e.target.value);
    handleApi(1, e.target.value, searchtext);
  };

  const formatDate = (string) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };

  const handleApi = (pagenumber, pagesize, filtertext) => {
    setLoading(true);
    axios
      .get(
        `${config.baseUrl}/account/getalltransactiondetails?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}`
      )
      .then((response) => {
        setItems(response.data.ResponseData);
        setTotalRecord(response.data.ResponseData[0].TotalRecord);
        setpageCount(
          Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize)
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setLoading(false);
      });
  };

  const handleclosepopup = () => {
    setSatete({ visible: null });
    localStorage.setItem("ChargedId", 0);
  };

  const handleModelpopup = (id, chargeid) => {
    localStorage.setItem("ChargedId", chargeid);
    setSatete({ visible: id });
  };

  const handleRefund = (chargeid) => {
    let loginuserID = localStorage.getItem("ID");
    setSatete({ visible: null });
    if (chargeid != "") {
      setLoading(true);
      axios
        .post(
          `${config.baseUrl}/account/refundstripepaymentcharge`,
          {
            chargeid: chargeid,
            createdby: loginuserID,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (
            res.data.ResponseCode === "SUCCESS" &&
            res.data.ResponseID === 0
          ) {
            toastSuccess("Refund Successfull!");
            handleApi(1, perPageSize, "");
          } else {
            toastError("Enable to refund");
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    handleApi(1, perPageSize, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Sidebar />
      <ToastContainer />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Transaction History</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Transaction History
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header text-right">
                    <h3 className="card-title">Transaction History</h3>
                  </div>

                  {/* <!-- /.card-header --> */}

                  <div className="card-body">
                    <div
                      id="example1_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 table-responsive "
                    >
                      <div className="row">
                        <div className="col-sm-6 col-md-6">
                          <select className="" onChange={ChangePageSize}>
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                        </div>

                        <div className="col-sm-6 col-md-6 text-right">
                          <div
                            id="example1_filter"
                            className="dataTables_filter"
                          >
                            <label>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="example1"
                                onChange={ChangeFilterText}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <table
                            id="example1"
                            className="table table-bordered dataTable dtr-inline"
                            aria-describedby="example1_info"
                          >
                            <thead>
                              <tr>
                                <th
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Action"
                                >
                                  Action
                                </th>
                                <th
                                  className="sorting sorting_asc"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                  aria-label="ID: activate to sort column descending"
                                >
                                  ID
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="PaymentId: activate to sort column ascending"
                                >
                                  Payment Id
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="PaymentObject: activate to sort column ascending"
                                >
                                  Payment Object
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="ChargeId: activate to sort column ascending"
                                >
                                  Charge Id
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="ChargeObject: activate to sort column ascending"
                                >
                                  Charge Object
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="ChargeAmount: activate to sort column ascending"
                                >
                                  Charge Amount(Cent)
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Transaction: activate to sort column ascending"
                                >
                                  Transaction
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Currency: activate to sort column ascending"
                                >
                                  Currency
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="CustomerId: activate to sort column ascending"
                                >
                                  Customer Id
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Amount: activate to sort column ascending"
                                >
                                  Amount
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Paid: activate to sort column ascending"
                                >
                                  Paid
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="PaymentIntent: activate to sort column ascending"
                                >
                                  Payment Intent
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="PaymentMethod: activate to sort column ascending"
                                >
                                  Payment Method
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="ReceiptEmail: activate to sort column ascending"
                                >
                                  Receipt Email
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="ReceiptNumber: activate to sort column ascending"
                                >
                                  Receipt Number
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Status: activate to sort column ascending"
                                >
                                  Status
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="CreatedDate: activate to sort column ascending"
                                >
                                  Created Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="example1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="CreatedBY: activate to sort column ascending"
                                >
                                  Created BY
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {items.length > 0 ? (
                                items.map((item) => (
                                  <tr className="odd">
                                    <td>
                                      <a href={item.ReceiptURL} target="_blank">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-sm mb-2"
                                        >
                                          Recipt
                                        </button>
                                      </a>
                                      {item.RefundStatus != "succeeded" ? (
                                        <>
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-sm mb-2"
                                            onClick={() =>
                                              handleModelpopup(
                                                item.ID,
                                                item.ChargeId
                                              )
                                            }
                                          >
                                            Refund
                                          </button>
                                          <Modal
                                            show={statee.visible === item.ID}
                                          >
                                            <div
                                              className="modal fade show"
                                              style={{
                                                display: "block",
                                                paddingRight: "17px",
                                              }}
                                              aria-modal="true"
                                              role="dialog"
                                            >
                                              <div className="modal-dialog modal-md">
                                                <div className="modal-content">
                                                  <div className="modal-header">
                                                    <h4 className="modal-title">
                                                      Confirmation
                                                    </h4>
                                                    <button
                                                      type="button"
                                                      className="close"
                                                      data-dismiss="modal"
                                                      aria-label="Close"
                                                      onClick={() =>
                                                        handleclosepopup()
                                                      }
                                                    >
                                                      <span aria-hidden="true">
                                                        ×
                                                      </span>
                                                    </button>
                                                  </div>

                                                  <div className="modal-body">
                                                    <p>
                                                      Are you sure you want to
                                                      refund.
                                                    </p>
                                                  </div>
                                                  <div className="modal-footer text-right">
                                                    <button
                                                      type="button"
                                                      className="btn btn-primary"
                                                      onClick={() =>
                                                        handleRefund(
                                                          item.ChargeId
                                                        )
                                                      }
                                                    >
                                                      Yes
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-default"
                                                      data-dismiss="modal"
                                                      onClick={() =>
                                                        handleclosepopup()
                                                      }
                                                    >
                                                      No
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Modal>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td
                                      className="dtr-control sorting_1"
                                      tabIndex="0"
                                    >
                                      {item.ID}
                                    </td>
                                    <td>
                                      {item.RefundStatus == "succeeded" ? (
                                        <>
                                          {item.PaymentIntentId}
                                          <br />
                                          <small className="text-danger font-weight-bold">
                                            Rf.: {item.RefundId}
                                          </small>
                                        </>
                                      ) : (
                                        <>{item.PaymentIntentId}</>
                                      )}
                                    </td>
                                    <td>{item.PaymentIntentObject}</td>
                                    <td>{item.ChargeId}</td>
                                    <td>{item.ChargeObject}</td>
                                    <td className="custom-tr-width">
                                      {item.ChargeAmount}
                                    </td>
                                    <td>
                                      {item.RefundStatus == "succeeded" ? (
                                        <>
                                          {item.BalanceTransaction}
                                          <br />
                                          <small className="text-danger font-weight-bold">
                                            Rf.: {item.RefundTransactionId}
                                          </small>
                                        </>
                                      ) : (
                                        <>{item.BalanceTransaction}</>
                                      )}
                                    </td>
                                    <td>{item.Currency}</td>
                                    <td>{item.CustomerId}</td>
                                    <td>{item.Amount}</td>
                                    <td>
                                      {item.Paid === "true" ? (
                                        <span>True</span>
                                      ) : item.Paid === "false" ? (
                                        <span>False</span>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>{item.PaymentIntent}</td>
                                    <td>{item.PaymentMethod}</td>
                                    <td>
                                      {item.ReceiptEmail === "null" ? "" : ""}
                                    </td>
                                    <td>
                                      {item.ReceiptNumber === "null" ? "" : ""}
                                    </td>
                                    <td>
                                      {item.RefundStatus == "succeeded" ? (
                                        <>
                                          {item.Status}
                                          <br />
                                          <small className="text-danger font-weight-bold">
                                            Refunded
                                          </small>
                                        </>
                                      ) : (
                                        <>{item.Status}</>
                                      )}
                                    </td>
                                    <td>{formatDate(item.CreatedDate)}</td>
                                    <td>{item.CreatedBy}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={19} align="center">
                                    Data not found!
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-sm-12 col-md-5"
                          key={remountComponent}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={items.length > 0 ? pageCount : 0}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            pageClassName={"page-link"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- /.card-body --> */}
                </div>
                {/* <!-- /.card --> */}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
};

export default TransactionHistoryList;
