import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import { toastError } from '../../../common/Toast';
import Footer from '../../../Layout/footer/Footer'
import Sidebar from '../../../Layout/sidebar/Sidebar'
import config from '../../../config/config';
import YearWiseBarChart from './YearWiseBarChart'
import MounthWiseBarChart from './MounthWiseBarChart';
import CustomLoader from "../../../Layout/CustomLoader";

const UserReports = (props) => {
    // date-picker
    let date = new Date();
    let month = date.getMonth() + 1;
    let endDate = date.getFullYear() + '-' + (month >= 10 ? month : '0' + month) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : + date.getDate());
    let startDate = date.getFullYear() + '-' + (month >= 10 ? month : '0' + month) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : + date.getDate());
    // let startDate = date.getFullYear() + '-' + (month >= 10 ? date.getMonth() : '0' + date.getMonth()) + '-' + date.getDate();
    // let endDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

    const minOffset = 0;
    const maxOffset = 5;
    const currentyear = (new Date()).getFullYear();
    const [presentYear, setPresentYear] = useState(currentyear);
    const [selectedYear, setSelectedYear] = useState(currentyear);
    const [yearwise, setYearwise] = useState([]);
    const [mounthwise, setmounthwise] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [datefromchange, setDatefromchange] = useState(startDate);
    const [datetochange, setDatetochange] = useState(endDate);

    const handleYearList = (e) => {
        setSelectedYear(e.target.value);
    }

    const options = [];
    for (let i = minOffset; i <= maxOffset; i++) {
        const year = presentYear - i;
        options.push(<option value={year}>{year}</option>);
    }

    const handleApi = (year) => {
        if (!loading === true) {
            setLoading(true);
            axios.get(`${config.baseUrl}/report/yearwiseusercount?year=${year}`)
                .then((response) => {
                    let responseObjData = response.data.ResponseData;

                    setYearwise(responseObjData);
                    setLoading(false);
                }).catch((err) => {
                    console.log(err)
                    setError(err);
                    setLoading(false);
                })
        }
        else {
            toastError("Somthing went wrong!");
        }
    }

    const handleChartUser = (fromdate, todate) => {
        if (!loading === true) {
            setLoading(true);
            axios.get(`${config.baseUrl}/report/datewiseusercount?fromdate=${fromdate}&todate=${todate}`)
                .then((response) => {
                    let responseObjData = response.data.ResponseData;
                    setmounthwise(responseObjData);
                    setLoading(false);
                }).catch((err) => {
                    console.log(err)
                    setError(err);
                    setLoading(false);
                })
        }
        else {
            toastError("Somthing went wrong!");
        }
    }

    const handleYearChangeClick = () => {
        handleApi(selectedYear);
    }

    const handleFromDate = (e) => {
        setDatefromchange(e.target.value)
    }
    const handleToDate = (e) => {
        setDatetochange(e.target.value)
    }

    const handleDateChange = () => {
        handleChartUser(datefromchange, datetochange);
    }

    useEffect(() => {
        handleApi(selectedYear);
        handleChartUser(datefromchange, datetochange);
    }, [])

    return (
        <Fragment>
            <Sidebar />
            <ToastContainer />
            <CustomLoader loading={loading} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>User Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">User Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-dark">
                                    <div className="card-header">
                                        <h3 className="card-title">User's Monthly Report</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <Formik  {...props}
                                            // initialValues={formValues}
                                            enableReinitialize>
                                            {/* {({
                                                handleChange,
                                                errors,
                                                touched
                                            }) => ( */}
                                            <Form>
                                                <div className="row">
                                                    <div className="col-sm-3 col-md-3">
                                                        <div className='form-group'>
                                                            <label className='form-label col-sm-6'>Year</label>
                                                            <div className='col-sm-10'>
                                                                <Field as="select" id="Year" name="Year" className='form-control' value={selectedYear} onChange={(e) => handleYearList(e)} >
                                                                    {/* <option value="">2022</option>
                                                                    <option value="2021">2021</option>
                                                                     */}
                                                                    {options}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 col-md-3 mt-4">
                                                        <button type="button" className="btn btn-primary mt-2" onClick={handleYearChangeClick}>Search</button>
                                                    </div>
                                                </div>
                                            </Form>

                                        </Formik>

                                        <YearWiseBarChart userData={yearwise} />

                                        {/* /.card-body */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-dark">
                                    <div className="card-header">
                                        <h3 className="card-title">User's Daily Report</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <Formik {...props}
                                            enableReinitialize >
                                            <Form>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>From Date</label>
                                                            <Field type="date" className="form-control" name="fromdate" id="fromdate" dateformat="yyyy/mm/dd" value={datefromchange} onChange={(e) => handleFromDate(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>To Date</label>
                                                            <Field type="date" className="form-control" name="todate" id="todate" dateformat="yyyy/mm/dd" value={datetochange} onChange={(e) => handleToDate(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 col-md-3 mt-4">
                                                        <button type="button" className="btn btn-primary mt-2" onClick={handleDateChange}>Search</button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Formik>
                                        <MounthWiseBarChart userData={mounthwise} />

                                        {/* /.card-body */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >

            <Footer />
        </Fragment >
    )
}

export default UserReports