import React from "react"
import { Navigate, Outlet } from "react-router-dom"

const useAuth = () => {
	//get item from localstorage

	let user = "";

	const _user = localStorage.getItem("user")
	const role = "Admin"

	if (_user) {
		user = JSON.parse(_user)
	}
	if (user) {
		return {
			auth: true,
			role: role, //user.UserType //role,
		}
	} else {
		return {
			auth: false,
			role: null,
		}
	}
}

//protected Route state
const ProtectedRouteType = {
	roleRequired: "Admin"// "ADMIN" | "USER"
}

const ProtectedRoutes = (props, ProtectedRouteType) => {
	const { auth, role } = useAuth()
	// // //if the role required is there or not
	// if (props.roleRequired) {
	// 	return auth ? (
	// 		props.roleRequired === role ? (
	// 			<Outlet />
	// 		) : (
	// 			<Navigate to="/denied" />
	// 		)
	// 	) : (
	// 		<Navigate to="/login" />
	// 	)
	// } else {
	// 	return auth ? <Outlet /> : <Navigate to="/login" />
	// }

	return auth ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoutes
