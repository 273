import React, { Fragment, useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../../Layout/footer/Footer";
import Sidebar from "../../Layout/sidebar/Sidebar";
import Select from "react-select";
import config from "../../config/config";
import { toastSuccess, toastError } from "../../common/Toast";
import { ToastContainer } from "react-toastify";
import CustomLoader from "../../Layout/CustomLoader";


const AddFreeImpression = () => {
  const navigate = useNavigate();
  const paramObject = useParams();

  const [loading, setLoading] = useState(false);
  const [dropdownitems, setDropDownItems] = useState([]);
  const [selectedUserValues, setSelectedUserValues] = useState([]);
  const [impression, setImpression] = useState(0);

  const validationSchema = Yup.object().shape({
    selectUser: Yup.array().min(1, "Please select at least one user"),
    impression: Yup.number().required("Impression is required"),
  });

  useEffect(() => {
    handleUserDropdownApi();

    if (paramObject.id > 0) {
      setSelectedUserValues([{ value: paramObject.id, label: paramObject.name }]);
    }
  }, []);

  const handleUserDropdownApi = () => {
    if (!loading) {
      setLoading(true);
      axios
        .get(`${config.baseUrl}/users/fordropdown`)
        .then((response) => {
          const dropdownItemOptions = response.data.ResponseData.map((option) => ({
            label: option.FullName,
            value: option.ID,
          }));
          setDropDownItems(dropdownItemOptions);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const userIds = values.selectUser.map((user) => user.value);
    axios
      .post(`${config.baseUrl}/account/addAdminFreeImpression`, {
        userids: userIds,
        impression: values.impression,
      },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.ResponseCode === "SUCCESS") {
          toastSuccess(res.data.ResponseMessage);
          navigate("/free-impression");
        } else {
          toastError("Something went wrong: " + res.data.ResponseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error occurred", error);
        setLoading(false);
      });
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      height: "100%",
      position: "relative",
      background: "#343a40",
      color: "white",
    }),
    menu: () => ({
      boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
      position: "absolute",
      background: "#343a40",
      width: "100%",
      color: "white",
      zIndex: 99,
    }),
  };
  

  return (
    <Fragment>
      <Sidebar />
      <ToastContainer />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        {/* ... Your other code ... */}
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header text-right">
                <h3 className="card-title">Add Free Impression</h3>
              </div>
              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  selectUser: selectedUserValues,
                  impression: impression,
                }}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, touched, handleChange, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label>Select Users</label>
                          <Select
                            id="selectUser"
                            name="selectUser"
                            backspaceRemovesValue={false}
                            controlShouldRenderValue={true}
                            hideSelectedOptions={true}
                            isClearable={true}
                            options={dropdownitems}
                            placeholder="Please select Users"
                            styles={selectStyles}
                            value={selectedUserValues}
                            isMulti
                            onChange={(selectedOptions) => {
                              setSelectedUserValues(selectedOptions);
                              setFieldValue("selectUser", selectedOptions);
                            }}
                            onBlur={() => setFieldTouched("selectUser", true)}
                          />
                          <span className="text-danger">
                            {touched.selectUser && errors.selectUser ? errors.selectUser : ""}
                          </span>
                        </div>
                        <div className="form-group col-md-6">
                          <label>Impression</label>
                          <Field
                            type="number"
                            className="form-control"
                            id="impression"
                            name="impression"
                          />
                          <span className="text-danger">
                            {touched.impression && errors.impression ? errors.impression : ""}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="button-container">
                            <button type="submit" className="btn me-2 btn-primary">
                              Save
                            </button>
                            <Link to="/free-impression" className="btn btn-secondary">
                              Cancel
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default AddFreeImpression;
