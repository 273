import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Fragment, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { toastError } from "../../common/Toast";
import config from "../../config/config";
import Footer from "../../Layout/footer/Footer";
import Sidebar from "../../Layout/sidebar/Sidebar";
import CityChart from "./CityChart";
import CountrySummaryChart from "./CountrySummaryChart";
import CustomLoader from "../../Layout/CustomLoader";

const CampaignDetails = () => {
  const paramObject = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  //const [items, setItems] = useState([]);
  const [perPageSize, setperPageSize] = useState(10);
  const [feedbackCampaignPerPageSize, setFeedbackCampaignperPageSize] =
    useState(10);
  const [searchtext, setSearchText] = useState("");
  const [feedbackcampaignsearchtext, setFeedbackCampaignSearchText] =
    useState("");

  const [playedusersearchtext, setPlayedUserSearchText] = useState("");
  const [playedUsertotalrecord, setPlayedUserTotalRecord] = useState(0);
  const [playedUserpageCount, setPlayedUserpageCount] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [feedbackpageCount, setFeedbackpageCount] = useState(0);
  const [playedUserperPageSize, setplayedUserperPageSize] = useState(10);
  const [campaginreview, setCampaginreview] = useState([]);
  const [FeedbackCampaginreview, setFeedbackCampaginreview] = useState([]);
  const [totalrecord, setTotalRecord] = useState(0);
  const [feedbacktotalrecord, setFeedbackTotalRecord] = useState(0);
  const [campaigndetails, setCampaignDetails] = useState([]);
  const [countrychartdetail, setCountryChartDetails] = useState([]);
  const [citychartdetail, setCityChartDetails] = useState([]);
  const [playeduserdetail, setPlayedUserDetails] = useState([]);
  const [setplayCount, setPlayCount] = useState({
    CampaginName: "",
    PlayCount: "",
    CallToActionCount: "",
    RemainingImpressionCount: "",
    reviewcount: "",
  });
  const [campaignId, setCampaignID] = useState(0);
  const [remountComponent, setRemountComponent] = useState(0);
  const [
    remountFeedbackCampaignComponent,
    setFeedbackCampaignRemountComponent,
  ] = useState(0);

  useEffect(() => {
    if (paramObject.id > 0) {
      setCampaignID(paramObject.id);
      handleApi(paramObject.id);
      BindPlayedUser(1, playedUserperPageSize, "", 0, "asc", paramObject.id);
      BindCampaginReview(1, perPageSize, "", 0, "asc", paramObject.id);
    }
  }, []);

  const handleApi = (campaignid) => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(
          `${config.baseUrl}/campaign/getcampaigndatailslist?campaignid=${campaignid}`
        )
        .then((response) => {
          const CampaignDetails = response.data.ResponseData.CampaignDetails;
          const CityWisePlayCount =
            response.data.ResponseData.CityWisePlayCount;
          const CountryWisePlayCount =
            response.data.ResponseData.CountryWisePlayCount;
          const PlayedUsers = response.data.ResponseData.PlayedUsers[0];
          const ReviewCount = response.data.ResponseData.ReviewCount[0];
          setCampaignDetails(CampaignDetails);
          //setCampaginreview(response.data.ResponseData.CampaignReview);
          const { PlayCount, CallToActionCount, RemainingImpressionCount } =
            PlayedUsers;
          const reviewcount = ReviewCount.ReviewCount;
          const CampaginName = CampaignDetails[0].CampaignName;
          CountryWisePlayCount.forEach((q) => {
            let maxVal = 0xffffff;
            let randomNumber = Math.random() * maxVal;
            randomNumber = Math.floor(randomNumber);
            let randColor = randomNumber.toString(16);
            q["color"] = "#" + randColor;
          });
          setCountryChartDetails(CountryWisePlayCount);
          CityWisePlayCount.forEach((q) => {
            let maxVal = 0xffffff;
            let randomNumber = Math.random() * maxVal;
            randomNumber = Math.floor(randomNumber);
            let randColor = randomNumber.toString(16);
            q["color"] = "#" + randColor;
          });
          setCityChartDetails(CityWisePlayCount);
          setPlayCount({
            CampaginName,
            PlayCount,
            CallToActionCount,
            RemainingImpressionCount,
            reviewcount,
          });

          if (CampaignDetails[0]?.ToolKitId == 12) {
            BindFeedbackCampaginReview(
              1,
              feedbackCampaignPerPageSize,
              "",
              0,
              "desc",
              paramObject.id
            );
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const BindPlayedUser = (
    pagenumber,
    pagesize,
    filtertext,
    ordercolumn,
    currentorder,
    campaignid
  ) => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(
          `${config.baseUrl}/campaign/campaignwiseplayeduser?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&campaignid=${campaignid}`
        )
        .then((response) => {
          setPlayedUserDetails(response.data.ResponseData);
          if (response.data.ResponseData.length > 0) {
            setPlayedUserTotalRecord(response.data.ResponseData[0].TotalRecord);
            setPlayedUserpageCount(
              Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize)
            );
          }

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const BindCampaginReview = (
    pagenumber,
    pagesize,
    filtertext,
    ordercolumn,
    currentorder,
    campaignid
  ) => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(
          `${config.baseUrl}/campaign/getreview?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&campainId=${campaignid}&userId=${0}`
        )
        .then((response) => {
          setCampaginreview(response.data.ResponseData);
          if (response.data.ResponseData.length > 0) {
            setTotalRecord(response.data.ResponseData[0].TotalRecord);
            setpageCount(
              Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize)
            );
          }

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const BindFeedbackCampaginReview = (
    pagenumber,
    pagesize,
    filtertext,
    ordercolumn,
    currentorder,
    campaignid
  ) => {
    if (!loading === true) {
      setLoading(true);

      axios
        .get(
          `${config.baseUrl}/campaign/getfeedbackcampaignreview?pagenumber=${pagenumber}&pagesize=${pagesize}&filtertext=${filtertext}&ordercolumn=${ordercolumn}&currentorder=${currentorder}&campaignid=${campaignid}`
        )
        .then((response) => {
          setFeedbackCampaginreview(response.data.ResponseData);
          if (response.data.ResponseData.length > 0) {
            setFeedbackTotalRecord(response.data.ResponseData[0].TotalRecord);
            setFeedbackpageCount(
              Math.ceil(response.data.ResponseData[0].TotalRecord / pagesize)
            );
          }

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setError(err);

          setLoading(false);
        });
    } else {
      toastError("Somthing went wrong!");
    }
  };

  const changePage = ({ selected }) => {
    selected = selected + 1;
    BindCampaginReview(selected, perPageSize, searchtext, 0, "asc", campaignId);
  };

  const changeFeedbackCampaignPage = ({ selected }) => {
    selected = selected + 1;
    BindFeedbackCampaginReview(
      selected,
      feedbackCampaignPerPageSize,
      feedbackcampaignsearchtext,
      0,
      "asc",
      campaignId
    );
  };

  const playedUserchangePage = ({ selected }) => {
    selected = selected + 1;
    BindPlayedUser(
      selected,
      playedUserperPageSize,
      playedusersearchtext,
      0,
      "asc",
      campaignId
    );
  };

  const playedUserChangePageSize = (e) => {
    setplayedUserperPageSize(e.target.value);
    BindPlayedUser(
      1,
      e.target.value,
      playedusersearchtext,
      0,
      "asc",
      campaignId
    );
  };

  const PlayeduserChangeFilterText = (e) => {
    setPlayedUserSearchText(e.target.value);
    BindPlayedUser(1, perPageSize, e.target.value, 0, "asc", campaignId);
  };

  const ChangeFilterText = (e) => {
    setSearchText(e.target.value);
    setRemountComponent(Math.random());
    BindCampaginReview(1, perPageSize, e.target.value, 0, "asc", campaignId);
  };

  const ChangeFeedbackFilterText = (e) => {
    setFeedbackCampaignSearchText(e.target.value);
    setFeedbackCampaignRemountComponent(Math.random());
    BindFeedbackCampaginReview(
      1,
      feedbackCampaignPerPageSize,
      e.target.value,
      0,
      "asc",
      campaignId
    );
  };

  const ChangePageSize = (e) => {
    setperPageSize(e.target.value);
    BindCampaginReview(1, e.target.value, searchtext, 0, "asc", campaignId);
  };

  const ChangeFeedbackPageSize = (e) => {
    setFeedbackCampaignperPageSize(e.target.value);
    BindFeedbackCampaginReview(
      1,
      e.target.value,
      feedbackcampaignsearchtext,
      0,
      "asc",
      campaignId
    );
  };

  const formatDate = (string) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };

  return (
    <Fragment>
      <Sidebar />
      <CustomLoader loading={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <ol className="breadcrumb float-md-left">
                  <li className="breadcrumb-item">
                    <h1>{setplayCount.CampaginName} Campaign Details</h1>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            {/* <!-- TABLE: Campaign Details --> */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title">Song</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="remove"
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  {/* <!-- /.card-header --> */}
                  <div className="card-body">
                    <div
                      id="example1_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 table-responsive"
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            id="example1"
                            className="table table-bordered dataTable dtr-inline"
                            aria-describedby="example1_info"
                          >
                            <thead>
                              <tr>
                                <th>Song Title</th>
                                <th>Genre Name</th>
                                {/* <th>Country</th> */}
                                <th>ArtWork</th>
                                <th>BillBoard</th>
                                <th>Music</th>
                                <th>Play Count</th>
                                {/* <th>Action</th> */}
                                {/* <th>ArtWorkImageName</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {campaigndetails.length > 0 ? (
                                campaigndetails.map((item) => (
                                  <tr className="odd">
                                    <td>{item.SongTitle}</td>
                                    <td>{item.GenreName}</td>
                                    {/* <td><img src="https://pixabay.com/photos/tree-sunset-clouds-sky-silhouette-736885/" style={{ width: "50px", height: "50px" }} /> </td>
                                                            <td><img src="https://pixabay.com/photos/tree-sunset-clouds-sky-silhouette-736885/" style={{ width: "50px", height: "50px" }} /></td> */}
                                    {/* <td>{item.CountryName}</td> */}
                                    <td>
                                      {item.ArtWorkImageName != "" &&
                                      item.ArtWorkImageName != undefined ? (
                                        <>
                                          <a
                                            href={item.ArtWorkImageName}
                                            target="_blank"
                                          >
                                            <img
                                              src={item.ArtWorkImageName}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                              }}
                                            />
                                          </a>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {item.BillBoardImage != "" &&
                                      item.BillBoardImage != undefined ? (
                                        <>
                                          <a
                                            href={item.BillBoardImage}
                                            target="_blank"
                                          >
                                            <img
                                              src={item.BillBoardImage}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                              }}
                                            />
                                          </a>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      <audio controls>
                                        <source
                                          src={item.MusicFileName}
                                          type="audio/mpeg"
                                        ></source>
                                      </audio>
                                    </td>
                                    <td> {item.PlayCount}</td>
                                    {/* <td>
                                                                        <audio controls>
                                                                            <source src={item.MusicFileName} type="audio/mpeg"></source>
                                                                        </audio>
                                                                    </td> */}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6} align="center">
                                    Data not found!
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /.card-body --> */}
                </div>
                {/* <!-- /.card --> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-info elevation-1">
                    <i className="fas fa-play-circle"></i>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">Played</span>
                    <span className="info-box-number">
                      {setplayCount.PlayCount}
                    </span>
                  </div>
                  {/* <!-- /.info-box-content --> */}
                </div>
                {/* <!-- /.info-box --> */}
              </div>
              {/* <!-- /.col --> */}
              <div className="col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-danger elevation-1">
                    <i className="fas fa-stopwatch"></i>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">Remaining</span>
                    <span className="info-box-number">
                      {setplayCount.RemainingImpressionCount}
                    </span>
                  </div>
                </div>
              </div>
              {/* <!-- /.col --> */}
              {/* <!-- fix for small devices only --> */}
              {/* <div className="clearfix hidden-md-up"></div> */}
              {/* <div className="col-md-3">
                                <div className="info-box">
                                    <span className="info-box-icon bg-success elevation-1"><i className="fas fa-user-circle"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Profile View</span>
                                        <span className="info-box-number">{setplayCount.CallToActionCount}</span>
                                    </div>
                                </div>
                            </div> */}
              {/* <!-- /.col --> */}
              <div className="col-12 col-sm- col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-warning elevation-1">
                    <i className="fas fa-registered"></i>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">Review</span>
                    <span className="info-box-number">
                      {setplayCount.reviewcount}
                    </span>
                  </div>
                  {/* <!-- /.info-box-content --> */}
                </div>
                {/* <!-- /.info-box --> */}
              </div>
              {/* <!-- /.col --> */}
            </div>
            {/* Charts */}
            <div className="row">
              <div className="col-md-6">
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Country Chart</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="remove"
                      >
                        <i className="fas fa-times" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <CountrySummaryChart countrydata={countrychartdetail} />
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">City Chart</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="remove"
                      >
                        <i className="fas fa-times" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <CityChart citydata={citychartdetail} />
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
            </div>
            {/* table */}
            <div className="row">
              <div className="col-md-4">
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title"> Played User</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="remove"
                      >
                        <i className="fas fa-times" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      id="example1_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 table-responsive"
                    >
                      <div className="row">
                        <div className="col-sm-6 col-md-6">
                          <select
                            className=""
                            onChange={playedUserChangePageSize}
                          >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                        </div>

                        <div className="col-sm-6 col-md-6 text-right">
                          <div
                            id="example1_filter"
                            className="dataTables_filter"
                          >
                            <label>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="example1"
                                onChange={PlayeduserChangeFilterText}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            id="example1"
                            className="table table-bordered dataTable dtr-inline"
                            aria-describedby="example1_info"
                          >
                            <thead>
                              <tr>
                                <th width="50px;">Image</th>
                                <th>Name</th>
                                <th width="200px;">City</th>
                              </tr>
                            </thead>
                            <tbody>
                              {playeduserdetail.length > 0 ? (
                                playeduserdetail.map((item) => (
                                  <tr className="odd">
                                    <td>
                                      {/* <a href={item.ProfileImage} target="_blank">
                                                                            <img src={item.ProfileImage} style={{ width: "50px", height: "50px" }} />
                                                                        </a> */}
                                      {item.ProfileImage != "" &&
                                      item.ProfileImage != undefined ? (
                                        <>
                                          <a
                                            href={item.ProfileImage}
                                            target="_blank"
                                          >
                                            <img
                                              src={item.ProfileImage}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                                borderRadius: "10px",
                                              }}
                                            />
                                          </a>
                                        </>
                                      ) : (
                                        <a
                                          href={"/dist/img/numella.png"}
                                          target="_blank"
                                        >
                                          <img
                                            src="/dist/img/numella.png"
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                          />
                                        </a>
                                      )}
                                    </td>
                                    <td>{item.FullName}</td>
                                    <td>{item.City}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={3} align="center">
                                    Data not found!
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-sm-6 col-md-6"
                          key={remountComponent}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={
                              playeduserdetail.length > 0
                                ? playedUserpageCount
                                : 0
                            }
                            onPageChange={playedUserchangePage}
                            containerClassName={"pagination"}
                            pageClassName={"page-link"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                      {/* <!-- /.table-responsive --> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* </div>
                        <div className="row"> */}
              <div className="col-md-8">
                {campaigndetails[0]?.ToolKitId !== 12 && (
                  <div className="card card-dark">
                    <div className="card-header">
                      <h3 className="card-title">Campaign Review</h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus" />
                        </button>
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="remove"
                        >
                          <i className="fas fa-times" />
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div
                        id="example1_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 table-responsive"
                      >
                        <div className="row">
                          <div className="col-sm-6 col-md-6">
                            <select className="" onChange={ChangePageSize}>
                              <option value="10">10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                            </select>
                          </div>

                          <div className="col-sm-6 col-md-6 text-right">
                            <div
                              id="example1_filter"
                              className="dataTables_filter"
                            >
                              <label>
                                <input
                                  type="search"
                                  className="form-control form-control-sm"
                                  placeholder=""
                                  aria-controls="example1"
                                  onChange={ChangeFilterText}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              id="example1"
                              className="table table-bordered dataTable dtr-inline"
                            >
                              <thead>
                                <tr>
                                  {/* <th>Image</th> */}
                                  <th>Name</th>
                                  <th>Review Star</th>
                                  <th>Review Text</th>
                                  <th>Created On</th>
                                  <th>User</th>
                                  <th>Earing</th>
                                </tr>
                              </thead>
                              <tbody>
                                {campaginreview.length > 0 ? (
                                  campaginreview.map((item) => (
                                    <tr className="odd">
                                      {/* <td><img src={item.ProfileImage} style={{ width: "50px", height: "50px" }} /> </td> */}
                                      {/* <td>{item.ProfileImage}</td>                                                                     */}
                                      <td>{item.CampaignName}</td>
                                      <td>{item.Rating}</td>
                                      <td>{item.ReviewContent}</td>
                                      <td>{formatDate(item.CreatedDate)}</td>
                                      <td>{item.FullName}</td>
                                      <td>
                                        {item.Earning === 0
                                          ? "Individual"
                                          : "Rideshare"}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={6} align="center">
                                      Data not found!
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-sm-6 col-md-6"
                            key={remountComponent}
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={
                                campaginreview.length > 0 ? pageCount : 0
                              }
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              pageClassName={"page-link"}
                              previousLinkClassName={"page-link"}
                              nextLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                        {/* <!-- /.table-responsive --> */}
                      </div>
                    </div>
                  </div>
                )}

                {campaigndetails[0]?.ToolKitId == 12 && (
                  <div className="card card-dark">
                    <div className="card-header">
                      <h3 className="card-title">Feedback Campaign Review</h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus" />
                        </button>
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="remove"
                        >
                          <i className="fas fa-times" />
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="dataTables_wrapper dt-bootstrap4 table-responsive">
                        <div className="row">
                          <div className="col-sm-6 col-md-6">
                            <select
                              className=""
                              onChange={ChangeFeedbackPageSize}
                            >
                              <option value="10">10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                            </select>
                          </div>

                          <div className="col-sm-6 col-md-6 text-right">
                            <div
                              id="example1_filter"
                              className="dataTables_filter"
                            >
                              <label>
                                <input
                                  type="search"
                                  className="form-control form-control-sm"
                                  placeholder=""
                                  aria-controls="example1"
                                  onChange={ChangeFeedbackFilterText}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              id="example1"
                              className="table table-bordered dataTable dtr-inline"
                            >
                              <thead>
                                <tr>
                                  <th>Profile Image</th>
                                  <th>User</th>
                                  <th>City</th>
                                  <th>Beat</th>
                                  <th>Lyrics</th>
                                  <th>Support Artist?</th>
                                  <th>Feedback</th>
                                  <th>Created Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {FeedbackCampaginreview.length > 0 ? (
                                  FeedbackCampaginreview.map((item) => (
                                    <tr className="odd">
                                      <td>
                                        {item.ProfileImage != "" &&
                                        item.ProfileImage != undefined ? (
                                          <>
                                            <a
                                              href={item.ProfileImage}
                                              target="_blank"
                                            >
                                              <img
                                                src={item.ProfileImage}
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  borderRadius: "10px",
                                                }}
                                              />
                                            </a>
                                          </>
                                        ) : (
                                          <a
                                            href={"/dist/img/numella.png"}
                                            target="_blank"
                                          >
                                            <img
                                              src="/dist/img/numella.png"
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                              }}
                                            />
                                          </a>
                                        )}
                                      </td>
                                      <td>
                                        {item.FullName}
                                        <br></br>
                                        {item.EmailId}
                                      </td>
                                      <td>{item.CityName}</td>
                                      <td>{item.Beat}</td>
                                      <td>{item.Lyrics}</td>
                                      <td>
                                        {item.IsSupportArtist == true
                                          ? "YES"
                                          : "NO"}
                                      </td>
                                      <td>{item.Feedback}</td>
                                      <td>{formatDate(item.CreatedDate)}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={6} align="center">
                                      Data not found!
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-sm-6 col-md-6"
                            key={remountFeedbackCampaignComponent}
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={
                                FeedbackCampaginreview.length > 0
                                  ? feedbackpageCount
                                  : 0
                              }
                              onPageChange={changeFeedbackCampaignPage}
                              containerClassName={"pagination"}
                              pageClassName={"page-link"}
                              previousLinkClassName={"page-link"}
                              nextLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                        {/* <!-- /.table-responsive --> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </Fragment>
  );
};

export default CampaignDetails;
