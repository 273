import React from 'react'
import { Pie } from 'react-chartjs-2'

const CityChart = (props) => {
    const labels = props.citydata.map((m) => m.City); //["Canada", "Nigeria", "United Kingdom", "United States"];


    // const jsonData = [props.canadaCount, props.nigeriaCount, props.unitedkingdomCount, props.unitedstatesCount];
    const jsonData = props.citydata.map((m) => m.CityWisePlayCount); //[10, 20, 30, 40, 50];

    const data = {
        labels: labels,
        datasets: [
            {
                label: props.citydata.map((m) => m.City),// "My First dataset",
                // backgroundColor: "rgb(255, 99, 132)",
                // borderColor: "rgb(0,0,255)",
                data: jsonData,
                hoverBackgroundColor: "#00a65a",
                backgroundColor: props.citydata.map((m) => m.color),// ['#f56954', '#00a65a', '#f39c12', '#efefef', '#ff8643'],
            },
        ],
    };
    return (
        <div>
            <Pie data={data} width={600} height={350} options={{ maintainAspectRatio: false }} />
        </div>
    )
}

export default CityChart