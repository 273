import React from "react";
// import { Pie, Doughnut } from "react-chartjs-2";

// import {Chart, ArcElement} from 'chart.js'
// Chart.register(ArcElement);

import Chart from "chart.js/auto";
import { Pie, Doughnut } from "react-chartjs-2";

const CampaignPieChart = (props) => {
    const labels = ["Artist", "Business"];
    
    const jsonData = [props.artistCampaignCount, props.advertiserCampaignCount];
    const  data = {
            labels: labels,
            datasets: [
                {
                    data: jsonData,
                    backgroundColor: ['#b64993', '#4e5ce8'],
                    hoverOffset: 4
                },
            ],
        };

    const options={
        maintainAspectRatio: false, 
        animation: {
            animateScale: true
        }
    }

    return (
        <div>
            {/* <Pie id="campaignsummry" data={data} width={600} height={350} options={{maintainAspectRatio: false}} /> */}

            <Doughnut id="campaignsummry" data={data} width={600} height={350}  options={options} />
        </div>
    );
};
export default CampaignPieChart;